import React, { useReducer, useContext, useState } from 'react'
import './style.scss'

import Dropzone  from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GlobalContext } from '../../context/GlobalState'

import { Form } from 'react-bootstrap'
import { useEffect } from 'react'

import { isFileFiltrable } from '../../utils'
import supportedTypes from './supportedTypes'
import {getIcon, computeNewFiles, uploadedUrls} from './utils'
import reducer from './reducer'

import SectionHeader from './section-header'
import RejectedToast from './rejected-toast'
import FileTable from './file-table'

const FilesUploader = (props) => {
  const {
    maxSize, maxFiles,
    acceptedFormats, filters,
    onFileUploaded, onFileRemoved, onFilterChanged,
    projectId, label
  } = props

  const [files, dispatch] = useReducer(reducer, {})
  const {getUploadUrls} = useContext(GlobalContext)
  const [ignoredFiles, setIgnoredFiles] = useState([])
  const [hasFilterableFile, setHasFilterableFile] = useState(false)

  useEffect(() => {
    if (!Object.keys((files || {})).length) {
      return
    }

    Object.keys(files).forEach(name => {
      // MARK: - Set up differences between xlsx and csv
      if (filters && isFileFiltrable(files[name])) {
        setHasFilterableFile(true)
      }

      dispatch({
        type: 'add_existent_file',
        payload: files[name]
      })
    })
  }, [files, filters])


  // events
  const onFilesDrop = (droppedFiles) =>  {
    const {newFiles, ignoredFiles} = computeNewFiles(files, droppedFiles, maxFiles)
    setIgnoredFiles(ignoredFiles)

    const mappedFiles = newFiles.reduce((files, file) => {
      // MARK: - Set up differences between xlsx and csv
      if (filters && isFileFiltrable(file)) {
        setHasFilterableFile(true)
      }

      files[file.name] = {
        name: file.name,
        icon: <FontAwesomeIcon icon={getIcon(file)} />,
        size: parseInt(Math.ceil(file.size / 1000)) + 'kb',
        originalFile: file,
        uploading: false,
        progress: 0
      }
      return files
    }, {})

    if (newFiles.length) {
      dispatch({
        type: 'add_files',
        payload: mappedFiles
      })

      uploadFiles(newFiles, mappedFiles)
    }
  }

  const uploadFiles = (droppedFiles, mappedFiles) => {
    const onError = (payload) => dispatch({
      type: 'upload_failed',
      payload
    })

    const onProgress = (progress, name) => dispatch({
      type: 'update_progress',
      payload: {
        name,
        progress
      }
    })

    const onCompleted = (file, name, presignedUrl) => {
      dispatch({
        type: 'upload_completed',
        payload: {
          name
        }
      })
      onFileUploaded({
        ...presignedUrl,
        format: file.type,
        size: file.size
      })
    }

    getUploadUrls(projectId, droppedFiles.map(file => file.name))
      .then((presignedUrls) => uploadedUrls(presignedUrls, mappedFiles, onProgress, onCompleted, onError) )
      .catch(() => droppedFiles.forEach((file) => onError(file.name)))
  }

  const onDeleteFile = (e, file) => {
    e.preventDefault()
    onFileRemoved(file.name, file.id)
    
    dispatch({
      type: 'delete_file',
      payload: {
        name: file.name
      }
    })
  }

  const onFilterSelected = (e, file) => {
    const filter = e.target.value
    const filterIsNull = filter === 'no-filter'
    const {name, id} = file

    dispatch({
      type: 'filter_selected',
      payload: { name, filter }
    })
    

    if (onFilterChanged) {
      const parsedFilter = filterIsNull ? null : filter
      onFilterChanged(name, parsedFilter, id)
    }
  }

  const dropzoneAccepts = supportedTypes
    .filter(t => acceptedFormats.indexOf(t.extension.substr(1)) > -1)
    .map(t => t.type)
  

  return <Form.Group>
    <Form.Label>{ label }</Form.Label>
    <Dropzone
      accept={dropzoneAccepts}
      onDrop={onFilesDrop}
      maxSize={maxSize}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, fileRejections }) => 
        <div>
          <SectionHeader 
            getRootProps={getRootProps} 
            getInputProps={getInputProps} 
            isDragActive={isDragActive} 
            isDragAccept={isDragAccept} 
            isDragReject={isDragReject}
            acceptedFormats={acceptedFormats} 
            maxFiles={maxFiles} maxSize={maxSize}
          />

          <RejectedToast 
            fileRejections={fileRejections} 
            ignoredFiles={ignoredFiles} 
            maxFiles={maxFiles} maxSize={maxSize} 
          />

          <FileTable 
            files={files} 
            hasFilterableFile={hasFilterableFile} 
            filters={filters} 
            onFilterSelected={onFilterSelected} 
            onDeleteFile={onDeleteFile}
          />
        </div>
      }
    </Dropzone>
  </Form.Group>
}

export default FilesUploader
