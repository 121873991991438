import React, { useContext, useEffect, useState } from 'react'

import { Form } from 'react-bootstrap'
import Select from 'react-select'
import { selectStylesBlack, selectTheme, noOptionsMessage } from '../../utils'

import { GlobalContext } from '../../context/GlobalState'

export const allProjectsOption = {
  value: '',
  label: 'All projects'
}

const ProjectsDropdown = (props) => {
  const { projects } = useContext(GlobalContext)
  const [projectsDropdownItems, setProjectsDropdownItems] = useState([allProjectsOption])


  useEffect(() => {
    setProjectsDropdownItems([allProjectsOption]
      .concat((props.projects || projects)
        .map(project => {
          return {
            value: project.id,
            label: project.name
          }
        })
      )
    )

    return () => {
      setProjectsDropdownItems([allProjectsOption])
    }
  }, [projects, props.projects])

  return (
    <Form.Group>
      <Form.Label>Project</Form.Label>
      <Select name="project"
        data-dropup-auto="false"
        placeholder="Select project..."
        styles={selectStylesBlack}
        defaultValue={allProjectsOption}
        value={props.value}
        theme={selectTheme}
        className="form-control"
        options={ projectsDropdownItems }
        noOptionsMessage={noOptionsMessage}
        onChange={props.onChange}
      />
    </Form.Group>
  )
}

export default ProjectsDropdown
