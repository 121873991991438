import React, { useState, useContext, useEffect } from 'react'

import { Row, Col, Form, Button, Toast } from 'react-bootstrap'
import Select from 'react-select'

import { GlobalContext } from '../../context/GlobalState'

import Loading from '../loading/Loading'

import { selectStyles, selectTheme, noOptionsMessage } from '../../utils'

const MemberForm = (props) => {
  const { getUsers, addProjectMember } = useContext(GlobalContext)
  const [errorMsg, setErrorMsg] = useState(null)
  const [showError, setShowError] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [users, setUsers] = useState([])
  const [loadingUsers, setLoadingUsers] = useState(false)
  const [userToAdd, setUserToAdd] = useState(null)

  useEffect(() => {
    setLoadingUsers(true)
    getUsers(props.studioId, props.userType)
      .then((data) => {
        setLoadingUsers(false)
        const nonMembers = data
          .filter((user) => {
            return !props.users.find((u) => u.id === user.id)
          })
          .sort((a, b) => {
            return a.username > b.username
              ? 1 : -1
          })
        setUsers(nonMembers)
      })
      .catch(() => setLoadingUsers(false))
    // eslint-disable-next-line
  }, [props.users])

  const onUserSelected = (user) => {
    const selectedUser = users.find((u) => u.id === user.value)
    setUserToAdd(selectedUser)
  }

  const onHide = (member) => {
    props.onHide(member)
  }

  const addMember = (e) => {
    e.preventDefault()

    setSubmitting(true)
    setShowError(false)

    addProjectMember(props.project.id, userToAdd)
      .then((res) => {
        resetValues()
        onHide(res)
      })
      .catch((err) => {
        setSubmitting(false)
        let errorMessage = ''
        try {
          let error = JSON.parse(err.response.text)
          if (error.detail) {
            errorMessage = error.detail
          }
        } catch (e) {
          errorMessage = err.response.text
        }
        setErrorMsg(errorMessage)
        setShowError(!!errorMessage)
      })
  }

  const resetValues = () => {
    setSubmitting(false)
    setErrorMsg(null)
  }

  return (loadingUsers
    ? <Loading />
    : <Form>
      <Toast show={showError}
        onClose={() => setShowError(false)}>
        <Toast.Header>
          <strong className="mr-auto">
            Member couldn't be added
          </strong>
        </Toast.Header>
        <Toast.Body>{ errorMsg }</Toast.Body>
      </Toast>
      { !users.length
        ? <Toast show={true}>
          <Toast.Header closeButton={false}>
            <strong className="mr-auto">
              Can't add new members
            </strong>
          </Toast.Header>
          <Toast.Body>
            All the users are already members of this project
          </Toast.Body>
        </Toast>
        : <Form.Group>
          <Form.Label>User</Form.Label>
          <Select id="user"
            data-dropup-auto="false"
            name="user"
            placeholder="Select user..."
            styles={selectStyles}
            theme={selectTheme}
            className="form-control"
            options={ users.map((user) => {
              return {
                value: user.id,
                label: user.username
              }
            }) }
            noOptionsMessage={noOptionsMessage}
            onChange={onUserSelected}
          />
        </Form.Group>
      }
      <Row>
        <Col>
          <Button id="submit-existent-btn"
            variant="primary"
            type="button"
            onClick={addMember}
            disabled={ submitting || !userToAdd }>
            { submitting
              ? 'SUBMITTING...'
              : 'ADD MEMBER' }
          </Button>
          <Button id="cancel-btn"
            variant="default"
            onClick={() => onHide()}
            disabled={submitting}>
            CANCEL
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default MemberForm
