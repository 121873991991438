import React from 'react'
import './new-request.scss'

import { Controller } from 'react-hook-form'
import cN from "classnames"

import { Form } from 'react-bootstrap'

import { selectStyles, selectTheme, noOptionsMessage } from '../../utils'
import LPSelection from '../lp-selection'


const TargetLanguageSection = (props) => {
    const {control, errors, languages, isDisabled} = props

    const className = cN('form-control', {
        'is-invalid': errors.target_languages
    })
    const options = languages.map((lang) => ({
        value: lang.code,
        label: lang.name
    }))
    const allItem = {
        label: "Add all",
        value: "*"
    }

    return <Form.Group>
        <Form.Label>Target Languages</Form.Label>
        <Controller as={LPSelection} 
                    id="target_languages"
                    name="target_languages"
                    isMulti
                    control={control}
                    placeholder="Select language(s)..."
                    styles={selectStyles}
                    theme={selectTheme}
                    className={className}
                    options={options}
                    allOption= {allItem}
                    noOptionsMessage={noOptionsMessage}
                    isDisabled={isDisabled}
        />
        <Form.Control.Feedback type="invalid">
            {errors.target_languages?.message}
        </Form.Control.Feedback>
    </Form.Group>
}

export default TargetLanguageSection
