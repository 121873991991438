import React from 'react'

import DetailsTab from './tab-details'
import FilesTab from './tab-files'
import HistoryTab from './tab-history'
 
export const tabs = [{
    key: 'details',
    label: 'LR DETAILS',
    content: p => <DetailsTab request={p.request} results={p.results} />
}, {
    key: 'files',
    label: 'LR FILES',
    content: p => <FilesTab request={p.request} results={p.results} />
}, {
    key: 'history',
    label: 'LR HISTORY',
    content: p => <HistoryTab results={p.results} request={p.request} history={p.history} />
}]

export default tabs