import React from 'react'
import LPDateTime from '../../../lp-datetime'
import LPTooltip from '../../../lp-tooltip'
import {languageLabelFromCode} from '../../../../utils'
import '../style.scss'

//
const special_job_statuses = ['unassignable']
const job_status_labels = {
    pending: 'PENDING',
    unassignable: 'UNASSIGNED',
    processing: 'PROCESSING...'
}
//


export function TotalRow (props) {
    const {wordcount, price, currency} = (props?.languages || {})

    return <tr className='table-label prominent'>
        <td>
            <span>Totals</span>
        </td>
        <td>
            <span>{wordcount}</span>
        </td>
        <td>
            <PriceLabel details={{price, currency}} />
        </td>
        <td></td>
    </tr>
}

export function LanguageRow (props) {
    const {details, isKWSPM} = props
    const {created_at, language, wordcount, jobassignment_state, url} = details

    // in case wordcount is undefined, job status is processing
    const isProcessing = wordcount === undefined
    
    // in case user is KWSPM and job status is unassignable, show unassignedDetails
    const showUnassignedDetails = isKWSPM && jobassignment_state === special_job_statuses[0] && !url

    const Assigned = created_at 
        ? <LPDateTime timestamp={created_at} /> 
        : <span>{job_status_labels.pending}</span>

    const Unassigned = <LPTooltip tooltip={
        <span>Some files for this language could not be assigned</span>
    }>
        <span>{job_status_labels.unassignable}</span>
    </LPTooltip>
    const Processing = <span>{job_status_labels.processing}</span>

    const status = isProcessing 
        ? Processing
        : showUnassignedDetails 
            ? Unassigned 
            : Assigned

            
    return <tr>
        <td>{languageLabelFromCode(language)}</td>
        <td>
            <span>{wordcount}</span>
        </td>
        <td>
            <PriceLabel details={details} />
        </td>
        <td className='normal-cursor'>
            {status}
        </td>
    </tr>
}


export function PriceLabel (props) {
    const {details} = props
    const {price, currency} = details || {}

    const displayPrice = price || ' '
    const displayCurrency = currency || ''

    return <span>{displayPrice + ' ' + displayCurrency}</span>
}