/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ProjectServiceLite model module.
 * @module model/ProjectServiceLite
 * @version v1
 */
class ProjectServiceLite {
    /**
     * Constructs a new <code>ProjectServiceLite</code>.
     * @alias module:model/ProjectServiceLite
     * @param service {module:model/ProjectServiceLite.ServiceEnum} 
     * @param memoqName {String} memoq project name
     * @param memoqGuid {String} memoq project guid
     */
    constructor(service, memoqName, memoqGuid) { 
        
        ProjectServiceLite.initialize(this, service, memoqName, memoqGuid);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, service, memoqName, memoqGuid) { 
        obj['service'] = service;
        obj['memoq_name'] = memoqName;
        obj['memoq_guid'] = memoqGuid;
    }

    /**
     * Constructs a <code>ProjectServiceLite</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProjectServiceLite} obj Optional instance to populate.
     * @return {module:model/ProjectServiceLite} The populated <code>ProjectServiceLite</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProjectServiceLite();

            if (data.hasOwnProperty('service')) {
                obj['service'] = ApiClient.convertToType(data['service'], 'String');
            }
            if (data.hasOwnProperty('memoq_name')) {
                obj['memoq_name'] = ApiClient.convertToType(data['memoq_name'], 'String');
            }
            if (data.hasOwnProperty('memoq_guid')) {
                obj['memoq_guid'] = ApiClient.convertToType(data['memoq_guid'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/ProjectServiceLite.ServiceEnum} service
 */
ProjectServiceLite.prototype['service'] = undefined;

/**
 * memoq project name
 * @member {String} memoq_name
 */
ProjectServiceLite.prototype['memoq_name'] = undefined;

/**
 * memoq project guid
 * @member {String} memoq_guid
 */
ProjectServiceLite.prototype['memoq_guid'] = undefined;





/**
 * Allowed values for the <code>service</code> property.
 * @enum {String}
 * @readonly
 */
ProjectServiceLite['ServiceEnum'] = {

    /**
     * value: "lite"
     * @const
     */
    "lite": "lite",

    /**
     * value: "platinum"
     * @const
     */
    "platinum": "platinum"
};



export default ProjectServiceLite;

