import React from 'react'
import cN from 'classnames'


import '../style.scss'


export default function SectionHeader (props) {
    const {
        getRootProps, getInputProps, 
        isDragActive, isDragAccept, isDragReject,
        maxFiles, maxSize, acceptedFormats
    } = props

    const label = Math.floor(maxSize / Math.pow(10, 6))

    return <section {...getRootProps()}
        className={cN('Dropzone', {
            active: isDragActive,
            valid: isDragAccept,
            invalid: isDragReject
        })}>
        <input {...getInputProps()} />

        <i className='onek-drag-drop big mb-3'></i>

        <span>
            Drag files here or click to select (filenames must be unique)
        </span>
        <span>
            Max size per file: {label}MB
            { maxFiles && <span>{' | '} Max files: {maxFiles}</span> }
        </span>
        <span>
            Accepted file types: { acceptedFormats.join(', ') }
        </span>
    </section>
}