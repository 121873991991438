import {configureMenuItems, isProjectPage, isClientPage, isArchivedPage} from './utils'


// generate logic for Client PM routes
export const clientPMRoutes = ({
    currentProject, currentClient,
    toggleClientEdit, toggleProjectEdit, toggleUserEdit,
    history
}, callback) => (pathComponents) => {
    
    configureMenuItems[2].isActive = true
    configureMenuItems[2].onClick = () => toggleUserEdit(true)

    // /project/{projectId}
    if (isProjectPage(pathComponents)) {
        if (!currentProject || !currentClient) {
            return callback([configureMenuItems[2]])
        }

        const itemClient = configureMenuItems[0]
        itemClient.isActive = false
        itemClient.onClick = () => {
            toggleClientEdit(currentClient)
            history.replace('/client/' + currentProject.client)
        }

        const itemProj = configureMenuItems[1]
        itemProj.isActive = true
        itemProj.onClick = () => toggleProjectEdit(currentProject)

        const items = [itemClient, itemProj].concat(configureMenuItems[2])
        return callback(items)
    }

    // / && /dashboard && /client/{clientId} && /client/{clientId}/archived/*
    const clientPage =  !pathComponents[0] ||
                        isClientPage(pathComponents) ||
                        pathComponents[0] === 'dashboard'
    if (clientPage) {
        if (!currentClient) {
            return callback([configureMenuItems[2]])
        }

        const item = configureMenuItems[0]
        item.isActive = true
        item.onClick = () => {
            toggleClientEdit(currentClient)
            if (!pathComponents[2]) { return }

            // /client/{clientId}/*
            history.replace('/client/' + currentClient.id)
        }

        const items = isArchivedPage(pathComponents) ? [] : [item, configureMenuItems[2]]

        return callback(items)
    }

    const items = [configureMenuItems[2]]

    callback(items)
}
