import React from 'react'
import {Button} from 'react-bootstrap'
import LPHeaderCol from './components/lp-header-col/LPHeaderCol'
import LPDateTime from './components/lp-datetime';


// COLUMN TYPES
export const defaultColumn = (dataField, text, opts = {}, icon = 'onek-request') => ({
    dataField,
    text,
    ...opts,
    headerFormatter: (column, _i, { sortElement }) => <LPHeaderCol
        icon={icon}
        text={column.text}
        sortElement={sortElement} />
})

export const buttonColumn = (dataField, text, onclick = function() {}, opts,  icon = 'onek-request') => ({
    dataField,
    text,
    formatter: (cell, ...args) => <Button className='row-button text-left' onClick={e => onclick(e, ...args)}>{cell}</Button>,
    headerFormatter: (column, _i, { sortElement }) => <LPHeaderCol
        icon={icon}
        text={column.text}
        sortElement={sortElement} />,
    ...opts,
})

export const dateTimeColumn = (dataField, text, opts, icon = 'onek-request') => ({
    dataField,
    text,
    ...opts,
    headerClasses: 'datetime',
    formatter: t => <LPDateTime timestamp={t} />,
    headerFormatter: (column, _i, { sortElement }) => <LPHeaderCol
        icon={icon}
        text={column.text}
        sortElement={sortElement} />
})