const letters = [
    // Latin
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'V', 'X', 'Y', 'Z',
    // chinese
    '的', '是', '不', '了', '人', '我', '在', '有', '他', '这', '为', '之', '大', '来', '以', '个', '中', '上', '们'
]
const lettersInGroups = 9


//
export function getRandomLettersGroup() {
    const group = []

    for (let j = 0; j < lettersInGroups; j++) {
        group.push(letters[Math.floor(Math.random() * letters.length)])
    }

    return group
}

export function generateGroups (groupsAmount) {
    const letterGroups = []
  
    for (let i = 0; i < groupsAmount; i++) {
        letterGroups.push(getRandomLettersGroup())
    }
  
    return letterGroups
}

export const capitalize = string => !string ? string : string.charAt(0).toUpperCase() + string.substr(1)