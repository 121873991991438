import React, { useContext, useState, useEffect } from 'react'
import './kws-dashboard.scss'

import { useHistory, useLocation, Switch, Route, Link } from 'react-router-dom'

import { Container, Row, Col, Button, Image } from 'react-bootstrap'

import { GlobalContext } from '../../context/GlobalState'

import ClientsTable from '../clients-table/ClientsTable'
import ClientSettings from '../client-settings/ClientSettings'
import KWSStatistics from '../kws-statistics/KWSStatistics'

const KWSDashboard = () => {
  const history = useHistory()
  const location = useLocation()
  const isStatsPage = location.pathname.endsWith('stats')
  const { 
    user, 
    clients, getClients, clientsNotFound, 
    resetProjectsList, 
    getBpsStudios,
    toggleClientNew, clientNewIsOpen,
  } = useContext(GlobalContext)

  const [studios, setStudios] = useState(null)

  useEffect(() => {
    if (!user?.isKWSPM) {
      history.replace('/')
    } else if (!clients.length && !clientsNotFound) {
      getClients()
    }

    getBpsStudios()
      .then(setStudios)
      .catch(() => setStudios([]))

    resetProjectsList()
    // eslint-disable-next-line
  }, [])

  const onModalHide = () => {
    toggleClientNew(false)
  }

  return (
    <Container fluid className="KWSDashboard">
      <Row className="hero">
        <div className="banner"></div>
        <Col xs className="d-flex flex-column">
          <div className="section-header has-logo">
            <Image src="/assets/images/kws-logo-white.svg"
              alt="KWS Logo" className="logo" fluid/>
            <h1 className="ellipsis">
              { user.is_admin ? 'SUPERUSER' : user.name } DASHBOARD
            </h1>
          </div>
          <Row>
            <Col className="d-flex justify-content-end align-items-end">
              { isStatsPage
                ? <Button id="view-clients-btn"
                  as={Link}
                  to="/dashboard"
                  variant="primary">
                  VIEW CLIENTS
                </Button>
                : <Button id="view-stats-btn"
                  as={Link}
                  to="/dashboard/stats"
                  variant="primary">
                  <i className="onek-statistics" />{' '}
                  VIEW STATISTICS
                </Button>
              }
              { user.is_admin &&
                <Button id="new-client-btn"
                  variant="secondary"
                  onClick={() => toggleClientNew(true)}>
                  <i className="onek-new" />{' '}
                  NEW CLIENT
                </Button>
              }
            </Col>
          </Row>
        </Col>
      </Row>
      <Switch>
        <Route exact path="/dashboard">
          <ClientsTable studios={studios} />
        </Route>
        <Route exact path="/dashboard/stats">
          <KWSStatistics />
        </Route>
            </Switch>
      <ClientSettings
        id="new-client-modal"
        show={clientNewIsOpen}
        onHide={onModalHide} />
    </Container>
  )
}

export default KWSDashboard
