import {parseAssignments} from './events'
import {parseGeneralInfo} from './info'
import {dateLabel} from './utils'

/*
    draft_created_at: "2022-01-07T09:42:17.325506Z"
    files: {
        Loki2: ["gtgtgtt.html"]
    }
    full_language_name: "Arabic - Modern Standard"
    id: 474
    invoiced_at: null
    locreq_id: 994
    project_name: "E-Line - Beyond Blue - 2020_EnUS_01"
    review_accepted: {}
    review_assigned: {}
    review_delivered: {}
    review_not_responded: {}
    review_rejected: {}
    service: "lite"
    started_at: "2022-01-07T09:42:27.201620Z"
    started_by: "superuser"
    translation_accepted: {}
    translation_assigned: {
        Loki2: {gtgtgtt.html: "2022-01-07 09:43:25.767378+00:00"}
        Loki6: {gtgtgtt.html: "2022-01-07 09:45:35.828405+00:00"}
    }
    translation_delivered: {}
    translation_not_responded: {}
    translation_rejected: {
        Loki2: {gtgtgtt.html: "2022-01-07 09:45:21.000805+00:00"}
        Loki6: {gtgtgtt.html: "2022-01-07 09:46:57.536489+00:00"}
    }
*/

/**
 * event: {
 *  type: 'translation_assigned,
 *  user: 'Loki2',
 *  file: 'gtgtgtt.html' //job?
 *  timestamp: '2022-01-07 09:46:57.536489+00:00'
 * }
 * 
 * // ordered cronologically //scratch that, they already are
 * // grouped by file (job)
 */

//
export const generateHistoryContent = (languageHistory, request) => [
    // 1: General info
    parseGeneralInfo(languageHistory, request),
    // 2: Assignments history
    parseJobs(languageHistory)
].join('\n')

const parseJobs = languageHistory => {
    const {invoiced_at} = languageHistory
    const assignments = parseAssignments(languageHistory)

    const invoicedLabel = invoiced_at ? 'Invoiced: ' + dateLabel(invoiced_at) : ''
    return assignments.join('\n') + '\n\n' + invoicedLabel
}

//
export const generateFile = (filename, content) => {
    const element = document.createElement('a');
    const encodedHref = 'data:text/plain;charset=utf-8,' + encodeURIComponent(content)

    element.setAttribute('href', encodedHref);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}