/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The StatisticsWordcountChart model module.
 * @module model/StatisticsWordcountChart
 * @version v1
 */
class StatisticsWordcountChart {
    /**
     * Constructs a new <code>StatisticsWordcountChart</code>.
     * @alias module:model/StatisticsWordcountChart
     * @param date {Date} 
     * @param count100 {Number} 
     * @param count101 {Number} 
     * @param countTotal {Number} 
     * @param countNoMatch {Number} 
     * @param count5074 {Number} 
     * @param count7584 {Number} 
     * @param count8594 {Number} 
     * @param count9599 {Number} 
     * @param countFragments {Number} 
     * @param countRepetitions {Number} 
     * @param countXTranslated {Number} 
     */
    constructor(date, count100, count101, countTotal, countNoMatch, count5074, count7584, count8594, count9599, countFragments, countRepetitions, countXTranslated) { 
        
        StatisticsWordcountChart.initialize(this, date, count100, count101, countTotal, countNoMatch, count5074, count7584, count8594, count9599, countFragments, countRepetitions, countXTranslated);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, date, count100, count101, countTotal, countNoMatch, count5074, count7584, count8594, count9599, countFragments, countRepetitions, countXTranslated) { 
        obj['date'] = date;
        obj['count_100'] = count100;
        obj['count_101'] = count101;
        obj['count_total'] = countTotal;
        obj['count_no_match'] = countNoMatch;
        obj['count_50_74'] = count5074;
        obj['count_75_84'] = count7584;
        obj['count_85_94'] = count8594;
        obj['count_95_99'] = count9599;
        obj['count_fragments'] = countFragments;
        obj['count_repetitions'] = countRepetitions;
        obj['count_x_translated'] = countXTranslated;
    }

    /**
     * Constructs a <code>StatisticsWordcountChart</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/StatisticsWordcountChart} obj Optional instance to populate.
     * @return {module:model/StatisticsWordcountChart} The populated <code>StatisticsWordcountChart</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new StatisticsWordcountChart();

            if (data.hasOwnProperty('date')) {
                obj['date'] = ApiClient.convertToType(data['date'], 'Date');
            }
            if (data.hasOwnProperty('count_100')) {
                obj['count_100'] = ApiClient.convertToType(data['count_100'], 'Number');
            }
            if (data.hasOwnProperty('count_101')) {
                obj['count_101'] = ApiClient.convertToType(data['count_101'], 'Number');
            }
            if (data.hasOwnProperty('count_total')) {
                obj['count_total'] = ApiClient.convertToType(data['count_total'], 'Number');
            }
            if (data.hasOwnProperty('count_no_match')) {
                obj['count_no_match'] = ApiClient.convertToType(data['count_no_match'], 'Number');
            }
            if (data.hasOwnProperty('count_50_74')) {
                obj['count_50_74'] = ApiClient.convertToType(data['count_50_74'], 'Number');
            }
            if (data.hasOwnProperty('count_75_84')) {
                obj['count_75_84'] = ApiClient.convertToType(data['count_75_84'], 'Number');
            }
            if (data.hasOwnProperty('count_85_94')) {
                obj['count_85_94'] = ApiClient.convertToType(data['count_85_94'], 'Number');
            }
            if (data.hasOwnProperty('count_95_99')) {
                obj['count_95_99'] = ApiClient.convertToType(data['count_95_99'], 'Number');
            }
            if (data.hasOwnProperty('count_fragments')) {
                obj['count_fragments'] = ApiClient.convertToType(data['count_fragments'], 'Number');
            }
            if (data.hasOwnProperty('count_repetitions')) {
                obj['count_repetitions'] = ApiClient.convertToType(data['count_repetitions'], 'Number');
            }
            if (data.hasOwnProperty('count_x_translated')) {
                obj['count_x_translated'] = ApiClient.convertToType(data['count_x_translated'], 'Number');
            }
            if (data.hasOwnProperty('count_adjusted')) {
                obj['count_adjusted'] = ApiClient.convertToType(data['count_adjusted'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} date
 */
StatisticsWordcountChart.prototype['date'] = undefined;

/**
 * @member {Number} count_100
 */
StatisticsWordcountChart.prototype['count_100'] = undefined;

/**
 * @member {Number} count_101
 */
StatisticsWordcountChart.prototype['count_101'] = undefined;

/**
 * @member {Number} count_total
 */
StatisticsWordcountChart.prototype['count_total'] = undefined;

/**
 * @member {Number} count_no_match
 */
StatisticsWordcountChart.prototype['count_no_match'] = undefined;

/**
 * @member {Number} count_50_74
 */
StatisticsWordcountChart.prototype['count_50_74'] = undefined;

/**
 * @member {Number} count_75_84
 */
StatisticsWordcountChart.prototype['count_75_84'] = undefined;

/**
 * @member {Number} count_85_94
 */
StatisticsWordcountChart.prototype['count_85_94'] = undefined;

/**
 * @member {Number} count_95_99
 */
StatisticsWordcountChart.prototype['count_95_99'] = undefined;

/**
 * @member {Number} count_fragments
 */
StatisticsWordcountChart.prototype['count_fragments'] = undefined;

/**
 * @member {Number} count_repetitions
 */
StatisticsWordcountChart.prototype['count_repetitions'] = undefined;

/**
 * @member {Number} count_x_translated
 */
StatisticsWordcountChart.prototype['count_x_translated'] = undefined;

/**
 * @member {Number} count_adjusted
 */
StatisticsWordcountChart.prototype['count_adjusted'] = undefined;






export default StatisticsWordcountChart;

