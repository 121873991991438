/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The LocalizationRequestStateUpdate model module.
 * @module model/LocalizationRequestStateUpdate
 * @version v1
 */
class LocalizationRequestStateUpdate {
    /**
     * Constructs a new <code>LocalizationRequestStateUpdate</code>.
     * @alias module:model/LocalizationRequestStateUpdate
     * @param stateOld {module:model/LocalizationRequestStateUpdate.StateOldEnum} 
     * @param stateNew {module:model/LocalizationRequestStateUpdate.StateNewEnum} 
     */
    constructor(stateOld, stateNew) { 
        
        LocalizationRequestStateUpdate.initialize(this, stateOld, stateNew);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, stateOld, stateNew) { 
        obj['state_old'] = stateOld;
        obj['state_new'] = stateNew;
    }

    /**
     * Constructs a <code>LocalizationRequestStateUpdate</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/LocalizationRequestStateUpdate} obj Optional instance to populate.
     * @return {module:model/LocalizationRequestStateUpdate} The populated <code>LocalizationRequestStateUpdate</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LocalizationRequestStateUpdate();

            if (data.hasOwnProperty('state_old')) {
                obj['state_old'] = ApiClient.convertToType(data['state_old'], 'String');
            }
            if (data.hasOwnProperty('state_new')) {
                obj['state_new'] = ApiClient.convertToType(data['state_new'], 'String');
            }
            if (data.hasOwnProperty('subject')) {
                obj['subject'] = ApiClient.convertToType(data['subject'], 'String');
            }
            if (data.hasOwnProperty('body')) {
                obj['body'] = ApiClient.convertToType(data['body'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/LocalizationRequestStateUpdate.StateOldEnum} state_old
 */
LocalizationRequestStateUpdate.prototype['state_old'] = undefined;

/**
 * @member {module:model/LocalizationRequestStateUpdate.StateNewEnum} state_new
 */
LocalizationRequestStateUpdate.prototype['state_new'] = undefined;

/**
 * required if cancelling LR
 * @member {String} subject
 */
LocalizationRequestStateUpdate.prototype['subject'] = undefined;

/**
 * required if cancelling LR
 * @member {String} body
 */
LocalizationRequestStateUpdate.prototype['body'] = undefined;





/**
 * Allowed values for the <code>state_old</code> property.
 * @enum {String}
 * @readonly
 */
LocalizationRequestStateUpdate['StateOldEnum'] = {

    /**
     * value: "draft"
     * @const
     */
    "draft": "draft",

    /**
     * value: "draft_to_ongoing"
     * @const
     */
    "draft_to_ongoing": "draft_to_ongoing",

    /**
     * value: "ongoing"
     * @const
     */
    "ongoing": "ongoing",

    /**
     * value: "delivered"
     * @const
     */
    "delivered": "delivered",

    /**
     * value: "closed"
     * @const
     */
    "closed": "closed",

    /**
     * value: "cancelled"
     * @const
     */
    "cancelled": "cancelled",

    /**
     * value: "ongoing_to_cancelled"
     * @const
     */
    "ongoing_to_cancelled": "ongoing_to_cancelled",

    /**
     * value: "invoiced"
     * @const
     */
    "invoiced": "invoiced"
};


/**
 * Allowed values for the <code>state_new</code> property.
 * @enum {String}
 * @readonly
 */
LocalizationRequestStateUpdate['StateNewEnum'] = {

    /**
     * value: "draft"
     * @const
     */
    "draft": "draft",

    /**
     * value: "draft_to_ongoing"
     * @const
     */
    "draft_to_ongoing": "draft_to_ongoing",

    /**
     * value: "ongoing"
     * @const
     */
    "ongoing": "ongoing",

    /**
     * value: "delivered"
     * @const
     */
    "delivered": "delivered",

    /**
     * value: "closed"
     * @const
     */
    "closed": "closed",

    /**
     * value: "cancelled"
     * @const
     */
    "cancelled": "cancelled",

    /**
     * value: "ongoing_to_cancelled"
     * @const
     */
    "ongoing_to_cancelled": "ongoing_to_cancelled",

    /**
     * value: "invoiced"
     * @const
     */
    "invoiced": "invoiced"
};



export default LocalizationRequestStateUpdate;

