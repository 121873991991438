import axios from 'axios'
import supportedTypes from './supportedTypes'

export const getExtension = (file) => {
    return file.name
      .substr(file.name.lastIndexOf('.'))
      .toLowerCase()
}
  
export const defaultIcon = 'file-alt'

export const getIcon = (file) => {
    const type = supportedTypes
        .find(t => t.extension === getExtension(file))

    return type ? type.icon : defaultIcon
}

export const computeNewFiles = (files, droppedFiles, maxFiles) => {
    const filesNames = Object.keys(files)
    const ignoredFiles = []
    let newFiles = []

    if ((filesNames.length + droppedFiles.length) > maxFiles) {
      let limitNewFiles = maxFiles - filesNames.length
      let newFilesCount = 0

      droppedFiles.forEach((file) => {
        if (filesNames.indexOf(file.name) > -1) {
          newFiles.push(file)
        } else if (newFilesCount < limitNewFiles) {
          newFiles.push(file)
          newFilesCount++
        } else {
            ignoredFiles.concat(file)
        }
      })
    } else {
      newFiles = droppedFiles
    }

    return {newFiles, ignoredFiles}
}

export function uploadedUrls(presignedUrls, mappedFiles, onProgress, onCompleted, onError) {
    presignedUrls.urls.forEach(presignedUrl => {
        const fields = presignedUrl.fields
        const fileName = presignedUrl.name

        if (fileName && mappedFiles[fileName]) {
        const file = mappedFiles[fileName].originalFile
        const formData = new FormData()
        formData.append('Key', fields.key)
        formData.append('AWSAccessKeyId', fields.AWSAccessKeyId)
        formData.append('policy', fields.policy)
        formData.append('signature', fields.signature)
        formData.append('Content-Type', file.type)

        if (fields.hasOwnProperty('x-amz-security-token')) {
            formData.append('x-amz-security-token', fields['x-amz-security-token'])
        }

        formData.append('file', file)

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress: function(progressEvent) {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)

                onProgress(progress, fileName)
                // DISPATCH on progress
                /*
                dispatch({
                    type: 'update_progress',
                    payload: {
                    name: fileName,
                    progress: progress
                    }
                })
                /** */
            }
        }

        axios.post(presignedUrl.url, formData,config)
            .then(() => {
                onCompleted(file, fileName, presignedUrl)
                /*
                dispatch({
                    type: 'upload_completed',
                    payload: {
                    name: fileName
                    }
                })
                props.onFileUploaded({
                    ...presignedUrl,
                    format: file.type,
                    size: file.size
                })
                /** */
            })
            .catch((e) => {
                onError(e, fileName)
                /*
                dispatch({
                    type: 'upload_failed',
                    payload: fileName
                })
                /** */
            })
        }
    })
}