/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import S3PresignedURL from './S3PresignedURL';

/**
 * The ClientUpdate model module.
 * @module model/ClientUpdate
 * @version v1
 */
class ClientUpdate {
    /**
     * Constructs a new <code>ClientUpdate</code>.
     * @alias module:model/ClientUpdate
     */
    constructor() { 
        
        ClientUpdate.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ClientUpdate</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ClientUpdate} obj Optional instance to populate.
     * @return {module:model/ClientUpdate} The populated <code>ClientUpdate</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ClientUpdate();

            if (data.hasOwnProperty('logo')) {
                obj['logo'] = S3PresignedURL.constructFromObject(data['logo']);
            }
            if (data.hasOwnProperty('banner')) {
                obj['banner'] = S3PresignedURL.constructFromObject(data['banner']);
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/S3PresignedURL} logo
 */
ClientUpdate.prototype['logo'] = undefined;

/**
 * @member {module:model/S3PresignedURL} banner
 */
ClientUpdate.prototype['banner'] = undefined;






export default ClientUpdate;

