import React, { useContext, useEffect } from 'react'
import './background.scss'

import { GlobalContext } from '../../context/GlobalState'
import cN from 'classnames'

import { getRandomLettersGroup } from '../../utils'

let localLettersGroups = []

const Background = (props) => {
  const { user, lettersGroups } = useContext(GlobalContext)

  useEffect(() => {
    localLettersGroups = JSON.parse(JSON.stringify(lettersGroups))
  }, [lettersGroups])

  function getGroup (index) {
    if (!user && props.activeGroup === index) {
      return getRandomLettersGroup()
    }

    return localLettersGroups[index]
  }

  return (
    <div id="background">
      {
        localLettersGroups.map((_group, i) => {
          return (
            <div className={cN('letter-group', {
              'highlightable': !user
            })}
              key={`group-${Math.random()}`}
              id={`group-${i}`}>
              { getGroup(i).map((letter) => {
                return (
                  <div className="letter" key={`letter-${Math.random()}`}>
                    { letter }
                  </div>
                )
              })}
            </div>
          )
        })
      }
    </div>
  )
}

export default Background
