import React, { useContext } from 'react'
import { Table, Button, Spinner, Row } from 'react-bootstrap'

import { GlobalContext } from '../../../../context/GlobalState'

import ResourceRow from './resourceRow'
import { resourceRoles } from '../../../../utils'

const resourceRoleParser = value => id => ({
    id,
    can_translate: value === resourceRoles[0].value,
    can_review: value === resourceRoles[1].value
})


const ResourceTable = props => {
    const {
        project, service, resources,
        setErrorMsg, setResources, 
    } = props
    const { 
        updateResource, updatingResources, setResourcesIsSyncing,resourceChanges, setResourceChanges
    } = useContext(GlobalContext)

    if (!resources) {
        return <></>
    }

    const onSubmittingChanges = () => {
        setResourcesIsSyncing(service)
        const body = Object.keys(resourceChanges).map(id => {
            const newRole = resourceChanges[id]
            return resourceRoleParser(newRole)(id)
        })

        updateResource(project.id, service, body)
          .then((updatedResources) => {
            setResources(updatedResources)
            setResourceChanges()
          })
          .catch(() => {
            setErrorMsg(`resources role couldn't be updated`)
          })
          .finally(() => setResourcesIsSyncing(false))
    }

    const onRoleSelected = resource => changedRole => {
        setErrorMsg(null)

        const id = resource.ids[0]
        // check resource value and changed value
        const value = resource.role === changedRole ? false : changedRole
        setResourceChanges({id, value})
    }

    const buttonDisabled = !Object.keys(resourceChanges).length || updatingResources
    
    return <>
        <Table striped className="small">
            <thead>
                <tr>
                    <th>MemoQ Account</th>
                    <th>Full Name</th>
                    <th className="w-100">Target Languages</th>
                    <th>Role</th>
                </tr>
            </thead>
            <tbody>
            { resources.map(resource => <ResourceRow 
                key={resource.memoq_username} 
                resource={{...resource, role: resourceChanges[resource.ids[0]] || resource.role}} 
                service={service}
                onRoleSelected={onRoleSelected(resource)} 
                />
            )}
            </tbody>
        </Table>
        <Button variant="primary"
            type="button"
            onClick={onSubmittingChanges}
            disabled={buttonDisabled}>

            {updatingResources 
                ? <Row>
                    <Spinner animation='border' 
                        className='d-flex justify-content-center'
                        style={{
                            width: '20px', height: '20px', 
                            marginLeft: '5px', marginRight: '5px', marginTop: '0px'
                        }} 
                    />
                  <span style={{ marginRight: '5px' }}>SAVING...</span>
                </Row>
                : <span>SAVE CHANGES</span>
            }
        </Button>
    </>
}

export default ResourceTable