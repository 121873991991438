export const configureMenuItems = [{
    id: 'client',
    label: 'Client settings',
}, {
    id: 'project',
    label: 'Project settings',
}, {
    id: 'account',
    label: 'My Account',
    isActive: true
}]


//
// /project/{projectId} && /project/{projectId}/*
export const isProjectPage  = pathComponents => pathComponents[0] === 'project'
// /client/{clientId} && /client/{clientId}/*
export const isClientPage   = pathComponents => pathComponents[0] === 'client'
// /client/{clientId}/archived
export const isArchivedPage = pathComponents => pathComponents[2] === 'archived'
