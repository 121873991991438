import ApiClient from '../api-client/src/ApiClient'
import AuthApi from '../api-client/src/api/AuthApi'
import UsersApi from '../api-client/src/api/UsersApi'
import ProjectsApi from '../api-client/src/api/ProjectsApi'
import ClientsApi from '../api-client/src/api/ClientsApi'
import DashboardApi from '../api-client/src/api/DashboardApi'
import BpsApi from '../api-client/src/api/BpsApi'
import MemoqApi from '../api-client/src/api/MemoqApi'
import InternalApi from '../api-client/src/api/InternalApi'

export const APIClient = new ApiClient()
export const AuthAPI = new AuthApi(APIClient)
export const UsersAPI = new UsersApi(APIClient)
export const ProjectsAPI = new ProjectsApi(APIClient)
export const ClientsAPI = new ClientsApi(APIClient)
export const DashboardAPI = new DashboardApi(APIClient)
export const BpsAPI = new BpsApi(APIClient)
export const MemoqAPI = new MemoqApi(APIClient)
export const InternalAPI = new InternalApi(APIClient)