export const checkForUnassignedFiles = (ProjectsAPI, refreshTokenPipe) => {
    const foo = (projectId) => new Promise((resolve, reject) => {
        ProjectsAPI.projectsCheckMemoqTranslationsCreate(projectId, (err, data) => {
            refreshTokenPipe(err)
            .then((reAuthenticated) => {
                if (reAuthenticated) {
                    foo(projectId)
                    .then(resolve, reject)
                    return
                } 
                
                if (err) {
                    reject(err)
                    return
                }
                    
                resolve(data)
            })
        })
    })

    return foo
}
