import {tranlationEvents, reviewEvents} from './utils'

/** Source

    files: {
        Loki2: ["gtgtgtt.html"]
    }
    translation_assigned: {
        Loki2: {
            gtgtgtt.html: "2022-01-07 09:43:25.767378+00:00"
            gtgtg3t.html: "2022-01-07 09:43:25.767378+00:00"
        }
        Loki6: {gtgtgtt.html: "2022-01-07 09:45:35.828405+00:00"}
    }
    translation_rejected: {
        Loki2: {gtgtgtt.html: "2022-01-07 09:45:21.000805+00:00"}
        Loki6: {gtgtgtt.html: "2022-01-07 09:46:57.536489+00:00"}
    }
    translation_delivered: {}
*/


/** Result 

    '
        Translation Job 1
        Files: gtgtgtt.html

            Assigned to: Loki2
            on: 2022-01-07 09:43:25

            Rejected by: Loki2
            on: 2022-01-07 09:45:21

            Re-assigned to: Loki6
            ...
    '
*/

const tranlationEventsReduce = (languageHistory, reduce, res = {}) => {
    /** example of specificEvents
        {
            Loki2: {gtgtgtt.html: "2022-01-07 09:43:25.767378+00:00"}
            Loki6: {gtgtgtt.html: "2022-01-07 09:45:35.828405+00:00"}
        } 
    */
    Object.keys(tranlationEvents).forEach(type => {
        const specificEvents = languageHistory[type]
        Object.keys(specificEvents).forEach(user => {
            const files = specificEvents[user]
            res = reduce({files, type, user}, res)
        })

        return res
    })

    return res
}

const reviewEventsReduce = (languageHistory, reduce, res = {}) => {
    /** example of specificEvents
        {
            Loki2: {gtgtgtt.html: "2022-01-07 09:43:25.767378+00:00"}
            Loki6: {gtgtgtt.html: "2022-01-07 09:45:35.828405+00:00"}
        } 
    */
    Object.keys(reviewEvents).forEach(type => {
        const specificEvents = languageHistory[type]
        Object.keys(specificEvents).forEach(user => {
            const files = specificEvents[user]
            res = reduce({files, type, user}, res)
        })

        return res
    })

    return res
}



/** Example
    {
        'gtgtgtt.html': [{
            type: Assigned
            user: Loki2
            timestamp: 2022-01-07 09:43:25,
            ifFirst: true
        }]
    }
*/
const groupByJob = languageHistory => {
    const translation = tranlationEventsReduce(languageHistory, ({files, type, user}, acc) => {
        const jobId = Object.keys(files).join(', ')
        acc[jobId] = acc[jobId] || []

        const first = Object.keys(files)[0]
        const timestamp = files[first]
        const isFirst = !acc[jobId].length
        acc[jobId].push({type, user, timestamp, isFirst})

        return acc
    })

    const review = reviewEventsReduce(languageHistory, ({files, type, user}, acc) => {
        const jobId = Object.keys(files).join(', ')
        acc[jobId] = acc[jobId] || []

        const first = Object.keys(files)[0]
        const timestamp = files[first]
        const isFirst = !acc[jobId].length
        acc[jobId].push({type, user, timestamp, isFirst})

        return acc
    })

    return [translation, review]
}


// parsers
export const parseAssignments = languageHistory => {
    const assignments = []
    const [translation, review] = groupByJob(languageHistory)

    Object.keys(translation).forEach((files, i) => {
        const job = translation[files]
        const jobEvents = []
        const ordered = []
        /*
            {
                'gtgtgtt.html': [{
                    type: Assigned
                    user: Loki2
                    timestamp: 2022-01-07 09:43:25,
                    ifFirst: true
                }]
            }
        /** */

        // job general info
        const jobLabel = '\n\nTranslation job ' + (i + 1)
        const filesLabel = 'Files: ' + files

        // job events
        job.forEach(event => {
            const {user, isFirst, timestamp, type} = event
            const label = tranlationEvents[type]({user, timestamp}, isFirst)
            jobEvents.push({label, timestamp})
        })

        // jobevent sort
        jobEvents.sort((j1, j2) => new Date(j1.timestamp).getTime() - new Date(j2.timestamp).getTime())
        jobEvents.forEach(j => ordered.push(j.label))

        assignments.push([jobLabel, filesLabel, ...ordered].join('\n'))
    })

    Object.keys(review).forEach((files, i) => {
        const job = review[files]
        const jobEvents = []
        const ordered = []

        // job general info
        const jobLabel = '\n\nReview job ' + (i + 1)
        const filesLabel = 'Files: ' + files

        // job events
        job.forEach(event => {
            const {user, isFirst, timestamp, type} = event
            const label = reviewEvents[type]({user, timestamp}, isFirst)
            jobEvents.push({label, timestamp})
        })

        // jobevent sort
        jobEvents.sort((j1, j2) => new Date(j1.timestamp).getTime() - new Date(j2.timestamp).getTime())
        jobEvents.forEach(j => ordered.push(j.label))

        assignments.push([jobLabel, filesLabel, ...ordered].join('\n'))
    })

    return assignments
}
