import React, { useState, useContext, useEffect } from 'react'

import { Modal, Row, Col, Tab, Nav, Toast } from 'react-bootstrap'
import cN from 'classnames'
import { toast } from 'react-toastify'

import { GlobalContext } from '../../context/GlobalState'
import ClientForm from './ClientForm'
import Users from './Users'
import Contacts from './Contacts'


const ClientSettings = (props) => {
  const { 
    user, 
    getClients, setCurrentClient, 
    updatingResources, setResourcesIsSyncing 
  } = useContext(GlobalContext)
  const defaultActiveTab = user && user.isKWSPM
    ? 'info'
    : 'users'
  const [activeTab, setActiveTab] = useState(defaultActiveTab)
  const [editMode, setEditMode] = useState(false)
  const [client, setClient] = useState(null)
  const [showSuccess, setShowSuccess] = useState(false)

  useEffect(() => {
    if (props.client) {
      setEditMode(true)
      setClient(props.client)
    }
  }, [props.client,activeTab])

  useEffect(() => {
    if (!props.show && !props.client) {
      setEditMode(false)
      setClient(null)
      setShowSuccess(false)
      setActiveTab(defaultActiveTab)
    }
  }, [props.show, props.client, defaultActiveTab])

  const selectTab = (key) => {
    setActiveTab(key)
  }

  const onFormSubmitted = (client) => {
    if (!editMode) {
      setEditMode(true)
      setShowSuccess(true)
      toast.success(`Client ${client.name} successfully created`)
      // Update client's list
      getClients()
      .then(() => {
        setCurrentClient(client)
        setResourcesIsSyncing(false)
      })
    } else {
      toast.success(`Client ${client.name} successfully updated`)
      
      setCurrentClient(client)
      setResourcesIsSyncing(false)
    }

    setClient(client)
  }


  return (
    <Modal show={props.show} onHide={props.onHide}
      centered scrollable size={'lg'}
      backdrop='static'
      dialogClassName="tabbed-modal">
      <Modal.Header closeButton={!updatingResources}>
        <Modal.Title>
          { editMode ? `${client?.name} Settings` : 'Create a new Client' }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tab.Container
          activeKey={activeTab}
          onSelect={(k) => selectTab(k)}>
          <Row className="flex-nowrap">
            <Col sm="auto" className="tabs">
              <Nav className="flex-row flex-lg-column">
                <Nav.Item>
                  <Nav.Link eventKey="info"
                    disabled={updatingResources}
                    className={cN({ 'd-none': !user?.isKWSPM })}>
                    CLIENT INFO
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="users"
                    className={cN({ 'd-none': !user?.isKWSPM })}
                    disabled={!editMode || updatingResources}>
                    TEAM MEMBERS
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="contacts"
                    className={cN({ 'd-none': !user?.isKWSPM })}
                    disabled={!editMode || updatingResources}>
                    KWS PMs
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col className="tab-container">
              <Tab.Content>
                <Tab.Pane eventKey="info">
                <Toast show={showSuccess}
                    className="success"
                    onClose={() => setShowSuccess(false)}>
                    <Toast.Header>
                      <strong className="mr-auto">
                        Client created
                      </strong>
                    </Toast.Header>
                    <Toast.Body>
                      Now you can
                      <button className="link"
                        onClick={() => {
                          setShowSuccess(false)
                          setActiveTab('users')
                        }}>
                        add team members
                      </button>
                      or
                      <button className="link"
                        onClick={() => {
                          setShowSuccess(false)
                          setActiveTab('contacts')
                        }}>
                        bring more KWS PMs
                      </button>to this client
                    </Toast.Body>
                  </Toast>
                  <ClientForm onSubmit={onFormSubmitted}
                    client={client}
                    onCancel={props.onHide} />
                </Tab.Pane>
                <Tab.Pane eventKey="users">
                  <Users
                    client={client}
                    activeTab={activeTab} />
                </Tab.Pane>
                <Tab.Pane eventKey="contacts">
                  <Contacts client={client} activeTab={activeTab} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  )
}

export default ClientSettings
