/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Studio model module.
 * @module model/Studio
 * @version v1
 */
class Studio {
    /**
     * Constructs a new <code>Studio</code>.
     * @alias module:model/Studio
     * @param id {String} 
     * @param label {String} 
     * @param location {String} 
     * @param languageStatus {String} 
     */
    constructor(id, label, location, languageStatus) { 
        
        Studio.initialize(this, id, label, location, languageStatus);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, label, location, languageStatus) { 
        obj['id'] = id;
        obj['label'] = label;
        obj['location'] = location;
        obj['language_status'] = languageStatus;
    }

    /**
     * Constructs a <code>Studio</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Studio} obj Optional instance to populate.
     * @return {module:model/Studio} The populated <code>Studio</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Studio();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('label')) {
                obj['label'] = ApiClient.convertToType(data['label'], 'String');
            }
            if (data.hasOwnProperty('location')) {
                obj['location'] = ApiClient.convertToType(data['location'], 'String');
            }
            if (data.hasOwnProperty('language_status')) {
                obj['language_status'] = ApiClient.convertToType(data['language_status'], 'String');
            }
            if (data.hasOwnProperty('last_sync_at')) {
                obj['last_sync_at'] = ApiClient.convertToType(data['last_sync_at'], 'Date');
            }
        }
        return obj;
    }


}

/**
 * @member {String} id
 */
Studio.prototype['id'] = undefined;

/**
 * @member {String} label
 */
Studio.prototype['label'] = undefined;

/**
 * @member {String} location
 */
Studio.prototype['location'] = undefined;

/**
 * @member {String} language_status
 */
Studio.prototype['language_status'] = undefined;

/**
 * last sync time
 * @member {Date} last_sync_at
 */
Studio.prototype['last_sync_at'] = undefined;






export default Studio;

