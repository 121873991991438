import React from 'react'

import UserMenu from './user-menu'
import GoToMenu from './goto-menu'
import CreateMenu from './create-menu'
import ConfigureMenu from './configure-menu'

// Component
const NavigationMenus = () => {
    return <div className="d-flex">
        <GoToMenu />
        <CreateMenu />
        <ConfigureMenu />
        <UserMenu />
    </div>
}

export default NavigationMenus