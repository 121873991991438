export const supportedTypes = [{
  type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  icon:'file-word',
  extension: '.docx'
}, {
  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  icon: 'file-excel',
  extension: '.xlsx'
}, {
  type: 'text/plain',
  icon: 'file-alt',
  extension: '.txt'
}, {
  type: 'text/html',
  icon: 'file-alt',
  extension: '.html'
}, {
  type: 'application/json',
  icon: 'file-alt',
  extension: '.json'
}, {
  type: 'application/pdf',
  icon: 'file-pdf',
  extension: '.pdf'
}, {
  type: 'application/vnd.ms-powerpoint',
  icon: 'file-powerpoint',
  extension: '.ppt'
}, {
  type: 'image/jpeg',
  icon: 'file-image',
  extension: '.jpg'
}, {
  type: 'image/png',
  icon: 'file-image',
  extension: '.png'
}, {
  type: '.csv',
  icon: 'file-csv',
  extension: '.csv'
}, {
  type: '.srt',
  icon: 'file-alt',
  extension: '.srt'
}, {
  type: '.xlf',
  icon: 'file-code',
  extension: '.xlf'
}, {
  type: '.xliff',
  icon: 'file-code',
  extension: '.xliff'
}, {
  type: '.yml',
  icon: 'file-alt',
  extension: '.yml'
}, {
  type: 'video/mp4',
  icon: 'file-video',
  extension: '.mp4'
}, {
  type: '.vob',
  icon: 'file-video',
  extension: '.vob'
}, {
  type: 'video/avi',
  icon: 'file-video',
  extension: '.avi'
}
]

export default supportedTypes