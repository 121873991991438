import React, { useState, useContext, useEffect } from 'react'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from "yup"
import cN from "classnames"

import { Row, Col, Form, Button, Toast } from 'react-bootstrap'

import { GlobalContext } from '../../context/GlobalState'

import { capitalize } from '../../utils'

const UserForm = (props) => {
  const { createUser, editUser } = useContext(GlobalContext)
  const [errorMsg, setErrorMsg] = useState(null)
  const [showError, setShowError] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [editMode, setEditMode] = useState(false)

  const schema = yup.object().shape({
    username: yup.string().trim()
      .matches(/^[a-zA-Z0-9+\-_@.]*$/, 'Username may contain only letters, numbers and @/./+/-/_ characters')
      .required('Username is required')
      // eslint-disable-next-line
      .max(35, 'Max number of characters: ${max}'),
    first_name: yup.string().trim()
      .required('First Name is required')
      // eslint-disable-next-line
      .max(35, 'Max number of characters: ${max}'),
    last_name: yup.string().trim()
      .required('Last Name is required')
      // eslint-disable-next-line
      .max(35, 'Max number of characters: ${max}'),
    email: yup.string().trim()
      .email('E-mail is invalid')
      .required('E-mail is required'),
  })

  useEffect(() => {
    if (props.user) {
      setEditMode(true)
    }

    return () => {
      setEditMode(false)
      resetValues()
    }
    // eslint-disable-next-line
  }, [props.user])

  let formInit = {
    mode: 'onChange',
    resolver: yupResolver(schema)
  }

  if (props.user) {
    formInit.defaultValues = {
      username: props.user.username,
      first_name: props.user.first_name,
      last_name: props.user.last_name,
      email: props.user.email,
      is_admin: props.user.is_admin
    }
  }

  const { handleSubmit, register, errors, setError, formState, reset, control } = useForm(formInit)

  const onHide = (member) => {
    props.onHide(member)
  }

  const onSubmit = (data) => {
    data = {
      ...data,
      is_admin: !!data.is_admin // Value always present
    }

    setSubmitting(true)
    setShowError(false)

    const promise = editMode
      ? editUser(props.user.id, data)
      : createUser(props.clientId, data)

    promise
      .then(onHide)
      .catch((err) => {
        setSubmitting(false)
        try {
          let error = JSON.parse(err.response.text)
          if (error.detail) {
            setErrorMsg(error.detail)
            setShowError(!!error.detail)
          } else {
            Object.entries(error)
              .forEach(([fieldName, messages]) => {
                setError(fieldName, {
                  type: 'manual',
                  message: capitalize(messages[0])
                })
              })
          }
        } catch (e) {
          setErrorMsg(err.response.text)
          setShowError(!!err.response.text)
        }
      })
  }

  const resetValues = () => {
    reset()
    setSubmitting(false)
    setErrorMsg(null)
    setShowError(false)
  }

  return <Form onSubmit={handleSubmit(onSubmit)}>
    <Toast show={showError}
      onClose={() => setShowError(false)}>
      <Toast.Header>
        <strong className="mr-auto">
          User Account {editMode ? 'update':'creation'} failed
        </strong>
      </Toast.Header>
      <Toast.Body>{ errorMsg }</Toast.Body>
    </Toast>
    <Row className="fields">
      <Col>
        <Form.Group>
          <Form.Label>Username</Form.Label>
          <input id="username"
            name="username"
            type="text"
            className={cN('form-control', {
              'is-invalid': errors.username
            })}
            ref={register}
          />
          <Form.Control.Feedback type="invalid">
            {errors.username?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>First Name</Form.Label>
          <input id="first_name"
            name="first_name"
            type="text"
            className={cN('form-control', {
              'is-invalid': errors.first_name
            })}
            ref={register}
          />
          <Form.Control.Feedback type="invalid">
            {errors.first_name?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Last Name</Form.Label>
          <input id="last_name"
            name="last_name"
            type="text"
            className={cN('form-control', {
              'is-invalid': errors.last_name
            })}
            ref={register}
          />
          <Form.Control.Feedback type="invalid">
            {errors.last_name?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>E-mail</Form.Label>
          <input id="email"
            name="email"
            type="text"
            className={cN('form-control', {
              'is-invalid': errors.email
            })}
            ref={register}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <div className="form-check">
          <Controller name="is_admin"
            control={control}
            render={({value, onChange, ref}) => (
              <input id="admin-checkbox"
                name="is_admin"
                className="form-check-input"
                type="checkbox"
                value={value}
                checked={value}
                defaultChecked={false}
                onChange={e => onChange(e.target.checked)}
                ref={ref}
              />
            )}
            />
            <Form.Label htmlFor="admin-checkbox">
              Set this user as Administrator
            </Form.Label>
          </div>
        </Form.Group>
      </Col>
    </Row>
    <Row>
      <Col>
        <Button id="submit-btn"
          variant="primary"
          type="submit"
          disabled={ submitting || !formState.isDirty || !formState.isValid }>
          { submitting
            ? 'Submitting...'
            : editMode ? 'EDIT USER' : 'ADD USER' }
        </Button>
        <Button id="cancel-btn"
          variant="default"
          onClick={() => onHide()}
          disabled={submitting}>
          CANCEL
        </Button>
      </Col>
    </Row>
  </Form>
}

export default UserForm
