/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The UserPartialUpdate model module.
 * @module model/UserPartialUpdate
 * @version v1
 */
class UserPartialUpdate {
    /**
     * Constructs a new <code>UserPartialUpdate</code>.
     * @alias module:model/UserPartialUpdate
     * @param clientId {Array.<String>} 
     * @param firstName {String} 
     * @param lastName {String} 
     * @param email {String} 
     * @param password {String} <ul><li>Your password can’t be too similar to your other personal information.</li><li>Your password must contain at least 8 characters.</li><li>Your password can’t be a commonly used password.</li><li>Your password can’t be entirely numeric.</li></ul>
     * @param projectNotificationsEnabled {Boolean} 
     * @param picture {String} 
     */
    constructor(clientId, firstName, lastName, email, password, projectNotificationsEnabled, picture) { 
        
        UserPartialUpdate.initialize(this, clientId, firstName, lastName, email, password, projectNotificationsEnabled, picture);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, clientId, firstName, lastName, email, password, projectNotificationsEnabled, picture) { 
        obj['client_id'] = clientId;
        obj['first_name'] = firstName;
        obj['last_name'] = lastName;
        obj['email'] = email;
        obj['password'] = password;
        obj['project_notifications_enabled'] = projectNotificationsEnabled;
        obj['picture'] = picture;
    }

    /**
     * Constructs a <code>UserPartialUpdate</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UserPartialUpdate} obj Optional instance to populate.
     * @return {module:model/UserPartialUpdate} The populated <code>UserPartialUpdate</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserPartialUpdate();

            if (data.hasOwnProperty('client_id')) {
                obj['client_id'] = ApiClient.convertToType(data['client_id'], ['String']);
            }
            if (data.hasOwnProperty('first_name')) {
                obj['first_name'] = ApiClient.convertToType(data['first_name'], 'String');
            }
            if (data.hasOwnProperty('last_name')) {
                obj['last_name'] = ApiClient.convertToType(data['last_name'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('password')) {
                obj['password'] = ApiClient.convertToType(data['password'], 'String');
            }
            if (data.hasOwnProperty('project_notifications_enabled')) {
                obj['project_notifications_enabled'] = ApiClient.convertToType(data['project_notifications_enabled'], 'Boolean');
            }
            if (data.hasOwnProperty('picture')) {
                obj['picture'] = ApiClient.convertToType(data['picture'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Array.<String>} client_id
 */
UserPartialUpdate.prototype['client_id'] = undefined;

/**
 * @member {String} first_name
 */
UserPartialUpdate.prototype['first_name'] = undefined;

/**
 * @member {String} last_name
 */
UserPartialUpdate.prototype['last_name'] = undefined;

/**
 * @member {String} email
 */
UserPartialUpdate.prototype['email'] = undefined;

/**
 * <ul><li>Your password can’t be too similar to your other personal information.</li><li>Your password must contain at least 8 characters.</li><li>Your password can’t be a commonly used password.</li><li>Your password can’t be entirely numeric.</li></ul>
 * @member {String} password
 */
UserPartialUpdate.prototype['password'] = undefined;

/**
 * @member {Boolean} project_notifications_enabled
 */
UserPartialUpdate.prototype['project_notifications_enabled'] = undefined;

/**
 * @member {String} picture
 */
UserPartialUpdate.prototype['picture'] = undefined;






export default UserPartialUpdate;

