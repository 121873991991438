/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import MultilingualOption from './MultilingualOption';

/**
 * The S3UploadedFile model module.
 * @module model/S3UploadedFile
 * @version v1
 */
class S3UploadedFile {
    /**
     * Constructs a new <code>S3UploadedFile</code>.
     * @alias module:model/S3UploadedFile
     * @param name {String} 
     * @param url {String} 
     * @param fields {Object.<String, String>} 
     * @param fileType {module:model/S3UploadedFile.FileTypeEnum} 
     * @param size {Number} 
     */
    constructor(name, url, fields, fileType, size) { 
        
        S3UploadedFile.initialize(this, name, url, fields, fileType, size);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, name, url, fields, fileType, size) { 
        obj['name'] = name;
        obj['url'] = url;
        obj['fields'] = fields;
        obj['file_type'] = fileType;
        obj['size'] = size;
    }

    /**
     * Constructs a <code>S3UploadedFile</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/S3UploadedFile} obj Optional instance to populate.
     * @return {module:model/S3UploadedFile} The populated <code>S3UploadedFile</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new S3UploadedFile();

            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
            if (data.hasOwnProperty('fields')) {
                obj['fields'] = ApiClient.convertToType(data['fields'], {'String': 'String'});
            }
            if (data.hasOwnProperty('file_type')) {
                obj['file_type'] = ApiClient.convertToType(data['file_type'], 'String');
            }
            if (data.hasOwnProperty('format')) {
                obj['format'] = ApiClient.convertToType(data['format'], 'String');
            }
            if (data.hasOwnProperty('size')) {
                obj['size'] = ApiClient.convertToType(data['size'], 'Number');
            }
            if (data.hasOwnProperty('multilingual')) {
                obj['multilingual'] = MultilingualOption.constructFromObject(data['multilingual']);
            }
        }
        return obj;
    }


}

/**
 * @member {String} name
 */
S3UploadedFile.prototype['name'] = undefined;

/**
 * @member {String} url
 */
S3UploadedFile.prototype['url'] = undefined;

/**
 * @member {Object.<String, String>} fields
 */
S3UploadedFile.prototype['fields'] = undefined;

/**
 * @member {module:model/S3UploadedFile.FileTypeEnum} file_type
 */
S3UploadedFile.prototype['file_type'] = undefined;

/**
 * @member {String} format
 */
S3UploadedFile.prototype['format'] = undefined;

/**
 * @member {Number} size
 */
S3UploadedFile.prototype['size'] = undefined;

/**
 * @member {module:model/MultilingualOption} multilingual
 */
S3UploadedFile.prototype['multilingual'] = undefined;





/**
 * Allowed values for the <code>file_type</code> property.
 * @enum {String}
 * @readonly
 */
S3UploadedFile['FileTypeEnum'] = {

    /**
     * value: "source"
     * @const
     */
    "source": "source",

    /**
     * value: "reference"
     * @const
     */
    "reference": "reference",

    /**
     * value: "zipped_uploads"
     * @const
     */
    "zipped_uploads": "zipped_uploads",

    /**
     * value: "localized"
     * @const
     */
    "localized": "localized"
};



export default S3UploadedFile;

