/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ClientStatus from '../model/ClientStatus';
import StatisticsFileCount from '../model/StatisticsFileCount';
import StatisticsFileCountAvg from '../model/StatisticsFileCountAvg';
import StatisticsLRChart from '../model/StatisticsLRChart';
import StatisticsLRLanguageChart from '../model/StatisticsLRLanguageChart';
import StatisticsTotalLR from '../model/StatisticsTotalLR';
import StatisticsTotalProjects from '../model/StatisticsTotalProjects';
import StatisticsTotalWordcount from '../model/StatisticsTotalWordcount';
import StatisticsWordcountChart from '../model/StatisticsWordcountChart';

/**
* Dashboard service.
* @module api/DashboardApi
* @version v1
*/
export default class DashboardApi {

    /**
    * Constructs a new DashboardApi. 
    * @alias module:api/DashboardApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the dashboardClientsList operation.
     * @callback module:api/DashboardApi~dashboardClientsListCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/ClientStatus>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page A page number within the paginated result set.
     * @param {module:api/DashboardApi~dashboardClientsListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/ClientStatus>}
     */
    dashboardClientsList(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ClientStatus];
      return this.apiClient.callApi(
        '/dashboard/clients/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the dashboardProjectsFilecountAvgList operation.
     * @callback module:api/DashboardApi~dashboardProjectsFilecountAvgListCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/StatisticsFileCountAvg>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page A page number within the paginated result set.
     * @param {String} opts.dateFrom filter by starting month (inclusive), format: 'YYYY-mm'
     * @param {String} opts.dateTo filter by until month (exclusive), format: 'YYYY-mm'
     * @param {Number} opts.clientId filter by client id
     * @param {module:api/DashboardApi~dashboardProjectsFilecountAvgListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/StatisticsFileCountAvg>}
     */
    dashboardProjectsFilecountAvgList(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'date_from': opts['dateFrom'],
        'date_to': opts['dateTo'],
        'client_id': opts['clientId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [StatisticsFileCountAvg];
      return this.apiClient.callApi(
        '/dashboard/projects/filecount/avg', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the dashboardProjectsFilecountChartList operation.
     * @callback module:api/DashboardApi~dashboardProjectsFilecountChartListCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/StatisticsFileCount>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} dateFrom filter by date starting from given time, format: 'YYYY-mm-dd'
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page A page number within the paginated result set.
     * @param {String} opts.dateTo filter by date until given time, format: 'YYYY-mm-dd'
     * @param {Number} opts.clientId filter by client id
     * @param {module:api/DashboardApi~dashboardProjectsFilecountChartListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/StatisticsFileCount>}
     */
    dashboardProjectsFilecountChartList(dateFrom, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'dateFrom' is set
      if (dateFrom === undefined || dateFrom === null) {
        throw new Error("Missing the required parameter 'dateFrom' when calling dashboardProjectsFilecountChartList");
      }

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'date_from': dateFrom,
        'date_to': opts['dateTo'],
        'client_id': opts['clientId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [StatisticsFileCount];
      return this.apiClient.callApi(
        '/dashboard/projects/filecount/chart', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the dashboardProjectsLocreqsLanguageList operation.
     * @callback module:api/DashboardApi~dashboardProjectsLocreqsLanguageListCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/StatisticsLRLanguageChart>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} dateFrom filter by date starting from given time, format: 'YYYY-mm-dd'
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page A page number within the paginated result set.
     * @param {String} opts.dateTo filter by date until given time, format: 'YYYY-mm-dd'
     * @param {Number} opts.clientId filter by client id
     * @param {module:api/DashboardApi~dashboardProjectsLocreqsLanguageListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/StatisticsLRLanguageChart>}
     */
    dashboardProjectsLocreqsLanguageList(dateFrom, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'dateFrom' is set
      if (dateFrom === undefined || dateFrom === null) {
        throw new Error("Missing the required parameter 'dateFrom' when calling dashboardProjectsLocreqsLanguageList");
      }

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'date_from': dateFrom,
        'date_to': opts['dateTo'],
        'client_id': opts['clientId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [StatisticsLRLanguageChart];
      return this.apiClient.callApi(
        '/dashboard/projects/locreqs/language', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the dashboardProjectsLocreqsList operation.
     * @callback module:api/DashboardApi~dashboardProjectsLocreqsListCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/StatisticsLRChart>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} state filter by localization request state: open,invoiced
     * @param {String} dateFrom filter by date starting from given time, format: 'YYYY-mm-dd'
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page A page number within the paginated result set.
     * @param {Number} opts.projectId filter by project id
     * @param {String} opts.dateTo filter by date until given time, format: 'YYYY-mm-dd'
     * @param {Number} opts.clientId filter by client id
     * @param {module:api/DashboardApi~dashboardProjectsLocreqsListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/StatisticsLRChart>}
     */
    dashboardProjectsLocreqsList(state, dateFrom, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'state' is set
      if (state === undefined || state === null) {
        throw new Error("Missing the required parameter 'state' when calling dashboardProjectsLocreqsList");
      }
      // verify the required parameter 'dateFrom' is set
      if (dateFrom === undefined || dateFrom === null) {
        throw new Error("Missing the required parameter 'dateFrom' when calling dashboardProjectsLocreqsList");
      }

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'project_id': opts['projectId'],
        'state': state,
        'date_from': dateFrom,
        'date_to': opts['dateTo'],
        'client_id': opts['clientId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [StatisticsLRChart];
      return this.apiClient.callApi(
        '/dashboard/projects/locreqs', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the dashboardProjectsLocreqsTotalList operation.
     * @callback module:api/DashboardApi~dashboardProjectsLocreqsTotalListCallback
     * @param {String} error Error message, if any.
     * @param {module:model/StatisticsTotalLR} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} state filter by localization request state: open,invoiced
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page A page number within the paginated result set.
     * @param {Number} opts.projectId filter by project id
     * @param {Number} opts.clientId filter by client id
     * @param {module:api/DashboardApi~dashboardProjectsLocreqsTotalListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/StatisticsTotalLR}
     */
    dashboardProjectsLocreqsTotalList(state, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'state' is set
      if (state === undefined || state === null) {
        throw new Error("Missing the required parameter 'state' when calling dashboardProjectsLocreqsTotalList");
      }

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'project_id': opts['projectId'],
        'state': state,
        'client_id': opts['clientId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = StatisticsTotalLR;
      return this.apiClient.callApi(
        '/dashboard/projects/locreqs/total', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the dashboardProjectsTotalList operation.
     * @callback module:api/DashboardApi~dashboardProjectsTotalListCallback
     * @param {String} error Error message, if any.
     * @param {module:model/StatisticsTotalProjects} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page A page number within the paginated result set.
     * @param {Number} opts.clientId filter by client id
     * @param {module:api/DashboardApi~dashboardProjectsTotalListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/StatisticsTotalProjects}
     */
    dashboardProjectsTotalList(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'client_id': opts['clientId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = StatisticsTotalProjects;
      return this.apiClient.callApi(
        '/dashboard/projects/total', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the dashboardProjectsWordcountList operation.
     * @callback module:api/DashboardApi~dashboardProjectsWordcountListCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/StatisticsWordcountChart>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} dateFrom filter by date starting from given time, format: 'YYYY-mm-dd'
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page A page number within the paginated result set.
     * @param {Number} opts.projectId filter by project id
     * @param {String} opts.language filter by target languages
     * @param {Boolean} opts.includeAdjusted include adjusted word count, default: false (true/false)
     * @param {String} opts.dateTo filter by date until given time, format: 'YYYY-mm-dd'
     * @param {Number} opts.clientId filter by client id
     * @param {module:api/DashboardApi~dashboardProjectsWordcountListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/StatisticsWordcountChart>}
     */
    dashboardProjectsWordcountList(dateFrom, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'dateFrom' is set
      if (dateFrom === undefined || dateFrom === null) {
        throw new Error("Missing the required parameter 'dateFrom' when calling dashboardProjectsWordcountList");
      }

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'project_id': opts['projectId'],
        'language': opts['language'],
        'include_adjusted': opts['includeAdjusted'],
        'date_from': dateFrom,
        'date_to': opts['dateTo'],
        'client_id': opts['clientId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [StatisticsWordcountChart];
      return this.apiClient.callApi(
        '/dashboard/projects/wordcount', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the dashboardProjectsWordcountTotalList operation.
     * @callback module:api/DashboardApi~dashboardProjectsWordcountTotalListCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/StatisticsTotalWordcount>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page A page number within the paginated result set.
     * @param {Number} opts.projectId filter by project id
     * @param {String} opts.language filter by target languages
     * @param {String} opts.view type of response, currently only supports clientpm
     * @param {Boolean} opts.includeAdjusted include adjusted word count, default: false (true/false)
     * @param {String} opts.dateFrom filter by date starting from given time, format: 'YYYY-mm-dd', required when requested is coming from KWS PM
     * @param {String} opts.dateTo filter by date until given time, format: 'YYYY-mm-dd'
     * @param {Number} opts.clientId filter by client id
     * @param {module:api/DashboardApi~dashboardProjectsWordcountTotalListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/StatisticsTotalWordcount>}
     */
    dashboardProjectsWordcountTotalList(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'project_id': opts['projectId'],
        'language': opts['language'],
        'view': opts['view'],
        'include_adjusted': opts['includeAdjusted'],
        'date_from': opts['dateFrom'],
        'date_to': opts['dateTo'],
        'client_id': opts['clientId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [StatisticsTotalWordcount];
      return this.apiClient.callApi(
        '/dashboard/projects/wordcount/total', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
