import React, { useContext } from 'react'
import { Image, NavDropdown, Nav } from 'react-bootstrap'
import { NavLink, useHistory } from 'react-router-dom'

import { GlobalContext } from '../../../context/GlobalState'

import '../styles.css'
import './styles.scss'

import AccountForm from '../../account-form'


const UserMenu = () => {
  const { 
    user, authenticating, logout, toggleUserEdit, userFormIsOpen,
    openTerms, termsNotAccepted 
  } = useContext(GlobalContext)
  const history = useHistory()
  const dropdownTitle = user && <div style={{display: 'inline-block'}}>
    {(user.picture_download_link && user.picture) ? <Image 
      src={user.picture_download_link}
      className='profile-picture'
      />
      : <><i className='onek-client' />{' '}</>
    }
    { user.first_name ? user.first_name + ' ' + user.last_name : user.username }
  </div>

  const onLogout = () => {
    logout(true)
    history.replace('/login')
  }

  const openTermsPage = () => {
    openTerms()
    history.push('/terms')
  }

  const openAccountForm = () => {
    toggleUserEdit(true)
  }


  return (
    user
      ? <>
        <NavDropdown title={dropdownTitle} className='headermenu'>
          { !termsNotAccepted &&
            <>
              <NavDropdown.Item id="my-account-link"
                                className="dropdown-menu-item"
                                onClick={ openAccountForm }>
                My Account
              </NavDropdown.Item>
              { !user.isKWSPM &&
                <NavDropdown.Item id="terms-link"
                                  className="dropdown-menu-item"
                                  onClick={ openTermsPage }>
                  Terms and Conditions
                </NavDropdown.Item>
              }
              <NavDropdown.Divider />
            </>
          }
          <NavDropdown.Item id="logout-link"
                            className="dropdown-menu-item"
                            onClick={ onLogout }>Log Out
          </NavDropdown.Item>
        </NavDropdown>
        <AccountForm show={userFormIsOpen} />
      </>
      : authenticating
        ? <span></span>
        : <Nav.Link id="login-link"
          as={NavLink}
          to="/login"
          activeClassName="active">
          Log In
        </Nav.Link>
  )
}

export default UserMenu
