/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import SuperPower from '../model/SuperPower';

/**
* Internal service.
* @module api/InternalApi
* @version v1
*/
export default class InternalApi {

    /**
    * Constructs a new InternalApi. 
    * @alias module:api/InternalApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the internalCleanerCreate operation.
     * @callback module:api/InternalApi~internalCleanerCreateCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} opts.deleteType all - all clients with their data will be deleted.                 client - specific client will be deleted given by client_id.                 project - specific project will be deleted given by project_id
     * @param {String} opts.clientId client id to be deleted
     * @param {String} opts.projectId project id to be deleted
     * @param {module:api/InternalApi~internalCleanerCreateCallback} callback The callback function, accepting three arguments: error, data, response
     */
    internalCleanerCreate(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'delete_type': opts['deleteType'],
        'client_id': opts['clientId'],
        'project_id': opts['projectId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/internal/cleaner', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the internalGeneratorClientCreate operation.
     * @callback module:api/InternalApi~internalGeneratorClientCreateCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} projectCount number of projects to generate
     * @param {module:api/InternalApi~internalGeneratorClientCreateCallback} callback The callback function, accepting three arguments: error, data, response
     */
    internalGeneratorClientCreate(projectCount, callback) {
      let postBody = null;
      // verify the required parameter 'projectCount' is set
      if (projectCount === undefined || projectCount === null) {
        throw new Error("Missing the required parameter 'projectCount' when calling internalGeneratorClientCreate");
      }

      let pathParams = {
      };
      let queryParams = {
        'project_count': projectCount
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/internal/generator/client', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the internalSuperpowerCreate operation.
     * @callback module:api/InternalApi~internalSuperpowerCreateCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/SuperPower} data 
     * @param {module:api/InternalApi~internalSuperpowerCreateCallback} callback The callback function, accepting three arguments: error, data, response
     */
    internalSuperpowerCreate(data, callback) {
      let postBody = data;
      // verify the required parameter 'data' is set
      if (data === undefined || data === null) {
        throw new Error("Missing the required parameter 'data' when calling internalSuperpowerCreate");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/internal/superpower', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the internalSuperpowerList operation.
     * @callback module:api/InternalApi~internalSuperpowerListCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page A page number within the paginated result set.
     * @param {module:api/InternalApi~internalSuperpowerListCallback} callback The callback function, accepting three arguments: error, data, response
     */
    internalSuperpowerList(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/internal/superpower', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
