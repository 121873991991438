/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ClientFull model module.
 * @module model/ClientFull
 * @version v1
 */
class ClientFull {
    /**
     * Constructs a new <code>ClientFull</code>.
     * @alias module:model/ClientFull
     * @param id {Number} 
     * @param name {String} 
     * @param studio {String} 
     * @param bpsClientId {Number} 
     * @param memoqEndpoint {String} 
     */
    constructor(id, name, studio, bpsClientId, memoqEndpoint) { 
        
        ClientFull.initialize(this, id, name, studio, bpsClientId, memoqEndpoint);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, name, studio, bpsClientId, memoqEndpoint) { 
        obj['id'] = id;
        obj['name'] = name;
        obj['studio'] = studio;
        obj['bps_client_id'] = bpsClientId;
        obj['memoq_endpoint'] = memoqEndpoint;
    }

    /**
     * Constructs a <code>ClientFull</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ClientFull} obj Optional instance to populate.
     * @return {module:model/ClientFull} The populated <code>ClientFull</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ClientFull();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('logo')) {
                obj['logo'] = ApiClient.convertToType(data['logo'], 'String');
            }
            if (data.hasOwnProperty('banner')) {
                obj['banner'] = ApiClient.convertToType(data['banner'], 'String');
            }
            if (data.hasOwnProperty('studio')) {
                obj['studio'] = ApiClient.convertToType(data['studio'], 'String');
            }
            if (data.hasOwnProperty('bps_client_id')) {
                obj['bps_client_id'] = ApiClient.convertToType(data['bps_client_id'], 'Number');
            }
            if (data.hasOwnProperty('memoq_endpoint')) {
                obj['memoq_endpoint'] = ApiClient.convertToType(data['memoq_endpoint'], 'String');
            }
            if (data.hasOwnProperty('ongoing_count')) {
                obj['ongoing_count'] = ApiClient.convertToType(data['ongoing_count'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
ClientFull.prototype['id'] = undefined;

/**
 * @member {String} name
 */
ClientFull.prototype['name'] = undefined;

/**
 * @member {String} logo
 */
ClientFull.prototype['logo'] = undefined;

/**
 * @member {String} banner
 */
ClientFull.prototype['banner'] = undefined;

/**
 * @member {String} studio
 */
ClientFull.prototype['studio'] = undefined;

/**
 * @member {Number} bps_client_id
 */
ClientFull.prototype['bps_client_id'] = undefined;

/**
 * @member {String} memoq_endpoint
 */
ClientFull.prototype['memoq_endpoint'] = undefined;

/**
 * ongoing project count
 * @member {Number} ongoing_count
 */
ClientFull.prototype['ongoing_count'] = undefined;






export default ClientFull;

