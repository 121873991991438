import {
    gotoMenuItems, isProjectPage, isClientPage, isArchivedPage
} from './utils'


export const clientPMRoutes = ({user, currentClient, setCurrentClient, getProjectByID}, callback) => (pathComponents) => {
    const {id} = user.client
    const items = []
    items.push(gotoMenuItems[0]) // stats
    items.push(gotoMenuItems[2]) // project list
    items.push(gotoMenuItems[3]) // archived

    if (!currentClient) {
        setCurrentClient(user.client)
    }

    // /project/{projectId}
    if (isProjectPage(pathComponents)) {
        const projectId = pathComponents[1]
        getProjectByID(projectId)
        .then(() => {
            items[0].link = '/client/' + id + '/stats'
            items[1].link = '/client/' + id
            items[2].link = '/client/' + id + '/archived'
            callback(items)
        })
        .catch(console.error)
        return
    }

    // /client/{clientId}/archived && /client/{clientId}/archived/{archivedProjectId}
    if (isClientPage(pathComponents) && isArchivedPage(pathComponents)) {
        const lrList = pathComponents[3]
        items[0].link = '/client/' + id + '/stats'
        items[1].link = '/client/' + id
        items[2].link = lrList ? '/client/' + id + '/archived' : false
        callback(items)
        return
    }

    // /client/{clientId}/stats
    if (isClientPage(pathComponents) && pathComponents[2] === 'stats') {
        items[0].link = false
        items[1].link = '/client/' + id
        items[2].link = '/client/' + id + '/archived'
        callback(items)
        return
    }

    // /dashboard && /client/{clientId}
    items[0].link = '/client/' + id + '/stats'
    items[1].link = false
    items[2].link = '/client/' + id + '/archived'
    callback(items)
}
