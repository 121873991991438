import React from 'react'
import { Button, Table } from 'react-bootstrap'
import Loading from '../../loading/Loading'
import './style.scss'

import { downloadFile, languageLabelFromCode } from '../../../utils'


export const onDownload = (e, url, name) => {
    e.preventDefault()
    downloadFile(url, name)
}


export default function FilesTab(props) {
    const {request, results} = props

    if (!(request && results)) {
        return <Loading />
    }

    const {languages, multilingual, combined} = results || {}

    const isFileDownloadable = file => !!(file && file.url && file.name)

    const filteredLanguages = (languages || []).filter(isFileDownloadable)
    const multipleLangs = filteredLanguages.length > 1
    // MULTILINGUALS
    if (multilingual) {
        const {name, url} = multilingual

        // modify languages
        languages.forEach(language => {
            language.name = name
            language.url = url
        })
    }
    // ALL TARGETS
    const allTargets = []
    if (combined) {
        const {name, url} = combined
        allTargets.push({name, url})
    }


    function FileTableRow (props) {
        const {files, label} = props || {}

        return (files || []).map(file => {
            const {name, url, language} = (file || {})
            const languageLabel = label || languageLabelFromCode(language)

            if (!languageLabel) {
                return null
            }

            return <tr key={languageLabel}>
                <td>{languageLabel}</td>
                <td>
                    {
                        (file && name && url) 
                            ? <a className='table-label' href={url}>
                                {name}
                            </a>
                            : <span className='table-label'>
                                Not available yet
                            </span>
                    }
                    
                </td>
            </tr>
        })
    }
    
    return <>
        <RequestFilesDetail request={request} results={results} />
        {<Table striped responsive>
            <thead>
                <tr>
                    <th>TARGET LANGUAGES</th>
                    <th>DOWNLOAD FILES</th>
                </tr>
            </thead>
            <tbody>
                <FileTableRow files={languages || []} />

                {multipleLangs && <FileTableRow files={allTargets} label='All Targets File' />}
            </tbody>
        </Table>}
    </>
}


export function RequestFilesDetail (props) {
    const {request, results} = props
    const {url, name} = (results?.uploads || {})

    const sourceFiles = request.files?.filter(file => file.file_type === 'source')
    const referenceFiles = request.files?.filter(file => file.file_type === 'reference')
    
    const FileDetails = ({files, label, url, name}) => <p className="uppercase text-left">
    <i className="onek-files big"></i>
        <span>{label} files uploaded: {files.length}</span>
        { url && <>
            <span>{' | '}</span>
            <Button
                id="download-uploads-btn"
                variant="link" className="as-link "
                onClick={ e => onDownload(e, url, name) }>
                <i className="onek-drag-drop" />{' '}
                Download
            </Button>
        </> }
    </p>

    return <>
        <FileDetails files={sourceFiles} label={'Source'} url={url} name={name} />
        <FileDetails files={referenceFiles} label={'Reference'} url={referenceFiles[0]?.url} name={referenceFiles[0]?.name} />
    </>
}
