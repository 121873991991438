import React from 'react'
import { Toast } from 'react-bootstrap'
// import '../style.scss'

import {sizeLabel} from '../utils'

export default function FileOptionsError (props) {
    const {
      rejectedFiles, ignoredFiles, 
      fileOptions,
    } = props
   
    if (!rejectedFiles.length && !ignoredFiles.length) {
      return null
    }

    return <Toast>
      <Toast.Header closeButton={false}>
        <strong className='mr-auto'>Rejected files</strong>
      </Toast.Header>

      <Toast.Body>
        <ul className='mb-0'>
          { // MARK: - REJECTED
            rejectedFiles.map(file => {
              const {ext, size = 0, name} = file
              const {maxSize = 0} = fileOptions[ext] || {}
              const errorMsg = size > maxSize 
                ? `File is larger than ${sizeLabel(maxSize)}` 
                : 'File type is not supported'

              return <li key={name}>
                { `${name}: ${errorMsg}` }
              </li>
            })
          }

          { // MARK: - IGNORED
            ignoredFiles.map(file => {
              const {ext, path} = file
              const {maxFiles = 0} = fileOptions[ext] || {}
              return <li key={path}>
                { `${path}: Only ${maxFiles} files of type ${ext} are allowed` }
              </li>
            })
          }
        </ul>
      </Toast.Body>
    </Toast>
  }