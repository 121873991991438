import React from 'react'
import { Toast } from 'react-bootstrap'
import '../style.scss'

export default function RejectedToast (props) {
    const {fileRejections, ignoredFiles, maxFiles, maxSize} = props
    const errorMessage = {
        'file-invalid-type': 'File type is not supported',
        'file-too-large': `File is larger than ${maxSize}MB`
    }

    if (!fileRejections.length && !ignoredFiles.length) {
        return null
    }

    return <Toast>
      <Toast.Header closeButton={false}>
        <strong className='mr-auto'>Rejected files</strong>
      </Toast.Header>

      <Toast.Body>
        <ul className='mb-0'>
          {
            fileRejections.map(({ file, errors }) => {
              let error = errors.pop()

              if (!error) {
                error = {}
                if (file.size && file.size > maxSize) {
                  error.code = 'file-too-large'
                } else {
                  error.code = 'file-invalid-type'
                }
              }

              return <li key={file.name}>
                { `${file.name}: ${errorMessage[error.code] || 'Unknown error'}` }
              </li>
            })
          }
          {
            ignoredFiles.map((file) => {
              return <li key={file.name}>
                { `${file.name}: Only ${maxFiles} files are allowed` }
              </li>
            })
          }
        </ul>
      </Toast.Body>
    </Toast>
  }