/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The BPSProject model module.
 * @module model/BPSProject
 * @version v1
 */
class BPSProject {
    /**
     * Constructs a new <code>BPSProject</code>.
     * @alias module:model/BPSProject
     * @param clientEmail {String} 
     * @param clientId {Number} 
     * @param clientPmEmail {String} 
     * @param clientPmId {Number} 
     * @param clientPmUsername {String} 
     * @param clientUsername {String} 
     * @param code {String} 
     * @param creatorEmail {String} 
     * @param creatorId {Number} 
     * @param creatorUsername {String} 
     * @param currencyId {String} 
     * @param currencyValue {String} 
     * @param descriptionProject {String} 
     * @param idProject {Number} 
     * @param invoiceTo {Number} 
     * @param invoiceToEmail {String} 
     * @param invoiceToUsername {String} 
     * @param kind {String} 
     * @param kindValue {String} 
     * @param name {String} 
     * @param nameCode {String} 
     * @param officeDescription {String} 
     * @param officeId {String} 
     * @param pricelistCode {String} 
     * @param pricelistDescription {String} 
     * @param pricelistId {Number} 
     * @param pricelistState {String} 
     * @param stateMnemo {String} 
     * @param stateValue {String} 
     * @param type {String} 
     * @param typeValue {String} 
     */
    constructor(clientEmail, clientId, clientPmEmail, clientPmId, clientPmUsername, clientUsername, code, creatorEmail, creatorId, creatorUsername, currencyId, currencyValue, descriptionProject, idProject, invoiceTo, invoiceToEmail, invoiceToUsername, kind, kindValue, name, nameCode, officeDescription, officeId, pricelistCode, pricelistDescription, pricelistId, pricelistState, stateMnemo, stateValue, type, typeValue) { 
        
        BPSProject.initialize(this, clientEmail, clientId, clientPmEmail, clientPmId, clientPmUsername, clientUsername, code, creatorEmail, creatorId, creatorUsername, currencyId, currencyValue, descriptionProject, idProject, invoiceTo, invoiceToEmail, invoiceToUsername, kind, kindValue, name, nameCode, officeDescription, officeId, pricelistCode, pricelistDescription, pricelistId, pricelistState, stateMnemo, stateValue, type, typeValue);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, clientEmail, clientId, clientPmEmail, clientPmId, clientPmUsername, clientUsername, code, creatorEmail, creatorId, creatorUsername, currencyId, currencyValue, descriptionProject, idProject, invoiceTo, invoiceToEmail, invoiceToUsername, kind, kindValue, name, nameCode, officeDescription, officeId, pricelistCode, pricelistDescription, pricelistId, pricelistState, stateMnemo, stateValue, type, typeValue) { 
        obj['clientEmail'] = clientEmail;
        obj['clientId'] = clientId;
        obj['clientPmEmail'] = clientPmEmail;
        obj['clientPmId'] = clientPmId;
        obj['clientPmUsername'] = clientPmUsername;
        obj['clientUsername'] = clientUsername;
        obj['code'] = code;
        obj['creatorEmail'] = creatorEmail;
        obj['creatorId'] = creatorId;
        obj['creatorUsername'] = creatorUsername;
        obj['currencyId'] = currencyId;
        obj['currencyValue'] = currencyValue;
        obj['descriptionProject'] = descriptionProject;
        obj['idProject'] = idProject;
        obj['invoiceTo'] = invoiceTo;
        obj['invoiceToEmail'] = invoiceToEmail;
        obj['invoiceToUsername'] = invoiceToUsername;
        obj['kind'] = kind;
        obj['kindValue'] = kindValue;
        obj['name'] = name;
        obj['nameCode'] = nameCode;
        obj['officeDescription'] = officeDescription;
        obj['officeId'] = officeId;
        obj['pricelistCode'] = pricelistCode;
        obj['pricelistDescription'] = pricelistDescription;
        obj['pricelistId'] = pricelistId;
        obj['pricelistState'] = pricelistState;
        obj['stateMnemo'] = stateMnemo;
        obj['stateValue'] = stateValue;
        obj['type'] = type;
        obj['typeValue'] = typeValue;
    }

    /**
     * Constructs a <code>BPSProject</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/BPSProject} obj Optional instance to populate.
     * @return {module:model/BPSProject} The populated <code>BPSProject</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new BPSProject();

            if (data.hasOwnProperty('clientEmail')) {
                obj['clientEmail'] = ApiClient.convertToType(data['clientEmail'], 'String');
            }
            if (data.hasOwnProperty('clientId')) {
                obj['clientId'] = ApiClient.convertToType(data['clientId'], 'Number');
            }
            if (data.hasOwnProperty('clientPmEmail')) {
                obj['clientPmEmail'] = ApiClient.convertToType(data['clientPmEmail'], 'String');
            }
            if (data.hasOwnProperty('clientPmId')) {
                obj['clientPmId'] = ApiClient.convertToType(data['clientPmId'], 'Number');
            }
            if (data.hasOwnProperty('clientPmUsername')) {
                obj['clientPmUsername'] = ApiClient.convertToType(data['clientPmUsername'], 'String');
            }
            if (data.hasOwnProperty('clientUsername')) {
                obj['clientUsername'] = ApiClient.convertToType(data['clientUsername'], 'String');
            }
            if (data.hasOwnProperty('code')) {
                obj['code'] = ApiClient.convertToType(data['code'], 'String');
            }
            if (data.hasOwnProperty('creatorEmail')) {
                obj['creatorEmail'] = ApiClient.convertToType(data['creatorEmail'], 'String');
            }
            if (data.hasOwnProperty('creatorId')) {
                obj['creatorId'] = ApiClient.convertToType(data['creatorId'], 'Number');
            }
            if (data.hasOwnProperty('creatorUsername')) {
                obj['creatorUsername'] = ApiClient.convertToType(data['creatorUsername'], 'String');
            }
            if (data.hasOwnProperty('currencyId')) {
                obj['currencyId'] = ApiClient.convertToType(data['currencyId'], 'String');
            }
            if (data.hasOwnProperty('currencyValue')) {
                obj['currencyValue'] = ApiClient.convertToType(data['currencyValue'], 'String');
            }
            if (data.hasOwnProperty('descriptionProject')) {
                obj['descriptionProject'] = ApiClient.convertToType(data['descriptionProject'], 'String');
            }
            if (data.hasOwnProperty('idProject')) {
                obj['idProject'] = ApiClient.convertToType(data['idProject'], 'Number');
            }
            if (data.hasOwnProperty('invoiceTo')) {
                obj['invoiceTo'] = ApiClient.convertToType(data['invoiceTo'], 'Number');
            }
            if (data.hasOwnProperty('invoiceToEmail')) {
                obj['invoiceToEmail'] = ApiClient.convertToType(data['invoiceToEmail'], 'String');
            }
            if (data.hasOwnProperty('invoiceToUsername')) {
                obj['invoiceToUsername'] = ApiClient.convertToType(data['invoiceToUsername'], 'String');
            }
            if (data.hasOwnProperty('kind')) {
                obj['kind'] = ApiClient.convertToType(data['kind'], 'String');
            }
            if (data.hasOwnProperty('kindValue')) {
                obj['kindValue'] = ApiClient.convertToType(data['kindValue'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('nameCode')) {
                obj['nameCode'] = ApiClient.convertToType(data['nameCode'], 'String');
            }
            if (data.hasOwnProperty('officeDescription')) {
                obj['officeDescription'] = ApiClient.convertToType(data['officeDescription'], 'String');
            }
            if (data.hasOwnProperty('officeId')) {
                obj['officeId'] = ApiClient.convertToType(data['officeId'], 'String');
            }
            if (data.hasOwnProperty('pricelistCode')) {
                obj['pricelistCode'] = ApiClient.convertToType(data['pricelistCode'], 'String');
            }
            if (data.hasOwnProperty('pricelistDescription')) {
                obj['pricelistDescription'] = ApiClient.convertToType(data['pricelistDescription'], 'String');
            }
            if (data.hasOwnProperty('pricelistId')) {
                obj['pricelistId'] = ApiClient.convertToType(data['pricelistId'], 'Number');
            }
            if (data.hasOwnProperty('pricelistState')) {
                obj['pricelistState'] = ApiClient.convertToType(data['pricelistState'], 'String');
            }
            if (data.hasOwnProperty('stateMnemo')) {
                obj['stateMnemo'] = ApiClient.convertToType(data['stateMnemo'], 'String');
            }
            if (data.hasOwnProperty('stateValue')) {
                obj['stateValue'] = ApiClient.convertToType(data['stateValue'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'String');
            }
            if (data.hasOwnProperty('typeValue')) {
                obj['typeValue'] = ApiClient.convertToType(data['typeValue'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} clientEmail
 */
BPSProject.prototype['clientEmail'] = undefined;

/**
 * @member {Number} clientId
 */
BPSProject.prototype['clientId'] = undefined;

/**
 * @member {String} clientPmEmail
 */
BPSProject.prototype['clientPmEmail'] = undefined;

/**
 * @member {Number} clientPmId
 */
BPSProject.prototype['clientPmId'] = undefined;

/**
 * @member {String} clientPmUsername
 */
BPSProject.prototype['clientPmUsername'] = undefined;

/**
 * @member {String} clientUsername
 */
BPSProject.prototype['clientUsername'] = undefined;

/**
 * @member {String} code
 */
BPSProject.prototype['code'] = undefined;

/**
 * @member {String} creatorEmail
 */
BPSProject.prototype['creatorEmail'] = undefined;

/**
 * @member {Number} creatorId
 */
BPSProject.prototype['creatorId'] = undefined;

/**
 * @member {String} creatorUsername
 */
BPSProject.prototype['creatorUsername'] = undefined;

/**
 * @member {String} currencyId
 */
BPSProject.prototype['currencyId'] = undefined;

/**
 * @member {String} currencyValue
 */
BPSProject.prototype['currencyValue'] = undefined;

/**
 * @member {String} descriptionProject
 */
BPSProject.prototype['descriptionProject'] = undefined;

/**
 * @member {Number} idProject
 */
BPSProject.prototype['idProject'] = undefined;

/**
 * @member {Number} invoiceTo
 */
BPSProject.prototype['invoiceTo'] = undefined;

/**
 * @member {String} invoiceToEmail
 */
BPSProject.prototype['invoiceToEmail'] = undefined;

/**
 * @member {String} invoiceToUsername
 */
BPSProject.prototype['invoiceToUsername'] = undefined;

/**
 * @member {String} kind
 */
BPSProject.prototype['kind'] = undefined;

/**
 * @member {String} kindValue
 */
BPSProject.prototype['kindValue'] = undefined;

/**
 * @member {String} name
 */
BPSProject.prototype['name'] = undefined;

/**
 * @member {String} nameCode
 */
BPSProject.prototype['nameCode'] = undefined;

/**
 * @member {String} officeDescription
 */
BPSProject.prototype['officeDescription'] = undefined;

/**
 * @member {String} officeId
 */
BPSProject.prototype['officeId'] = undefined;

/**
 * @member {String} pricelistCode
 */
BPSProject.prototype['pricelistCode'] = undefined;

/**
 * @member {String} pricelistDescription
 */
BPSProject.prototype['pricelistDescription'] = undefined;

/**
 * @member {Number} pricelistId
 */
BPSProject.prototype['pricelistId'] = undefined;

/**
 * @member {String} pricelistState
 */
BPSProject.prototype['pricelistState'] = undefined;

/**
 * @member {String} stateMnemo
 */
BPSProject.prototype['stateMnemo'] = undefined;

/**
 * @member {String} stateValue
 */
BPSProject.prototype['stateValue'] = undefined;

/**
 * @member {String} type
 */
BPSProject.prototype['type'] = undefined;

/**
 * @member {String} typeValue
 */
BPSProject.prototype['typeValue'] = undefined;






export default BPSProject;

