/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import BPSClient from '../model/BPSClient';
import Studio from '../model/Studio';

/**
* Bps service.
* @module api/BpsApi
* @version v1
*/
export default class BpsApi {

    /**
    * Constructs a new BpsApi. 
    * @alias module:api/BpsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the bpsClientsList operation.
     * @callback module:api/BpsApi~bpsClientsListCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/BPSClient>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} opts.studioId filter by studio id
     * @param {module:api/BpsApi~bpsClientsListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/BPSClient>}
     */
    bpsClientsList(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'studio_id': opts['studioId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [BPSClient];
      return this.apiClient.callApi(
        '/bps/clients', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the bpsStudiosList operation.
     * @callback module:api/BpsApi~bpsStudiosListCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Studio>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/BpsApi~bpsStudiosListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Studio>}
     */
    bpsStudiosList(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Studio];
      return this.apiClient.callApi(
        '/bps/studios', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
