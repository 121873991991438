import React, { useState, useContext, useEffect } from 'react'

import { Modal, Row, Col, Tab, Nav } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Dialog from 'react-bootstrap-dialog'

import { GlobalContext } from '../../context/GlobalState'

import ProjectForm from './ProjectForm'
import Templates from './Templates'
import Members from './Members'
import ProjectContacts from './ProjectContacts'
import ServiceTab from './ServiceTab'

const NewProject = (props) => {
  const { 
    addProject, updateProject, archiveProject, 
    user, memoqProjects, memoqProjectsNotFound, getMemoqProjects, projectIsSyncing,
    updatingResources, resourceChanges, setResourceChanges
  } = useContext(GlobalContext)
  const [editMode, setEditMode] = useState(false)
  const defaultTitle = 'Create a new project'
  const editModeTitle = 'Edit project'
  const [modalTitle, setModalTitle] = useState(defaultTitle)
  const [activeTab, setActiveTab] = useState('info')
  const [projectToEdit, setProjectToEdit] = useState(null)
  const [dialog, setDialog] = useState(null)

  
  //
  useEffect(() => {
    if (props.project) {
      setProjectToEdit(props.project)
      setEditMode(true)
      setModalTitle(editModeTitle)

      if (!memoqProjects.length && !memoqProjectsNotFound) {
        getMemoqProjects(props.clientId)
      }
    }

    return () => resetModal()
    // eslint-disable-next-line
  }, [props.project])


  // MAK: - TAB TRANSITION
  const showConfimationDialog = callback => dialog.show({
    title: 'There are unsaved data',
    body: 'Are you sure you want to leave the tab?',
    actions: [
      Dialog.Action(
        'CANCEL',
        () => {},
        'btn-default'
      ),
      Dialog.Action(
        'OK',
        callback,
        'btn-default'
        //'btn-danger'
      )
    ],
    bsSize: 'small',
  })
  const checkResourceChanges = (callback) => {
    if (Object.keys(resourceChanges).length) {
      showConfimationDialog(() => {
        setResourceChanges()
        callback()
      })
      return
    }
    callback()
  }
  const onTransition = (key) => {
    if (key === 'templates') {
      setModalTitle('Project Templates')
    } else {
      setModalTitle(editMode ? editModeTitle : defaultTitle)
    }

    setActiveTab(key)
  }
  const selectTab = (key) => {
    // check if activeTab is lite or platinum and 
    if (activeTab === 'lite' || activeTab === 'platinum') {
      // check if it has member changes not pushed
      checkResourceChanges(() => onTransition(key))
      return
    } 
    onTransition(key)
  }

  /** Project Events Handlers */
  const onProjectCreated = (newProject) => {
    addProject(newProject)
    setTimeout(() => {
      toast.success(`Project ${newProject.name} successfully created`)
    }, 10)
    setProjectToEdit(newProject)
    setEditMode(true)

    if (!memoqProjects.length && !memoqProjectsNotFound) {
      getMemoqProjects(props.clientId)
    }
  }

  const onProjectUpdated = (project) => {
    setProjectToEdit(project)
    updateProject(project)
    setTimeout(() => {
      toast.success(`Project ${project.name} has been updated`)
    }, 10)
  }

  const onProjectArchived = (project) => {
    archiveProject(project.id)
    resetModal()
    onHide(project, true)
  }

  const onProjectSynced = (project) => {
    setProjectToEdit(project)
    if (props.onSync) {
      props.onSync(project)
    }
  }

  const onProjectDeleted = () => {
    setEditMode(false)
    resetModal()
    onHide()
  }
  /** */

  const resetModal = () => {
    setEditMode(false)
    setModalTitle(defaultTitle)
    setProjectToEdit(null)
    setActiveTab('info')
  }

  const onHide = (project, archived) => {
    if (activeTab === 'lite' || activeTab === 'platinum') {
      // check if it has member changes not pushed
      checkResourceChanges(() => {
        props.onHide(project || projectToEdit, archived)
        resetModal()
      })
      return
    } 
    props.onHide(project || projectToEdit, archived)
    resetModal()
  }

  const panesEnabled = !projectIsSyncing && !updatingResources
  const litePaneEnabled = !projectIsSyncing && (!updatingResources ||  updatingResources === 'lite')
  const platinumPaneEnabled  = !projectIsSyncing && (!updatingResources ||  updatingResources === 'platinum')

  return (
    <Modal show={props.show} onHide={onHide} backdrop='static'
      centered scrollable size={'lg'}
      keyboard={false}
      dialogClassName="tabbed-modal">
      <Dialog ref={setDialog} />
      <Modal.Header closeButton={panesEnabled}>
        <Modal.Title>
          { modalTitle }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tab.Container
          activeKey={activeTab}
          onSelect={(k) => selectTab(k)}>
          <Row>
            <Col sm="auto" className="tabs">
              <Nav className="flex-row flex-lg-column">
                <Nav.Item>
                  <Nav.Link eventKey="info" disabled={!panesEnabled}>
                    PROJECT INFO
                  </Nav.Link>
                </Nav.Item>
                { user.isKWSPM &&
                  <Nav.Item>
                    <Nav.Link eventKey="lite"
                      disabled={!editMode || !litePaneEnabled}>
                      LITE
                    </Nav.Link>
                  </Nav.Item>
                }
                { user.isKWSPM &&
                  <Nav.Item>
                    <Nav.Link eventKey="platinum"
                      disabled={!editMode || !platinumPaneEnabled}>
                      PLATINUM
                    </Nav.Link>
                  </Nav.Item>
                }
                <Nav.Item>
                  <Nav.Link eventKey="templates"
                    disabled={!editMode || !panesEnabled}>
                    TEMPLATES
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="members"
                    disabled={!editMode || !panesEnabled}>
                    TEAM MEMBERS
                  </Nav.Link>
                </Nav.Item>
                { user.isKWSPM &&
                  <Nav.Item>
                    <Nav.Link eventKey="contacts"
                      disabled={!editMode || !panesEnabled}>
                      KWS PMs
                    </Nav.Link>
                  </Nav.Item>
                }
              </Nav>
            </Col>
            <Col className="tab-container">
              <Tab.Content>
                <Tab.Pane eventKey="info">
                    <ProjectForm project={projectToEdit}
                      clientId={props.clientId}
                      onProjectCreated={onProjectCreated}
                      onProjectDeleted={onProjectDeleted}
                      onProjectArchived={onProjectArchived}
                      onProjectSynced={onProjectSynced}
                      onCancel={onHide} />
                </Tab.Pane>
                <Tab.Pane eventKey="lite">
                  { (editMode && litePaneEnabled) &&
                    <ServiceTab service={"lite"}
                      onProjectUpdated={onProjectUpdated}
                      activeTab={activeTab}
                      project={projectToEdit} /> }
                </Tab.Pane>
                <Tab.Pane eventKey="platinum">
                  { (editMode && platinumPaneEnabled) &&
                    <ServiceTab service={"platinum"}
                      onProjectUpdated={onProjectUpdated}
                      activeTab={activeTab}
                      project={projectToEdit} /> }
                </Tab.Pane>
                <Tab.Pane eventKey="templates">
                  { (editMode && panesEnabled) &&
                    <Templates activeTab={activeTab}
                      project={projectToEdit} /> }
                </Tab.Pane>
                <Tab.Pane eventKey="members">
                  { (editMode && panesEnabled) &&
                    <Members activeTab={activeTab}
                      clientId={props.clientId}
                      project={projectToEdit} /> }
                </Tab.Pane>
                <Tab.Pane eventKey="contacts">
                  { (editMode && panesEnabled) &&
                    <ProjectContacts activeTab={activeTab}
                      clientId={props.clientId}
                      project={projectToEdit} /> }
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  )
}

export default NewProject
