import React, { useContext, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { Container, Row, Col, Button, Image } from 'react-bootstrap'
import { toast } from 'react-toastify';
import Dialog from 'react-bootstrap-dialog'

import { GlobalContext } from '../../context/GlobalState'
import cN from 'classnames'

import RequestsTable from '../requests-table/RequestsTable'
import Loading from '../loading/Loading'
import EntityNotFound from '../entity-not-found/EntityNotFound'
import NewRequest from '../new-request/NewRequest'
import StartRequest from '../start-request/StartRequest'
import RequestDetails from '../request-details/RequestDetails'
import ContactPM from '../contact-pm/ContactPM'
import NewProject from '../new-project/NewProject'
import LPTooltip from '../lp-tooltip'
import LPDateTime from '../lp-datetime';

import {checkIfThereAreActiveServices} from '../../utils'


/*
// logic of active services
const checkIfThereAreActiveServices = (project) => {
  if (!project?.services) {
    return false
  }
  
  const activeServices = project.services.filter(service => service.is_active)
  return !!activeServices.length
}
/** */

const ProjectDashboard = () => {
  const { 
    loadingProject,
    currentProject, setCurrentProject, updateProject, toggleProjectEdit, projectEditIsOpen, projectToEdit, 
    getRequests, requests, requestsNotFound, requestsCount, clearRequests, addRequest, updateRequest, deleteRequest, 
    user, contact, 
    // getClientByID, 
    getClients, currentClient,
    getResources, 
    requestNewIsOpen, toggleRequestNew,
  } = useContext(GlobalContext)
  
  const { id } = useParams()
  // const [client, setClient] = useState(null)
  const [startRequestModalShow, setStartRequestModalShow] = useState(false)
  const [requestToStart, setRequestToStart] = useState(null)
  const [requestDetailsModalShow, setRequestDetailsModalShow] = useState(false)
  const [requestToView, setRequestToView] = useState(null)
  const [contactModalShow, setContactModalShow] = useState(false)
  const [contactAction, setContactAction] = useState(null)
  const [requestIdToEdit, setRequestIdToEdit] = useState(null)
  const [dialog, setDialog] = useState(null)
  const history = useHistory()

  useEffect(() => {
    if (!currentProject) {
      return
    }

    if (!requests.length && !requestsNotFound) {
      getRequests(id)
      fetchResources(id, "platinum")
      fetchResources(id, "lite")
    }

    return clearRequests
    // eslint-disable-next-line
  }, [currentProject])

  const onNewRequestModalHide = (newRequest, startNow, deleted) => {
    toggleRequestNew(false)

    if (newRequest) {
      if (requestIdToEdit) {
        if (deleted) {
          dialog.show({
            title: 'DELETE REQUEST',
            body: `Do you really want to delete this Localization Request?`,
            actions: [
              Dialog.Action(
                'CANCEL',
                () => {},
                'btn-default'
              ),
              Dialog.Action(
                'YES, DELETE',
                () => {
                  deleteRequest(id, newRequest.id)
                    .then(() => {
                      toast.success(`Request ${newRequest.number} deleted`)
                    })
                },
                'btn-danger'
              )
            ],
            bsSize: 'small',
          })
        } else {
          updateRequest(newRequest, id)
          setTimeout(() => {
            toast.success(`Request ${newRequest.number} updated`)
          }, 10)
        }
      } else {
        addRequest(newRequest)
        setTimeout(() => {
          toast.success(`Request ${newRequest.number} created`)
        }, 10)
      }
    }

    if (startNow) {
      startRequest(null, newRequest)
    }

    setRequestIdToEdit(null)
  }

  const fetchResources = (id , service) => {
    // getResources can return a 404, but the app need to go on...
    getResources(id, service)
    .catch(() => {}) // keep this no-op. 
  }

  const startRequest = (e, request) => {
    if (e) {
      e.stopPropagation()
    }
    setRequestToStart(request)
    setStartRequestModalShow(true)
  }

  const onStartRequestModalHide = (request) => {
    setStartRequestModalShow(false)
    setRequestToStart(null)

    if (request) {
      setTimeout(() => {
        if (request.state === 'ongoing') {
          toast.success(`Request ${request.number} successfully started`)
        } else if (request.state.endsWith('ongoing')) {
          toast.info(`The Request ${request.number} processing is still ongoing, you will receive an email when it is finally started...`)
        } else if (!request.target_languages ||
          !request.target_languages.length) {
            toast.error(`Request couldn't be started. All target languages were automatically removed from this request, since they are not present anymore in the pricelist.`)
        }
      }, 10)
    }
  }

  const viewRequest = (e, request) => {
    if (e) {
      e.stopPropagation()
    }
    setRequestToView(request)
    setRequestDetailsModalShow(true)
  }

  const onRequestDetailsModalHide = (action) => {
    setRequestDetailsModalShow(false)

    if (action === 'cancel' && user.isKWSPM) {
      contact(id, requestToView, {}, action)
        .then(() => {
          toast.success(`Request ${requestToView.number} successfully cancelled`)
          updateRequest({
            ...requestToView,
            state: 'cancelled'
          }, id)
        })
        .catch(() => {
          toast.error(`Request ${requestToView.number} couldn't be cancelled. Please, try again later`)
        })
    } else if (action) {
      setContactAction(action)
      setContactModalShow(true)
    } else {
      setRequestToView(null)
    }
  }

  const onContactModalHide = (messageSent, action) => {
    setContactModalShow(false)
    setContactAction(null)

    if (messageSent) {
      if (action === 'cancel') {
        toast.success(`Request to cancel ${requestToView.number} successfully sent`)
        toast.warn('Please note that it may not be possible to fully cancel this request and localization work may be charged partially; The Keywords Studios PM will get back to you soon.', {
          autoClose: 10000
        })
        updateRequest({
          ...requestToView,
          state: 'ongoing_to_cancelled'
        }, id)
      } else {
        toast.success(`Message to PM successfully sent`)
      }

      setRequestToView(null)
    } else {
      setRequestDetailsModalShow(true)
    }
  }

  const editRequest = (request) => {
    setRequestIdToEdit(request.id)
    toggleRequestNew(true)
  }

  const onRequestClick = (e, request) => {
    e.stopPropagation()

    if (request.state === 'draft') {
      editRequest(request)
    }
  }

  const onEditClick = () => {
    toggleProjectEdit(currentProject)
  }

  const onEditProjectModalHide = (project, archived) => {
    toggleProjectEdit(false)
    if (archived) {
      // Redirect to Client Dashboard
      if (user.isKWSPM) {
        getClients()
        history.push('/client/' + project.client)
      } else {
        history.push('/')
      }
    } else if (project) {
      setCurrentProject(project)
      updateProject(project)
    }
  }
  
  const onProjectSync = (project) => {
    setCurrentProject(project)
  }

  if (loadingProject) {
    return <Loading />
  }

  if (!loadingProject && !currentProject) {
    return <EntityNotFound entityName="Project" />
  }

  return <Container fluid className="ProjectDashboard">
    <Row className="hero">
      { currentClient &&
        <div className="banner"
          style={ currentClient.banner ? {backgroundImage: `url('${currentClient.banner}')`}: null }>
        </div>
      }
      <Col xs className="d-flex flex-column">
        <div className={cN('section-header', {
            'has-logo': currentClient && currentClient.logo
          })}>
          { !!currentClient && !!currentClient.logo &&
            <Image src={ currentClient.logo }
              alt={ currentClient.name } className="logo" fluid/>
          }
          <h1 className="ellipsis">
            { currentProject.name } DASHBOARD
          </h1>
          <div className="section-subheader multi-line">
            <span>TOTAL REQUESTS: { requestsCount || 0 }</span>
            <span className="uppercase">
              CREATED:{' '}
              <LPDateTime timestamp={currentProject.created_at} />
            </span>
          </div>
          <div className="section-subheader multi-line">
            <span>ONGOING REQUESTS: { currentProject.ongoing_count || 0 }</span>
            <span className="uppercase">
              LAST UPDATED:{' '}
              <LPDateTime timestamp={currentProject.updated_at} />
            </span>
          </div>
        </div>
        <Row>
          <Col className="d-flex justify-content-end align-items-end">
            <Button id="edit-btn"
              onClick={onEditClick}
              variant="primary">
              <i className="onek-active-projects" />{' '}
              EDIT PROJECT
            </Button>
            { checkIfThereAreActiveServices(currentProject)
              ? <Button id="new-request-btn"
                variant="secondary"
                onClick={() => toggleRequestNew(true)}>
                <i className="onek-new" />{' '}
                NEW REQUEST
              </Button>
              : <LPTooltip placement="bottom"
                tooltip="Requests can be created only when Project is ONGOING">
                <Button id="new-request-btn"
                  variant="secondary" className="disabled">
                  <i className="onek-new" />{' '}
                  NEW REQUEST
                </Button>
              </LPTooltip>
            }
          </Col>
        </Row>
      </Col>
    </Row>
    <RequestsTable
      project={currentProject}
      onStartClick={startRequest}
      onViewClick={viewRequest}
      onRowClick={onRequestClick}
    />
    <NewRequest id="new-request-modal"
      show={requestNewIsOpen}
      onHide={onNewRequestModalHide}
      project={currentProject}
      requestId={requestIdToEdit} />
    <StartRequest id="start-request-modal"
      show={startRequestModalShow}
      onHide={onStartRequestModalHide}
      projectId={id}
      request={requestToStart} />
    <RequestDetails id="request-details-modal"
      show={requestDetailsModalShow}
      onHide={onRequestDetailsModalHide}
      request={requestToView} />
    <ContactPM id="contact-modal"
      show={contactModalShow}
      onHide={onContactModalHide}
      action={contactAction}
      request={requestToView} />
    <Dialog ref={ (el) => setDialog(el) }/>
    <NewProject
      id="edit-project-modal"
      show={projectEditIsOpen}
      project={projectToEdit}
      clientId={currentProject?.client}
      onHide={onEditProjectModalHide}
      onSync={onProjectSync} />
  </Container>
}

export default ProjectDashboard
