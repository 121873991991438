import React from 'react'
import './request-status.scss'

import { OverlayTrigger, Tooltip} from 'react-bootstrap'

import cN from 'classnames'

import { capitalize } from '../../utils'

const StatusTooltip = (props) => {
  const {currentStatus, parsedStatus, status} = props

  return <>
    <strong>{ currentStatus.toUpperCase() }</strong>
    { !parsedStatus.startsWith(status) && parsedStatus !== invoiced &&
      <span>{' '}(PROCESSING)</span>
    }
    { props.error &&
      <p>Error: { capitalize(props.error) }</p>
    }
  </>
}

// status logic
const statuses = [
  'draft',
  'ongoing',
  'delivered',
  'closed'
]
const cancelled = 'cancelled'
const invoiced = 'invoiced'

const computeCurrentStatus = (status) => {
  const i = status.indexOf('_to_')
  return i > -1 ? status.substr(i + 4) : status
}
const computeParsedStatus = (status, currentStatus) => {
  return currentStatus === cancelled
    ? status.replace(cancelled, statuses[3])
    : status
}

const RequestStatus = (props) => {
  

  // Handle intermediate state
  const currentStatus = computeCurrentStatus(props.status)

  // Treat cancelled as closed
  const parsedStatus = computeParsedStatus(props.status, currentStatus)

  
  // Status computations
  const lastDotStyle = (status) => {
    return {
      done: parsedStatus.startsWith(status) || currentStatus === invoiced,
      'in-progress': (!parsedStatus.startsWith(status) && currentStatus !== invoiced),
      error: (!!props.error || currentStatus === cancelled),
    }
  }

  const dotsStyle = (status) => {
    return {
      done: statuses.indexOf(status) < statuses.indexOf(currentStatus) || currentStatus === cancelled || currentStatus === invoiced,
    }
  }


  return (
    <div className="RequestStatus">
      {
        statuses.map((status) => {
          if (parsedStatus.endsWith(status) || (parsedStatus === invoiced && status === statuses.at(-1))) {
            return <OverlayTrigger placement="top"
              key={status}
              overlay={
                <Tooltip>
                  <StatusTooltip 
                    currentStatus={currentStatus}
                    parsedStatus={parsedStatus} 
                    status={status}
                    error={props.error}
                  />
                </Tooltip>
              }>
              <div key={status} className={cN('status', lastDotStyle(status))}>
              </div>
            </OverlayTrigger>
          }

          return <div key={status} className={cN('status', dotsStyle(status))} />
        })
      }
    </div>
  )
}

export default RequestStatus
