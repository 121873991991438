import React from 'react'
import {Button} from 'react-bootstrap'
import TargetLanguages from '../target-languages/TargetLanguages'

import {downloadFile} from '../../utils'
import { source_languages } from '../../temp_lists'


import {defaultColumn, dateTimeColumn} from '../../tableColumnGenerators'

const getLanguageLabel = code => (source_languages.find((lang) => lang.code === code)?.name || code)


const languagesColumn = (dataField, text) => defaultColumn(dataField, text, {
    formatter: (cell) => cell && getLanguageLabel(cell),
    headerClasses: 'source-lang',
    classes: 'transform-none',
}, 'onek-source')

const targetsColumn = (dataField, text) => defaultColumn(dataField, text, {
    formatter: (cell) => cell && <TargetLanguages langs={cell} />
}, 'onek-target')

const priceColumn = (dataField, text) => defaultColumn(dataField, text, {
    formatter: (cell, item) => (item.total_price || '') + ' ' + (item.currency || ''),
    headerClasses: 'price',
    sort: true
}, 'onek-target')

const filesColumn = (dataField, text) => defaultColumn(dataField, text, {
    headerClasses: 'files',
    formatter: (cell, item) => {
        const downloadFiles = () => {
            Object.keys(item.file_urls).forEach(name => {
                const url = item.file_urls[name]
                downloadFile(url, name)
            });
        }
        return <Button onClick={downloadFiles}>Download</Button>
    }
})


// COLUMN GEN
export const columns = [ 
    defaultColumn('original_locreq_id', 'LR', {
        headerClasses: 'lr', 
        sort: true, 
        formatter: cell => 'LR - ' + cell,
    }),
    defaultColumn('service', 'Service', {headerClasses: 'service'}, 'onek-service'),
    dateTimeColumn('start', 'Created', {sort: true}, 'onek-started'), 
    dateTimeColumn('end', 'Closed', {sort: true}, 'onek-delivered'),
    languagesColumn('source_language', 'Source Language'),
    targetsColumn('target_language', 'Target Languages'),
    priceColumn('total_price', 'Cost'),
    filesColumn('file_urls', 'Files')
    /** */
]

export default columns