/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import BPSProject from '../model/BPSProject';
import BPSService from '../model/BPSService';
import ClientCreate from '../model/ClientCreate';
import ClientFull from '../model/ClientFull';
import ClientUpdate from '../model/ClientUpdate';
import Exception from '../model/Exception';
import InlineResponse200 from '../model/InlineResponse200';
import InlineResponse2001 from '../model/InlineResponse2001';
import LocalizationRequestArchived from '../model/LocalizationRequestArchived';
import MemoqProject from '../model/MemoqProject';
import ProjectArchived from '../model/ProjectArchived';
import ProjectCreateKWSPM from '../model/ProjectCreateKWSPM';
import ProjectFull from '../model/ProjectFull';
import S3PresignedURL from '../model/S3PresignedURL';
import User from '../model/User';
import UserCreate from '../model/UserCreate';

/**
* Clients service.
* @module api/ClientsApi
* @version v1
*/
export default class ClientsApi {

    /**
    * Constructs a new ClientsApi. 
    * @alias module:api/ClientsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the clientsArchivedProjectRead operation.
     * @callback module:api/ClientsApi~clientsArchivedProjectReadCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProjectArchived} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} archivedProjectId 
     * @param {String} clientId 
     * @param {module:api/ClientsApi~clientsArchivedProjectReadCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProjectArchived}
     */
    clientsArchivedProjectRead(archivedProjectId, clientId, callback) {
      let postBody = null;
      // verify the required parameter 'archivedProjectId' is set
      if (archivedProjectId === undefined || archivedProjectId === null) {
        throw new Error("Missing the required parameter 'archivedProjectId' when calling clientsArchivedProjectRead");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling clientsArchivedProjectRead");
      }

      let pathParams = {
        'archived_project_id': archivedProjectId,
        'client_id': clientId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ProjectArchived;
      return this.apiClient.callApi(
        '/clients/{client_id}/archived_project/{archived_project_id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the clientsArchivedProjectsArchivedLocreqsRead operation.
     * @callback module:api/ClientsApi~clientsArchivedProjectsArchivedLocreqsReadCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/LocalizationRequestArchived>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} archivedProjectId 
     * @param {String} clientId 
     * @param {module:api/ClientsApi~clientsArchivedProjectsArchivedLocreqsReadCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/LocalizationRequestArchived>}
     */
    clientsArchivedProjectsArchivedLocreqsRead(archivedProjectId, clientId, callback) {
      let postBody = null;
      // verify the required parameter 'archivedProjectId' is set
      if (archivedProjectId === undefined || archivedProjectId === null) {
        throw new Error("Missing the required parameter 'archivedProjectId' when calling clientsArchivedProjectsArchivedLocreqsRead");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling clientsArchivedProjectsArchivedLocreqsRead");
      }

      let pathParams = {
        'archived_project_id': archivedProjectId,
        'client_id': clientId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [LocalizationRequestArchived];
      return this.apiClient.callApi(
        '/clients/{client_id}/archived_projects/{archived_project_id}/archived_locreqs', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the clientsArchivedProjectsRead operation.
     * @callback module:api/ClientsApi~clientsArchivedProjectsReadCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/ProjectArchived>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} clientId 
     * @param {module:api/ClientsApi~clientsArchivedProjectsReadCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/ProjectArchived>}
     */
    clientsArchivedProjectsRead(clientId, callback) {
      let postBody = null;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling clientsArchivedProjectsRead");
      }

      let pathParams = {
        'client_id': clientId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ProjectArchived];
      return this.apiClient.callApi(
        '/clients/{client_id}/archived_projects', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the clientsBannerRead operation.
     * @callback module:api/ClientsApi~clientsBannerReadCallback
     * @param {String} error Error message, if any.
     * @param {module:model/S3PresignedURL} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} filename 
     * @param {module:api/ClientsApi~clientsBannerReadCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/S3PresignedURL}
     */
    clientsBannerRead(filename, callback) {
      let postBody = null;
      // verify the required parameter 'filename' is set
      if (filename === undefined || filename === null) {
        throw new Error("Missing the required parameter 'filename' when calling clientsBannerRead");
      }

      let pathParams = {
        'filename': filename
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = S3PresignedURL;
      return this.apiClient.callApi(
        '/clients/banner/{filename}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the clientsBpsProjectsList operation.
     * @callback module:api/ClientsApi~clientsBpsProjectsListCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/BPSProject>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} clientId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page A page number within the paginated result set.
     * @param {module:api/ClientsApi~clientsBpsProjectsListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/BPSProject>}
     */
    clientsBpsProjectsList(clientId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling clientsBpsProjectsList");
      }

      let pathParams = {
        'client_id': clientId
      };
      let queryParams = {
        'page': opts['page']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [BPSProject];
      return this.apiClient.callApi(
        '/clients/{client_id}/bps/projects', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the clientsBpsProjectsTargetLanguagesList operation.
     * @callback module:api/ClientsApi~clientsBpsProjectsTargetLanguagesListCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/BPSService>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} bpsProjectId 
     * @param {String} clientId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page A page number within the paginated result set.
     * @param {module:api/ClientsApi~clientsBpsProjectsTargetLanguagesListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/BPSService>}
     */
    clientsBpsProjectsTargetLanguagesList(bpsProjectId, clientId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'bpsProjectId' is set
      if (bpsProjectId === undefined || bpsProjectId === null) {
        throw new Error("Missing the required parameter 'bpsProjectId' when calling clientsBpsProjectsTargetLanguagesList");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling clientsBpsProjectsTargetLanguagesList");
      }

      let pathParams = {
        'bps_project_id': bpsProjectId,
        'client_id': clientId
      };
      let queryParams = {
        'page': opts['page']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [BPSService];
      return this.apiClient.callApi(
        '/clients/{client_id}/bps/projects/{bps_project_id}/target_languages', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the clientsCreate operation.
     * @callback module:api/ClientsApi~clientsCreateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ClientFull} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/ClientCreate} data 
     * @param {module:api/ClientsApi~clientsCreateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ClientFull}
     */
    clientsCreate(data, callback) {
      let postBody = data;
      // verify the required parameter 'data' is set
      if (data === undefined || data === null) {
        throw new Error("Missing the required parameter 'data' when calling clientsCreate");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ClientFull;
      return this.apiClient.callApi(
        '/clients/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the clientsLogoRead operation.
     * @callback module:api/ClientsApi~clientsLogoReadCallback
     * @param {String} error Error message, if any.
     * @param {module:model/S3PresignedURL} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} filename 
     * @param {module:api/ClientsApi~clientsLogoReadCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/S3PresignedURL}
     */
    clientsLogoRead(filename, callback) {
      let postBody = null;
      // verify the required parameter 'filename' is set
      if (filename === undefined || filename === null) {
        throw new Error("Missing the required parameter 'filename' when calling clientsLogoRead");
      }

      let pathParams = {
        'filename': filename
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = S3PresignedURL;
      return this.apiClient.callApi(
        '/clients/logo/{filename}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the clientsMemoqProjectsList operation.
     * @callback module:api/ClientsApi~clientsMemoqProjectsListCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/MemoqProject>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} clientId 
     * @param {module:api/ClientsApi~clientsMemoqProjectsListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/MemoqProject>}
     */
    clientsMemoqProjectsList(clientId, callback) {
      let postBody = null;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling clientsMemoqProjectsList");
      }

      let pathParams = {
        'client_id': clientId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [MemoqProject];
      return this.apiClient.callApi(
        '/clients/{client_id}/memoq/projects/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the clientsProjectsCreate operation.
     * @callback module:api/ClientsApi~clientsProjectsCreateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProjectFull} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} clientId 
     * @param {module:model/ProjectCreateKWSPM} data 
     * @param {module:api/ClientsApi~clientsProjectsCreateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProjectFull}
     */
    clientsProjectsCreate(clientId, data, callback) {
      let postBody = data;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling clientsProjectsCreate");
      }
      // verify the required parameter 'data' is set
      if (data === undefined || data === null) {
        throw new Error("Missing the required parameter 'data' when calling clientsProjectsCreate");
      }

      let pathParams = {
        'client_id': clientId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ProjectFull;
      return this.apiClient.callApi(
        '/clients/{client_id}/projects/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the clientsProjectsList operation.
     * @callback module:api/ClientsApi~clientsProjectsListCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse200} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} clientId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.ordering Which field to use when ordering the results. values: (-) name,created_at,state
     * @param {Number} opts.page A page number within the paginated result set.
     * @param {module:api/ClientsApi~clientsProjectsListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse200}
     */
    clientsProjectsList(clientId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling clientsProjectsList");
      }

      let pathParams = {
        'client_id': clientId
      };
      let queryParams = {
        'ordering': opts['ordering'],
        'page': opts['page']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse200;
      return this.apiClient.callApi(
        '/clients/{client_id}/projects/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the clientsRead operation.
     * @callback module:api/ClientsApi~clientsReadCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ClientFull} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} clientId 
     * @param {module:api/ClientsApi~clientsReadCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ClientFull}
     */
    clientsRead(clientId, callback) {
      let postBody = null;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling clientsRead");
      }

      let pathParams = {
        'client_id': clientId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ClientFull;
      return this.apiClient.callApi(
        '/clients/{client_id}/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the clientsTriggerAsyncList operation.
     * @callback module:api/ClientsApi~clientsTriggerAsyncListCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page A page number within the paginated result set.
     * @param {module:api/ClientsApi~clientsTriggerAsyncListCallback} callback The callback function, accepting three arguments: error, data, response
     */
    clientsTriggerAsyncList(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/clients/trigger_async', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the clientsUpdate operation.
     * @callback module:api/ClientsApi~clientsUpdateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ClientFull} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} clientId 
     * @param {module:model/ClientUpdate} data 
     * @param {module:api/ClientsApi~clientsUpdateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ClientFull}
     */
    clientsUpdate(clientId, data, callback) {
      let postBody = data;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling clientsUpdate");
      }
      // verify the required parameter 'data' is set
      if (data === undefined || data === null) {
        throw new Error("Missing the required parameter 'data' when calling clientsUpdate");
      }

      let pathParams = {
        'client_id': clientId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ClientFull;
      return this.apiClient.callApi(
        '/clients/{client_id}/', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the clientsUsersCreate operation.
     * @callback module:api/ClientsApi~clientsUsersCreateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/User} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} clientId 
     * @param {module:model/UserCreate} data 
     * @param {module:api/ClientsApi~clientsUsersCreateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/User}
     */
    clientsUsersCreate(clientId, data, callback) {
      let postBody = data;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling clientsUsersCreate");
      }
      // verify the required parameter 'data' is set
      if (data === undefined || data === null) {
        throw new Error("Missing the required parameter 'data' when calling clientsUsersCreate");
      }

      let pathParams = {
        'client_id': clientId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = User;
      return this.apiClient.callApi(
        '/clients/{client_id}/users', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the clientsUsersList operation.
     * @callback module:api/ClientsApi~clientsUsersListCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2001} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} clientId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page A page number within the paginated result set.
     * @param {String} opts.userType filter by user type, if not set, all users will be returnedclient-pm,kws-pm
     * @param {module:api/ClientsApi~clientsUsersListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse2001}
     */
    clientsUsersList(clientId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling clientsUsersList");
      }

      let pathParams = {
        'client_id': clientId
      };
      let queryParams = {
        'page': opts['page'],
        'user_type': opts['userType']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2001;
      return this.apiClient.callApi(
        '/clients/{client_id}/users', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
