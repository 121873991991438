import { target_languages, services } from '../temp_lists'

export const addSeparatorToLanguageCode = code => code.substr(0, 3).toLowerCase() + '-' + code.substr(3).toUpperCase()

export const languageLabelFromCode = code => target_languages.find(l => l.code === code)?.name

export const serviceLabelFromCode = code => services.find(l => l.code === code)?.name

// logic of active services
export const checkIfThereAreActiveServices = project => {
  if (!project?.services) {
    return false
  }
  
  const activeServices = project.services.filter(service => service.is_active)
  return !!activeServices.length
}