import {configureMenuItems, isProjectPage, isClientPage, isArchivedPage} from './utils'

// generate logic for KWSPM routes
export const clientKWSPMRoutes = ({
    currentProject, currentClient,
    toggleClientEdit, toggleProjectEdit, toggleUserEdit,
    history, user
}, callback) => (pathComponents) => {

    configureMenuItems[2].isActive = true
    configureMenuItems[2].onClick = () => toggleUserEdit(true)
    
    // /project/{projectId}
    if (isProjectPage(pathComponents)) {
        if (!currentProject || !currentClient) {
            return callback([configureMenuItems[2]])
        }

        const itemClient = configureMenuItems[0]
        itemClient.isActive = !user?.isClientPM 
        itemClient.onClick = () => {
            toggleClientEdit(currentClient)
            history.replace('/client/' + currentProject.client)
        }

        const itemProj = configureMenuItems[1]
        itemProj.isActive = true
        itemProj.onClick = () => toggleProjectEdit(currentProject)

        const items = [itemClient, itemProj].concat(configureMenuItems[2])
        return callback(items)
    }

    if (isClientPage(pathComponents)) {
        if (!currentClient) {
            return callback([configureMenuItems[2]])
        }

        // /client/{clientId}
        if (!pathComponents[2]) {
            const item = configureMenuItems[0]
            item.isActive = true
            item.onClick = () => {
                toggleClientEdit(currentClient)
                history.replace('/client/' + currentClient.id)
            }
            callback([item, configureMenuItems[2]])
            return
        }

        // /client/{clientId}/*
        const item = configureMenuItems[0]
        item.isActive = false
        item.onClick = () => {
            toggleClientEdit(currentClient)
            if (!pathComponents[2]) { return }

            // /client/{clientId}/*
            history.replace('/client/' + currentClient.id)
        }

        const items = isArchivedPage(pathComponents) ? [item] : [item, configureMenuItems[2]]

        return callback(items)
    }

    const items = [configureMenuItems[2]]

    callback(items)
}
