import axios from 'axios'
import supportedTypes from '../supportedTypes'

// logic helpers
export const sizeLabel = size => {
    const kbSize = Math.floor( size / Math.pow(10, 3) )
    const mbSize = Math.floor( size / Math.pow(10, 6) )
    const gbSize = Math.floor( size / Math.pow(10, 9) )

    return gbSize ? gbSize + 'GB' : mbSize ? mbSize + 'MB' : kbSize + 'KB'
}

// 
export const getExtension = file => file.name
    .substr(file.name.lastIndexOf('.'))
    .toLowerCase()
  
export const defaultIcon = 'file-alt'

export const getIcon = file => {
    const type = supportedTypes
        .find(t => t?.extension === getExtension(file))

    return type ? type?.icon : defaultIcon
}
//

const groupFiles = files => {
    const groups = {}
    files.forEach(file => {
        if (!file) {return}

        const type = getExtension(file)
        groups[type] = groups[type] || []
        groups[type].push(file)
    })
    return groups
}

export const filterValidFiles = (alreadyDropped, newFiles, validationOptions) => {
    const droppedList = Object.keys(alreadyDropped).map(name => alreadyDropped[name]) || []


    // A file could be uploaded multiple times. 
    // In that case, simply remove the duplicate files in newFiles
    const newList = Object.keys(newFiles)
    // eslint-disable-next-line array-callback-return
    .map(key => {
        const alreadyUploaded = droppedList.findIndex(dropped => newFiles[key].name === dropped.name) > -1

        if (!alreadyUploaded) {
            return newFiles[key]
        }
    }) || []

    const droppedGrouped = groupFiles(droppedList)
    const newGrouped = groupFiles(newList)
    const validFiles = []
    const rejectedFiles = [] // too big
    const ignoredFiles = [] // too many


    // for files too big, its easy,
    // for too many files, it needs to check the files that are already here...

    Object.keys(newGrouped)
    .forEach(ext => {

        // jpg and jpeg are interchangeable. Go figures...
        const typ = ext.substring(1)
        const type = typ === 'jpeg' ? 'jpg' : typ
        const typeValidationOptions = validationOptions[type]

        const {maxFiles, maxSize} = typeValidationOptions || {}
        let files = newGrouped[ext].map(file => {
            file.ext = type
            return file
        })

        if (maxSize) {
            // check file size
            files = files.filter(file => {
                const isValid = file.size < maxSize
                if (!isValid) {
                    rejectedFiles.push(file)
                }
                return isValid
            })
        }

        if (maxFiles) {
            // check number of files already uploaded
            droppedGrouped[ext] = droppedGrouped[ext] || []
            droppedGrouped['.jpg'] = droppedGrouped['.jpg'] || []
            droppedGrouped['.jpeg'] = droppedGrouped['.jpeg'] || []

            const droppedCount = (ext === '.jpg' || ext === '.jpeg') 
            ? droppedGrouped['.jpg'].length + droppedGrouped['.jpeg'].length
            : droppedGrouped[ext].length
            
            files = files.filter((file, i) => {
                const isValid = (i + droppedCount) < maxFiles
                if (!isValid) {
                    ignoredFiles.push(file)
                }
                return isValid
            })
        }

        files.forEach(file => validFiles.push(file))
    })
    
    return {validFiles, rejectedFiles, ignoredFiles}
}


export const computeNewFiles = (files, droppedFiles, maxFiles) => {
    const filesNames = Object.keys(files)
    const ignoredFiles = []
    let newFiles = []

    if ((filesNames.length + droppedFiles.length) > maxFiles) {
      let limitNewFiles = maxFiles - filesNames.length
      let newFilesCount = 0

      droppedFiles.forEach((file) => {
        if (filesNames.indexOf(file.name) > -1) {
          newFiles.push(file)
        } else if (newFilesCount < limitNewFiles) {
          newFiles.push(file)
          newFilesCount++
        } else {
            ignoredFiles.concat(file)
        }
      })
    } else {
      newFiles = droppedFiles
    }

    return {newFiles, ignoredFiles}
}

//
export function uploadedUrls(presignedUrls, mappedFiles, onProgress, onCompleted, onError) {
    presignedUrls.urls.forEach(presignedUrl => {
        const fields = presignedUrl.fields
        const fileName = presignedUrl.name

        if (fileName && mappedFiles[fileName]) {
        const file = mappedFiles[fileName].originalFile
        const formData = new FormData()
        formData.append('Key', fields.key)
        formData.append('AWSAccessKeyId', fields.AWSAccessKeyId)
        formData.append('policy', fields.policy)
        formData.append('signature', fields.signature)
        formData.append('Content-Type', file.type)

        if (fields.hasOwnProperty('x-amz-security-token')) {
            formData.append('x-amz-security-token', fields['x-amz-security-token'])
        }

        formData.append('file', file)

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress: function(progressEvent) {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)

                onProgress(progress, fileName)
            }
        }

        axios.post(presignedUrl.url, formData,config)
            .then(() => onCompleted(file, fileName, presignedUrl))
            .catch((e) => onError(e, fileName))
        }
    })
}