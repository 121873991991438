/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import S3PresignedURL from './S3PresignedURL';

/**
 * The S3PresignedURLResponse model module.
 * @module model/S3PresignedURLResponse
 * @version v1
 */
class S3PresignedURLResponse {
    /**
     * Constructs a new <code>S3PresignedURLResponse</code>.
     * @alias module:model/S3PresignedURLResponse
     * @param urls {Array.<module:model/S3PresignedURL>} 
     */
    constructor(urls) { 
        
        S3PresignedURLResponse.initialize(this, urls);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, urls) { 
        obj['urls'] = urls;
    }

    /**
     * Constructs a <code>S3PresignedURLResponse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/S3PresignedURLResponse} obj Optional instance to populate.
     * @return {module:model/S3PresignedURLResponse} The populated <code>S3PresignedURLResponse</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new S3PresignedURLResponse();

            if (data.hasOwnProperty('urls')) {
                obj['urls'] = ApiClient.convertToType(data['urls'], [S3PresignedURL]);
            }
        }
        return obj;
    }


}

/**
 * @member {Array.<module:model/S3PresignedURL>} urls
 */
S3PresignedURLResponse.prototype['urls'] = undefined;






export default S3PresignedURLResponse;

