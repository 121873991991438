import React from 'react'
import './target-languages.scss'

import { OverlayTrigger, Tooltip} from 'react-bootstrap'

import { addSeparatorToLanguageCode } from '../../utils'

// params
const LABEL_SEPARATOR = ', '
const LABEL_TRUNCATION = ' ...'
const LANGUAGES_IN_ONE_LINE_OF_TOOLTIP = 10
const MAX_LANGUAGES_IN_LINE = 5

// LABEL LINE LOGIC
const languagesLabel = (languages) => {
  const labels = languages.slice(0, MAX_LANGUAGES_IN_LINE)

  const label = labels.join(LABEL_SEPARATOR) + (languages.length === labels.length ? '' : LABEL_TRUNCATION)
  
  return label
}

// TOOLTIP COMPONENT
const LanguagesTooltip = (props) => {
  const {languages} = props
  const lineLabels = []

  languages.forEach((language, i) => {
    const lineIndex = Math.floor(i / LANGUAGES_IN_ONE_LINE_OF_TOOLTIP)
    lineLabels[lineIndex] = lineLabels[lineIndex] || []
    lineLabels[lineIndex].push(language)
  })

  return <div>
    {lineLabels.map((lineLabel, i) => <span key={'TooltipLine - ' + i}>
      {lineLabel.join(LABEL_SEPARATOR)}
      <br />
    </span>)}
  </div>
}

// COMPONENT
const TargetLanguages = (props) => {

  const parsedLanguages = props.langs.map(addSeparatorToLanguageCode)
  const languagesString = parsedLanguages.join(LABEL_SEPARATOR)

  switch (props.langs.length) {
    case 0:
      return ''
    case 1:
    case 2:
      return <span className="transform-none">
        { languagesString }
      </span>
    default:
      return <OverlayTrigger placement="bottom"
        overlay={
          <Tooltip>
            <LanguagesTooltip languages={parsedLanguages} />
          </Tooltip>
        }>
        <div className="TargetLanguages ellipsis transform-none">
          { languagesLabel(parsedLanguages) }
        </div>
      </OverlayTrigger>
  }
}

export default TargetLanguages
