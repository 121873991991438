import React, { useState } from 'react'
import './App.scss'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

import Background from './components/background/Background'
import Header from './components/header/Header'
import Dashboard from './components/dashboard/Dashboard'
import ProjectDashboard from './components/project-dashboard/ProjectDashboard'
import Guest from './components/guest/Guest'
import Terms from './components/terms/Terms'
import PrivateRoute from './components/PrivateRoute'
import KWSDashboard from './components/kws-dashboard/KWSDashboard'
import JobAssignment from './components/job-assignment/job-assignment'
import ArchivedProjects from './components/archived-projects'
import ArchivedRequests from './components/archived-requests'

import GlobalProvider from './context/GlobalState'

import { defaults } from 'react-chartjs-2'

// Customize ChartJS Defaults
const ticks = {
  callback: (value) => value.toLocaleString()
}
defaults.line.scales.yAxes[0].ticks = ticks
defaults.bar.scales.yAxes[0].ticks = ticks
defaults.global.tooltips.callbacks.label = (item, data) => {
  let label = data.datasets[item.datasetIndex].label || ''

  if (label) {
    label += ': '
  }

  return label + parseFloat(item.value).toLocaleString()
}

function App() {
  const [activeGroup, setActiveGroup] = useState(null)

  function highlightLetters(e) {
    e.stopPropagation()

    var elements = document.elementsFromPoint(e.clientX, e.clientY)
    var group = elements.find(function (el) {
      return el.classList.contains('letter-group')
    })

    if (group && !group.classList.contains('highlight')) {
      document
        .querySelectorAll('.letter-group')
        .forEach(function (el) {
          el.classList.remove('highlight')
        })
      group.classList.add('highlight')
      const groupId = parseInt(group.id.substr(group.id.indexOf('-') + 1))
      setActiveGroup(groupId)
    }
  }

  return (
    <div className="App"
      onMouseMove={highlightLetters}>
      <ToastContainer />
      <GlobalProvider>
        <Background activeGroup={activeGroup} />
        <Router>
          <Header />
          <Switch>
            <Route path={["/login", "/forgot-password", "/reset-password"]}>
              <Guest />
            </Route>
            <Route exact path="/terms">
              <Terms />
            </Route>
            <PrivateRoute exact path={[
              "/",
              "/client/:id",
              "/stats",
              "/client/:id/stats",
            ]}>
              <Dashboard />
            </PrivateRoute>
            <PrivateRoute exact path="/project/:id">
              <ProjectDashboard />
            </PrivateRoute>
            <PrivateRoute exact path={["/dashboard", "/dashboard/stats"]}>
              <KWSDashboard />
            </PrivateRoute>
            <PrivateRoute exact path={['/jobs/:projectId/:requestId/:language/:token']}>
              <JobAssignment />
            </PrivateRoute>

            <PrivateRoute exact path={"/client/:clientId/archived"}>
              <ArchivedProjects />
            </PrivateRoute>
            <PrivateRoute exact path={"/client/:clientId/archived/:projectId"}>
              <ArchivedRequests />
            </PrivateRoute>
          </Switch>
        </Router>
      </GlobalProvider>
    </div>
  )
}

export default App
