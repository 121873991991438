import React, { useState, useContext, useEffect } from 'react'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from "yup"
import cN from "classnames"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col, Form, Spinner } from 'react-bootstrap'
import Select from 'react-select'

import { GlobalContext } from '../../../context/GlobalState'

import { capitalize, selectStyles, selectTheme, noOptionsMessage } from '../../../utils'

import Loading from '../../loading/Loading'
import ResourcesSection from './resources'


const ServiceTab = props => {
  const { 
    memoqProjects, memoqProjectsNotFound, editProject, 
    pollProjectUpdate, updatingResources, setResourcesIsSyncing,
    getResources 
  } = useContext(GlobalContext)
  const [selectedProject, setSelectedProject] = useState(null)
  const [resources, setResources] = useState(null)
  const [resourcesNotFound, setResourcesNotFound] = useState(false)
  const [serviceIsActive, setServiceIsActive] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  

  const schema = yup.object().shape({
    memoq_project_guid_lite: yup.object().shape({
      value: yup.string().nullable()
    }),
    memoq_project_guid_platinum: yup.object().shape({
      value: yup.string().nullable()
    })
  })

  useEffect(() => {

    if (props.project && memoqProjects) {
      prefillValues(props.project)
    }

    if (props.project && props.service && !resources) {
      fetchResources()
    }

    // eslint-disable-next-line
  }, [props.project, memoqProjects])

  const { control, errors, setValue, trigger, setError } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const onSubmit = (selectedItem) => {
    setSubmitting(true)
    setResourcesIsSyncing(props.service)
    // setSpinner(true)
    editProject(props.project.id, {
      bps_project_id: props.project.bps_project_id,
      name: props.project.name,
      description: props.project.description,
      services: [{
        service: props.service,
        memoq_guid: selectedItem.value,
        memoq_name: selectedItem.label
      }]
    })
    .then(project => {
      pollProjectUpdate(project.id)
      .then(() => {
        setSelectedProject(selectedItem)
        fetchResources(null, serviceErrorHandler)
        props.onProjectUpdated(project)
      })
      .catch(console.error)
      .finally(() => setResourcesIsSyncing(false))
    })
    .catch((e) => { 
      // set error
      updatingResources(false)
      serviceErrorHandler(e)
    })
    .finally(() => setSubmitting(false))
  }

  const prefillValues = project => {
    if (project.services && project.services.length) {

      // set active services
      const actives = {}

      project.services.forEach(function (service) {

        actives[service.service] = service.is_active

        if (service.service === props.service
          && memoqProjects.length) {
          const item = {
            value: service.memoq_guid,
            label: service.memoq_name,
          }
          setValue('memoq_project_guid_' + service.service, item)
          setSelectedProject(item)
          setServiceIsActive(service.is_active)
        }
      })
    }

    trigger()
  }

  const fetchResources = (onResult = () => {}, onError = () => {}) => {
    getResources(props.project.id, props.service)
      .then((resources) => {
        setResources(resources)
        onResult()
      })
      .catch(() => {
        setServiceIsActive(false)
        setResourcesNotFound(true)
        onError()
      })
  }

  const serviceErrorHandler = errorResponse => {
    const defaultMessage = 'This service is either missing or not configured properly'

    const {status, body} = errorResponse || {}
    const message = status === 409 ? body : defaultMessage

    setError(`memoq_project_guid_${props.service}.value`, {
      type: 'manual',
      message
    })
  }


  return (<>
    <h1>
      Translation { capitalize(props.service) } Service
      <button className="icon-only" color="teal"> <FontAwesomeIcon  color={serviceIsActive ? "Chartreuse"  : "Crimson"} icon={serviceIsActive ? "check" : "times"}  /> </button>
    </h1>

    {/* PROJECT SERVICE SECTION */}
    <Row className="fields">
      <Col>
        <Form className="h-auto">
          <Form.Group>
            <Form.Label> MemoQ Project </Form.Label>
            <Controller
              id={ `memoq_project_guid_${props.service}` }
              name={ `memoq_project_guid_${props.service}` }
              render={({ onChange }) => (
                memoqProjectsNotFound
                  ? <p className="text-danger">Memoq Projects not found</p>
                  : selectedProject ||
                    (!props.project?.services?.find((s) => s.service === props.service) && memoqProjects.length)
                    ? <Row>
                      <Col>
                      <Select
                        data-dropup-auto="false"
                        onChange={(selectedItem) => {
                          onChange(selectedItem)
                          onSubmit(selectedItem)
                        }}
                        placeholder="Select project..."
                        styles={selectStyles}
                        theme={selectTheme}
                        className={cN('form-control', {
                          'is-invalid': errors[`memoq_project_guid_${props.service}`]
                        })}
                        options={ memoqProjects.map((project) => {
                          return {
                            value: project.guid,
                            label: project.name
                          }
                        }) }
                        noOptionsMessage={noOptionsMessage}
                        defaultValue={ selectedProject || null }
                        isDisabled={submitting || (selectedProject &&
                          props.project?.services?.find((s) => s.service === props.service))}
                      />
                      </Col>
                    { updatingResources && <Col style={{width: '20px'}}>
                      <Spinner animation='border' 
                          className='d-flex justify-content-center'
                          style={{
                              width: '20px', height: '20px', 
                              marginLeft: '5px', marginRight: '5px'
                          }} 
                      />
                    </Col>}
                  </Row>
                  : <Row>
                    <Loading resource="MemoQ projects" />
                  </Row>
              )}
              control={control}
            />
            <Form.Control.Feedback type="invalid">
              {errors[`memoq_project_guid_${props.service}`]?.value?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Col>
      {}
    </Row>
    {/* PROJECT SERVICE SECTION */}


    {/* RESOURCES SECTION */}
    <Row>
      <Col>
        <ResourcesSection resources={resources} 
                          resourcesNotFound={resourcesNotFound} 
                          setResources={setResources} 
                          setSubmitting={setSubmitting}
                          submitting={submitting}
                          serviceIsActive={serviceIsActive}
                          setServiceIsActive={setServiceIsActive}
                          project={props.project} 
                          service={props.service} />
      </Col>
    </Row>
  </>)
}

export default ServiceTab
