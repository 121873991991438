import moment from 'moment'


export const roundUpDateTime = dateTime => {
    const rounded = moment(Number(dateTime) * 1000)
    const minutes = rounded.minute()
    
    if (minutes < 30) {
        if (minutes) {
            rounded.set({
            hour:   rounded.get('hour'),
            minute: 30,
            second: 0
        })
      }
      
    } else {
        rounded.set({
            hour:   rounded.get('hour') + 1,
            minute: 0,
            second: 0
        })
    }
  
    return rounded.unix()
}
  
export const compareDates = (d1, d2) => moment(d1).isAfter(d2, 'day')