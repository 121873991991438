import React from 'react'
import Dropzone  from 'react-dropzone'

import { Button, Toast } from 'react-bootstrap'

const UploadFileBtn = (props) =>  {
  const {
    accept, children, maxSize, uploading, 
    onFilesDrop
  } = props

  //
  const fileMaxSize = maxSize * 1000000
  const errorMessage = {
    'file-invalid-type': 'File type must be an image',
    'file-too-large': `File is larger than ${maxSize}MB`
  }
  // 

  // EVENTS
  const onFilesDropped = (droppedFiles) =>  {
    const image = droppedFiles.pop()
    onFilesDrop(image)
  }
  //


  return <Dropzone
      onDrop={onFilesDropped}
      accept={accept}
      maxSize={fileMaxSize}
      noDrag noClick noKeyboard>
    {({ getRootProps, getInputProps, fileRejections, open }) => (
      <div>
        <section {...getRootProps()} className="Dropzone-btn-only">
          <input {...getInputProps()} />
          <Button id="logo-btn"
            variant="secondary"
            type="button"
            className="mr-2"
            disabled={uploading}
            onClick={open}>
            { uploading ? 'Uploading...' : children }
          </Button>
          { !!fileMaxSize && <span>(max size: {maxSize}MB)</span> }
        </section>

        { !!fileRejections.length &&
          <Toast className="mt-2">
            <Toast.Header closeButton={false}>
              <strong className="mr-auto">Rejected file</strong>
            </Toast.Header>
            <Toast.Body>
                {
                  fileRejections.map(({ file, errors }) => {
                    let error = errors.pop()

                    if (!error) {
                      error = {}
                      if (file.size && file.size > fileMaxSize) {
                        error.code = 'file-too-large'
                      } else {
                        error.code = 'file-invalid-type'
                      }
                    }

                    return <span key={file.name}>
                      { `${file.name}: ${errorMessage[error.code] || 'Unknown error'}` }
                    </span>
                  })
                }
            </Toast.Body>
          </Toast>
        }
      </div>
    )}
  </Dropzone>
  
}

export default UploadFileBtn
