import React, { useState, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'

import { Row, Col, Card, Form, Button, Toast } from 'react-bootstrap'
import cN from 'classnames'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from "yup"

import { GlobalContext } from '../../context/GlobalState'

const ResetPassword = () => {
  let { uid, token } = useParams()
  const { resetPassword } = useContext(GlobalContext)

  const [errorMsg, setErrorMsg] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const schema = yup.object().shape({
    password1: yup.string().required('Password is required'),
    password2: yup.string()
     .oneOf([yup.ref('password1'), null], 'Passwords must match')
  })

  const { handleSubmit, control, errors, reset } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    setSubmitting(true)
    setShowError(false)

    resetPassword(uid, {
      ...data,
      token
    })
      .then(() => {
        setSubmitting(false)
        setShowSuccess(true)
        reset()
      })
      .catch((err) => {
        setSubmitting(false)
        try {
          let errorMsg = JSON.parse(err.response.text).detail
          setErrorMsg(errorMsg)
        } catch (e) {
          let errorMsg = err.response.text
          setErrorMsg(errorMsg)
        }
        setShowError(true)
      })
  }

  return (
    <Card className="Guest-form">
      <Card.Body>
      { !!uid && !!token
        ? <>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <h1>SET A NEW PASSWORD</h1>
            <Toast className="success" show={showSuccess}>
              <Toast.Header closeButton={false}>
                <strong className="mr-auto">Password updated</strong>
              </Toast.Header>
              <Toast.Body>
                Your password has been updated, you can now use it to log in
              </Toast.Body>
            </Toast>
            <Toast show={showError}
              onClose={() => setShowError(false)}>
              <Toast.Header>
                <strong className="mr-auto">Password update failed</strong>
              </Toast.Header>
              <Toast.Body>{ errorMsg }</Toast.Body>
            </Toast>
            <Form.Group controlId="password1">
              <Form.Label>New Password</Form.Label>
              <Controller as={Form.Control}
                name="password1"
                type="password"
                control={control}
                defaultValue=""
                className={cN({
                  'is-invalid': errors.password1
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password1?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="password2">
              <Form.Label>Password Confirmation</Form.Label>
              <Controller as={Form.Control}
                name="password2"
                type="password"
                control={control}
                defaultValue=""
                className={cN({
                  'is-invalid': errors.password2
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password2?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Button id="submit-btn"
              variant="primary"
              type="submit"
              disabled={submitting}>
              { submitting ? 'Submitting...' : 'Submit' }
            </Button>
            <Row>
              <Col sm={6} className="d-flex justify-content-center justify-content-sm-start">
              </Col>
              <Col sm={6} className="d-flex align-items-center justify-content-center justify-content-sm-end mt-3 mt-sm-0">
              </Col>
            </Row>
          </Form>
          <div className="text-center">
            <strong>
              <Link id="back-link"
                to="/login">
                Go to login
              </Link>
            </strong>
          </div>
        </>
        : <Toast id="wrong-url-toast">
          <Toast.Header closeButton={false}>
            <strong className="mr-auto">Wrong URL</strong>
          </Toast.Header>
          <Toast.Body>
            <p>The URL to reset your password is invalid.</p>
            <Link to="/login">Go to login</Link>
          </Toast.Body>
        </Toast>
      }
      </Card.Body>
    </Card>
  )
}

export default ResetPassword
