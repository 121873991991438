/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ProjectMember model module.
 * @module model/ProjectMember
 * @version v1
 */
class ProjectMember {
    /**
     * Constructs a new <code>ProjectMember</code>.
     * @alias module:model/ProjectMember
     * @param id {Number} 
     * @param email {String} 
     * @param username {String} 
     * @param firstName {String} 
     * @param lastName {String} 
     * @param createdAt {String} 
     * @param isAdmin {Boolean} 
     * @param clientId {Number} 
     * @param userType {module:model/ProjectMember.UserTypeEnum} 
     */
    constructor(id, email, username, firstName, lastName, createdAt, isAdmin, clientId, userType) { 
        
        ProjectMember.initialize(this, id, email, username, firstName, lastName, createdAt, isAdmin, clientId, userType);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, email, username, firstName, lastName, createdAt, isAdmin, clientId, userType) { 
        obj['id'] = id;
        obj['email'] = email;
        obj['username'] = username;
        obj['first_name'] = firstName;
        obj['last_name'] = lastName;
        obj['created_at'] = createdAt;
        obj['is_admin'] = isAdmin;
        obj['client_id'] = clientId;
        obj['user_type'] = userType;
    }

    /**
     * Constructs a <code>ProjectMember</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProjectMember} obj Optional instance to populate.
     * @return {module:model/ProjectMember} The populated <code>ProjectMember</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProjectMember();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('first_name')) {
                obj['first_name'] = ApiClient.convertToType(data['first_name'], 'String');
            }
            if (data.hasOwnProperty('last_name')) {
                obj['last_name'] = ApiClient.convertToType(data['last_name'], 'String');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'String');
            }
            if (data.hasOwnProperty('is_admin')) {
                obj['is_admin'] = ApiClient.convertToType(data['is_admin'], 'Boolean');
            }
            if (data.hasOwnProperty('client_id')) {
                obj['client_id'] = ApiClient.convertToType(data['client_id'], 'Number');
            }
            if (data.hasOwnProperty('user_type')) {
                obj['user_type'] = ApiClient.convertToType(data['user_type'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
ProjectMember.prototype['id'] = undefined;

/**
 * @member {String} email
 */
ProjectMember.prototype['email'] = undefined;

/**
 * @member {String} username
 */
ProjectMember.prototype['username'] = undefined;

/**
 * @member {String} first_name
 */
ProjectMember.prototype['first_name'] = undefined;

/**
 * @member {String} last_name
 */
ProjectMember.prototype['last_name'] = undefined;

/**
 * @member {String} created_at
 */
ProjectMember.prototype['created_at'] = undefined;

/**
 * @member {Boolean} is_admin
 */
ProjectMember.prototype['is_admin'] = undefined;

/**
 * @member {Number} client_id
 */
ProjectMember.prototype['client_id'] = undefined;

/**
 * @member {module:model/ProjectMember.UserTypeEnum} user_type
 */
ProjectMember.prototype['user_type'] = undefined;





/**
 * Allowed values for the <code>user_type</code> property.
 * @enum {String}
 * @readonly
 */
ProjectMember['UserTypeEnum'] = {

    /**
     * value: "client-pm"
     * @const
     */
    "client-pm": "client-pm",

    /**
     * value: "kws-pm"
     * @const
     */
    "kws-pm": "kws-pm"
};



export default ProjectMember;

