import React from 'react'

import Moment from 'react-moment'
import moment from 'moment'
import LPTooltip from './lp-tooltip'

const LPDateTime = (props) => {

  if (!props.timestamp) {
    return null
  }

  const offset = moment(props.timestamp, 'X').utcOffset()
  const offsetStr = offset
    ? `GMT${offset > 0 ? '+' : ''}${parseInt(offset / 60)}`
    : null

  const datetime = <Moment format="DD/MMM/YY hh:mm A" unix>
    { props.timestamp }
  </Moment>
  
  const fullDatetime = <span>
    <Moment format="DD/MMM/YY hh:mm A " unix>
      { props.timestamp }
    </Moment>
    {offsetStr}
  </span>

  return <LPTooltip
    placement="top"
    tooltip={fullDatetime}>
    { datetime }
  </LPTooltip>
}

export default LPDateTime
