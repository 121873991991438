/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Add model module.
 * @module model/Add
 * @version v1
 */
class Add {
    /**
     * Constructs a new <code>Add</code>.
     * @alias module:model/Add
     * @param numa {Number} 
     * @param numb {Number} 
     */
    constructor(numa, numb) { 
        
        Add.initialize(this, numa, numb);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, numa, numb) { 
        obj['numa'] = numa;
        obj['numb'] = numb;
    }

    /**
     * Constructs a <code>Add</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Add} obj Optional instance to populate.
     * @return {module:model/Add} The populated <code>Add</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Add();

            if (data.hasOwnProperty('numa')) {
                obj['numa'] = ApiClient.convertToType(data['numa'], 'Number');
            }
            if (data.hasOwnProperty('numb')) {
                obj['numb'] = ApiClient.convertToType(data['numb'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} numa
 */
Add.prototype['numa'] = undefined;

/**
 * @member {Number} numb
 */
Add.prototype['numb'] = undefined;






export default Add;

