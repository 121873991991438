export * from './history'
export * from './letters'
export * from './theme'
export * from './date'
export * from './file'
export * from './targetLanguage'

/* misc */
export const noOptionsMessage = ({ inputValue }) => `No matches found with ${inputValue}`

export const disassebleRoute = route => {
  if (!route) {
    return []
  }

  const routeElements = route.split('/')
  routeElements.shift()

  return routeElements
}

export const resourceRoles = [{
    value: 'T',
    name: 'Translator'
}, {
    value: 'R',
    name: 'Reviewer'
}, {
    value: 'N/A',
    name: 'Not Applicable'
}]

/* parsers */
const userTypes = {
  kwsPM: 'kws-pm',
  clientPM: 'client-pm'
}
export const parseUser = u => ({
  ...u,
  name: (u.first_name || u.last_name)
    ? `${u.first_name} ${u.last_name}`
    : u.username,
  isKWSPM: u.user_type === userTypes.kwsPM,
  isClientPM: u.user_type === userTypes.clientPM,
})

export const parseAssociatedClients = (users, clientId) => {
  if (!(users && users.length)) {
    return {sortedUsers: users, activeUsersMap: {}}
  }

  const activeUsersMap = {}
  const sortedUsers = sortAssociatedUsers(
    users.map(user => {
      const isActive = parseUserClients(user, clientId)
      activeUsersMap[user.id] = isActive
      
      return parseUser(user)
    })
  )

  return {sortedUsers, activeUsersMap}
}

const parseUserClients = (user, clientId) => {
  const {clients = []} = user
  return (clients && clients.length && !!clients.find(client => Number(client) === Number(clientId)))
}

const sortAssociatedUsers = (users) => {
  return users.sort((a, b) => {
    return a.name.toLowerCase() > b.name.toLowerCase()
      ? 1 : -1
  })
}
