/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ProjectServiceLite from './ProjectServiceLite';

/**
 * The ProjectUpdateKWSPM model module.
 * @module model/ProjectUpdateKWSPM
 * @version v1
 */
class ProjectUpdateKWSPM {
    /**
     * Constructs a new <code>ProjectUpdateKWSPM</code>.
     * @alias module:model/ProjectUpdateKWSPM
     * @param description {String} 
     * @param name {String} 
     * @param bpsProjectId {Number} 
     * @param services {Array.<module:model/ProjectServiceLite>} 
     */
    constructor(description, name, bpsProjectId, services) { 
        
        ProjectUpdateKWSPM.initialize(this, description, name, bpsProjectId, services);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, description, name, bpsProjectId, services) { 
        obj['description'] = description;
        obj['name'] = name;
        obj['bps_project_id'] = bpsProjectId;
        obj['services'] = services;
    }

    /**
     * Constructs a <code>ProjectUpdateKWSPM</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProjectUpdateKWSPM} obj Optional instance to populate.
     * @return {module:model/ProjectUpdateKWSPM} The populated <code>ProjectUpdateKWSPM</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProjectUpdateKWSPM();

            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('bps_project_id')) {
                obj['bps_project_id'] = ApiClient.convertToType(data['bps_project_id'], 'Number');
            }
            if (data.hasOwnProperty('services')) {
                obj['services'] = ApiClient.convertToType(data['services'], [ProjectServiceLite]);
            }
        }
        return obj;
    }


}

/**
 * @member {String} description
 */
ProjectUpdateKWSPM.prototype['description'] = undefined;

/**
 * @member {String} name
 */
ProjectUpdateKWSPM.prototype['name'] = undefined;

/**
 * @member {Number} bps_project_id
 */
ProjectUpdateKWSPM.prototype['bps_project_id'] = undefined;

/**
 * @member {Array.<module:model/ProjectServiceLite>} services
 */
ProjectUpdateKWSPM.prototype['services'] = undefined;






export default ProjectUpdateKWSPM;

