/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ProjectService from './ProjectService';
import UserWithoutClient from './UserWithoutClient';

/**
 * The ProjectFull model module.
 * @module model/ProjectFull
 * @version v1
 */
class ProjectFull {
    /**
     * Constructs a new <code>ProjectFull</code>.
     * @alias module:model/ProjectFull
     * @param id {Number} 
     * @param state {module:model/ProjectFull.StateEnum} 
     * @param name {String} 
     * @param client {Number} 
     * @param createdAt {String} 
     * @param createdBy {module:model/UserWithoutClient} 
     * @param updatedAt {String} 
     * @param updatedBy {module:model/UserWithoutClient} 
     * @param description {String} 
     * @param sourceLanguage {module:model/ProjectFull.SourceLanguageEnum} 
     * @param services {Array.<module:model/ProjectService>} 
     * @param deliveredCount {Number} 
     * @param ongoingCount {Number} 
     * @param bpsProjectId {Number} 
     */
    constructor(id, state, name, client, createdAt, createdBy, updatedAt, updatedBy, description, sourceLanguage, services, deliveredCount, ongoingCount, bpsProjectId) { 
        
        ProjectFull.initialize(this, id, state, name, client, createdAt, createdBy, updatedAt, updatedBy, description, sourceLanguage, services, deliveredCount, ongoingCount, bpsProjectId);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, state, name, client, createdAt, createdBy, updatedAt, updatedBy, description, sourceLanguage, services, deliveredCount, ongoingCount, bpsProjectId) { 
        obj['id'] = id;
        obj['state'] = state;
        obj['name'] = name;
        obj['client'] = client;
        obj['created_at'] = createdAt;
        obj['created_by'] = createdBy;
        obj['updated_at'] = updatedAt;
        obj['updated_by'] = updatedBy;
        obj['description'] = description;
        obj['source_language'] = sourceLanguage;
        obj['services'] = services;
        obj['delivered_count'] = deliveredCount;
        obj['ongoing_count'] = ongoingCount;
        obj['bps_project_id'] = bpsProjectId;
    }

    /**
     * Constructs a <code>ProjectFull</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProjectFull} obj Optional instance to populate.
     * @return {module:model/ProjectFull} The populated <code>ProjectFull</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProjectFull();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('state')) {
                obj['state'] = ApiClient.convertToType(data['state'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('client')) {
                obj['client'] = ApiClient.convertToType(data['client'], 'Number');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'String');
            }
            if (data.hasOwnProperty('created_by')) {
                obj['created_by'] = UserWithoutClient.constructFromObject(data['created_by']);
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'String');
            }
            if (data.hasOwnProperty('updated_by')) {
                obj['updated_by'] = UserWithoutClient.constructFromObject(data['updated_by']);
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('source_language')) {
                obj['source_language'] = ApiClient.convertToType(data['source_language'], 'String');
            }
            if (data.hasOwnProperty('services')) {
                obj['services'] = ApiClient.convertToType(data['services'], [ProjectService]);
            }
            if (data.hasOwnProperty('delivered_count')) {
                obj['delivered_count'] = ApiClient.convertToType(data['delivered_count'], 'Number');
            }
            if (data.hasOwnProperty('ongoing_count')) {
                obj['ongoing_count'] = ApiClient.convertToType(data['ongoing_count'], 'Number');
            }
            if (data.hasOwnProperty('bps_project_id')) {
                obj['bps_project_id'] = ApiClient.convertToType(data['bps_project_id'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
ProjectFull.prototype['id'] = undefined;

/**
 * @member {module:model/ProjectFull.StateEnum} state
 */
ProjectFull.prototype['state'] = undefined;

/**
 * @member {String} name
 */
ProjectFull.prototype['name'] = undefined;

/**
 * @member {Number} client
 */
ProjectFull.prototype['client'] = undefined;

/**
 * @member {String} created_at
 */
ProjectFull.prototype['created_at'] = undefined;

/**
 * @member {module:model/UserWithoutClient} created_by
 */
ProjectFull.prototype['created_by'] = undefined;

/**
 * @member {String} updated_at
 */
ProjectFull.prototype['updated_at'] = undefined;

/**
 * @member {module:model/UserWithoutClient} updated_by
 */
ProjectFull.prototype['updated_by'] = undefined;

/**
 * @member {String} description
 */
ProjectFull.prototype['description'] = undefined;

/**
 * @member {module:model/ProjectFull.SourceLanguageEnum} source_language
 */
ProjectFull.prototype['source_language'] = undefined;

/**
 * @member {Array.<module:model/ProjectService>} services
 */
ProjectFull.prototype['services'] = undefined;

/**
 * @member {Number} delivered_count
 */
ProjectFull.prototype['delivered_count'] = undefined;

/**
 * @member {Number} ongoing_count
 */
ProjectFull.prototype['ongoing_count'] = undefined;

/**
 * @member {Number} bps_project_id
 */
ProjectFull.prototype['bps_project_id'] = undefined;





/**
 * Allowed values for the <code>state</code> property.
 * @enum {String}
 * @readonly
 */
ProjectFull['StateEnum'] = {

    /**
     * value: "new"
     * @const
     */
    "new": "new",

    /**
     * value: "ongoing"
     * @const
     */
    "ongoing": "ongoing",

    /**
     * value: "closed"
     * @const
     */
    "closed": "closed"
};


/**
 * Allowed values for the <code>source_language</code> property.
 * @enum {String}
 * @readonly
 */
ProjectFull['SourceLanguageEnum'] = {

    /**
     * value: "engUS"
     * @const
     */
    "engUS": "engUS"
};



export default ProjectFull;

