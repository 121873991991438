import React, { useState, useContext, useEffect } from 'react'
import './new-request.scss'
import { useParams } from 'react-router-dom'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from "yup"
import cN from "classnames"
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Modal, Form, Button, Toast, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'

import Select from 'react-select'
import ReactDatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"

import { GlobalContext } from '../../context/GlobalState'

import { capitalize, selectStyles, selectTheme, noOptionsMessage, isFileFiltrable } from '../../utils'

import { services, source_languages, target_languages } from '../../temp_lists'

import FilesUploader from '../files-uploader'
import GroupedFilesUploader from '../files-uploader/grouped-uploader'
import Loading from '../loading/Loading'

import TargetLanguagesSection from './TargetLanguagesSection'


const minDate = moment().startOf('day').toDate()

const invalidFilters = (files) => {
  let res = false
  Object.keys(files).forEach(fileName => {
    const file = files[fileName]
    if (isFileFiltrable(file)) {
      const {multilingual} = file
      if (!multilingual) {
        res = true
      }
    }
  })

  return res
}

const fileWithErrors = files => files &&
    Object.keys(files).length &&
    !!Object.keys(files)
      .find((key) => files[key].error)


const NewRequest = (props) => {
  const {project} = props
  const steps = [1, 2, 3]
  const defaultFilterList = [{ guid: 'no-filter', name: 'No filter'}]
  const { id } = useParams()
  const { createRequest, getRequestByID, editRequest, getProjectTemplates, getMemoqFilters } = useContext(GlobalContext)
  const [currentStep, setCurrentStep] = useState(1)
  const [errorMsg, setErrorMsg] = useState(null)
  const [errorTitle, setErrorTitle] = useState(null)
  const [showError, setShowError] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [sourceFiles, setSourceFiles] = useState({})
  const [referenceFiles, setReferenceFiles] = useState({})
  const [deletedFiles, setDeletedFiles] = useState([])
  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [projectLanguages, setProjectLanguages] = useState([])
  const [request, setRequest] = useState(null)
  const [templates, setTemplates] = useState([])
  const [filters, setFilters] = useState(defaultFilterList)
  const [multilingualUpdates, setMultilingualUpdates] = useState({})
  const [selectedService, setSelectedService] = useState(null)
  const [availableServices, setAvailableServices] = useState([])

  const schema = yup.object().shape({
    source_language: yup.object().shape({
      value: yup.string().required('Source Language is required')
    }),
    target_languages: yup.array()
      .required('Select at least one Target Language')
      .ensure('Select at least one Target Language'),
    service: yup.object().shape({
      value: yup.string().required('Service is required')
    }),
    description: yup.string().trim()
      .required('Description is required'),
    desired_delivery: yup.date()
      .nullable()
      .required('Desired delivery date is required')
      .min(minDate, 'Desired delivery date can\'t be in the past'),
    instruction: yup.string().trim()
  })

  const { handleSubmit, control, register, errors, setError, setValue, clearErrors, formState, trigger, getValues } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const prefillValues = request => {
    const sourceLanguage = source_languages
      .find((lang) => lang.code === request.source_language)
    const targetLanguages = target_languages
      .filter((lang) => {
        return request.target_languages.indexOf(lang.code) > -1
      })
      .map ((lang) => {
        return {
          value: lang.code,
          label: lang.name
        }
      })
    const service = services
      .find((service) => {
        return service.code === request.service
      })
    const serviceItem = {
      value: service.code,
      label: service.name
    }

    setValue('source_language', {
      value: sourceLanguage.code,
      label: sourceLanguage.name
    })
    setValue('target_languages', targetLanguages)
    setValue('service', serviceItem)
    onServiceSelected(serviceItem, true)
    setValue('description', request.description)
    setValue('desired_delivery', moment(request.desired_delivery, 'X').toDate())
    setValue('instruction', request.instruction)

    request.files && request.files.length &&
    request.files.forEach((file) => {
      if (file.file_type === 'source') {
        onSourceFileUploaded(file)
      } else if (file.file_type === 'reference') {
        onReferenceFileUploaded(file)
      }
    })

    trigger()
  }

  const onServiceSelected = (item, dontFilterOut) => {
    const projLangs = project.target_languages_per_service[item.value]
    const currentTargetLangs = getValues('target_languages')

    setSelectedService(item.value)
    setProjectLanguages(projLangs || [])

    if (!dontFilterOut &&
      currentTargetLangs && currentTargetLangs.length) {
      setValue('target_languages', currentTargetLangs
        .filter((i) => projLangs.some((l) => l.code === i.value)))
    }

    getMemoqFilters(project.id, item.value)
      .then((data) => {
        // sort data alphabetically by name
        data.sort( (a, b) => a.name.localeCompare(b.name) );
        setFilters(data)
      })
      .catch(() => setFilters([]))
  }

  useEffect(() => {
    if (props.project && props.show) {
      getProjectTemplates(id)
        .then((data) => setTemplates(data.results))
    }

    return () => {
      setProjectLanguages([])
    }
    // eslint-disable-next-line
  }, [props.project, props.show])

  useEffect(() => {
    if (props.requestId) {
      setEditMode(true)
      setLoading(true)
      getRequestByID(props.requestId, id)
        .then((request) => {
          setLoading(false)
          setRequest(request)
          prefillValues(request)
          if (request && request.state_meta &&
            request.state_meta.errors) {
            let errorMsg = capitalize(request.state_meta.errors)

            if (!request.state_meta.errors.match(/BPS/i)) {
              errorMsg += '. Remove the files with errors (if any) to update and/or start the request'
            }
            setShowError(true)
            setErrorTitle('Request start failed')
            setErrorMsg(errorMsg)
          }
        })
        .catch((err) => {
          setLoading(false)
          try {
            let error = JSON.parse(err.response.text)
            if (error.detail || err.response.text) {
              setErrorMsg(error.detail || err.response.text)
              setShowError(true)
              setErrorTitle('Unable to fetch request data')
            }
          } catch (e) {
            setErrorMsg(err.response.text)
            setShowError(!!err.response.text)
          }
        })
    }

    return () => {
      setEditMode(false)
      resetValues()
    }
    // eslint-disable-next-line
  }, [props.requestId])

  const resetValues = () => {
    setCurrentStep(1)
    setSubmitting(false)
    setErrorMsg(null)
    setErrorTitle(null)
    setShowError(false)
    setSourceFiles({})
    setReferenceFiles({})
    setDeletedFiles([])
    setSelectedService(null)
  }

  const onSubmit = data => {
    // Parse values and include files
    const { start, ...rest } = data
    data = {
      ...rest,
      source_language: data.source_language.value,
      target_languages: data.target_languages.map((item) => {
        return item.value
      }),
      service: data.service.value,
      desired_delivery: moment(data.desired_delivery).format('YYYY-MM-DD'),
      files_source: Object.keys(sourceFiles).map((name) => sourceFiles[name]),
      instruction: data.instruction || null,
      files_reference: Object.keys(referenceFiles).map((name) => referenceFiles[name]),
      files_deleted: deletedFiles,
      multilingual_updates: multilingualUpdates
    }

    setSubmitting(true)
    setShowError(false)

    const promise = editMode
      ? editRequest(id, props.requestId, data)
      : createRequest(id, data)

    promise
      .then((res) => {
        onHide(res, !!start)
      })
      .catch((err) => {
        setSubmitting(false)
        try {
          let error = JSON.parse(err.response.text)
          if (error.detail) {
            setErrorMsg(error.detail)
            setShowError(true)
            setErrorTitle(editMode
              ? 'Request update failed'
              : 'Request creationg failed'
            )
          } else {
            Object.entries(error)
              .forEach(([fieldName, messages]) => {
                if (schema.fields[fieldName] &&
                  schema.fields[fieldName].fields) {
                  fieldName += '.value'
                }
                setError(fieldName, {
                  type: 'manual',
                  message: capitalize(messages[0])
                })
              })
          }
        } catch (e) {
          if (err.response.text) {
            setErrorMsg(err.response.text)
            setShowError(true)
            setErrorTitle(editMode
              ? 'Request update failed'
              : 'Request creationg failed'
            )
          }
        }
      })
  }

  const onTemplateSelected = (selectedTemplate) => {
    const template = templates
      .find((t) => t.id === selectedTemplate.value)

    if (template) {
      const sourceLanguage = source_languages
        .find((lang) => lang.code === template.source_language)
      const targetLanguages = target_languages
        .filter((lang) => {
          return template.target_languages.indexOf(lang.code) > -1
        })
        .map ((lang) => {
          return {
            value: lang.code,
            label: lang.name
          }
        })
      const service = services
        .find((service) => {
          return service.code === template.service
        })
      const serviceItem = {
        value: service.code,
        label: service.name
      }

      setValue('source_language', {
        value: sourceLanguage.code,
        label: sourceLanguage.name
      })
      setValue('target_languages', targetLanguages)
      setValue('service', serviceItem)
      onServiceSelected(serviceItem)

      const fieldsToValidate = [
        'source_language',
        'source_language.value',
        'target_languages',
        'target_languages.value',
        'service.value'
      ]

      clearErrors (fieldsToValidate)

      trigger(fieldsToValidate)
    }

    return selectedTemplate
  }

  const onSourceFileUploaded = (file) => {
    if (filters[0] && filters[0].guid !== 'no-filter' && isFileFiltrable(file)) {
      file.multilingual = filters[0]
    }

    setSourceFiles((sourceFiles) => {
      return {
        ...sourceFiles,
        [file.name]: file
      }
    })
  }

  const deleteFile = (id) => {
    if (id) {
      setDeletedFiles((deletedFiles) => {
        if (deletedFiles.indexOf(id) < 0) {
          deletedFiles.push(id)
        }

        return deletedFiles
      })
    }
  }

  const onSourceFileRemoved = (name, id) => {
    const { [name]: removed, ...rest } = sourceFiles
    setSourceFiles(rest)
    deleteFile(id)
  }

  const onReferenceFileUploaded = (file) => {
    setReferenceFiles((referenceFiles) => {
      return {
        ...referenceFiles,
        [file.name]: file
      }
    })
  }

  const onReferenceFileRemoved = (name, id) => {
    const { [name]: removed, ...rest } = referenceFiles
    setReferenceFiles(rest)
    deleteFile(id)
  }

  const onFilterChanged = (name, filterId, fileId) => {
    const filter = filterId
      ? filters.find((filter) => filter.guid === filterId)
      : null

    if (fileId) {
      setMultilingualUpdates((updates) => {
        return {
          ...updates,
          [fileId]: filter
        }
      })
    } else {
      setSourceFiles((sourceFiles) => {
        return {
          ...sourceFiles,
          [name]: {
            ...sourceFiles[name],
            multilingual: filter
          }
        }
      })
    }
  }

  const onDelete = (e) => {
    e.preventDefault()
    onHide(request, null, true)
  }

  const onHide = (request, startNow, deleted) => {
    resetValues()

    props.onHide(request, startNow, deleted)
  }
 
  const referenceFileButtonIsDisabled = Object.keys(sourceFiles).length < 1 || fileWithErrors(sourceFiles) || invalidFilters(sourceFiles)

  // MARK: - AVAILABLE SERVICES
  useEffect(() => {
    const {available_service_levels, services: projectServices} = props.project || {}

    const activeServices = services
    .filter(service => {
      const isAvailable = available_service_levels.indexOf(service.code) > -1
      if (!isAvailable) {
        return false
      }
  
      const availableService = projectServices.find(s => service.code === s.service)
      return availableService?.is_active
    })

    setAvailableServices(activeServices)
  }, [props.project])
  //

  return (
    <Modal show={props.show} onHide={onHide}
      centered scrollable size={'lg'}
      className="NewRequest">
      <Modal.Header closeButton={true}>
        <Modal.Title>
          { editMode ? 'Edit request' : 'Create new request'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="request-steps" className="flex-shrink-0">
          { steps.map((step) => {
            return <div key={step}
              className={cN('step', {
              'active': step === currentStep,
              'done': step < currentStep
            })}></div>
          })}
        </div>
        { loading
          ? <Loading />
          : <Form onSubmit={handleSubmit(onSubmit)}>
          <Toast show={showError}
            onClose={() => setShowError(false)}>
            <Toast.Header>
              <strong className="mr-auto">
                { errorTitle }
              </strong>
            </Toast.Header>
            <Toast.Body>{ errorMsg }</Toast.Body>
          </Toast>
          <div id="step-1"
            className={cN({ 'd-none': currentStep !== 1 })}>
            <h4>
              Please fill out the request’s information below. All fields are required
            </h4>
            <Row>
              <Col md={12} lg={6}>
                { !editMode &&
                  <Form.Group>
                    <Form.Label>Template</Form.Label>
                    <Select id="template"
                      data-dropup-auto="false"
                      name="template"
                      placeholder="Select template..."
                      styles={selectStyles}
                      defaultValue={[]}
                      theme={selectTheme}
                      className="form-control"
                      options={ templates.map((template) => {
                        return {
                          value: template.id,
                          label: template.name
                        }
                      }) }
                      noOptionsMessage={noOptionsMessage}
                      onChange={onTemplateSelected}
                    />
                  </Form.Group>
                }
                <Form.Group>
                <Form.Label>Service</Form.Label>
                  <Controller
                    render={
                      (fieldProps) => (
                        <Select
                          data-dropup-auto="false"
                          onChange={(item) => {
                            fieldProps.onChange(item);
                            onServiceSelected(item)
                          }}
                          placeholder="Select service..."
                          styles={selectStyles}
                          theme={selectTheme}
                          className={cN('form-control', {
                            'is-invalid': errors.service
                          })}
                          options={ 
                            /*
                            services
                            .filter((service) => {
                              return !props.project ||
                                !props.project.available_service_levels ||
                                props.project.available_service_levels
                                  .indexOf(service.code) > -1
                            })
                            /** */
                            availableServices.map(service => ({
                              value: service.code,
                              label: service.name
                            }) )
                          }
                          noOptionsMessage={noOptionsMessage}
                          menuPlacement="bottom"
                          value={fieldProps.value}/>
                      )
                    }
                    id="service"
                    name="service"
                    control={control}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.service?.value.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Source Language</Form.Label>
                  <Controller as={Select}
                    id="source_language"
                    name="source_language"
                    control={control}
                    placeholder="Select language..."
                    styles={selectStyles}
                    theme={selectTheme}
                    className={cN('form-control', {
                      'is-invalid': errors.source_language
                    })}
                    options={ source_languages.map((lang) => {
                      return {
                        value: lang.code,
                        label: lang.name
                      }
                    }) }
                    noOptionsMessage={noOptionsMessage}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.source_language?.value.message}
                  </Form.Control.Feedback>
                </Form.Group>
                

                <TargetLanguagesSection control={control} errors={errors} languages={projectLanguages} isDisabled={!selectedService} />

              </Col>
              <Col md={12} lg={6}>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <textarea id="description"
                    name="description"
                    rows="4"
                    className={cN('form-control', {
                      'is-invalid': errors.description
                    })}
                    ref={register}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Desired delivery date</Form.Label>
                  <Controller id="desired_delivery"
                    control={control}
                    name="desired_delivery"
                    render={({ onChange, onBlur, value}) => (
                      <div>
                        <ReactDatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          placeholderText="Select date"
                          wrapperClassName={cN('form-control', {
                            'is-invalid': errors.desired_delivery
                          })}
                          className="form-control"
                          popperPlacement="auto"
                          minDate={minDate}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.desired_delivery?.message}
                        </Form.Control.Feedback>
                      </div>
                    )}
                  />
                </Form.Group>
                <Form.Group>
                  <div className="form-check">
                    <input id="start-checkbox"
                      name="start"
                      type="checkbox"
                      className="form-check-input"
                      value="start"
                      ref={register} />
                    <Form.Label htmlFor="start-checkbox">
                      <strong>START</strong> IMMEDIATELY
                    </Form.Label>
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div id="step-2"
            className={cN({ 'd-none': currentStep !== 2 })}>
            <h4>
              Drag and drop or choose one or more source files to translate. You will need at least ONE file for this request.
            </h4>
            <FilesUploader id="files_source"
              projectId={id}
              requestId={props.requestId}
              label="Source files"
              files={sourceFiles}
              maxFiles={20}
              maxSize={10 * Math.pow(10, 6)}
              acceptedFormats={[
                'html',
                'csv',
                'json',
                'srt',
                'xlf',
                'xliff',
                'yml',
                'docx',
                'txt',
                'xlsx'
              ]}
              filters={filters}
              onFileUploaded={onSourceFileUploaded}
              onFileRemoved={onSourceFileRemoved}
              onFilterChanged={onFilterChanged} />
          </div>
          <div id="step-3"
            className={cN({ 'd-none': currentStep !== 3 })}>
            <h4>
              You can add more reference files and additional assets for this request. These are optional, and not required to submit your request.
            </h4>
            <Form.Group>
              <Form.Label>Specific instructions</Form.Label>
              <textarea id="instruction"
                name="instruction"
                className={cN('form-control', {
                  'is-invalid': errors.instruction
                })}
                ref={register}
              />
              <Form.Control.Feedback type="invalid">
                {errors.instruction?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <GroupedFilesUploader
              projectId={id}
              requestId={props.requestId}
              label="Reference files"
              files={referenceFiles}
              onFileUploaded={onReferenceFileUploaded}
              onFileRemoved={onReferenceFileRemoved}
              fileOptions={{
                xlsx: {maxSize: 15 * Math.pow(10, 6), maxFiles: 5},
                docx: {maxSize: 15 * Math.pow(10, 6), maxFiles: 5},
                pdf:  {maxSize: 15 * Math.pow(10, 6), maxFiles: 5},
                txt:  {maxSize: 15 * Math.pow(10, 6), maxFiles: 5},
                pptx: {maxSize: 15 * Math.pow(10, 6), maxFiles: 5},
                jpg:  {maxSize: 15 * Math.pow(10, 6), maxFiles: 5},
                png:  {maxSize: 15 * Math.pow(10, 6), maxFiles: 5},

                mp4: {maxSize: 2 * Math.pow(10, 9), maxFiles: 2},
                vob: {maxSize: 2 * Math.pow(10, 9), maxFiles: 2},
                avi: {maxSize: 2 * Math.pow(10, 9), maxFiles: 2},
              }}
            />
          </div>
        </Form>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button id="cancel-btn"
          variant="default"
          onClick={() => { onHide() }}
          className={cN({ 'd-none': currentStep !== 1 })}
          disabled={submitting}>
          CANCEL { editMode ? 'EDIT' : 'REQUEST' }
        </Button>
        <Button id="back-btn"
          variant="default"
          className={cN({ 'd-none': currentStep === 1 })}
          onClick={() => setCurrentStep(currentStep - 1)}>
          <FontAwesomeIcon icon="caret-left" />{' '}
          GO BACK
        </Button>

        <Button id="source-files-btn"
          variant="secondary"
          onClick={() => setCurrentStep(2)}
          disabled={ (!formState.isDirty && !editMode) || !formState.isValid }
          className={cN('btn-next', { 'd-none': currentStep !== 1 })}>
          { editMode ? 'EDIT' : 'ADD' } SOURCE FILES
          {' '}<FontAwesomeIcon icon="caret-right" />
        </Button>

        
        <Button id="reference-files-btn"
          variant="secondary"
          onClick={() => setCurrentStep(3)}
          disabled={ referenceFileButtonIsDisabled }
          className={cN('btn-next', { 'd-none': currentStep !== 2 })}>
          {
            (referenceFileButtonIsDisabled && Object.keys(sourceFiles).length)
            ? <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip style={{color: 'red'}}>Warning: no xlsx filter is available in the corresponding memoQ project, please contact the KWS PM</Tooltip>
              }>
              <div>
                { editMode ? 'EDIT' : 'ADD' } REFERENCE FILES
                {' '}<FontAwesomeIcon icon="caret-right" />
              </div>
            </OverlayTrigger> 

            : <div>
              { editMode ? 'EDIT' : 'ADD' } REFERENCE FILES
              {' '}<FontAwesomeIcon icon="caret-right" />
            </div>
          }
        </Button>

        <div className={cN({ 'd-none': currentStep !== steps.length })}>
          <Button id="delete-btn"
            className={cN({ 'd-none': !editMode })}
            variant="danger"
            onClick={onDelete}
            disabled={submitting}>
            DELETE REQUEST
          </Button>
          <Button id="submit-btn"
            variant="primary"
            type="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={submitting || fileWithErrors(referenceFiles)}>
            { submitting ? 'PROCESSING...' : (editMode ? 'SAVE REQUEST' : 'CREATE REQUEST') }
            {' '}<i className="onek-go" />
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default NewRequest
