import React, { useState } from 'react'


const getLanguageLRC = languageDetails => {
  const {price, currency} = (languageDetails || {})
  if (!(languageDetails &&price && currency)) {
    return ' '
  }
  return Number(price) + ' ' + currency
}

const getTotalLRC = request => {
  const {total_price, currency} = request
  if (!total_price || !currency) {
    return ' '
  }
  
  return Number(total_price) + ' ' + currency
}


const RequestPrice = (props) => {
  const { request, details } = props
  const [price] = useState(details ? getLanguageLRC(details) : getTotalLRC(request))

  return <span>{price}</span>
}

export default RequestPrice