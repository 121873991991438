import axios from 'axios'


export const downloadFile = (url, name) => fetch(url)
  .then(response => response.blob())
  .then(blob => {
    const blobURL = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = blobURL
    a.style.display = 'none'

    if (name) {
      a.download = name
    }

    document.body.appendChild(a)
    a.click()
    a.remove()
  })
  .catch(console.error)

export function uploadImage (presignedUrl, file) {
  const fields = presignedUrl.fields
  const formData = new FormData()
  formData.append('Key', fields.key)
  formData.append('AWSAccessKeyId', fields.AWSAccessKeyId)
  formData.append('policy', fields.policy)
  formData.append('signature', fields.signature)

  if (fields.hasOwnProperty('x-amz-security-token')) {
    formData.append('x-amz-security-token', fields['x-amz-security-token'])
  }

  formData.append('file', file)

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }

  return axios.post(presignedUrl.url, formData, config)
}

export const getFileExtension = fileName => {
  if (!fileName) {
    return ''
  }

  return fileName
    .substr(fileName.lastIndexOf('.'))
    .toLowerCase()
}

export const isFileFiltrable = file => {
  const fileExt = getFileExtension(file.name)
  return fileExt === '.xlsx' || fileExt === '.csv'
}