export const source_languages = [{
  code: 'engUS',
  name: 'English - USA'
}]

export const target_languages = [{
  code: 'afrAF',
  name: 'Afrikaans - South Africa'
}, {
  code: 'albAL',
  name: 'Albanian - Albania'
}, {
  code: 'amhET',
  name: 'Amharic - Ethiopia'
}, {
  code: 'araAE',
  name: 'Arabic -  United Arab Emirates'
}, {
  code: 'araBH',
  name: 'Arabic - Bahrain'
}, {
  code: 'araEG',
  name: 'Arabic - Egypt'
}, {
  code: 'araKW',
  name: 'Arabic - Kuwait'
}, {
  code: 'araLB',
  name: 'Arabic - Lebanon'
}, {
  code: 'araMS',
  name: 'Arabic - Modern Standard'
}, {
  code: 'araOM',
  name: 'Arabic - Oman'
}, {
  code: 'araQA',
  name: 'Arabic - Qatar'
}, {
  code: 'araSA',
  name: 'Arabic - Saudi Arabia'
}, {
  code: 'asmIN',
  name: 'Assamese - Republic of India'
}, {
  code: 'azeAZ',
  name: 'Azerbaijani - Azerbaijan'
}, {
  code: 'baqES',
  name: 'Basque - Spain'
}, {
  code: 'belBY',
  name: 'Belarusian - Belarus'
}, {
  code: 'benBD',
  name: 'Bangla - Bangladesh'
}, {
  code: 'benIN',
  name: 'Bengali - Republic of India'
}, {
  code: 'bosBH',
  name: 'Bosnian (Latin) - Bosnia and Herzegovina'
}, {
  code: 'bulBG',
  name: 'Bulgarian - Bulgaria'
}, {
  code: 'burMM',
  name: 'Burmese - Burma'
}, {
  code: 'catES',
  name: 'Catalan - Spain'
}, {
  code: 'chrUS',
  name: 'Cherokee - USA'
}, {
  code: 'chsCL',
  name: 'Classical Simplified Chinese - PRC'
}, {
  code: 'chsCN',
  name: 'Simplified Mandarin Chinese - PRC'
}, {
  code: 'chsSG',
  name: 'Simplified Mandarin Chinese - Singapore'
}, {
  code: 'chtCL',
  name: 'Classical Traditional Chinese - Taiwan'
}, {
  code: 'chtHG',
  name: 'Traditional Cantonese Chinese - Hong Kong'
}, {
  code: 'chtTW',
  name: 'Traditional Mandarin Chinese - Taiwan'
}, {
  code: 'ckbIQ',
  name: 'Central Kurdish (Arabic) - Iraq'
}, {
  code: 'cymUK',
  name: 'Welsh - UK'
}, {
  code: 'czeCZ',
  name: 'Czech - Czech Republic'
}, {
  code: 'danDK',
  name: 'Danish - Denmark'
}, {
  code: 'dutNL',
  name: 'Dutch - Netherlands'
}, {
  code: 'engAU',
  name: 'English - Australia'
}, {
  code: 'engCA',
  name: 'English - Canada'
}, {
  code: 'engEI',
  name: 'English - Ireland'
}, {
  code: 'engIN',
  name: 'English - Republic of India'
}, {
  code: 'engNZ',
  name: 'English - New Zealand'
}, {
  code: 'engSG',
  name: 'English - Singapore'
}, {
  code: 'engUK',
  name: 'English - UK'
}, {
  code: 'engUS',
  name: 'English - USA'
}, {
  code: 'engZA',
  name: 'English - South Africa'
}, {
  code: 'estEE',
  name: 'Estonian - Estonia'
}, {
  code: 'fasIR',
  name: 'Farsi - Iran'
}, {
  code: 'finFI',
  name: 'Finnish - Finland'
}, {
  code: 'freBE',
  name: 'French - Belgium'
}, {
  code: 'freCA',
  name: 'French - Canada'
}, {
  code: 'freCH',
  name: 'French - Switzerland'
}, {
  code: 'freFR',
  name: 'French - France'
}, {
  code: 'freLU',
  name: 'French - Luxembourg'
}, {
  code: 'freUS',
  name: 'French - American'
}, {
  code: 'geoGE',
  name: 'Georgian - Georgia'
}, {
  code: 'gerAT',
  name: 'German - Austria'
}, {
  code: 'gerCH',
  name: 'German - Switzerland'
}, {
  code: 'gerDE',
  name: 'German - Germany'
}, {
  code: 'gerLI',
  name: 'German - Liechtenstein'
}, {
  code: 'glaUK',
  name: 'Scottish Gaelic - UK'
}, {
  code: 'glgES',
  name: 'Galician - Spain'
}, {
  code: 'greCY',
  name: 'Greek - Cyprus'
}, {
  code: 'greGR',
  name: 'Greek - Greece'
}, {
  code: 'gujIN',
  name: 'Gujarati - Republic of India'
}, {
  code: 'hatHT',
  name: 'Haitian Creole - Haiti'
}, {
  code: 'hauWA',
  name: 'Hausa (Latin) - Nigeria'
}, {
  code: 'hebIL',
  name: 'Hebrew - Israel'
}, {
  code: 'hinIN',
  name: 'Hindi - Republic of India'
}, {
  code: 'hrvHR',
  name: 'Croatian - Croatia'
}, {
  code: 'hunHU',
  name: 'Hungarian - Hungary'
}, {
  code: 'hyeAR',
  name: 'Armenian - Armenia'
}, {
  code: 'iboWA',
  name: 'Igbo - Nigeria'
}, {
  code: 'IceIS',
  name: 'Icelandic - Iceland'
}, {
  code: 'ikuCA',
  name: 'Inuktitut (Latin) - Canada'
}, {
  code: 'indID',
  name: 'Indonesian - Indonesia'
}, {
  code: 'irlIE',
  name: 'Irish - Ireland'
}, {
  code: 'itaCH',
  name: 'Italian - Switzerland'
}, {
  code: 'itaIT',
  name: 'Italian - Italy'
}, {
  code: 'jpnJP',
  name: 'Japanese - Japan'
}, {
  code: 'kanIN',
  name: 'Kannada - Republic of India'
}, {
  code: 'kazKZ',
  name: 'Kazakh - Kazakistan'
}, {
  code: 'khmKA',
  name: 'Khmer - Cambodia'
}, {
  code: 'kinRW',
  name: 'Kinyarwanda - Rwanda'
}, {
  code: 'kirKG',
  name: 'Kyrgyz - Kyrgyzstan'
}, {
  code: 'kokIN',
  name: 'Konkani - Republic of India'
}, {
  code: 'konCO',
  name: 'Kikongo - Congo'
}, {
  code: 'korKR',
  name: 'Korean - Republic of Korea'
}, {
  code: 'laoLA',
  name: 'Lao - Laos'
}, {
  code: 'lavLV',
  name: 'Latvian - Latvia'
}, {
  code: 'litLT',
  name: 'Lithuanian - Lithuania'
}, {
  code: 'ltzLU',
  name: 'Luxembourgish - Luxembourg'
}, {
  code: 'malML',
  name: 'Malay - Malaysia'
}, {
  code: 'marIN',
  name: 'Marathi - Republic of India'
}, {
  code: 'mgcME',
  name: 'Montenegrin (Cyrillic) - Montenegro'
}, {
  code: 'mglME',
  name: 'Montenegrin (Latin) - Montenegro'
}, {
  code: 'mkdMK',
  name: 'Macedonian - Republic of Macedonia'
}, {
  code: 'mlgMG',
  name: 'Malagasy - Madagascar'
}, {
  code: 'mltMT',
  name: 'Maltese - Malta'
}, {
  code: 'monMO',
  name: 'Mongolian (Cyrillic) - Mongolia'
}, {
  code: 'mriNZ',
  name: 'Maori - New Zealand'
}, {
  code: 'mymIN',
  name: 'Malayalam - Republic of India'
}, {
  code: 'nahMX',
  name: 'Nahuatl - Mexico'
}, {
  code: 'navUS',
  name: 'Navajo - US'
}, {
  code: 'nepNE',
  name: 'Nepali - Nepal'
}, {
  code: 'nnoNO',
  name: 'Norwegian (Nynorsk) - Norway'
}, {
  code: 'norNO',
  name: 'Norwegian - Norway'
}, {
  code: 'nsoZA',
  name: 'Sesotho sa Leboa - South Africa'
}, {
  code: 'oriIN',
  name: 'Odia - Republic of India'
}, {
  code: 'panIN',
  name: 'Punjabi - Republic of India'
}, {
  code: 'panPK',
  name: 'Punjabi (Arabic) - Pakistan'
}, {
  code: 'papNL',
  name: 'Papiamento - Netherlands'
}, {
  code: 'pinCN',
  name: 'Pinyin - PRC'
}, {
  code: 'polPL',
  name: 'Polish - Poland'
}, {
  code: 'porBR',
  name: 'Portuguese - Brazil'
}, {
  code: 'porPT',
  name: 'Portuguese - Portugal'
}, {
  code: 'prsAF',
  name: 'Dari - Afghanistan'
}, {
  code: 'pusAF',
  name: 'Pashto - Afghanistan'
}, {
  code: 'qucGC',
  name: 'K\'iche\' - Guatemala'
}, {
  code: 'quePE',
  name: 'Quechua - Peru'
}, {
  code: 'rumMD',
  name: 'Moldavian - Moldavia'
}, {
  code: 'rumRO',
  name: 'Romanian - Romania'
}, {
  code: 'rusRU',
  name: 'Russian - Russian Federation'
}, {
  code: 'sinCL',
  name: 'Sinhala - Sri Lanka'
}, {
  code: 'sloSK',
  name: 'Slovakian - Slovakia'
}, {
  code: 'slvSI',
  name: 'Slovenian - Slovenia'
}, {
  code: 'smnSM',
  name: 'Samoan - Samoa'
}, {
  code: 'sndPK',
  name: 'Sindhi (Arabic) - Pakistan'
}, {
  code: 'somSO',
  name: 'Somali - Somalia'
}, {
  code: 'spaAR',
  name: 'Spanish - Argentina'
}, {
  code: 'spaCL',
  name: 'Spanish - Chile'
}, {
  code: 'spaCO',
  name: 'Spanish - Colombia'
}, {
  code: 'spaES',
  name: 'Spanish - Spain'
}, {
  code: 'spaLA',
  name: 'Spanish - LATAM'
}, {
  code: 'spaMX',
  name: 'Spanish - Mexico'
}, {
  code: 'spaNT',
  name: 'Spanish - Neutral'
}, {
  code: 'spaUS',
  name: 'Spanish - USA'
}, {
  code: 'srcBH',
  name: 'Serbian (Cyrillic) - Bosnia and Herzegovina'
}, {
  code: 'srcSR',
  name: 'Serbian (Cyrillic) - Serbia'
}, {
  code: 'srpSR',
  name: 'Serbian - Serbia'
}, {
  code: 'swaKE',
  name: 'KiSwahili - Kenya'
}, {
  code: 'swaTZ',
  name: 'KiSwahili - Tanzania'
}, {
  code: 'sweSE',
  name: 'Swedish - Sweden'
}, {
  code: 'tamIN',
  name: 'Tamil - Republic of India'
}, {
  code: 'tatRU',
  name: 'Tatar - Russia'
}, {
  code: 'telIN',
  name: 'Telugu - Republic of India'
}, {
  code: 'tetID',
  name: 'Tetum - Indonesia'
}, {
  code: 'tgkTJ',
  name: 'Tajik (Cyrillic) - Tajikistan'
}, {
  code: 'tglRP',
  name: 'Tagalog - Philippines'
}, {
  code: 'thaTH',
  name: 'Thai - Thailand'
}, {
  code: 'tirET',
  name: 'Tigrinya - Ethiopia'
}, {
  code: 'tsnZA',
  name: 'Setswana - South Africa'
}, {
  code: 'tukTM',
  name: 'Turkmen - Turkmenistan'
}, {
  code: 'turTR',
  name: 'Turkish - Turkey'
}, {
  code: 'uigCN',
  name: 'Uyghur - PRC'
}, {
  code: 'ukrUA',
  name: 'Ukrainian - Ukraine'
}, {
  code: 'urdUR',
  name: 'Urdu - Pakistan'
}, {
  code: 'uzbUZ',
  name: 'Uzbek - Uzbekistan'
}, {
  code: 'valES',
  name: 'Valencian - Spain'
}, {
  code: 'vieVI',
  name: 'Vietnamese - Vietnam'
}, {
  code: 'vlsBE',
  name: 'Flemish - Belgium'
}, {
  code: 'wolWO',
  name: 'Wolof - Senegal'
}, {
  code: 'xhoZA',
  name: 'IsiXhosa - South Africa'
}, {
  code: 'yorYO',
  name: 'Yoruba - Nigeria'
}, {
  code: 'zulZA',
  name: 'IsiZulu - South Africa'
}]

export const project_names = [
  'BLACK OPS 4 DLC4',
  'COD 2020 ENVIRONMENTAL TEXT',
  'COD BLACK OPS',
  'COD BLACK OPS CUBAN SPANISH',
  'COD COMPANION APP',
  'COD MAGMA GUIDE',
  'COD MARKETING 2020',
  'COD MOBILE 2019',
  'COD MOBILE 2020',
  'COD MOBILE SOCIAL 2020',
  'COD MW MAGMA',
  'COD MW SEASON 1 & 2',
  'COD MW SEASON 3',
  'COD MW SEASON 4',
  'COD MW SEASON 5',
  'COD SOCIAL 2020',
  'COD.COM 2020',
  'COMPANION APP 2020',
  'CONSUMER TECH 2019',
  'CONSUMER TECH 2020',
  'CRASH 2020 SOCIAL',
  'CRASH BANDICOOT 2020',
  'CTR NITRO FUELED 2020',
  'CTR SOCIAL',
  'CTR SOCIAL 2020',
  'CUSTOMER SUPPORT',
  'CUSTOMER SUPPORT 2020',
  'Marketing 2020',
  'MISCELLANEOUS',
  'MISCELLANEOUS 2020',
  'MOTD COD',
  'MOTD COD 2020',
  'MOTD CTR 2020',
  'PR LOCALIZATION 2020',
  'SEKIRO 2020 PATCH',
  'SELLTEXT 2020',
  'TONY HAWK PS1&2 REMASTERED',
  'TONY HAWK SOCIAL',
  'Zeus - COD 2020',
  'Boost',
  'Dragon\'s Dogma: Online Offline',
  'Escape 2020',
  'Shin-kai',
  'Slugger_2020',
  'Village'
]

export const services = [{
  code: 'lite',
  name: 'Translation Lite'
}, {
  code: 'platinum',
  name: 'Translation Platinum'
}]