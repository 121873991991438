/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The StatisticsLRChart model module.
 * @module model/StatisticsLRChart
 * @version v1
 */
class StatisticsLRChart {
    /**
     * Constructs a new <code>StatisticsLRChart</code>.
     * @alias module:model/StatisticsLRChart
     * @param date {Date} 
     * @param created {Number} 
     * @param started {Number} 
     * @param invoiced {Number} 
     */
    constructor(date, created, started, invoiced) { 
        
        StatisticsLRChart.initialize(this, date, created, started, invoiced);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, date, created, started, invoiced) { 
        obj['date'] = date;
        obj['created'] = created;
        obj['started'] = started;
        obj['invoiced'] = invoiced;
    }

    /**
     * Constructs a <code>StatisticsLRChart</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/StatisticsLRChart} obj Optional instance to populate.
     * @return {module:model/StatisticsLRChart} The populated <code>StatisticsLRChart</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new StatisticsLRChart();

            if (data.hasOwnProperty('date')) {
                obj['date'] = ApiClient.convertToType(data['date'], 'Date');
            }
            if (data.hasOwnProperty('created')) {
                obj['created'] = ApiClient.convertToType(data['created'], 'Number');
            }
            if (data.hasOwnProperty('started')) {
                obj['started'] = ApiClient.convertToType(data['started'], 'Number');
            }
            if (data.hasOwnProperty('invoiced')) {
                obj['invoiced'] = ApiClient.convertToType(data['invoiced'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} date
 */
StatisticsLRChart.prototype['date'] = undefined;

/**
 * @member {Number} created
 */
StatisticsLRChart.prototype['created'] = undefined;

/**
 * @member {Number} started
 */
StatisticsLRChart.prototype['started'] = undefined;

/**
 * @member {Number} invoiced
 */
StatisticsLRChart.prototype['invoiced'] = undefined;






export default StatisticsLRChart;

