import React from 'react'

const EntityNotFound = (props) => {
  const style = {
    padding: '100px',
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: 'black'
  }

  return (
    <p style={style}>
      { props.entityName } not found
    </p>
  )
}

export default EntityNotFound
