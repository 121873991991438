/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The PasswordResetConfirm model module.
 * @module model/PasswordResetConfirm
 * @version v1
 */
class PasswordResetConfirm {
    /**
     * Constructs a new <code>PasswordResetConfirm</code>.
     * @alias module:model/PasswordResetConfirm
     * @param token {String} 
     * @param password1 {String} <ul><li>Your password can’t be too similar to your other personal information.</li><li>Your password must contain at least 8 characters.</li><li>Your password can’t be a commonly used password.</li><li>Your password can’t be entirely numeric.</li></ul>
     * @param password2 {String} Enter the same password as before, for verification.
     */
    constructor(token, password1, password2) { 
        
        PasswordResetConfirm.initialize(this, token, password1, password2);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, token, password1, password2) { 
        obj['token'] = token;
        obj['password1'] = password1;
        obj['password2'] = password2;
    }

    /**
     * Constructs a <code>PasswordResetConfirm</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PasswordResetConfirm} obj Optional instance to populate.
     * @return {module:model/PasswordResetConfirm} The populated <code>PasswordResetConfirm</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PasswordResetConfirm();

            if (data.hasOwnProperty('token')) {
                obj['token'] = ApiClient.convertToType(data['token'], 'String');
            }
            if (data.hasOwnProperty('password1')) {
                obj['password1'] = ApiClient.convertToType(data['password1'], 'String');
            }
            if (data.hasOwnProperty('password2')) {
                obj['password2'] = ApiClient.convertToType(data['password2'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} token
 */
PasswordResetConfirm.prototype['token'] = undefined;

/**
 * <ul><li>Your password can’t be too similar to your other personal information.</li><li>Your password must contain at least 8 characters.</li><li>Your password can’t be a commonly used password.</li><li>Your password can’t be entirely numeric.</li></ul>
 * @member {String} password1
 */
PasswordResetConfirm.prototype['password1'] = undefined;

/**
 * Enter the same password as before, for verification.
 * @member {String} password2
 */
PasswordResetConfirm.prototype['password2'] = undefined;






export default PasswordResetConfirm;

