import React, { useState, useContext } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from "yup"

import { Card, Form, Button, Toast, Image } from 'react-bootstrap'
import cN from 'classnames'

import { GlobalContext } from '../../context/GlobalState'

const Login = () => {
  const { authenticateUser, generateLettersGroups } = useContext(GlobalContext)
  const location = useLocation()
  const history = useHistory()
  let initialErrorMsg = ''

  if (location && location.state &&
    location.state.unauthenticated) {
    initialErrorMsg = 'Your session has expired'
    window.history.replaceState(null, '')
  }

  const [errorMsg, setErrorMsg] = useState(initialErrorMsg)
  const [showError, setShowError] = useState(!!initialErrorMsg)
  const [submitting, setSubmitting] = useState(false)

  const schema = yup.object().shape({
    username: yup.string().required('Username is required'),
    password: yup.string().required('Password is required')
  })

  const { handleSubmit, control, errors } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    setSubmitting(true)
    setShowError(false)
    authenticateUser(data.username, data.password)
      .then(() => {
        let from = location.state
        ? location.state.from
        : '/'
        history.replace(from)

        // Re-generate set of letters
        generateLettersGroups()
      })
      .catch((err) => {
        setSubmitting(false)
        try {
          let errorMsg = JSON.parse(err.response?.text).detail
          setErrorMsg(errorMsg)
        } catch (e) {
          let errorMsg = err.response?.text
          setErrorMsg(errorMsg)
        }
        setShowError(true)
      })
  }

  return (
    <Card className="Guest-form">
      <Card.Body>
        <Image src="/assets/images/One-K-Logo_Blue.svg" alt="KWS" />
        <h1>BY KEYWORDS STUDIOS</h1>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Toast show={showError}
            onClose={() => setShowError(false)}>
            <Toast.Header>
              <strong className="mr-auto">Authentication failed</strong>
            </Toast.Header>
            <Toast.Body>{ errorMsg }</Toast.Body>
          </Toast>
          <Form.Group controlId="username">
            <Form.Label>Username</Form.Label>
            <Controller as={Form.Control}
              name="username"
              type="text"
              control={control}
              defaultValue=""
              className={cN({
                'is-invalid': errors.username
              })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.username?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Controller as={Form.Control}
              name="password"
              type="password"
              control={control}
              defaultValue=""
              className={cN({
                'is-invalid': errors.password
              })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Button id="submit-btn"
            variant="primary"
            type="submit"
            disabled={submitting}>
            { submitting ? 'Authenticating...' : 'Login >' }
          </Button>
        </Form>
        <div className="text-center">
          <strong>
            <Link id="forgot-pwd-link"
              to="/forgot-password">
              Forgot password
            </Link>
          </strong>
        </div>
      </Card.Body>
    </Card>
  )
}

export default Login
