/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The LRTranslationResultItem model module.
 * @module model/LRTranslationResultItem
 * @version v1
 */
class LRTranslationResultItem {
    /**
     * Constructs a new <code>LRTranslationResultItem</code>.
     * @alias module:model/LRTranslationResultItem
     * @param name {String} 
     * @param size {Number} 
     * @param url {String} 
     * @param createdAt {String} 
     * @param language {module:model/LRTranslationResultItem.LanguageEnum} 
     * @param wordcount {Number} 
     * @param price {Number} 
     * @param currency {String} 
     * @param jobassignmentState {String} 
     */
    constructor(name, size, url, createdAt, language, wordcount, price, currency, jobassignmentState) { 
        
        LRTranslationResultItem.initialize(this, name, size, url, createdAt, language, wordcount, price, currency, jobassignmentState);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, name, size, url, createdAt, language, wordcount, price, currency, jobassignmentState) { 
        obj['name'] = name;
        obj['size'] = size;
        obj['url'] = url;
        obj['created_at'] = createdAt;
        obj['language'] = language;
        obj['wordcount'] = wordcount;
        obj['price'] = price;
        obj['currency'] = currency;
        obj['jobassignment_state'] = jobassignmentState;
    }

    /**
     * Constructs a <code>LRTranslationResultItem</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/LRTranslationResultItem} obj Optional instance to populate.
     * @return {module:model/LRTranslationResultItem} The populated <code>LRTranslationResultItem</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LRTranslationResultItem();

            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('format')) {
                obj['format'] = ApiClient.convertToType(data['format'], 'String');
            }
            if (data.hasOwnProperty('size')) {
                obj['size'] = ApiClient.convertToType(data['size'], 'Number');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'String');
            }
            if (data.hasOwnProperty('language')) {
                obj['language'] = ApiClient.convertToType(data['language'], 'String');
            }
            if (data.hasOwnProperty('wordcount')) {
                obj['wordcount'] = ApiClient.convertToType(data['wordcount'], 'Number');
            }
            if (data.hasOwnProperty('price')) {
                obj['price'] = ApiClient.convertToType(data['price'], 'Number');
            }
            if (data.hasOwnProperty('currency')) {
                obj['currency'] = ApiClient.convertToType(data['currency'], 'String');
            }
            if (data.hasOwnProperty('jobassignment_state')) {
                obj['jobassignment_state'] = ApiClient.convertToType(data['jobassignment_state'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} name
 */
LRTranslationResultItem.prototype['name'] = undefined;

/**
 * @member {String} format
 */
LRTranslationResultItem.prototype['format'] = undefined;

/**
 * @member {Number} size
 */
LRTranslationResultItem.prototype['size'] = undefined;

/**
 * @member {String} url
 */
LRTranslationResultItem.prototype['url'] = undefined;

/**
 * @member {String} created_at
 */
LRTranslationResultItem.prototype['created_at'] = undefined;

/**
 * @member {module:model/LRTranslationResultItem.LanguageEnum} language
 */
LRTranslationResultItem.prototype['language'] = undefined;

/**
 * @member {Number} wordcount
 */
LRTranslationResultItem.prototype['wordcount'] = undefined;

/**
 * @member {Number} price
 */
LRTranslationResultItem.prototype['price'] = undefined;

/**
 * @member {String} currency
 */
LRTranslationResultItem.prototype['currency'] = undefined;

/**
 * @member {String} jobassignment_state
 */
LRTranslationResultItem.prototype['jobassignment_state'] = undefined;





/**
 * Allowed values for the <code>language</code> property.
 * @enum {String}
 * @readonly
 */
LRTranslationResultItem['LanguageEnum'] = {

    /**
     * value: "engUS"
     * @const
     */
    "engUS": "engUS"
};



export default LRTranslationResultItem;

