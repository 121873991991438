import React, { useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import './request-table.scss'

import { Button, Form, Row, Col } from 'react-bootstrap'
import Select from 'react-select'
import ReactDatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import cN from "classnames"
import moment from 'moment'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from "yup"

import Loading from '../loading/Loading'
import EntityNotFound from '../entity-not-found/EntityNotFound'
import RequestStatus from '../request-status/RequestStatus';
import LPHeaderCol from '../lp-header-col/LPHeaderCol'
import LPDateTime from '../lp-datetime';
import LPDesiredDate from '../lp-desireddate';

import RequestPrice from './RequestPrice';

import { capitalize, selectStyles, selectTheme, noOptionsMessage, roundUpDateTime, compareDates } from '../../utils'
import { services, source_languages } from '../../temp_lists'

import { GlobalContext } from '../../context/GlobalState'
import TargetLanguages from '../target-languages/TargetLanguages'


const RequestsTable = (props) => {
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [pageSize, setPageSize] = useState(50)
  const { getRequests, requests, requestsNotFound, requestsHasPages, requestsCount } = useContext(GlobalContext)
  const [submitting, setSubmitting] = useState(false)
  const [tableFiltered, setTableFiltered] = useState(false)
  const [shouldCloseStartDate, setShouldCloseStartDate] = useState(false)
  const [shouldCloseEndDate, setShouldCloseEndDate] = useState(false)
  const [selectedService, setSelectedService] = useState(null)
  const [selectedState, setSelectedState] = useState(null)
  const [pendingRequests, setPendingRequests] = useState(true)
  const [backgroundTask, setBackgroundTask] = useState()
  const pollingSec = 3

  // on will unmount
  useEffect(() => {
    return () => clearTimeout(backgroundTask)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // polling loop
  useEffect(() => {
    // filter pending requests (state === draft_to_ongoing)
    const pending = requests?.filter(r => r.state === 'draft_to_ongoing')
    if (!pending?.length) {
      return
    }

    getRequests(id)
    .finally(() => setBackgroundTask( 
        setTimeout(() => 
          setPendingRequests(!pendingRequests), 
            pollingSec * 1000)  
      )  
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingRequests, requests])
 
  /*
  const statuses = [
    'draft',
    'ongoing',
    'delivered',
    'closed',
    'cancelled'
  ]
  /** */
  const filterableStatuses = [
    'draft',
    'ongoing',
    'delivered',
    'invoiced',
    'cancelled'
  ]
  const today = new Date()

  const schema = yup.object().shape({
    number: yup.number()
      .transform(v => {
        return (v === '' || Number.isNaN(v))
          ? null
          : v
      })
      .nullable(true)
      .min(1, 'Minimum value is 1')
  })

  const { handleSubmit, control, register, errors, reset, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      start_date: {
        start: null,
        end: null
      },
      delivery_date: {
        start: null,
        end: null
      },
      service: selectedService,
      state: selectedState
    }
  })

  const onFilterSubmit = (data) => {
    setSubmitting(true)
    let filters = null

    if (data) {
      if (data.start_date.start && !data.start_date.end) {
        data.start_date.end = data.start_date.start
      }

      if (data.delivery_date.start && !data.delivery_date.end) {
        data.delivery_date.end = data.delivery_date.start
      }

      filters = {
        id: data.number
          ? parseInt(data.number)
          : null,
        service: data.service
          ? data.service.value
          : null,
        state: data.state
          ? data.state.value
          : null,
        startAfter: data.start_date && data.start_date.start
          ? moment(data.start_date.start).format('YYYY-MM-DD')
          : null,
        startBefore: data.start_date && data.start_date.end
          ? moment(data.start_date.end).add(1, 'day').format('YYYY-MM-DD')
          : null,
        endAfter: data.delivery_date && data.delivery_date.start
          ? moment(data.delivery_date.start).format('YYYY-MM-DD')
          : null,
        endBefore: data.delivery_date && data.delivery_date.end
          ? moment(data.delivery_date.end).add(1, 'day').format('YYYY-MM-DD')
          : null
      }
    }

    getRequestsPage(filters)
      .then(
        () => {
          setTableFiltered(!!data)
          setSubmitting(false)
        },
        () => setSubmitting(false)
      )
  }

  const onResetClick = () => {
    reset()
    setValue('service.value', null)
    setSelectedService(null)
    setValue('state.value', null)
    setSelectedState(null)
    if (tableFiltered) {
      setTableFiltered(false)
      onFilterSubmit()
    }
  }

  const submitOnEnter = (e) => {
    if (e && e.keyCode === 13) {
      handleSubmit(onFilterSubmit)()
    }
  }

  const parseDateRange = (value) => {
    if (value && value.start) {
      if (!value.end ||
        moment(value.start).isSame(moment(value.end))) {
        return `${moment(value.start).format('MMM DD')}`
      }
      return `${moment(value.start).format('MMM DD')} - ${moment(value.end).format('MMM DD')}`
    }

    return null
  }

  const pagination = requestsHasPages
    ?  paginationFactory({
      sizePerPage: pageSize,
      totalSize: requestsCount,
      sizePerPageList: [{
        text: '10',
        value: 10
      }, {
        text: '20',
        value: 20
      }, {
        text: '50',
        value: 50
      }, {
        text: `All (${requestsCount})`,
        value: requestsCount || 0
      }],
      showTotal: true,
      disablePageTitle: true
    })
    : null

  const columns = [{
    dataField: 'number',
    text: 'Request',
    sort: true,
    sortFunc: (a, b, order, dataField, rowA, rowB) => {
      const ida = Number(a.substring(3))
      const idb = Number(b.substring(3))
      
      return order === 'asc'
        ? ida - idb // asc
        : idb - ida // desc
    },
    headerClasses: 'number',
    headerFormatter: (column, _i, { sortElement }) => {
      return <LPHeaderCol
        icon="onek-request"
        text={column.text}
        sortElement={sortElement} />
    }
  }, {
    dataField: 'service',
    text: 'Service',
    formatter: (cell) => cell &&
      services.find((s) => s.code === cell).name,
    headerClasses: 'service',
    headerFormatter: (column) => {
      return <LPHeaderCol
        icon="onek-service"
        text={column.text} />
    }
  }, {
    dataField: 'state',
    text: 'Status',
    sort: true,
    formatter: (cell, row) => cell &&
      <RequestStatus status={ cell }
        error={ row.state_meta && row.state_meta.errors } />,
    headerClasses: 'status',
    headerFormatter: (column, _i, { sortElement }) => {
      return <LPHeaderCol
        icon="onek-status"
        text={column.text}
        sortElement={sortElement} />
    }
  }, {
    dataField: 'start',
    text: 'Started',
    sort: true,
    formatter: (cell) => cell &&
      <LPDateTime timestamp={cell} />,
    headerClasses: 'datetime',
    headerFormatter: (column, _i, { sortElement }) => {
      return <LPHeaderCol
        icon="onek-started"
        text={column.text}
        sortElement={sortElement} />
    }
  }, {
    dataField: 'end',
    text: 'Delivery',
    sort: true,
    // MARK: - Delivery date
    formatter: (actualDelivery, request) => {

      if (!actualDelivery) {
        return null
      }

      const desiredDelivery = moment(request.desired_delivery).unix()
      const roundedActualDelivery = roundUpDateTime(actualDelivery, 1000)
      const roundedDesiredDelivery = roundUpDateTime(desiredDelivery, 1000)
      
      if (compareDates(desiredDelivery, actualDelivery)) {
        return <LPDesiredDate desiredDelivery={roundedDesiredDelivery} actualDelivery={roundedActualDelivery} />
      }
      
      return <LPDateTime timestamp={roundedActualDelivery} />
    },
    headerClasses: 'date',
    headerFormatter: (column, _i, { sortElement }) => {
      return <LPHeaderCol
        icon="onek-delivered"
        text={column.text}
        sortElement={sortElement} />
    }
  }, {
    dataField: 'source_language',
    text: 'Source',
    formatter: (cell) => cell &&
      (source_languages.find((lang) => lang.code === cell)?.name || cell),
    headerClasses: 'source-lang',
    classes: 'transform-none',
    headerFormatter: (column) => {
      return <LPHeaderCol
        icon="onek-source"
        text={column.text} />
    }
  }, {
    dataField: 'target_languages',
    text: 'Targets',
    formatter: (cell) => cell &&
      <TargetLanguages langs={cell} />,
    headerFormatter: (column) => {
      return <LPHeaderCol
        icon="onek-target"
        text={column.text} />
    }
  },
  {
    dataField: 'wordcount_pricelist',
    text: 'Total Cost',
    sort: true,
    formatter: (cell, request) => <RequestPrice request={request} />,
    headerClasses: 'price',
    headerFormatter: (column) => {
      return <LPHeaderCol text={column.text} />
    }
  },
  {
    dataField: 'action',
    isDummyField: true,
    text: '',
    formatter: (_cell, row) => {
      switch (row.state) {
        case 'draft':
          return <Button block
            variant="secondary"
            className="start-lr-btn"
            onClick={(e) => props.onStartClick(e, row)}
            disabled={!row.target_languages || !row.target_languages.length}>
            <i className="onek-start"></i>{' '}
            START
          </Button>
        case 'draft_to_ongoing':
          return <Button block
            variant="secondary"
            className="started-lr-btn"
            disabled>
            <i className="onek-started"></i>{' '}
            STARTED
          </Button>
        case 'ongoing':
        case 'delivered':
        case 'cancelled':
        case 'ongoing_to_cancelled':
          return <Button block
            className="view-lr-btn"
            onClick={(e) => props.onViewClick(e, row)}
            variant="primary">
            VIEW DETAILS
          </Button>
        case 'invoiced':
          return <Button block
            className="view-lr-btn"
            onClick={(e) => props.onViewClick(e, row)}
            variant="primary">
            VIEW DETAILS
          </Button>
        default:
          return ''
      }
    },
    headerClasses: 'action'
  }]

  const handleTableChange = (type, { page, sortField, sortOrder, sizePerPage }) => {
    if (type === 'pagination' || type === 'sort') {
      const ordering = sortOrder === 'asc'
        ? sortField
        : '-' + sortField
        getRequestsPage({
          page,
          ordering,
          size: sizePerPage
        })

        if (pageSize !== sizePerPage) {
          setPageSize(sizePerPage)
        }
    }
  }

  const getRequestsPage = (opts) => {
    setLoading(true)
    return getRequests(id, opts).then(() => {
        setLoading(false)
      }, () => {
        setLoading(false)
      })
  }

  const rowEvents = {
    onClick: (e, request) => {
      props.onRowClick(e, request)
    }
  };
  
  return (
    <div className="ResourceTable">
      <Form id="lr-filters" onSubmit={handleSubmit(onFilterSubmit)}>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Number</Form.Label>
              <input id="number"
                type="number"
                name="number"
                min="1"
                placeholder="Request number"
                className={cN('form-control', {
                  'is-invalid': errors.number
                })}
                ref={register}
              />
              <Form.Control.Feedback type="invalid">
                {errors.number?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Service</Form.Label>
              <Controller
                id="service"
                name="service"
                render={({ onChange }) => (
                  <Select
                    data-dropup-auto="false"
                    onChange={(selectedItem) => {
                      onChange(selectedItem)
                      setSelectedService(selectedItem)
                    }}
                    placeholder="Select service"
                    styles={selectStyles}
                    theme={selectTheme}
                    className={cN('form-control', {
                      'is-invalid': errors.service
                    })}
                    options={ [{
                      code: null,
                      name: 'Any service'
                    }]
                      .concat(
                        services
                          .filter((service) => {
                            return !props.project.available_service_levels ||
                              props.project.available_service_levels
                                .indexOf(service.code) > -1
                          })
                      )
                      .map((service) => {
                        return {
                          value: service.code,
                          label: service.name
                        }
                      }) }
                    noOptionsMessage={noOptionsMessage}
                    defaultValue={ selectedService }
                    value={ selectedService }
                  />
                )}
                control={control}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Status</Form.Label>
              <Controller
                id="state"
                name="state"
                render={({ onChange }) => (
                  <Select
                    data-dropup-auto="false"
                    onChange={(selectedItem) => {
                      onChange(selectedItem)
                      setSelectedState(selectedItem)
                    }}
                    placeholder="Select status"
                    styles={selectStyles}
                    theme={selectTheme}
                    className={cN('form-control', {
                      'is-invalid': errors.state
                    })}
                    options={  filterableStatuses.map((status) => {
                        return {
                          value: status,
                          label: status
                            ? capitalize(status)
                            : 'Any status'
                        }
                      }) }
                    noOptionsMessage={noOptionsMessage}
                    defaultValue={ selectedState }
                    value={ selectedState }
                  />
                )}
                control={control}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Start date</Form.Label>
              <Controller id="start_date"
                control={control}
                name="start_date"
                render={({ onChange, onBlur, value}) => (
                  <div>
                    <ReactDatePicker
                      onChange={(dates) => {
                        const [start, end] = dates || [];

                        if (!end) {
                          setShouldCloseStartDate(!!start)
                        }

                        onChange({ start, end})
                      }}
                      onCalendarClose={ () => setShouldCloseStartDate(false) }
                      onBlur={onBlur}
                      selected={value.start}
                      startDate={value.start}
                      endDate={value.end}
                      placeholderText="Select range"
                      wrapperClassName={cN('form-control', {
                        'is-invalid': errors.start_date
                      })}
                      className="form-control"
                      popperPlacement="bottom"
                      selectsRange
                      shouldCloseOnSelect={shouldCloseStartDate}
                      maxDate={today}
                      isClearable
                      disabledKeyboardNavigation
                      onKeyDown={submitOnEnter}
                      value={ parseDateRange(value) }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.start_date?.message}
                    </Form.Control.Feedback>
                  </div>
                )}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Delivery date</Form.Label>
              <Controller id="delivery_date"
                control={control}
                name="delivery_date"
                render={({ onChange, onBlur, value}) => (
                  <div>
                    <ReactDatePicker
                      onChange={(dates) => {
                        const [start, end] = dates || [];

                        if (!end) {
                          setShouldCloseEndDate(!!start)
                        }

                        onChange({ start, end})
                      }}
                      onCalendarClose={ () => setShouldCloseEndDate(false) }
                      onBlur={onBlur}
                      selected={value.start}
                      startDate={value.start}
                      endDate={value.end}
                      placeholderText="Select range"
                      wrapperClassName={cN('form-control', {
                        'is-invalid': errors.delivery_date
                      })}
                      className="form-control"
                      popperPlacement="bottom"
                      selectsRange
                      shouldCloseOnSelect={shouldCloseEndDate}
                      maxDate={today}
                      isClearable
                      disabledKeyboardNavigation
                      onKeyDown={submitOnEnter}
                      value={ parseDateRange(value) }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.delivery_date?.message}
                    </Form.Control.Feedback>
                  </div>
                )}
              />
            </Form.Group>
          </Col>
          <Col xs="auto">
            <Button id="submit-btn"
              variant="primary"
              type="submit"
              disabled={submitting}>
              {' '}<FontAwesomeIcon icon="filter" />
              { submitting ? 'Filtering...' : 'FILTER' }
            </Button>
            <Button id="clear-btn"
              variant="secondary"
              type="reset"
              onClick={onResetClick}
              disabled={submitting}>
              {' '}<i className="onek-close" />
            </Button>
          </Col>
        </Row>
      </Form>
      {/** REQUEST TABLE */}
      <BootstrapTable id="requests-table"
        remote={{ filter: !!requestsHasPages, pagination: !!requestsHasPages }}
        keyField='id'
        data={ loading ? [] : requests }
        columns={ columns }
        defaultSorted={ [{
          dataField: 'number',
          order: 'asc'
        }] }
        defaultSortDirection={'asc'}
        pagination={ pagination }
        onTableChange={ handleTableChange }
        noDataIndication={ () => {
          return requestsNotFound
            ? <EntityNotFound entityName="Requests" />
            : <Loading />
        } }
        bootstrap4
        striped
        hover
        rowEvents={ rowEvents }
        rowClasses={ (row) => {
          return row.state === 'draft'
            ? 'clickable request-row'
            : 'request-row'
        } }

        wrapperClasses="table-responsive"
        classes="table-fixed" />
    </div>
  )
}

export default RequestsTable
