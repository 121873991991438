import React from 'react'

import { Container } from 'react-bootstrap'

const Loading = (props) => {
  return (
    <Container fluid>
      <span style={{ color: 'black' }}>
        Loading {props.resource ? `${props.resource}...` : null }
      </span>
    </Container>
  )
}

export default Loading
