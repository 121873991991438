import React, {useContext, useEffect, useState} from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { NavDropdown } from 'react-bootstrap'

import { GlobalContext } from '../../../context/GlobalState' 
import {disassebleRoute} from '../../../utils'
import {
    configureMenuItems, superuserRoutes, clientKWSPMRoutes, clientPMRoutes
} from './logic'

import '../styles.css'


// COMPONENT
const ConfigureMenu = (props) => {
    const [expanded, setExpanded] = useState(false)
    const title = 'CONFIGURE'

    const {configItems} = props
    const activeItems = configItems.filter(item => item.isActive)

    if (!activeItems.length) {
        return <NavDropdown title={title} disabled />
    }

    // events
    const handleToggle = (value, e) => {
        if (e?.preventDefault) {
            e.preventDefault()
        }
        setExpanded(value)
    }
    const handleOutsideClick = () => {
        setExpanded(false)
    }
    const handleClick = item => (e) => {
        item.onClick(e)
        setExpanded(false)
    }
    //

    return <NavDropdown title={title} 
                        show={expanded}
                        onToggle={handleToggle} 
                        className='headermenu'>
        {activeItems.map(item => 
            <NavDropdown.Item   id={item.id} 
                                className="dropdown-menu-item"
                                key={'Settings-' + item.id}
                                onBlur={handleOutsideClick}
                                onClick={handleClick(item)}>
                {item.label}
            </NavDropdown.Item>
        )}
    </NavDropdown>
}

// COMPONENT WRAPPER WITH LOGIC
const ConfigureMenuWrapper = () => {
    const [configItems, setConfigItems] = useState(configureMenuItems)
    const location = useLocation()
    const history = useHistory()

    const {
        toggleProjectEdit, currentProject,
        toggleClientEdit, currentClient, 
        toggleUserEdit, user
    } = useContext(GlobalContext)


    const callback = items => setTimeout(() => {
        setConfigItems(items)
    }, 50)

    const pmRoutes = clientPMRoutes({
        toggleProjectEdit, currentProject,
        toggleClientEdit, currentClient, toggleUserEdit, 
        history, user
    }, callback)

    const kwspmRoutes = clientKWSPMRoutes({
        toggleProjectEdit, currentProject,
        toggleClientEdit, currentClient, toggleUserEdit, 
        history, user
    }, callback)

    const superRoutes = superuserRoutes({
        toggleProjectEdit, currentProject,
        toggleClientEdit, currentClient, toggleUserEdit, 
        history, user
    }, callback)
   
    
    useEffect(() => {
        const pathComponents = disassebleRoute(location.pathname)

        if (user?.isClientPM) {
            pmRoutes(pathComponents)
            return
        }

        if (user?.isKWSPM && user?.username !== 'superuser') {
            kwspmRoutes(pathComponents)
            return
        }

        superRoutes(pathComponents)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, currentProject, currentClient])
        
    return <ConfigureMenu configItems={configItems} />
}

export default ConfigureMenuWrapper