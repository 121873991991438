import React from 'react'
import cN from "classnames"

import { Form } from 'react-bootstrap'


const InputForm = (props) => {
    const { 
        register, errors, 
        label, propertyName,
        children, noStyling = false
    } = props

    const childrenWithProps = React.Children.map(children, child => {
        // Checking isValidElement is the safe way and avoids a typescript
        // error too.
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                id: propertyName,
                name: propertyName,
                ref: register,
                className: cN({'form-control': !noStyling}, {'is-invalid': errors[propertyName]}, child.props.className)
            })
        }
        return child
    })
    
    return <Form.Group>
        {label && <Form.Label>{label}</Form.Label>}
        {childrenWithProps}
        <Form.Control.Feedback type="invalid">
            {errors[propertyName]?.message}
        </Form.Control.Feedback>
    </Form.Group>
}

export default InputForm
