/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The LocalizationRequestArchived model module.
 * @module model/LocalizationRequestArchived
 * @version v1
 */
class LocalizationRequestArchived {
    /**
     * Constructs a new <code>LocalizationRequestArchived</code>.
     * @alias module:model/LocalizationRequestArchived
     * @param id {Number} 
     * @param archivedProjectId {Number} 
     * @param originalLocreqId {Number} 
     * @param service {String} 
     * @param start {Date} 
     * @param end {Date} 
     * @param createdAt {Date} 
     * @param currency {String} 
     * @param totalPrice {Number} 
     * @param sourceLanguage {String} 
     * @param targetLanguage {Object} 
     * @param fileUrls {Object} 
     */
    constructor(id, archivedProjectId, originalLocreqId, service, start, end, createdAt, currency, totalPrice, sourceLanguage, targetLanguage, fileUrls) { 
        
        LocalizationRequestArchived.initialize(this, id, archivedProjectId, originalLocreqId, service, start, end, createdAt, currency, totalPrice, sourceLanguage, targetLanguage, fileUrls);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, archivedProjectId, originalLocreqId, service, start, end, createdAt, currency, totalPrice, sourceLanguage, targetLanguage, fileUrls) { 
        obj['id'] = id;
        obj['archived_project_id'] = archivedProjectId;
        obj['original_locreq_id'] = originalLocreqId;
        obj['service'] = service;
        obj['start'] = start;
        obj['end'] = end;
        obj['created_at'] = createdAt;
        obj['currency'] = currency;
        obj['total_price'] = totalPrice;
        obj['source_language'] = sourceLanguage;
        obj['target_language'] = targetLanguage;
        obj['file_urls'] = fileUrls;
    }

    /**
     * Constructs a <code>LocalizationRequestArchived</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/LocalizationRequestArchived} obj Optional instance to populate.
     * @return {module:model/LocalizationRequestArchived} The populated <code>LocalizationRequestArchived</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LocalizationRequestArchived();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('archived_project_id')) {
                obj['archived_project_id'] = ApiClient.convertToType(data['archived_project_id'], 'Number');
            }
            if (data.hasOwnProperty('original_locreq_id')) {
                obj['original_locreq_id'] = ApiClient.convertToType(data['original_locreq_id'], 'Number');
            }
            if (data.hasOwnProperty('service')) {
                obj['service'] = ApiClient.convertToType(data['service'], 'String');
            }
            if (data.hasOwnProperty('start')) {
                obj['start'] = ApiClient.convertToType(data['start'], 'Date');
            }
            if (data.hasOwnProperty('end')) {
                obj['end'] = ApiClient.convertToType(data['end'], 'Date');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('currency')) {
                obj['currency'] = ApiClient.convertToType(data['currency'], 'String');
            }
            if (data.hasOwnProperty('total_price')) {
                obj['total_price'] = ApiClient.convertToType(data['total_price'], 'Number');
            }
            if (data.hasOwnProperty('source_language')) {
                obj['source_language'] = ApiClient.convertToType(data['source_language'], 'String');
            }
            if (data.hasOwnProperty('target_language')) {
                obj['target_language'] = ApiClient.convertToType(data['target_language'], Object);
            }
            if (data.hasOwnProperty('file_urls')) {
                obj['file_urls'] = ApiClient.convertToType(data['file_urls'], Object);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
LocalizationRequestArchived.prototype['id'] = undefined;

/**
 * @member {Number} archived_project_id
 */
LocalizationRequestArchived.prototype['archived_project_id'] = undefined;

/**
 * @member {Number} original_locreq_id
 */
LocalizationRequestArchived.prototype['original_locreq_id'] = undefined;

/**
 * @member {String} service
 */
LocalizationRequestArchived.prototype['service'] = undefined;

/**
 * @member {Date} start
 */
LocalizationRequestArchived.prototype['start'] = undefined;

/**
 * @member {Date} end
 */
LocalizationRequestArchived.prototype['end'] = undefined;

/**
 * @member {Date} created_at
 */
LocalizationRequestArchived.prototype['created_at'] = undefined;

/**
 * @member {String} currency
 */
LocalizationRequestArchived.prototype['currency'] = undefined;

/**
 * @member {Number} total_price
 */
LocalizationRequestArchived.prototype['total_price'] = undefined;

/**
 * @member {String} source_language
 */
LocalizationRequestArchived.prototype['source_language'] = undefined;

/**
 * @member {Object} target_language
 */
LocalizationRequestArchived.prototype['target_language'] = undefined;

/**
 * @member {Object} file_urls
 */
LocalizationRequestArchived.prototype['file_urls'] = undefined;






export default LocalizationRequestArchived;

