import {gotoMenuItems, isProjectPage, isClientPage, isArchivedPage} from './utils'


export const clientKWSPMRoutes = ({user, currentClient, setCurrentClient, getProjectByID, getClientByID}, callback) => (pathComponents) => {
   
    const items = []
    items.push(gotoMenuItems[1])
    items.push(gotoMenuItems[2])
    items.push(gotoMenuItems[3])


    const f = (id) => {
        // /project/{projectId}
        if (isProjectPage(pathComponents)) {
            const projectId = pathComponents[1]
            getProjectByID(projectId)
            .then(() => {
                items[0].link = '/dashboard'
                items[1].link = '/client/' + id
                items[2].link = '/client/' + id + '/archived'
                callback(items)
            })
            .catch(console.error)
            return
        }

        // /client/{clientId} && /client/{clientId}/*
        if (isClientPage(pathComponents)) {
            const isLR = pathComponents[3]
            const archived = isArchivedPage(pathComponents)
            items[0].link = '/dashboard'
            items[1].link = archived ? '/client/' + id : false
            items[2].link = archived && !isLR ? false : '/client/' + id + '/archived'
            callback(items)
            return
        }
        
        // /dashboard
        items[0].link = false
        items[1].link = false
        items[2].link = false
        callback(items)
    }

    if (!currentClient?.id) {
        if (!currentClient && pathComponents[1]) {
            getClientByID(pathComponents[1])
            .then((client) => {
                setCurrentClient(client)
                f(client.id)
            })
            .catch(console.error)
        }

        return
    }

    f(currentClient.id)
}
