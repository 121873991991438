import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {getIcon} from './utils'


export default function reducer(state, action) {
    switch (action.type) {
      case 'add_files':
        return {
          ...state,
          ...action.payload
        }
      case 'update_progress':
        return {
          ...state,
          [action.payload.name]: {
            ...state[action.payload.name],
            progress: action.payload.progress
          }
        }
      case 'upload_completed':
        return {
          ...state,
          [action.payload.name]: {
            ...state[action.payload.name],
            progress: 100,
            completed: true
          }
        }
      case 'upload_failed':
        return {
          ...state,
          [action.payload]: {
            ...state[action.payload],
            progress: 100,
            failed: true
          }
        }
      case 'delete_file':
        const { [action.payload.name]: removed, ...rest } = state
  
        return rest
      case 'add_existent_file':
        let file = action.payload
  
        // Prevent overwriting
        if (state[file.name] || file.fields) {
          return state
        }
  
        file.downloadUrl = file.url
  
        return {
          ...state,
          [file.name]: {
            ...file,
            icon: <FontAwesomeIcon icon={getIcon(file)} />,
            size: parseInt(Math.ceil(file.size / 1000)) + 'kb',
            filter: file.multilingual?.guid,
            progress: 100,
            completed: true
          }
        }
      case 'filter_selected':
        return {
          ...state,
          [action.payload.name]: {
            ...state[action.payload.name],
            filter: action.payload.filter
          }
        }
      default:
        throw new Error()
    }
  }