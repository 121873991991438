/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The BPSClient model module.
 * @module model/BPSClient
 * @version v1
 */
class BPSClient {
    /**
     * Constructs a new <code>BPSClient</code>.
     * @alias module:model/BPSClient
     * @param id {Number} 
     * @param typeResource {String} 
     * @param channelId {String} 
     * @param channelName {String} 
     * @param officeId {String} 
     * @param officeName {String} 
     * @param username {String} 
     * @param companyName {String} 
     * @param email {String} 
     * @param toBeTracked {Boolean} 
     * @param numInProgressProjects {Number} 
     * @param hasInProgressProjects {Boolean} 
     */
    constructor(id, typeResource, channelId, channelName, officeId, officeName, username, companyName, email, toBeTracked, numInProgressProjects, hasInProgressProjects) { 
        
        BPSClient.initialize(this, id, typeResource, channelId, channelName, officeId, officeName, username, companyName, email, toBeTracked, numInProgressProjects, hasInProgressProjects);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, typeResource, channelId, channelName, officeId, officeName, username, companyName, email, toBeTracked, numInProgressProjects, hasInProgressProjects) { 
        obj['id'] = id;
        obj['typeResource'] = typeResource;
        obj['channelId'] = channelId;
        obj['channelName'] = channelName;
        obj['officeId'] = officeId;
        obj['officeName'] = officeName;
        obj['username'] = username;
        obj['companyName'] = companyName;
        obj['email'] = email;
        obj['toBeTracked'] = toBeTracked;
        obj['numInProgressProjects'] = numInProgressProjects;
        obj['hasInProgressProjects'] = hasInProgressProjects;
    }

    /**
     * Constructs a <code>BPSClient</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/BPSClient} obj Optional instance to populate.
     * @return {module:model/BPSClient} The populated <code>BPSClient</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new BPSClient();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('typeResource')) {
                obj['typeResource'] = ApiClient.convertToType(data['typeResource'], 'String');
            }
            if (data.hasOwnProperty('channelId')) {
                obj['channelId'] = ApiClient.convertToType(data['channelId'], 'String');
            }
            if (data.hasOwnProperty('channelName')) {
                obj['channelName'] = ApiClient.convertToType(data['channelName'], 'String');
            }
            if (data.hasOwnProperty('officeId')) {
                obj['officeId'] = ApiClient.convertToType(data['officeId'], 'String');
            }
            if (data.hasOwnProperty('officeName')) {
                obj['officeName'] = ApiClient.convertToType(data['officeName'], 'String');
            }
            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('companyName')) {
                obj['companyName'] = ApiClient.convertToType(data['companyName'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('toBeTracked')) {
                obj['toBeTracked'] = ApiClient.convertToType(data['toBeTracked'], 'Boolean');
            }
            if (data.hasOwnProperty('numInProgressProjects')) {
                obj['numInProgressProjects'] = ApiClient.convertToType(data['numInProgressProjects'], 'Number');
            }
            if (data.hasOwnProperty('hasInProgressProjects')) {
                obj['hasInProgressProjects'] = ApiClient.convertToType(data['hasInProgressProjects'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
BPSClient.prototype['id'] = undefined;

/**
 * @member {String} typeResource
 */
BPSClient.prototype['typeResource'] = undefined;

/**
 * @member {String} channelId
 */
BPSClient.prototype['channelId'] = undefined;

/**
 * @member {String} channelName
 */
BPSClient.prototype['channelName'] = undefined;

/**
 * @member {String} officeId
 */
BPSClient.prototype['officeId'] = undefined;

/**
 * @member {String} officeName
 */
BPSClient.prototype['officeName'] = undefined;

/**
 * @member {String} username
 */
BPSClient.prototype['username'] = undefined;

/**
 * @member {String} companyName
 */
BPSClient.prototype['companyName'] = undefined;

/**
 * @member {String} email
 */
BPSClient.prototype['email'] = undefined;

/**
 * @member {Boolean} toBeTracked
 */
BPSClient.prototype['toBeTracked'] = undefined;

/**
 * @member {Number} numInProgressProjects
 */
BPSClient.prototype['numInProgressProjects'] = undefined;

/**
 * @member {Boolean} hasInProgressProjects
 */
BPSClient.prototype['hasInProgressProjects'] = undefined;






export default BPSClient;

