import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import Moment from 'react-moment'
import LPTooltip from './lp-tooltip'


const LPDesiredDate = (props) => {
    const {desiredDelivery, actualDelivery} = props

    const content = <div className="d-flex">
        <Moment format="DD/MMM/YY hh:mm A" unix>
            { actualDelivery }
        </Moment>
        <FontAwesomeIcon style={{
            marginLeft: '4px',
            width: '16px',
            height: '16px',
        }} icon="exclamation" />
    </div>
    
    const tooltipContent = <span>
        The desired delivery date is: {' '}
        <Moment format="DD/MMM/YYYY" unix>
            { desiredDelivery }
        </Moment>
        <br />
        The actual delivery date is: {' '}
        <Moment format="DD/MMM/YYYY hh:mm A" unix>
            { actualDelivery }
        </Moment>
    </span>

    return <LPTooltip placement="top" tooltip={tooltipContent}>
        {content}
    </LPTooltip>
}

export default LPDesiredDate
