// PROJECTS
export const archivedProjectActionName = 'SET_ARCHIVED_PROJECTS'

export const projectsArchiveCreate = (ProjectsAPI, refreshTokenPipe, dispatch) => {
    const foo = (projectId) => new Promise((resolve, reject) => {
        ProjectsAPI.projectsArchiveCreate(projectId, (err, data) => {
            refreshTokenPipe(err)
            .then((reAuthenticated) => {
                if (reAuthenticated) {
                    foo(projectId)
                    .then(resolve, reject)
                    return
                } 
                
                if (err) {
                    reject(err)
                    return
                }
                    
                resolve(data)
            })
        })
    })
   
    return foo
}

export const getArchivedProjects = (ClientsAPI, refreshTokenPipe, dispatch) => {
    const foo = (clientId) => new Promise((resolve, reject) => {
        ClientsAPI.clientsArchivedProjectsRead(clientId, (err, data) => {
            refreshTokenPipe(err)
            .then((reAuthenticated) => {
                if (reAuthenticated) {
                    foo(clientId)
                    .then(resolve, reject)
                    return
                } 
                
                if (err) {
                    reject(err)
                    return
                }
                const projects = data.map(parseArchivedProject) 
                    
                resolve(projects)
                dispatch({
                    type: archivedProjectActionName,
                    payload: projects
                })
            })
        })
    })
    
    return foo
}

export const getArchivedProject = (ClientsAPI, refreshTokenPipe, dispatch) => {
    const foo = (projectId, clientId) =>  new Promise((resolve, reject) => {
        ClientsAPI.clientsArchivedProjectRead(projectId, clientId, (err, data) => {
            refreshTokenPipe(err)
            .then((reAuthenticated) => {
                if (reAuthenticated) {
                    foo(clientId)
                    .then(resolve, reject)
                    return
                } 
                
                if (err) {
                    reject(err)
                    return
                }
                    
                resolve(parseArchivedProject(data))
            })
        })
    })

    return foo
}

export const setArchivedProjects = (state, action) => {
    const archivedProjects = action.payload
      
    return {
      ...state,
      archivedProjects: archivedProjects || [],
      archivedProjectHasPages: !!archivedProjects.next || !!archivedProjects.previous,
      archivedProjectsCount: archivedProjects?.length || 0,
      archivedProjectsNotFound: !archivedProjects?.length
    }
}

function dateStringToMomentTime(dateString) {
    if (!dateString) {
        return null
    }

    const d = new Date(dateString).getTime()
    return (d - (d % 1000)) / 1000
}

function parseArchivedProject (archivedProject) {
    const {closed_at: closedString, created_at: createdString} = archivedProject
    const closed_at = dateStringToMomentTime(closedString)
    const created_at = dateStringToMomentTime(createdString)
    return {...archivedProject, closed_at, created_at}
}


// REQUESTS
export const archivedRequestsActionName = 'SET_ARCHIVED_REQUESTS'

export const getArchivedRequests = (ClientsAPI, refreshTokenPipe, dispatch) => {
    const foo = (projectId, clientId) =>  new Promise((resolve, reject) => {
        ClientsAPI.clientsArchivedProjectsArchivedLocreqsRead(projectId, clientId, (err, data) => {
            refreshTokenPipe(err)
            .then((reAuthenticated) => {
                if (reAuthenticated) {
                    foo(clientId)
                    .then(resolve, reject)
                    return
                } 
                
                if (err) {
                    reject(err)
                    return
                }

                const requests = data.map(parseArchivedRequest)
                resolve(requests)
                dispatch({
                    type: archivedRequestsActionName,
                    payload: requests
                })
            })
        })
    })
    
    return foo
}

function parseArchivedRequest (archivedRequest) {
    const {end: closedString, start: startString, created_at: createdString} = archivedRequest
    const end = dateStringToMomentTime(closedString)
    const created_at = dateStringToMomentTime(createdString)
    const start = dateStringToMomentTime(startString)
    return {...archivedRequest, end, created_at, start}
}

export const setArchivedRequests = (state, action) => {
    const archivedRequests = action.payload
      
    return {
      ...state,
      archivedRequests: archivedRequests || [],
      archivedRequestsHasPages: !!archivedRequests.next || !!archivedRequests.previous,
      archivedRequestsCount: archivedRequests?.length || 0,
      archivedRequestsNotFound: !archivedRequests?.length
    }
}