import React from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";

// https://codesandbox.io/s/lucid-ellis-kgw39?file=/src/MySelect.js:0-1011

const LPSelection = props => <ReactSelect
    {...props}
    options={[props.allOption, ...props.options]}
    onChange={selected => {
        const selectedItem = selected !== null &&
                             selected.length > 0 &&
                             selected[selected.length - 1]

        
        return props.onChange(selectedItem?.value === props.allOption.value ? props.options : selected)
    }}
/>

LPSelection.propTypes = {
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
    allOption: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
    })
};

LPSelection.defaultProps = {
    allOption: {
        label: "Select all",
        value: "*"
    }
};

export default LPSelection;
