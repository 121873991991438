import React, {useState, useContext} from 'react'
import { 
  Row, Col, Tab, Nav, Button, Spinner 
} from 'react-bootstrap'

import tabs from './tabs'
import {GlobalContext} from '../../../context/GlobalState'
import './style.scss'


export function RequestDetailsContent (props) {
  const { 
    request, results, history, onTabChanged, onHide, onRefreshRequired = () => {} 
  } = props
  const [isLoading, setIsLoading] = useState(false)
  const {
    user, checkForUnassignedFiles, currentProject
  } = useContext(GlobalContext)
  const {isKWSPM, isClientPM} = user
  
  const availableTabs = tabs
  if (isClientPM && tabs.length > 2) {
    availableTabs.pop()
  }
  
  const [activeTab, setActiveTab] = useState(availableTabs[0]?.key)


  // unassignable jobs
  const unassignableJobs = (results?.languages || []).find(l => l.jobassignment_state === 'unassignable')

  // pending jobs
  const allJobsArePending = !(results?.languages || []).find(l => l.jobassignment_state !== 'pending')
  
  // Button disable logic
  // check unassignable
  const checkUnassignableDisabled = !!(
    isLoading || 
    !unassignableJobs ||
    allJobsArePending
  )
  // actions
  const isOngoing = request.state.startsWith('ongoing')
  const contactDisabled = request.contacted_at || !isOngoing
  const cancelDisabled = (!isKWSPM && request.state !== 'ongoing') || (isKWSPM && !isOngoing)
  //


  const handleCheckUnassignable = () => {
    setIsLoading(true)
    checkForUnassignedFiles(currentProject.id)
    .then(onRefreshRequired)
    .finally(() => setIsLoading(false))
  }

  const handleSelectTab = (key) => {
    onTabChanged(key)
    setActiveTab(key)
  }

  return <> 
    <Tab.Container activeKey={activeTab} onSelect={handleSelectTab}>
      <Row className="flex-nowrap">

        <Col sm="auto" className="tabs">
          <Nav className="flex-row flex-lg-column">
            {/* HEADER */}
            {availableTabs.map(tab => 
              <Nav.Item key={tab.key}>
                <Nav.Link eventKey={tab.key}>
                  {tab.label}
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
        </Col>

        <Col className="tab-container no-padding">
          <Tab.Content>
            {/* CONTENT */}
            {availableTabs.map(tab => 
              <Tab.Pane eventKey={tab.key} key={tab.key}>
                {tab.content({request, results, history})}
              </Tab.Pane>
            )}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>

    {/* ACTIONS (FOOTER) */}
    <Row className='modal-footer'>
      <Col xs={8}>
        { isKWSPM &&
          <Button id="check-unassigned-btn"
            variant="danger"
            onClick={handleCheckUnassignable}
            disabled={checkUnassignableDisabled}>
              {isLoading 
                ? <Row>
                  <Spinner  animation='border' 
                            className='d-flex justify-content-center'
                            style={{
                              width: '20px', height: '20px', 
                              marginLeft: '5px', marginRight: '5px', marginTop: '0px'
                            }} 
                  />
                  <span style={{ marginRight: '5px' }} >checking...</span>
                </Row>
                : <span>check unassignable files</span>
              }
          </Button>
        }
      </Col>
      <Col xs={6}>
        { !isKWSPM &&
          <Button id="contact-pm-btn"
            variant="primary"
            onClick={() => onHide('contact')}
            disabled={contactDisabled}>
            CONTACT PM
          </Button>
        }
      </Col>
      <Col xs={6} className="text-right">
        <Button id="cancel-btn"
          variant="danger"
          disabled={cancelDisabled}
          onClick={() => onHide('cancel')}>
          { !isKWSPM && 'ASK TO ' }
          CANCEL REQUEST
        </Button>
      </Col>
    </Row>
  </>
}

export default RequestDetailsContent