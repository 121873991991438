import {resourceRoles} from '../../utils'

const resourceParser = (data) => {
    let resources = {}
    data.forEach(({ target_language, id, ...rest}) => {
        if (!resources[rest.memoq_username]) {
            resources[rest.memoq_username] = {
                ...rest,
                target_languages: [target_language],
                ids: [id],
                role: !!rest?.can_review
                    ? resourceRoles[1].value
                    : !!rest?.can_translate
                        ? resourceRoles[0].value
                        : resourceRoles[2].value
            }
        } else {
            resources[rest.memoq_username].ids.push(id)
            if (resources[rest.memoq_username].target_languages.indexOf(target_language) < 0) {
                resources[rest.memoq_username].target_languages.push(target_language)
            }
        }
    })

    return Object.values(resources)
}


export const getResources = (ProjectsAPI, refreshTokenPipe) => {
    const foo = (projectId, service) => new Promise((resolve, reject) => {
        ProjectsAPI.projectsServicesResourcesList(
            projectId,
            service,
            (err, data) => {
                refreshTokenPipe(err)
                    .then((reAuthenticated) => {
                    if (reAuthenticated) {
                        foo(projectId, service)
                        .then(resolve, reject)
                        return
                    }
                    
                    if (err) {
                        reject(err)
                        return
                    }

                    resolve(resourceParser(data))
                })
            }
        )
    })
    

    return foo
}

export const updateResource = (ProjectsAPI, refreshTokenPipe) => {
    const foo = (projectId, service, update) => {
        return new Promise((resolve, reject) => {
            ProjectsAPI.projectsServicesResourcesUpdate(
                projectId,
                service,
                update,
                (err, data) => {
                    refreshTokenPipe(err)
                        .then((reAuthenticated) => {
                        if (reAuthenticated) {
                            updateResource(projectId, service, update)
                            .then(resolve, reject)
                        } else {
                            if (err) {
                                reject(err)
                            } else {
                                resolve(resourceParser(data))
                            }
                        }
                    })
                }
            )
        })
    }

    return foo
}