import React, { useContext } from 'react'
import './header.scss'
import { Container, Navbar, Nav, Image } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

import NavigationMenus from '../navigation-menus/NavigationMenus'

import { GlobalContext } from '../../context/GlobalState'

const Header = () => {
  const { user } = useContext(GlobalContext)

  return (user
    ? <Navbar className="Header" variant="dark" expand="lg" fixed="top">
      <Container fluid>
        <Nav.Link id="home-link"
          as={NavLink}
          className="navbar-brand"
          to={ user.isKWSPM ? "/dashboard" : "/" }>
          <Image src="/assets/images/One-K-Logo_White.svg" alt="OneK logo" />
        </Nav.Link>
        <NavigationMenus />
      </Container>
    </Navbar>
    : null
  )
}

export default Header
