import React, { useContext, useEffect } from 'react'
import './guest.scss'

import { Container, Row, Col } from 'react-bootstrap'
import {
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom"

import { GlobalContext } from '../../context/GlobalState'

import Login from '../login/Login'
import ForgotPassword from '../forgotPassword/forgotPassword'
import ResetPassword from '../resetPassword/resetPassword'
import Loading from '../loading/Loading'

const Guest = () => {
  const location = useLocation()
  const { user, authenticating, authFailed, loggedOut, getUser } = useContext(GlobalContext)

  useEffect(() => {
    if (!authFailed && !loggedOut) {
      getUser().catch(() => {})
    }
    // eslint-disable-next-line
  }, [])

  const to = location.state
    ? location.state.from
    : '/'

  if (user) {
    return <Redirect to={ to } />
  } else if (authenticating) {
    return <Loading />
  }

  return (
    <Container fluid="md" className="d-flex h-100">
      <Row className="justify-content-center align-content-center w-100">
        <Col>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route path="/reset-password/:uid?/:token?">
              <ResetPassword />
            </Route>
          </Switch>
        </Col>
      </Row>
    </Container>
  )
}

export default Guest
