import React from 'react'
import { ProgressBar, Table, OverlayTrigger, Tooltip } from 'react-bootstrap'
import LPHeaderCol from '../../lp-header-col/LPHeaderCol'

import {  isFileFiltrable, downloadFile } from '../../../utils'
import '../style.scss'


export default function FileTable (props) {
    const {
        files, hasFilterableFile, filters, 
        onFilterSelected, onDeleteFile
    } = props

    if (!(files && !!Object.keys(files).length)) {
        return null
    }
     
    const onDownloadFile = (e, file) => {
        e.preventDefault()
    
        if (!file.downloadUrl) {
          throw new Error('Resource URL not provided! You need to provide one')
        }
    
        downloadFile(file.downloadUrl, file.name)
    }

    return <Table striped responsive className='small'>
        <thead>
            <tr>
                <th>
                    <LPHeaderCol icon='onek-files' text='File' />
                </th>
                { hasFilterableFile && <th>
                    <LPHeaderCol text='Filters' />
                </th> }
                <th className='progress-cell'>
                    <LPHeaderCol icon='onek-status' text='Status' />
                </th>
                <th className='size-cell'>
                    <LPHeaderCol icon='onek-size' text='Size' />
                </th>
                <th className='action-cell'></th>
            </tr>
        </thead>
        <tbody>
            { Object.entries(files).map(([name, file]) => 
                <FileTableRow 
                    key={name} 
                    file={file} 
                    filters={filters} 
                    hasFilterableFile={hasFilterableFile}
                    onFilterSelected={onFilterSelected} 
                    onDeleteFile={onDeleteFile} 
                    onDownloadFile={onDownloadFile}
                />
            )}
        </tbody>
    </Table>
}


function FileTableRow (props) {
    const {
        file, filters, hasFilterableFile,
        onFilterSelected, onDeleteFile, onDownloadFile
    } = props

    const thereAreFilters = filters && file.completed && isFileFiltrable(file)

    return  <tr>
        <td>{ file.icon } { file.name }</td>

        { hasFilterableFile && <td>
            { thereAreFilters && 
                <FileFilters    filters={filters} 
                                file={file} 
                                onFilterSelected={onFilterSelected} 
                />}
        </td> }

        <td>
            <FileError file={file} />
        </td>
        
        <td className='text-right'>{ file.size }</td>

        <td className='text-right'>
            { !!file.downloadUrl &&
                <button onClick={e => onDownloadFile(e, file)}
                        className='icon-only download-file-link'>
                    <i className='onek-drag-drop' />
                </button>
            }
            <button className='icon-only delete-file-btn'
                    onClick={e => onDeleteFile(e, file)}>
                <i className={ file.completed ? 'onek-delete' : 'close'} />
            </button>
        </td>
    </tr>
}

function FileFilters (props) {
    const {filters, file, onFilterSelected} = props

    return <select  name='filter'
                    className='filter-dropdown'
                    id={`filter-${file.name}`}
                    value={file.filter}
                    onChange={e => onFilterSelected(e, file)}>
        { filters.length 
            ? filters.map(filter => 
                <option key={filter.guid} value={filter.guid}>
                    {filter.name}
                </option>) 
            : <option key='no-filter' value='no-filter'>
                no-filter
            </option> 
        }
    </select>
}

function FileError (props) {
    const {file} = props
    const {error, completed, failed, progress} = file
    const label = completed ? 'Uploaded' : failed ? 'Upload failed' : `${progress}%`

    const ErrorTooltip = <Tooltip>{error}</Tooltip>

    return error 
        ? <OverlayTrigger placement='top' overlay={<ErrorTooltip />}>
            <ProgressBar variant='danger' now='100' label='Upload failed' />
        </OverlayTrigger>
        : <ProgressBar animated={progress < 100}
            variant={failed ? 'danger' : 'success'}
            now={progress}
            label={label} 
        />
}