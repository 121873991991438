/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The UserCreate model module.
 * @module model/UserCreate
 * @version v1
 */
class UserCreate {
    /**
     * Constructs a new <code>UserCreate</code>.
     * @alias module:model/UserCreate
     * @param username {String} 
     * @param firstName {String} 
     * @param lastName {String} 
     * @param email {String} 
     * @param isAdmin {Boolean} 
     */
    constructor(username, firstName, lastName, email, isAdmin) { 
        
        UserCreate.initialize(this, username, firstName, lastName, email, isAdmin);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, username, firstName, lastName, email, isAdmin) { 
        obj['username'] = username;
        obj['first_name'] = firstName;
        obj['last_name'] = lastName;
        obj['email'] = email;
        obj['is_admin'] = isAdmin;
    }

    /**
     * Constructs a <code>UserCreate</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UserCreate} obj Optional instance to populate.
     * @return {module:model/UserCreate} The populated <code>UserCreate</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserCreate();

            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('first_name')) {
                obj['first_name'] = ApiClient.convertToType(data['first_name'], 'String');
            }
            if (data.hasOwnProperty('last_name')) {
                obj['last_name'] = ApiClient.convertToType(data['last_name'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('is_admin')) {
                obj['is_admin'] = ApiClient.convertToType(data['is_admin'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {String} username
 */
UserCreate.prototype['username'] = undefined;

/**
 * @member {String} first_name
 */
UserCreate.prototype['first_name'] = undefined;

/**
 * @member {String} last_name
 */
UserCreate.prototype['last_name'] = undefined;

/**
 * @member {String} email
 */
UserCreate.prototype['email'] = undefined;

/**
 * @member {Boolean} is_admin
 */
UserCreate.prototype['is_admin'] = undefined;






export default UserCreate;

