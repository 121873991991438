import React, { useContext, useEffect, useState } from 'react'

import { Row, Col, Card, Alert, Badge, Form } from 'react-bootstrap'
import moment from 'moment'
import cN from 'classnames'

import { Doughnut, Line, Bar } from 'react-chartjs-2';

import ChartDateRange from '../chart-date-range/ChartDateRange'
import ProjectsDropdown, { allProjectsOption } from '../projects-dropdown/ProjectsDropdown'

import ClientsDropdown, { allClientsOption } from '../clients-dropdown/ClientsDropdown'

import { target_languages } from '../../temp_lists'

import { GlobalContext } from '../../context/GlobalState'

const ranges = {
  lastWeek: {
    dateRange: {
      start: moment().subtract(1, 'week').add(1, 'day').toDate(),
      end: new Date()
    },
    label: 'Last week'
  },
  lastMonth: {
    dateRange: {
      start: moment().subtract(1, 'month').add(1, 'day').toDate(),
      end: new Date()
    },
    label: 'Last month'
  },
  lastYear: {
    dateRange: {
      start: moment().subtract(1, 'year').add(1, 'day').toDate(),
      end: new Date()
    },
    label: 'Last year'
  }
}

const CustomDateRange = (props) => {
  return (
    <Form.Group>
      <Form.Label>Date range</Form.Label>
      <ChartDateRange
        ranges={ranges}
        maxDate={new Date()}
        value={props.value}
        onChange={props.onChange} />
    </Form.Group>
  )
}

const KWSStatistics = () => {
  const { getRequestsStats, getRequestsTotalsStats,
    getProjectsTotalsStats,
    getProjectsTotalsWordcount,
    getLRLanguages, getFilecountChart,
    getFilecountAvg, getProjects } = useContext(GlobalContext)

  const colorScale = {
    count_100: {
      color: '#00876c',
      label: '100'
    },
    count_101: {
      color: '#45976f',
      label: '101'
    },
    count_total: {
      color: '#6ba675',
      label: 'Total'
    },
    count_adjusted: {
      color: '#8eb47d',
      label: 'Adjusted'
    },
    count_95_99: {
      color: '#afc388',
      label: '95% - 99%'
    },
    count_85_94: {
      color: '#cfd197',
      label: '85% - 94%'
    },
    count_75_84: {
      color: '#eee0a9',
      label: '75% - 84%'
    },
    count_50_74: {
      color: '#eac98c',
      label: '50% - 74%'
    },
    count_fragments: {
      color: '#e7b074',
      label: 'Fragments'
    },
    count_repetitions: {
      color: '#e59761',
      label: 'Repetitions'
    },
    count_x_translated: {
      color: '#e27c56',
      label: 'X Translated'
    },
    count_no_match: {
      color: '#d43d51',
      label: 'No Match'
    },
  }

  const [openRequests, setOpenRequests] = useState([])
  const [openRequestsLoading, setOpenRequestsLoading] = useState(false)
  const [invoicedRequests, setInvoicedRequests] = useState([])
  const [invoicedRequestsLoading, setInvoicedRequestsLoading] = useState(false)
  const [projectsData, setProjectsData] = useState([])
  const [projectsTotal, setProjectsTotal] = useState(0)
  const [totalOpenRequests, setTotalOpenRequests] = useState(0)
  const [totalInvoicedRequests, setTotalInvoicedRequests] = useState(0)
  const [wordCountData, setWordCountData] = useState([])
  const [wordCountDataLoading, setWordCountDataLoading] = useState(false)
  const [adjustedWordCountData, setAdjustedWordCountData] = useState([])
  const [adjustedWordCountDataLoading, setAdjustedWordCountDataLoading] = useState(false)
  const [ongoingLRRange, setOngoingLRRange] = useState(ranges.lastWeek.dateRange)
  const [invoicedLRRange, setInvoicedLRRange] = useState(ranges.lastWeek.dateRange)
  const [wCRange, setWCRange] = useState(ranges.lastMonth.dateRange)
  const [wCClient, setWCClient] = useState(allClientsOption)
  const [wCClientProjects, setWCClientProjects] = useState(null)
  const [wCProject, setWCProject] = useState(allProjectsOption)
  const [adjustedWCRange, setAdjustedWCRange] = useState(ranges.lastMonth.dateRange)
  const [adjustedWCProject, setAdjustedWCProject] = useState(allProjectsOption)
  const [adjustedWCClient, setAdjustedWCClient] = useState(allClientsOption)
  const [adjustedwCClientProjects, setAdjustedWCClientProjects] = useState(null)
  const [languagesRange, setLanguagesRange] = useState(ranges.lastWeek.dateRange)
  const [requestedLanguages, setRequestedLanguages] = useState([])
  const [requestedLanguagesLoading, setRequestedLanguagesLoading] = useState(false)
  const [filesRange, setFilesRange] = useState(ranges.lastWeek.dateRange)
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [uploadedFilesLoading, setUploadedFilesLoading] = useState(false)
  const [avgFilesRange, setAvgFilesRange] = useState(ranges.lastYear.dateRange)
  const [avgUploadedFiles, setAvgUploadedFiles] = useState([])
  const [avgUploadedFilesLoading, setAvgUploadedFilesLoading] = useState(false)

  useEffect(() => {
    getOngoingRequests()
    getInvoicedRequests()
    getTotalRequests()
    getRequestedLanguages()
    getUploadedFiles()
    getAvgUploadedFiles()
    getWC()
    getAdjustedWC()

    getProjectsTotalsStats()
      .then((res) => {
        if (res) {
          setProjectsData([
            res.state_new,
            res.state_ongoing,
            res.state_closed
          ])
          setProjectsTotal(
            res.state_new +
            res.state_ongoing +
            res.state_closed
          )
        }
      })
    // eslint-disable-next-line
  }, [])

  const getTotalRequests = () => {
    getRequestsTotalsStats({ state: 'open' })
      .then((res) => {
        if (res && res.total !== undefined) {
          setTotalOpenRequests(res.total)
        }
      })

    getRequestsTotalsStats({ state: 'invoiced' })
      .then((res) => {
        if (res && res.total !== undefined) {
          setTotalInvoicedRequests(res.total)
        }
      })
  }

  const getOngoingRequests = (params) => {
    params = params || {}
    if (params.range) {
      setOngoingLRRange(params.range)
    } else {
      params.range = ongoingLRRange
    }

    setOpenRequestsLoading(true)
    getRequestsStats({
      state: 'open',
      dateFrom: moment(params.range.start).format('YYYY-MM-DD'),
      dateTo: moment(params.range.end).add(1, 'day').format('YYYY-MM-DD')
    })
      .then((res) => setOpenRequests(
        res.filter(item => item.started).length
          ? res
          : []
      ))
      .finally(() => setOpenRequestsLoading(false))
  }

  const getInvoicedRequests = (params) => {
    params = params || {}
    if (params.range) {
      setInvoicedLRRange(params.range)
    } else {
      params.range = invoicedLRRange
    }

    setInvoicedRequestsLoading(true)
    getRequestsStats({
      state: 'invoiced',
      dateFrom: moment(params.range.start).format('YYYY-MM-DD'),
      dateTo: moment(params.range.end).add(1, 'day').format('YYYY-MM-DD')
    })
      .then(setInvoicedRequests)
      .finally(() => setInvoicedRequestsLoading(false))
  }

  const getRequestedLanguages = (params) => {
    params = params || {}
    if (params.range) {
      setLanguagesRange(params.range)
    } else {
      params.range = languagesRange
    }

    setRequestedLanguagesLoading(true)
    getLRLanguages({
      dateFrom: moment(params.range.start).format('YYYY-MM-DD'),
      dateTo: moment(params.range.end).add(1, 'day').format('YYYY-MM-DD')
    })
      .then(setRequestedLanguages)
      .finally(() => setRequestedLanguagesLoading(false))
  }

  const getUploadedFiles = (params) => {
    params = params || {}
    if (params.range) {
      setFilesRange(params.range)
    } else {
      params.range = filesRange
    }

    setUploadedFilesLoading(true)
    getFilecountChart({
      dateFrom: moment(params.range.start).format('YYYY-MM-DD'),
      dateTo: moment(params.range.end).add(1, 'day').format('YYYY-MM-DD')
    })
      .then(setUploadedFiles)
      .finally(() => setUploadedFilesLoading(false))
  }

  const getAvgUploadedFiles = (params) => {
    params = params || {}
    if (params.range) {
      setAvgFilesRange(params.range)
    } else {
      params.range = avgFilesRange
    }

    setAvgUploadedFilesLoading(true)
    getFilecountAvg({
      dateFrom: moment(params.range.start).format('YYYY-MM'),
      dateTo: moment(params.range.end).add(1, 'day').format('YYYY-MM')
    })
      .then(setAvgUploadedFiles)
      .finally(() => setAvgUploadedFilesLoading(false))
  }

  const getWC = (params) => {
    params = params || {}
    if (params.range) {
      setWCRange(params.range)
    } else {
      params.range = wCRange
    }

    if (params.projectId) {
      setWCProject(params.projectId)
    } else if (!params.clientId) {
      params.projectId = wCProject
    }

    if (params.clientId) {
      setWCClient(params.clientId)
      setWCProject(allProjectsOption)
      if (params.clientId.value === '') {
        setWCClientProjects(null)
      } else {
        getProjects(params.clientId.value, null, null, true)
          .then((data) => setWCClientProjects(data.results))
      }
    } else {
      params.clientId = wCClient
    }

    setWordCountDataLoading(true)
    getProjectsTotalsWordcount({
      dateFrom: moment(params.range.start).format('YYYY-MM-DD'),
      dateTo: moment(params.range.end).add(1, 'day').format('YYYY-MM-DD'),
      projectId: params.projectId && params.projectId.value !== ''
        ? params.projectId.value
        : null,
      clientId: params.clientId && params.clientId.value !== ''
        ? params.clientId.value
        : null
    })
      .then(setWordCountData)
      .finally(() => setWordCountDataLoading(false))
  }

  const getAdjustedWC = (params) => {
    params = params || {}
    if (params.range) {
      setAdjustedWCRange(params.range)
    } else {
      params.range = adjustedWCRange
    }

    if (params.projectId) {
      setAdjustedWCProject(params.projectId)
    } else if (!params.clientId) {
      params.projectId = adjustedWCProject
    }

    if (params.clientId) {
      setAdjustedWCClient(params.clientId)
      setAdjustedWCProject(allProjectsOption)
      if (params.clientId.value === '') {
        setAdjustedWCClientProjects(null)
      } else {
        getProjects(params.clientId.value, null, null, true)
          .then((data) => setAdjustedWCClientProjects(data.results))
      }
    } else {
      params.clientId = adjustedWCClient
    }

    setAdjustedWordCountDataLoading(true)
    getProjectsTotalsWordcount({
      dateFrom: moment(params.range.start).format('YYYY-MM-DD'),
      dateTo: moment(params.range.end).add(1, 'day').format('YYYY-MM-DD'),
      projectId: params.projectId && params.projectId.value !== ''
        ? params.projectId.value
        : null,
      clientId: params.clientId && params.clientId.value !== ''
        ? params.clientId.value
        : null,
      includeAdjusted: true
    })
      .then(setAdjustedWordCountData)
      .finally(() => setAdjustedWordCountDataLoading(false))
  }

  return (
    <div className="Statistics">
      <Card>
        <Card.Header>Projects & Requests Overview</Card.Header>
        <Card.Body>
          <Row>
            <Col sm={6} md={4}>
              <h3>Projects</h3>
              <div className="relative">
                <Doughnut
                  data={{
                    labels: ['NEW', 'ONGOING', 'CLOSED'],
                    datasets: [
                      {
                        label: 'PROJECTS',
                        data: projectsData,
                        backgroundColor: [
                          '#2CB829',
                          '#FFB800',
                          '#7795FF'
                        ],
                        borderWidth: 0
                      },
                    ],
                  }}
                  legend={{ position: 'bottom', fullWidth: false }}
                  options={{ cutoutPercentage: 80 }}
                />
                <div id="projects-count">
                  <span className="big">{ projectsTotal }</span>
                  PROJECTS
                </div>
              </div>
              <h3 className="mt-5">Requests</h3>
              <Alert variant="dark">
                Open Requests
                <Badge variant="secondary">
                  { totalOpenRequests }
                </Badge>
              </Alert>
              <Alert variant="dark">
                Invoiced Requests
                <Badge variant="secondary">
                  { totalInvoicedRequests }
                </Badge>
              </Alert>
            </Col>
            <Col sm={6} md={8}>
              <h3 className="mt-5 title-and-filters">
                <span>Ongoing Requests</span>
                 <div className="filters">
                  <CustomDateRange
                    value={ongoingLRRange}
                    onChange={(range) => getOngoingRequests({ range })}
                  />
                </div>
              </h3>
              <div className={cN('chart-container', {
                  noData: !openRequests || !openRequests.length,
                  loading: openRequestsLoading
                })}>
                <Line data={{
                    labels: openRequests
                      .map((item) => moment(item.date).format('MMM DD')),
                    datasets: [
                      {
                        label: 'Ongoing',
                        lineTension: 0.2,
                        data: openRequests.map((item) => item.started),
                        fill: true,
                        backgroundColor: '#6E84D4',
                        borderColor: '#B0C2FF',
                      }
                    ]
                  }}
                  legend={{ display: false }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className="mt-5 title-and-filters">
                <span>Invoiced Requests</span>
                <div className="filters">
                  <CustomDateRange
                    value={invoicedLRRange}
                    onChange={(range) => getInvoicedRequests({ range })}
                  />
                </div>
              </h3>
              <div className={cN('chart-container', {
                  noData: !invoicedRequests || !invoicedRequests.length,
                  loading: invoicedRequestsLoading
                })}>
                <Line data={{
                    labels: invoicedRequests
                      .map((item) => moment(item.date).format('MMM DD')),
                    datasets: [
                      {
                        label: 'Invoiced',
                        lineTension: 0.2,
                        data: invoicedRequests.map((item) => item.invoiced),
                        fill: true,
                        backgroundColor: '#6E84D4',
                        borderColor: '#B0C2FF',
                      }
                    ]
                  }}
                  legend={{ display: false }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className="mt-5 title-and-filters">
                <span>Requested Languages</span>
                <div className="filters">
                  <CustomDateRange
                    value={languagesRange}
                    onChange={(range) => getRequestedLanguages({ range })}
                  />
                </div>
              </h3>
              <div className={cN('chart-container', {
                  noData: !requestedLanguages || !requestedLanguages.length,
                  loading: requestedLanguagesLoading
                })}>
                <Line data={{
                    labels: requestedLanguages
                      .map((item) => moment(item.date).format('MMM DD')),
                    datasets: [
                      {
                        label: 'Languages',
                        lineTension: 0.2,
                        data: requestedLanguages.map((item) => item.count),
                        fill: true,
                        backgroundColor: '#6E84D4',
                        borderColor: '#B0C2FF',
                      }
                    ]
                  }}
                  legend={{ display: false }}
                />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>Files Overview</Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <h3 className="mt-5 title-and-filters">
                <span>Uploaded files per day</span>
                 <div className="filters">
                  <CustomDateRange
                    value={filesRange}
                    onChange={(range) => getUploadedFiles({ range })}
                  />
                </div>
              </h3>
              <div className={cN('chart-container', {
                  noData: !uploadedFiles || !uploadedFiles.length,
                  loading: uploadedFilesLoading
                })}>
                <Line data={{
                    labels: uploadedFiles
                      .map((item) => moment(item.date).format('MMM DD')),
                    datasets: [
                      {
                        label: 'Files Count',
                        lineTension: 0.2,
                        data: uploadedFiles.map((item) => item.count),
                        fill: true,
                        backgroundColor: '#6E84D4',
                        borderColor: '#B0C2FF',
                      }
                    ]
                  }}
                  legend={{ display: false }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className="mt-5 title-and-filters">
                <span>Average uploaded files per day every month</span>
                 <div className="filters">
                  <CustomDateRange
                    value={avgFilesRange}
                    onChange={(range) => getAvgUploadedFiles({ range })}
                  />
                </div>
              </h3>
              <div className={cN('chart-container', {
                  noData: !avgUploadedFiles || !avgUploadedFiles.length,
                  loading: avgUploadedFilesLoading
                })}>
                <Line data={{
                    labels: avgUploadedFiles
                      .map((item) => moment(item.month, 'YYYY-MM').format('MMM YYYY')),
                    datasets: [
                      {
                        label: 'Files Count Average',
                        lineTension: 0.2,
                        data: avgUploadedFiles.map((item) => item.avg),
                        fill: true,
                        backgroundColor: '#6E84D4',
                        borderColor: '#B0C2FF',
                      }
                    ]
                  }}
                  legend={{ display: false }}
                />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>Wordcount Overview</Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <h3 className="mt-5 title-and-filters">
                <span>Wordcount</span>
                 <div className="filters">
                  <CustomDateRange
                    value={wCRange}
                    onChange={(range) => getWC({ range })}
                  />
                  <ClientsDropdown
                    value={wCClient}
                    onChange={(clientId) => getWC({ clientId })}
                  />
                  { wCClient.value !== '' &&
                    <ProjectsDropdown
                      projects={wCClientProjects}
                      value={wCProject}
                      onChange={(projectId) => getWC({ projectId })}
                    />
                  }
                </div>
              </h3>
              <div className={cN('chart-container', {
                  noData: !wordCountData || !wordCountData.length,
                  loading: wordCountDataLoading
                })}>
                <Bar
                  data={{
                    labels: wordCountData
                      .map((item) => target_languages
                        .find((lang) => lang.code === item.language)
                        .name),
                    datasets: wordCountData.length
                      ? Object.keys(wordCountData[0])
                        .filter((key) => key !== 'date' &&
                          key !== 'language' &&
                          !key.startsWith('project'))
                        .map((key) => {
                          return {
                            label: colorScale[key]
                              ? colorScale[key].label
                              : key,
                            data: wordCountData.map((item) => item[key]),
                            backgroundColor: colorScale[key]
                            ? colorScale[key].color
                            : null,
                          }
                        })
                      : []
                  }}
                  legend={{
                    reverse: true
                  }}
                  options= {{
                    scales: {
                      yAxes: [{
                        stacked: true,
                      }],
                      xAxes: [{
                        stacked: true,
                      }],
                    }
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className="mt-5 title-and-filters">
                <span>Adjusted Wordcount</span>
                 <div className="filters">
                  <CustomDateRange
                    value={adjustedWCRange}
                    onChange={(range) => getAdjustedWC({ range })}
                  />
                  <ClientsDropdown
                    value={adjustedWCClient}
                    onChange={(clientId) => getAdjustedWC({ clientId })}
                  />
                  { adjustedWCClient.value !== '' &&
                    <ProjectsDropdown
                      projects={adjustedwCClientProjects}
                      value={adjustedWCProject}
                      onChange={(projectId) => getAdjustedWC({ projectId })}
                    />
                  }
                </div>
              </h3>
              <div className={cN('chart-container', {
                  noData: !adjustedWordCountData || !adjustedWordCountData.length,
                  loading: adjustedWordCountDataLoading
                })}>
                <Bar
                  data={{
                    labels: adjustedWordCountData
                      .map((item) => target_languages
                        .find((lang) => lang.code === item.language)
                        .name),
                    datasets: adjustedWordCountData.length
                      ? Object.keys(adjustedWordCountData[0])
                        .filter((key) => key !== 'date' &&
                          key !== 'language' &&
                          !key.startsWith('project'))
                        .map((key) => {
                          return {
                            label: colorScale[key]
                              ? colorScale[key].label
                              : key,
                            data: adjustedWordCountData.map((item) => item[key]),
                            backgroundColor: colorScale[key]
                            ? colorScale[key].color
                            : null,
                          }
                        })
                      : []
                  }}
                  legend={{
                    reverse: true
                  }}
                  options= {{
                    scales: {
                      yAxes: [{
                        stacked: true,
                      }],
                      xAxes: [{
                        stacked: true,
                      }],
                    }
                  }}
                />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}

export default KWSStatistics

