import React from 'react'

import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const LPTooltip = (props) => {
  return <OverlayTrigger
    placement={props.placement}
    overlay={<Tooltip>{props.tooltip}</Tooltip>}>
    { props.children }
  </OverlayTrigger>
}

export default LPTooltip
