import React, { 
    // useState, 
    //useEffect, 
    useContext 
} from 'react'

import { Modal } from 'react-bootstrap'

import { GlobalContext } from '../../context/GlobalState'

import AccountFormContent from './account-form-content'


const AccountForm = (props) => {
    const { 
        toggleUserEdit
    } = useContext(GlobalContext)
    
    // EVENTS
    const onHide = (action) => {
        toggleUserEdit()
    }

    return <Modal   show={props.show} 
                    onHide={onHide}
                    centered 
                    scrollable 
                    size="lg"
                    dialogClassName="tabbed-modal"> 
        <Modal.Header closeButton={true}>
            <Modal.Title> ACCOUNT SETTINGS </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <AccountFormContent />
        </Modal.Body>
    </Modal>
}


export default AccountForm
