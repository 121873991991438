/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The UserWithoutClient model module.
 * @module model/UserWithoutClient
 * @version v1
 */
class UserWithoutClient {
    /**
     * Constructs a new <code>UserWithoutClient</code>.
     * @alias module:model/UserWithoutClient
     * @param id {Number} 
     * @param username {String} 
     * @param firstName {String} 
     * @param email {String} 
     * @param isAdmin {Boolean} 
     * @param userType {module:model/UserWithoutClient.UserTypeEnum} 
     * @param isActive {Boolean} 
     * @param clients {Object} 
     * @param projectNotificationsEnabled {Boolean} 
     * @param picture {String} 
     * @param pictureDownloadLink {String} 
     */
    constructor(id, username, firstName, email, isAdmin, userType, isActive, clients, projectNotificationsEnabled, picture, pictureDownloadLink) { 
        
        UserWithoutClient.initialize(this, id, username, firstName, email, isAdmin, userType, isActive, clients, projectNotificationsEnabled, picture, pictureDownloadLink);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, username, firstName, email, isAdmin, userType, isActive, clients, projectNotificationsEnabled, picture, pictureDownloadLink) { 
        obj['id'] = id;
        obj['username'] = username;
        obj['first_name'] = firstName;
        obj['email'] = email;
        obj['is_admin'] = isAdmin;
        obj['user_type'] = userType;
        obj['is_active'] = isActive;
        obj['clients'] = clients;
        obj['project_notifications_enabled'] = projectNotificationsEnabled;
        obj['picture'] = picture;
        obj['picture_download_link'] = pictureDownloadLink;
    }

    /**
     * Constructs a <code>UserWithoutClient</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UserWithoutClient} obj Optional instance to populate.
     * @return {module:model/UserWithoutClient} The populated <code>UserWithoutClient</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserWithoutClient();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('first_name')) {
                obj['first_name'] = ApiClient.convertToType(data['first_name'], 'String');
            }
            if (data.hasOwnProperty('last_name')) {
                obj['last_name'] = ApiClient.convertToType(data['last_name'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('is_admin')) {
                obj['is_admin'] = ApiClient.convertToType(data['is_admin'], 'Boolean');
            }
            if (data.hasOwnProperty('user_type')) {
                obj['user_type'] = ApiClient.convertToType(data['user_type'], 'String');
            }
            if (data.hasOwnProperty('is_active')) {
                obj['is_active'] = ApiClient.convertToType(data['is_active'], 'Boolean');
            }
            if (data.hasOwnProperty('clients')) {
                obj['clients'] = ApiClient.convertToType(data['clients'], Object);
            }
            if (data.hasOwnProperty('project_notifications_enabled')) {
                obj['project_notifications_enabled'] = ApiClient.convertToType(data['project_notifications_enabled'], 'Boolean');
            }
            if (data.hasOwnProperty('picture')) {
                obj['picture'] = ApiClient.convertToType(data['picture'], 'String');
            }
            if (data.hasOwnProperty('picture_download_link')) {
                obj['picture_download_link'] = ApiClient.convertToType(data['picture_download_link'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
UserWithoutClient.prototype['id'] = undefined;

/**
 * @member {String} username
 */
UserWithoutClient.prototype['username'] = undefined;

/**
 * @member {String} first_name
 */
UserWithoutClient.prototype['first_name'] = undefined;

/**
 * @member {String} last_name
 */
UserWithoutClient.prototype['last_name'] = undefined;

/**
 * @member {String} email
 */
UserWithoutClient.prototype['email'] = undefined;

/**
 * @member {Boolean} is_admin
 */
UserWithoutClient.prototype['is_admin'] = undefined;

/**
 * @member {module:model/UserWithoutClient.UserTypeEnum} user_type
 */
UserWithoutClient.prototype['user_type'] = undefined;

/**
 * @member {Boolean} is_active
 */
UserWithoutClient.prototype['is_active'] = undefined;

/**
 * @member {Object} clients
 */
UserWithoutClient.prototype['clients'] = undefined;

/**
 * @member {Boolean} project_notifications_enabled
 */
UserWithoutClient.prototype['project_notifications_enabled'] = undefined;

/**
 * @member {String} picture
 */
UserWithoutClient.prototype['picture'] = undefined;

/**
 * @member {String} picture_download_link
 */
UserWithoutClient.prototype['picture_download_link'] = undefined;





/**
 * Allowed values for the <code>user_type</code> property.
 * @enum {String}
 * @readonly
 */
UserWithoutClient['UserTypeEnum'] = {

    /**
     * value: "client-pm"
     * @const
     */
    "client-pm": "client-pm",

    /**
     * value: "kws-pm"
     * @const
     */
    "kws-pm": "kws-pm"
};



export default UserWithoutClient;

