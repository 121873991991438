import React, { useContext, useEffect } from 'react'

import { Container, Card, Button, Row, Col } from 'react-bootstrap'
import { Redirect, useLocation, useHistory } from "react-router-dom"

import cN from "classnames"

import { GlobalContext } from '../../context/GlobalState'

const termsStyles = {
  flex: 1,
  overflow: 'auto',
  marginBottom: '3rem'
}

const Terms = () => {
  const location = useLocation()
  const history = useHistory()
  const { user, logout, acceptTerms, termsNotAccepted, reviewingTerms, closeTerms } = useContext(GlobalContext)

  // eslint-disable-next-line
  useEffect(() => closeTerms, [])

  if ((!termsNotAccepted && !reviewingTerms) || !user) {
    const to = location.state
      ? location.state.from
      : '/login'
    return <Redirect to={ to } />
  }

  const onDecline = () => {
    if (user) {
      logout(true)
    }
    history.replace('/login')
  }

  const onClose = () => {
    history.goBack()
  }

  return (
    <Container className="d-flex h-100">
      <Card className="Guest-form">
        <Card.Header>TERMS OF SERVICE ACCEPTANCE</Card.Header>
        <Card.Body className="d-flex flex-column">
          <Row style={termsStyles}>
            <Col>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta consequuntur accusamus veniam expedita nam ut sed reiciendis inventore, dignissimos, autem quia! Asperiores mollitia iusto nesciunt facere. Numquam quasi eligendi esse!</p>
              <p>Fugit totam, sapiente numquam, eaque ratione molestiae iste minus distinctio et fuga ullam ipsum repellat odio dolorum veritatis nihil explicabo officiis in quisquam cupiditate. Nesciunt fugit porro nobis ipsa blanditiis.</p>
              <p>Aut doloribus tempora eos eligendi odio ducimus qui, sequi quisquam maxime debitis quidem libero? Nemo cum iure blanditiis ullam mollitia nihil rerum? Voluptatem doloribus debitis iusto dolorem, culpa reiciendis placeat.</p>
              <p>Eius, assumenda atque quod, debitis consectetur laboriosam odit veniam culpa porro perspiciatis voluptatem. Necessitatibus, labore a deleniti facilis quisquam commodi magnam ad reiciendis veniam obcaecati quam quae. Libero, ad suscipit.</p>
              <p>Sint odit ut, commodi eligendi veniam, perspiciatis debitis ullam architecto non fugiat mollitia rem excepturi omnis a minus quasi laudantium atque eos? Quidem incidunt obcaecati id iure voluptatem, illo rem!</p>
              <p>Ullam, temporibus laborum doloribus accusamus in eligendi quasi vitae voluptas deleniti alias! Nam eos ea a quisquam quod ipsam at praesentium quaerat, explicabo ex voluptatibus animi! Eveniet dolorum aliquid excepturi.</p>
              <p>Nisi, corrupti aspernatur. Libero repudiandae odit, eum officiis molestiae recusandae laborum vitae provident necessitatibus aut. Inventore doloremque id obcaecati illo praesentium in nemo asperiores, tempore, quod ea sed a quis.</p>
              <p>Reiciendis officiis placeat provident recusandae explicabo praesentium ad debitis molestiae porro itaque nam vitae, facere repellat consequuntur consectetur atque soluta iure quidem fugiat autem quod ducimus reprehenderit ipsa nihil. Magnam.</p>
              <p>Quidem nisi assumenda eaque et error, magni cumque quod repellendus porro dolorem, odio tenetur nihil iure quos beatae? Nostrum sit dolore deleniti quia voluptatem, neque minima reprehenderit possimus ut quas?</p>
              <p>Deleniti recusandae sapiente corrupti, dolore quo possimus provident, nihil sed velit voluptates, eos quidem! Debitis nam sed sapiente expedita reiciendis nisi autem aliquid! Perspiciatis, eaque velit sequi obcaecati modi unde.</p>
              <p>Ratione, eaque sed quae delectus dolorem expedita placeat necessitatibus, ducimus culpa quaerat architecto suscipit repellendus tempore! Itaque quidem, iste quasi expedita nobis animi sint ad voluptatum fuga voluptas ea vitae.</p>
              <p>Tempora voluptatem cumque hic impedit sit minus maiores incidunt odio, itaque harum, aperiam molestias quasi eos labore unde ipsum omnis nesciunt voluptatum quae cupiditate. Voluptate consequatur praesentium impedit sequi minus?</p>
              <p>Cupiditate at esse adipisci architecto nulla excepturi illo cumque aspernatur accusantium nihil? Labore impedit ut itaque repellat delectus. Mollitia, rem sit at adipisci quidem unde odio pariatur expedita minus doloribus.</p>
              <p>Asperiores quis ullam ea aspernatur eos, veritatis aut. Suscipit reprehenderit hic officia adipisci et aliquid consequatur, ex esse quisquam sapiente quibusdam tempora architecto molestiae illo ad distinctio id fugiat magnam.</p>
              <p>Voluptatum nihil, illo reprehenderit eaque sequi consequatur placeat laudantium eius deserunt velit harum, id, soluta iste corporis repellendus rem repellat qui laborum quibusdam cupiditate ea saepe atque praesentium. Rem, mollitia!</p>
              <p>Qui, debitis adipisci! Est at ab voluptas ipsa sint. Voluptas quaerat mollitia odit est quos maxime dolor nemo nostrum reprehenderit voluptatum. Cumque unde iusto minus sunt et voluptatem animi repellat.</p>
              <p>Numquam modi doloremque consequuntur aspernatur aliquid, quos magnam et quidem veritatis quia? Quisquam, enim delectus nemo officiis eveniet repellat impedit quam omnis? Sapiente doloribus non quia voluptatem alias, quaerat veritatis.</p>
              <p>Aut ipsum officiis a voluptate soluta omnis fugiat consequuntur, ea error nostrum eos labore, mollitia illo, debitis harum magnam veniam odit aliquid id asperiores impedit quod voluptatem. Cupiditate, quisquam ipsam!</p>
              <p>Harum et, beatae, atque aliquid laudantium blanditiis aperiam quas, nobis aliquam quisquam voluptatum ab esse laborum numquam odit nisi similique. Voluptatem quo mollitia officiis sunt voluptatibus enim quaerat sed earum!</p>
              <p>Non voluptas ratione est. Est animi, tempora reprehenderit ut illo quo, quisquam repudiandae a placeat optio ipsam reiciendis aliquam fuga at vel ad doloribus sunt minus qui tempore! Modi, quasi!</p>
            </Col>
          </Row>
          <Row className={ cN({ 'd-none': reviewingTerms }) }>
            <Col>
              <Button id="accept-btn"
                variant="primary"
                onClick={() => acceptTerms()}>
                ACCEPT TERMS
              </Button>
            </Col>
            <Col className="text-right">
              <Button id="decline-btn"
                variant="default"
                onClick={onDecline}>
                DECLINE
              </Button>
            </Col>
          </Row>
          <Row className={ cN({ 'd-none': !reviewingTerms }) }>
            <Col className="text-center">
              <Button id="close-btn"
                variant="primary"
                onClick={onClose}>
                CLOSE
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default Terms
