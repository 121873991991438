/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import PasswordReset from '../model/PasswordReset';
import PasswordResetConfirm from '../model/PasswordResetConfirm';
import TokenObtainPair from '../model/TokenObtainPair';
import TokenObtainPairResponse from '../model/TokenObtainPairResponse';
import TokenRefresh from '../model/TokenRefresh';
import TokenRefreshResponse from '../model/TokenRefreshResponse';
import TokenVerify from '../model/TokenVerify';

/**
* Auth service.
* @module api/AuthApi
* @version v1
*/
export default class AuthApi {

    /**
    * Constructs a new AuthApi. 
    * @alias module:api/AuthApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the authPasswordResetConfirmCreate operation.
     * @callback module:api/AuthApi~authPasswordResetConfirmCreateCallback
     * @param {String} error Error message, if any.
     * @param {Object} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} uid 
     * @param {module:model/PasswordResetConfirm} data 
     * @param {module:api/AuthApi~authPasswordResetConfirmCreateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object}
     */
    authPasswordResetConfirmCreate(uid, data, callback) {
      let postBody = data;
      // verify the required parameter 'uid' is set
      if (uid === undefined || uid === null) {
        throw new Error("Missing the required parameter 'uid' when calling authPasswordResetConfirmCreate");
      }
      // verify the required parameter 'data' is set
      if (data === undefined || data === null) {
        throw new Error("Missing the required parameter 'data' when calling authPasswordResetConfirmCreate");
      }

      let pathParams = {
        'uid': uid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/auth/{uid}/password_reset_confirm/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the authPasswordResetCreate operation.
     * @callback module:api/AuthApi~authPasswordResetCreateCallback
     * @param {String} error Error message, if any.
     * @param {Object} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/PasswordReset} data 
     * @param {module:api/AuthApi~authPasswordResetCreateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object}
     */
    authPasswordResetCreate(data, callback) {
      let postBody = data;
      // verify the required parameter 'data' is set
      if (data === undefined || data === null) {
        throw new Error("Missing the required parameter 'data' when calling authPasswordResetCreate");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/auth/password_reset/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the authTokenCreate operation.
     * @callback module:api/AuthApi~authTokenCreateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/TokenObtainPairResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/TokenObtainPair} data 
     * @param {module:api/AuthApi~authTokenCreateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/TokenObtainPairResponse}
     */
    authTokenCreate(data, callback) {
      let postBody = data;
      // verify the required parameter 'data' is set
      if (data === undefined || data === null) {
        throw new Error("Missing the required parameter 'data' when calling authTokenCreate");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = TokenObtainPairResponse;
      return this.apiClient.callApi(
        '/auth/token/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the authTokenRefreshCreate operation.
     * @callback module:api/AuthApi~authTokenRefreshCreateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/TokenRefreshResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/TokenRefresh} data 
     * @param {module:api/AuthApi~authTokenRefreshCreateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/TokenRefreshResponse}
     */
    authTokenRefreshCreate(data, callback) {
      let postBody = data;
      // verify the required parameter 'data' is set
      if (data === undefined || data === null) {
        throw new Error("Missing the required parameter 'data' when calling authTokenRefreshCreate");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = TokenRefreshResponse;
      return this.apiClient.callApi(
        '/auth/token/refresh/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the authTokenVerifyCreate operation.
     * @callback module:api/AuthApi~authTokenVerifyCreateCallback
     * @param {String} error Error message, if any.
     * @param {Object} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/TokenVerify} data 
     * @param {module:api/AuthApi~authTokenVerifyCreateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object}
     */
    authTokenVerifyCreate(data, callback) {
      let postBody = data;
      // verify the required parameter 'data' is set
      if (data === undefined || data === null) {
        throw new Error("Missing the required parameter 'data' when calling authTokenVerifyCreate");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/auth/token/verify/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
