import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from "yup"
import cN from "classnames"

import { Modal, Row, Col, Form, Button, Toast } from 'react-bootstrap'

import { GlobalContext } from '../../context/GlobalState'

import { capitalize } from '../../utils'

const ContactPM = (props) => {
  const { id } = useParams()
  const { contact } = useContext(GlobalContext)
  const [title, setTitle] = useState(null)
  const [buttonLabel, setButtonLabel] = useState(null)
  const [errorMsg, setErrorMsg] = useState(null)
  const [showError, setShowError] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const cancelSubject = `REQUEST TO CANCEL ${props.request?.number}`

  const schema = yup.object().shape({
    subject: props.action !== 'cancel'
      ? yup.string().required('Subject is required')
      : null,
    body: yup.string().required('Message is required')
  })

  const { handleSubmit, register, errors, setError, formState, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    switch (props.action) {
      case 'cancel':
        setTitle(`Cancel ${props.request.number}`)
        setButtonLabel('REQUEST CANCEL')
        setValue('subject', cancelSubject)
        break
      default:
        setTitle('Contact PM')
        setButtonLabel('SEND MESSAGE')
        setValue('subject', '')
    }
    return () => {
      setSubmitting(false)
      setShowError(false)
    }
  }, [props.action, props.request, setValue, cancelSubject])

  const onSubmit = data => {
    data = {
      ...data,
      subject: props.action === 'cancel'
        ? cancelSubject
        : data.subject
    }

    setSubmitting(true)
    setShowError(false)

    contact(id, props.request, data, props.action)
      .then(() => {
        props.onHide(true, props.action)
      })
      .catch((err) => {
        setSubmitting(false)
        let errorMsg = null

        try {
          let error = JSON.parse(err.response.text)
          if (error.detail) {
            errorMsg = error.detail
          } else if (Array.isArray(error)) {
            errorMsg = capitalize(error.pop())
          } else {
            Object.entries(error)
              .forEach(([fieldName, messages]) => {
                if (schema.fields[fieldName] &&
                  schema.fields[fieldName].fields) {
                  fieldName += '.value'
                }
                setError(fieldName, {
                  type: 'manual',
                  message: capitalize(messages[0])
                })
              })
          }
        } catch (e) {
          errorMsg = err.response.text
        }

        setErrorMsg(errorMsg)
        setShowError(!!errorMsg)
      })
  }

  return <Modal show={props.show} onHide={props.onHide}
    centered scrollable size={'lg'}>
    <Modal.Header closeButton={true}>
      <Modal.Title>{ title }</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <Form onSubmit={handleSubmit(onSubmit)}>
        <Toast show={showError}
          onClose={() => setShowError(false)}>
          <Toast.Header>
            <strong className="mr-auto">Message couldn't be submitted</strong>
          </Toast.Header>
          <Toast.Body>{ errorMsg }</Toast.Body>
        </Toast>
        <Form.Group>
          <Form.Label>Add Subject...</Form.Label>
          <input id="subject"
            type="text"
            name="subject"
            disabled={props.action === 'cancel'}
            className={cN('form-control', {
              'is-invalid': errors.subject
            })}
            ref={register}
          />
          <Form.Control.Feedback type="invalid">
            {errors.subject?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Add Message...</Form.Label>
          <textarea id="body"
            name="body"
            className={cN('form-control', {
              'is-invalid': errors.body
            })}
            ref={register}
          />
          <Form.Control.Feedback type="invalid">
            {errors.body?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Row>
          <Col className="text-center">
            <Button id="submit-btn"
              variant="primary"
              type="submit"
              disabled={submitting || !formState.isDirty || !formState.isValid  }>
              { submitting ? 'Submitting...' : buttonLabel }
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal.Body>
  </Modal>
}

export default ContactPM
