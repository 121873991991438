import React, { useContext } from 'react'
import { Col, Row, Image } from 'react-bootstrap'
import cN from 'classnames'
import { GlobalContext } from '../../context/GlobalState'

const ClientBanner = (props) => {
    const {bannerFooter, logoFooter, clientNameSuffix = ''} = props
    const { currentClient } = useContext(GlobalContext)

    const {banner, logo, name} = currentClient || {}

    return <Row className="hero">
        {/** BANNER */}
        { currentClient &&
        <div className="banner" style={ banner ? {backgroundImage: `url('${banner}')`}: null }>
        </div>
        }

        {/** LOGO */}
        <Col xs className="d-flex flex-column">
            <div className={cN('section-header', { 'has-logo': logo })}>
                { !!logo && 
                    <Image src={ logo } alt={ name } className="logo" fluid/> 
                }

                { !!name &&
                    <h1 className="ellipsis">
                        { name } {clientNameSuffix}
                    </h1>
                }
                {bannerFooter}
            </div>
            <Row>
                <Col className="d-flex justify-content-end align-items-end">
                    {logoFooter}
                </Col>
            </Row>
        </Col>
    </Row>
}

export default ClientBanner