import React, { useContext, useEffect, useState } from 'react'

import { Button, Table, OverlayTrigger, Tooltip } from 'react-bootstrap'

import { GlobalContext } from '../../context/GlobalState'

import Dialog from 'react-bootstrap-dialog'
import { toast } from 'react-toastify';

import EntityNotFound from '../entity-not-found/EntityNotFound'
import TemplateForm from './TemplateForm'
import LPTooltip from '../lp-tooltip'

const Templates = (props) => {
  const { getProjectTemplates, deleteProjectTemplate } = useContext(GlobalContext)
  const [templates, setTemplates] = useState([])
  const [templateToEdit, setTemplateToEdit] = useState(null)
  const [showForm, setShowForm] = useState(false)
  const [dialog, setDialog] = useState(null)

  useEffect(() => {
    if (props.project) {
      getProjectTemplates(props.project.id)
        .then((data) => {
          setTemplates(data.results)
        })
    }
    // eslint-disable-next-line
  }, [props.project])

  useEffect(() => {
    if (props.activeTab !== 'templates' && showForm) {
      // Hide form
      onFormHide()
    }
    // eslint-disable-next-line
  }, [props.activeTab])

  const onDeleteTemplate = (e, template) => {
    e.preventDefault()

    dialog.show({
      title: `DELETE LRT-${template.id}`,
      body: `Do you really want to delete this Template?`,
      actions: [
        Dialog.Action(
          'CANCEL',
          () => {},
          'btn-default'
        ),
        Dialog.Action(
          'YES, DELETE',
          () => {
            deleteProjectTemplate(props.project.id, template.id)
              .then(() => {
                toast.success(`LRT-${template.id} has been deleted`)
                setTemplates(templates.filter((t) => {
                  return t.id !== template.id
                }))
              })
          },
          'btn-danger'
        )
      ],
      bsSize: 'small',
    })
  }

  const onEditTemplate = (e, template) => {
    e.preventDefault()

    setTemplateToEdit(template)
    setShowForm(true)
  }

  const onFormHide = (template) => {
    setShowForm(false)

    if (template) {
      if (templateToEdit) {
        toast.success(`LRT-${template.id} has been updated`)
        setTemplates(templates.map((t) => {
          if (t.id === templateToEdit.id) {
            return template
          }

          return t
        }))
      } else {
        toast.success(`LRT-${template.id} has been created`)
        setTemplates([
          ...templates,
          template
        ])
      }
    }

    setTemplateToEdit(null)
  }

  return (
    <>{ showForm
      ? <TemplateForm
        project={props.project}
        template={templateToEdit}
        onHide={onFormHide} />
      : <>
        <Dialog ref={ (el) => setDialog(el) }/>
        <div className="text-right">
          {
            props.project?.state === 'ongoing'
              ? <Button id="new-template-btn"
              variant="secondary"
              onClick={() => { setShowForm(true) }}>
                <i className="onek-new" />{' '}
                NEW TEMPLATE
              </Button>
              : <LPTooltip placement="bottom"
                tooltip="Templates can be created only when Project is ONGOING">
                <Button id="new-template-btn"
                  variant="secondary"
                  className="disabled">
                  <i className="onek-new" />{' '}
                  NEW TEMPLATE
                </Button>
              </LPTooltip>
          }
        </div>
        { !templates.length
          ? <EntityNotFound entityName="Templates" />
          : <Table striped responsive className="small mt-4">
            <thead>
              <tr>
                <th colSpan="3">AVAILABLE TEMPLATES</th>
              </tr>
            </thead>
            <tbody>
            { templates.map((template) => {
              return (
                <tr key={template.id}>
                  <td>LRT-{template.id}</td>
                  <td className="w-100">{ template.name }</td>
                  <td>
                  <OverlayTrigger placement="top"
                    overlay={<Tooltip>Edit</Tooltip>}>
                    <button className="icon-only edit-template-btn"
                      onClick={(e) => onEditTemplate(e, template)}>
                      <i className="onek-add-info" />
                    </button>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top"
                    overlay={<Tooltip>Delete</Tooltip>}>
                    <button className="icon-only delete-template-btn"
                      onClick={(e) => onDeleteTemplate(e, template)}>
                      <i className="onek-delete" />
                    </button>
                  </OverlayTrigger>
                  </td>
                </tr>
              )
            })}
            </tbody>
          </Table>
        }
      </>
    }</>
  )
}

export default Templates
