import {defaultColumn, buttonColumn, dateTimeColumn} from '../../tableColumnGenerators'

// COLUMN GEN
export const columns = (buttonAction) => [ 
    buttonColumn('name', 'Project', buttonAction, { sort: true }),
    defaultColumn('state', 'Project Status', {
        headerClasses: 'status', 
        classes: 'text-center'
    }, 'onek-status'), 
    defaultColumn('lr_complete_count', 'Archived Requests', {
        headerClasses: 'count', 
        classes: 'text-center',
        sort: true
    }), 
    dateTimeColumn('created_at', 'Created', {sort: true}, 'onek-started'), 
    dateTimeColumn('closed_at', 'Closed', {sort: true}, 'onek-delivered'),
]

export default columns