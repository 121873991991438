/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ProjectResource model module.
 * @module model/ProjectResource
 * @version v1
 */
class ProjectResource {
    /**
     * Constructs a new <code>ProjectResource</code>.
     * @alias module:model/ProjectResource
     * @param id {Number} 
     * @param memoqUsername {String} 
     * @param name {String} 
     * @param surname {String} 
     * @param targetLanguage {module:model/ProjectResource.TargetLanguageEnum} 
     * @param canTranslate {Boolean} 
     * @param canReview {Boolean} 
     * @param resourceClassification {module:model/ProjectResource.ResourceClassificationEnum} 
     */
    constructor(id, memoqUsername, name, surname, targetLanguage, canTranslate, canReview, resourceClassification) { 
        
        ProjectResource.initialize(this, id, memoqUsername, name, surname, targetLanguage, canTranslate, canReview, resourceClassification);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, memoqUsername, name, surname, targetLanguage, canTranslate, canReview, resourceClassification) { 
        obj['id'] = id;
        obj['memoq_username'] = memoqUsername;
        obj['name'] = name;
        obj['surname'] = surname;
        obj['target_language'] = targetLanguage;
        obj['can_translate'] = canTranslate;
        obj['can_review'] = canReview;
        obj['resource_classification'] = resourceClassification;
    }

    /**
     * Constructs a <code>ProjectResource</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProjectResource} obj Optional instance to populate.
     * @return {module:model/ProjectResource} The populated <code>ProjectResource</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProjectResource();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('memoq_username')) {
                obj['memoq_username'] = ApiClient.convertToType(data['memoq_username'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('surname')) {
                obj['surname'] = ApiClient.convertToType(data['surname'], 'String');
            }
            if (data.hasOwnProperty('target_language')) {
                obj['target_language'] = ApiClient.convertToType(data['target_language'], 'String');
            }
            if (data.hasOwnProperty('can_translate')) {
                obj['can_translate'] = ApiClient.convertToType(data['can_translate'], 'Boolean');
            }
            if (data.hasOwnProperty('can_review')) {
                obj['can_review'] = ApiClient.convertToType(data['can_review'], 'Boolean');
            }
            if (data.hasOwnProperty('language_check_passed')) {
                obj['language_check_passed'] = ApiClient.convertToType(data['language_check_passed'], 'Boolean');
            }
            if (data.hasOwnProperty('classification_check_passed')) {
                obj['classification_check_passed'] = ApiClient.convertToType(data['classification_check_passed'], 'Boolean');
            }
            if (data.hasOwnProperty('resource_classification')) {
                obj['resource_classification'] = ApiClient.convertToType(data['resource_classification'], 'String');
            }
            if (data.hasOwnProperty('pricelist_configured_properly')) {
                obj['pricelist_configured_properly'] = ApiClient.convertToType(data['pricelist_configured_properly'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
ProjectResource.prototype['id'] = undefined;

/**
 * @member {String} memoq_username
 */
ProjectResource.prototype['memoq_username'] = undefined;

/**
 * @member {String} name
 */
ProjectResource.prototype['name'] = undefined;

/**
 * @member {String} surname
 */
ProjectResource.prototype['surname'] = undefined;

/**
 * @member {module:model/ProjectResource.TargetLanguageEnum} target_language
 */
ProjectResource.prototype['target_language'] = undefined;

/**
 * @member {Boolean} can_translate
 */
ProjectResource.prototype['can_translate'] = undefined;

/**
 * @member {Boolean} can_review
 */
ProjectResource.prototype['can_review'] = undefined;

/**
 * @member {Boolean} language_check_passed
 * @default true
 */
ProjectResource.prototype['language_check_passed'] = true;

/**
 * @member {Boolean} classification_check_passed
 * @default true
 */
ProjectResource.prototype['classification_check_passed'] = true;

/**
 * @member {module:model/ProjectResource.ResourceClassificationEnum} resource_classification
 */
ProjectResource.prototype['resource_classification'] = undefined;

/**
 * @member {Boolean} pricelist_configured_properly
 * @default false
 */
ProjectResource.prototype['pricelist_configured_properly'] = false;





/**
 * Allowed values for the <code>target_language</code> property.
 * @enum {String}
 * @readonly
 */
ProjectResource['TargetLanguageEnum'] = {

    /**
     * value: "afrAF"
     * @const
     */
    "afrAF": "afrAF",

    /**
     * value: "albAL"
     * @const
     */
    "albAL": "albAL",

    /**
     * value: "amhET"
     * @const
     */
    "amhET": "amhET",

    /**
     * value: "araAE"
     * @const
     */
    "araAE": "araAE",

    /**
     * value: "araBH"
     * @const
     */
    "araBH": "araBH",

    /**
     * value: "araEG"
     * @const
     */
    "araEG": "araEG",

    /**
     * value: "araKW"
     * @const
     */
    "araKW": "araKW",

    /**
     * value: "araLB"
     * @const
     */
    "araLB": "araLB",

    /**
     * value: "araMS"
     * @const
     */
    "araMS": "araMS",

    /**
     * value: "araOM"
     * @const
     */
    "araOM": "araOM",

    /**
     * value: "araQA"
     * @const
     */
    "araQA": "araQA",

    /**
     * value: "araSA"
     * @const
     */
    "araSA": "araSA",

    /**
     * value: "asmIN"
     * @const
     */
    "asmIN": "asmIN",

    /**
     * value: "azeAZ"
     * @const
     */
    "azeAZ": "azeAZ",

    /**
     * value: "baqES"
     * @const
     */
    "baqES": "baqES",

    /**
     * value: "belBY"
     * @const
     */
    "belBY": "belBY",

    /**
     * value: "benBD"
     * @const
     */
    "benBD": "benBD",

    /**
     * value: "benIN"
     * @const
     */
    "benIN": "benIN",

    /**
     * value: "bosBH"
     * @const
     */
    "bosBH": "bosBH",

    /**
     * value: "bulBG"
     * @const
     */
    "bulBG": "bulBG",

    /**
     * value: "burMM"
     * @const
     */
    "burMM": "burMM",

    /**
     * value: "catES"
     * @const
     */
    "catES": "catES",

    /**
     * value: "chrUS"
     * @const
     */
    "chrUS": "chrUS",

    /**
     * value: "chsCL"
     * @const
     */
    "chsCL": "chsCL",

    /**
     * value: "chsCN"
     * @const
     */
    "chsCN": "chsCN",

    /**
     * value: "chsSG"
     * @const
     */
    "chsSG": "chsSG",

    /**
     * value: "chtCL"
     * @const
     */
    "chtCL": "chtCL",

    /**
     * value: "chtHG"
     * @const
     */
    "chtHG": "chtHG",

    /**
     * value: "chtTW"
     * @const
     */
    "chtTW": "chtTW",

    /**
     * value: "ckbIQ"
     * @const
     */
    "ckbIQ": "ckbIQ",

    /**
     * value: "cymUK"
     * @const
     */
    "cymUK": "cymUK",

    /**
     * value: "czeCZ"
     * @const
     */
    "czeCZ": "czeCZ",

    /**
     * value: "danDK"
     * @const
     */
    "danDK": "danDK",

    /**
     * value: "dutNL"
     * @const
     */
    "dutNL": "dutNL",

    /**
     * value: "engAU"
     * @const
     */
    "engAU": "engAU",

    /**
     * value: "engCA"
     * @const
     */
    "engCA": "engCA",

    /**
     * value: "engEI"
     * @const
     */
    "engEI": "engEI",

    /**
     * value: "engIN"
     * @const
     */
    "engIN": "engIN",

    /**
     * value: "engNZ"
     * @const
     */
    "engNZ": "engNZ",

    /**
     * value: "engSG"
     * @const
     */
    "engSG": "engSG",

    /**
     * value: "engUK"
     * @const
     */
    "engUK": "engUK",

    /**
     * value: "engUS"
     * @const
     */
    "engUS": "engUS",

    /**
     * value: "engZA"
     * @const
     */
    "engZA": "engZA",

    /**
     * value: "estEE"
     * @const
     */
    "estEE": "estEE",

    /**
     * value: "fasIR"
     * @const
     */
    "fasIR": "fasIR",

    /**
     * value: "finFI"
     * @const
     */
    "finFI": "finFI",

    /**
     * value: "freBE"
     * @const
     */
    "freBE": "freBE",

    /**
     * value: "freCA"
     * @const
     */
    "freCA": "freCA",

    /**
     * value: "freCH"
     * @const
     */
    "freCH": "freCH",

    /**
     * value: "freFR"
     * @const
     */
    "freFR": "freFR",

    /**
     * value: "freLU"
     * @const
     */
    "freLU": "freLU",

    /**
     * value: "freUS"
     * @const
     */
    "freUS": "freUS",

    /**
     * value: "geoGE"
     * @const
     */
    "geoGE": "geoGE",

    /**
     * value: "gerAT"
     * @const
     */
    "gerAT": "gerAT",

    /**
     * value: "gerCH"
     * @const
     */
    "gerCH": "gerCH",

    /**
     * value: "gerDE"
     * @const
     */
    "gerDE": "gerDE",

    /**
     * value: "gerLI"
     * @const
     */
    "gerLI": "gerLI",

    /**
     * value: "glaUK"
     * @const
     */
    "glaUK": "glaUK",

    /**
     * value: "glgES"
     * @const
     */
    "glgES": "glgES",

    /**
     * value: "greCY"
     * @const
     */
    "greCY": "greCY",

    /**
     * value: "greGR"
     * @const
     */
    "greGR": "greGR",

    /**
     * value: "gujIN"
     * @const
     */
    "gujIN": "gujIN",

    /**
     * value: "hatHT"
     * @const
     */
    "hatHT": "hatHT",

    /**
     * value: "hauWA"
     * @const
     */
    "hauWA": "hauWA",

    /**
     * value: "hebIL"
     * @const
     */
    "hebIL": "hebIL",

    /**
     * value: "hinIN"
     * @const
     */
    "hinIN": "hinIN",

    /**
     * value: "hrvHR"
     * @const
     */
    "hrvHR": "hrvHR",

    /**
     * value: "hunHU"
     * @const
     */
    "hunHU": "hunHU",

    /**
     * value: "hyeAR"
     * @const
     */
    "hyeAR": "hyeAR",

    /**
     * value: "iboWA"
     * @const
     */
    "iboWA": "iboWA",

    /**
     * value: "IceIS"
     * @const
     */
    "IceIS": "IceIS",

    /**
     * value: "ikuCA"
     * @const
     */
    "ikuCA": "ikuCA",

    /**
     * value: "indID"
     * @const
     */
    "indID": "indID",

    /**
     * value: "irlIE"
     * @const
     */
    "irlIE": "irlIE",

    /**
     * value: "itaCH"
     * @const
     */
    "itaCH": "itaCH",

    /**
     * value: "itaIT"
     * @const
     */
    "itaIT": "itaIT",

    /**
     * value: "jpnJP"
     * @const
     */
    "jpnJP": "jpnJP",

    /**
     * value: "kanIN"
     * @const
     */
    "kanIN": "kanIN",

    /**
     * value: "kazKZ"
     * @const
     */
    "kazKZ": "kazKZ",

    /**
     * value: "khmKA"
     * @const
     */
    "khmKA": "khmKA",

    /**
     * value: "kinRW"
     * @const
     */
    "kinRW": "kinRW",

    /**
     * value: "kirKG"
     * @const
     */
    "kirKG": "kirKG",

    /**
     * value: "kokIN"
     * @const
     */
    "kokIN": "kokIN",

    /**
     * value: "konCO"
     * @const
     */
    "konCO": "konCO",

    /**
     * value: "korKR"
     * @const
     */
    "korKR": "korKR",

    /**
     * value: "laoLA"
     * @const
     */
    "laoLA": "laoLA",

    /**
     * value: "lavLV"
     * @const
     */
    "lavLV": "lavLV",

    /**
     * value: "litLT"
     * @const
     */
    "litLT": "litLT",

    /**
     * value: "ltzLU"
     * @const
     */
    "ltzLU": "ltzLU",

    /**
     * value: "malML"
     * @const
     */
    "malML": "malML",

    /**
     * value: "marIN"
     * @const
     */
    "marIN": "marIN",

    /**
     * value: "mgcME"
     * @const
     */
    "mgcME": "mgcME",

    /**
     * value: "mglME"
     * @const
     */
    "mglME": "mglME",

    /**
     * value: "mkdMK"
     * @const
     */
    "mkdMK": "mkdMK",

    /**
     * value: "mlgMG"
     * @const
     */
    "mlgMG": "mlgMG",

    /**
     * value: "mltMT"
     * @const
     */
    "mltMT": "mltMT",

    /**
     * value: "monMO"
     * @const
     */
    "monMO": "monMO",

    /**
     * value: "mriNZ"
     * @const
     */
    "mriNZ": "mriNZ",

    /**
     * value: "mymIN"
     * @const
     */
    "mymIN": "mymIN",

    /**
     * value: "nahMX"
     * @const
     */
    "nahMX": "nahMX",

    /**
     * value: "navUS"
     * @const
     */
    "navUS": "navUS",

    /**
     * value: "nepNE"
     * @const
     */
    "nepNE": "nepNE",

    /**
     * value: "nnoNO"
     * @const
     */
    "nnoNO": "nnoNO",

    /**
     * value: "norNO"
     * @const
     */
    "norNO": "norNO",

    /**
     * value: "nsoZA"
     * @const
     */
    "nsoZA": "nsoZA",

    /**
     * value: "oriIN"
     * @const
     */
    "oriIN": "oriIN",

    /**
     * value: "panIN"
     * @const
     */
    "panIN": "panIN",

    /**
     * value: "panPK"
     * @const
     */
    "panPK": "panPK",

    /**
     * value: "papNL"
     * @const
     */
    "papNL": "papNL",

    /**
     * value: "pinCN"
     * @const
     */
    "pinCN": "pinCN",

    /**
     * value: "polPL"
     * @const
     */
    "polPL": "polPL",

    /**
     * value: "porBR"
     * @const
     */
    "porBR": "porBR",

    /**
     * value: "porPT"
     * @const
     */
    "porPT": "porPT",

    /**
     * value: "prsAF"
     * @const
     */
    "prsAF": "prsAF",

    /**
     * value: "pusAF"
     * @const
     */
    "pusAF": "pusAF",

    /**
     * value: "qucGC"
     * @const
     */
    "qucGC": "qucGC",

    /**
     * value: "quePE"
     * @const
     */
    "quePE": "quePE",

    /**
     * value: "rumMD"
     * @const
     */
    "rumMD": "rumMD",

    /**
     * value: "rumRO"
     * @const
     */
    "rumRO": "rumRO",

    /**
     * value: "rusRU"
     * @const
     */
    "rusRU": "rusRU",

    /**
     * value: "sinCL"
     * @const
     */
    "sinCL": "sinCL",

    /**
     * value: "sloSK"
     * @const
     */
    "sloSK": "sloSK",

    /**
     * value: "slvSI"
     * @const
     */
    "slvSI": "slvSI",

    /**
     * value: "smnSM"
     * @const
     */
    "smnSM": "smnSM",

    /**
     * value: "sndPK"
     * @const
     */
    "sndPK": "sndPK",

    /**
     * value: "somSO"
     * @const
     */
    "somSO": "somSO",

    /**
     * value: "spaAR"
     * @const
     */
    "spaAR": "spaAR",

    /**
     * value: "spaCL"
     * @const
     */
    "spaCL": "spaCL",

    /**
     * value: "spaCO"
     * @const
     */
    "spaCO": "spaCO",

    /**
     * value: "spaES"
     * @const
     */
    "spaES": "spaES",

    /**
     * value: "spaLA"
     * @const
     */
    "spaLA": "spaLA",

    /**
     * value: "spaMX"
     * @const
     */
    "spaMX": "spaMX",

    /**
     * value: "spaNT"
     * @const
     */
    "spaNT": "spaNT",

    /**
     * value: "spaUS"
     * @const
     */
    "spaUS": "spaUS",

    /**
     * value: "srcBH"
     * @const
     */
    "srcBH": "srcBH",

    /**
     * value: "srcSR"
     * @const
     */
    "srcSR": "srcSR",

    /**
     * value: "srpSR"
     * @const
     */
    "srpSR": "srpSR",

    /**
     * value: "swaKE"
     * @const
     */
    "swaKE": "swaKE",

    /**
     * value: "swaTZ"
     * @const
     */
    "swaTZ": "swaTZ",

    /**
     * value: "sweSE"
     * @const
     */
    "sweSE": "sweSE",

    /**
     * value: "tamIN"
     * @const
     */
    "tamIN": "tamIN",

    /**
     * value: "tatRU"
     * @const
     */
    "tatRU": "tatRU",

    /**
     * value: "telIN"
     * @const
     */
    "telIN": "telIN",

    /**
     * value: "tetID"
     * @const
     */
    "tetID": "tetID",

    /**
     * value: "tgkTJ"
     * @const
     */
    "tgkTJ": "tgkTJ",

    /**
     * value: "tglRP"
     * @const
     */
    "tglRP": "tglRP",

    /**
     * value: "thaTH"
     * @const
     */
    "thaTH": "thaTH",

    /**
     * value: "tirET"
     * @const
     */
    "tirET": "tirET",

    /**
     * value: "tsnZA"
     * @const
     */
    "tsnZA": "tsnZA",

    /**
     * value: "tukTM"
     * @const
     */
    "tukTM": "tukTM",

    /**
     * value: "turTR"
     * @const
     */
    "turTR": "turTR",

    /**
     * value: "uigCN"
     * @const
     */
    "uigCN": "uigCN",

    /**
     * value: "ukrUA"
     * @const
     */
    "ukrUA": "ukrUA",

    /**
     * value: "urdUR"
     * @const
     */
    "urdUR": "urdUR",

    /**
     * value: "uzbUZ"
     * @const
     */
    "uzbUZ": "uzbUZ",

    /**
     * value: "valES"
     * @const
     */
    "valES": "valES",

    /**
     * value: "vieVI"
     * @const
     */
    "vieVI": "vieVI",

    /**
     * value: "vlsBE"
     * @const
     */
    "vlsBE": "vlsBE",

    /**
     * value: "wolWO"
     * @const
     */
    "wolWO": "wolWO",

    /**
     * value: "xhoZA"
     * @const
     */
    "xhoZA": "xhoZA",

    /**
     * value: "yorYO"
     * @const
     */
    "yorYO": "yorYO",

    /**
     * value: "zulZA"
     * @const
     */
    "zulZA": "zulZA"
};


/**
 * Allowed values for the <code>resource_classification</code> property.
 * @enum {String}
 * @readonly
 */
ProjectResource['ResourceClassificationEnum'] = {

    /**
     * value: "Freelance"
     * @const
     */
    "Freelance": "Freelance",

    /**
     * value: "Company"
     * @const
     */
    "Company": "Company"
};



export default ProjectResource;

