import React, {useContext, useState, useEffect} from 'react'
import { useLocation, NavLink, useHistory  } from 'react-router-dom'
import { NavDropdown, Nav } from 'react-bootstrap'

import { GlobalContext } from '../../../context/GlobalState'
import {disassebleRoute} from '../../../utils'
import {gotoMenuItems, clientPMRoutes, clientKWSPMRoutes, superuserRoutes} from './logic'

import '../styles.css'

// Log Out item
const LogOutItem = (props) => {
    const { logout } = useContext(GlobalContext)
    const history = useHistory()

    const onLogout = () => {
        props.onClick()
        logout(true)
        history.replace('/login')
    }

    return <NavDropdown.Item    id='logout-link' 
                                className="dropdown-item" 
                                onClick={onLogout}>
       Log Out
    </NavDropdown.Item>
}


// Component
const GoToMenu = (props) => {
    const [expanded, setExpanded] = useState(false)
    const title = 'GO TO...'
    const {navItems, isLoading} = props
    const activeItems = navItems.filter(item => item.link)

    // events
    const handleToggle = (value, e) => {
        if (e?.preventDefault) {
            e.preventDefault()
        }
        setExpanded(value)
    }
    const handleOutsideClick = () => {
        setExpanded(false)
    }
    const handleClick = () => {
        setExpanded(false)
    }
    //

    return <NavDropdown title={title} 
                        show={expanded}
                        onToggle={handleToggle}
                        className='headermenu'>
        {!isLoading && activeItems.map(item => 
            <Nav.Link   key={'Nav-' + item.id}
                        id={item.id + '-link'}
                        as={NavLink}
                        to={item.link}
                        onClick={handleClick}
                        onBlur={handleOutsideClick}
                        className="dropdown-item"
                        activeClassName="active" exact>
                <p style={{textTransform: 'none', margin: 0}}>{item.label}</p>
            </Nav.Link> 
        )}

        <NavDropdown.Divider />
        <LogOutItem onClick={handleClick} />
    </NavDropdown>
}


// Wrapper with logic
const GotoMenuWrapper = () => {
    const { 
        getClientByID, currentClient, setCurrentClient,
        getProjectByID,
        user
    } = useContext(GlobalContext)
    const [isLoading, setIsLoading] = useState(false)
    const [navItems, setNavItems] = useState(gotoMenuItems)
    const location = useLocation()

    // Routes Logic
    const callback = (value) => {
        setIsLoading(false)
        setNavItems(value)
    }
    const pmRoutes = clientPMRoutes({
        user, 
        currentClient, setCurrentClient, getClientByID, 
        getProjectByID
    }, callback)

    const kwspmRoutes = clientKWSPMRoutes({
        user, 
        currentClient, setCurrentClient, getClientByID, 
        getProjectByID
    }, callback)

    const superRoutes = superuserRoutes({
        getClientByID, getProjectByID
    }, callback)

    


    // MARK: - Logic when accessing a route
    useEffect(() => {
        const pathComponents = disassebleRoute(location.pathname)
        setIsLoading(true)

        if (user?.isClientPM) {
            pmRoutes(pathComponents)
            return
        }

        if (user?.isKWSPM && user?.username !== 'superuser') {
            kwspmRoutes(pathComponents)
            return
        }

        superRoutes(pathComponents)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])
    
    return <GoToMenu isLoading={isLoading} navItems={navItems} />
}


export default GotoMenuWrapper