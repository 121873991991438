import React from 'react'
import { Table } from 'react-bootstrap'

import './style.scss'

import { 
    languageLabelFromCode, 
    generateHistoryContent,
    generateFile 
} from '../../../utils'


export default function HistoryTab (props) {
    const {results, history, request} = props
    const {languages = []} = results || {}

    return <Table striped responsive className='no-padding'>
        <thead>
            <tr>
                <th>Target Languages</th>
                <th>History</th>
            </tr>
        </thead>
        <tbody>
            {languages.map(l => 
                <LanguageHistoryRow key={l.language} details={l} history={history} request={request} />
            )}
        </tbody>
    </Table>
}

function LanguageHistoryRow (props) {
    const {details, history, request} = props
    const {language} = details || {}
    const getLanguageHistory = language => (history || []).find(h => h.full_language_name === language)

    const languageLabel = languageLabelFromCode(language)
    const languageHistory = getLanguageHistory(languageLabel)

    const handleClick = () => {
        const content = generateHistoryContent(languageHistory, {
            ...request,
            target_language: languageLabel
        })
        const name = 'LR-' + (request.id || '') + '_' + language + '_history.txt'

        generateFile(name, content)
    }

    return <tr className='table-label prominent'>
        <td>
            <span>{languageLabel}</span>
        </td>
        <td>
            {languageHistory 
                ? <span className='link' onClick={handleClick}>download history</span>
                : <span>Not available</span>
            }
        </td>
    </tr>
}