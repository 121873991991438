import React, { useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Modal } from 'react-bootstrap'
import Dialog from 'react-bootstrap-dialog'

import { GlobalContext } from '../../context/GlobalState'

import RequestDetailsContent from './request-details-content'

const RequestDetails = (props) => {
  const { id: projectId } = useParams()
  const locreqId = props.request?.id
  const { 
    getRequestByID, getFileResults, getLanguagesHistory, 
    user
  } = useContext(GlobalContext)

  const [request, setRequest] = useState(null)
  const [results, setResults] = useState(null)
  const [history, setHistory] = useState(null)
  const [loading, setLoading] = useState(false)
  const [dialog, setDialog] = useState(null)
  const [titleSuffix, setTitle] = useState('details')

  const fetchData = () => {
    getLanguagesHistory(locreqId, projectId)
      .then(setHistory)
      .catch(console.error)
      
    getRequestByID(locreqId, projectId)
      .then(setRequest)
      .catch(console.error)
    
    getFileResults(locreqId, projectId)
      .then(setResults)
      .catch(console.error)
  }

  useEffect(() => {
    if (locreqId) {
      if (!loading && !results) {
        setLoading(true)
        fetchData()
      }
    }


    if (results) {
      setLoading(false)

      return () => {
        setLoading(false)
        setRequest(null)
        setResults(null)
        setHistory(null)
      }
    }
  // eslint-disable-next-line
  }, [props.request, projectId, loading])


  // EVENTS
  const onHide = (action) => {
    if (action === 'cancel' && user.isKWSPM) {
      dialog.show({
        title: 'CANCEL REQUEST',
        body: `Do you really want to cancel this Localization Request?`,
        actions: [
          Dialog.Action(
            'ABORT',
            () => {},
            'btn-default'
          ),
          Dialog.Action(
            'YES, CANCEL',
            () => {
              props.onHide(action)
            },
            'btn-danger'
          )
        ],
        bsSize: 'small',
      })
    } else {
      props.onHide(action)
    }
  }

  const onRefresh = () => {
    fetchData()
  }
  //
  

  return (request &&
    <>
      <Dialog ref={ (el) => setDialog(el) }/>
      <Modal  show={props.show} 
              onHide={onHide}
              centered 
              scrollable 
              size="lg"
              dialogClassName="tabbed-modal">
        <Modal.Header closeButton={true}>
          <Modal.Title>{request.number} {titleSuffix}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RequestDetailsContent 
            request={request} results={results}  history={history}
            onHide={onHide} onTabChanged={setTitle} onRefreshRequired={onRefresh} />
        </Modal.Body>
      </Modal>
    </>
  )
}


export default RequestDetails
