import React from 'react'
import {resourceRoles} from '../../../../utils'


const ResourceRoles = (props) => {
    const {resource, service, onRoleSelected} = props
    if (!resource) {
        return null
    }

    const {
        memoq_username,
        role,
    } = resource

    const handleSelected = e => onRoleSelected(e.target.value)
    
    /*
        can_review: false
        can_translate: true
        classification_check_passed: true
        ids: [1303]
        language_check_passed: true
        memoq_username: "Fede Arabo"
        name: "Fede Arabo"
        resource_classification: null
        role: "T"
        surname: "Fede Arabo"
        target_languages: ['araMS']
    */

    const availableRoles = resourceRoles.map(r => r)
    if (service === 'lite') {
        // remove second element, review
        availableRoles.splice(1, 1)
    }

    return <select name={`role-${memoq_username}`}
            data-dropup-auto="false"
            className="filter-dropdown"
            id={`filter-${memoq_username}`}
            value={role}
            onChange={handleSelected}>
            { availableRoles.map(({ value, name }) => 
                <option key={value} value={value}> 
                    {name}
                </option>
            )}
    </select>
  }
  
  export default ResourceRoles
  