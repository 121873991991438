import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'

import { Card, Form, Button, Toast } from 'react-bootstrap'
import cN from 'classnames'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from "yup"

import { GlobalContext } from '../../context/GlobalState'

const ForgotPassword = () => {
  const { forgotPassword } = useContext(GlobalContext)

  const [errorMsg, setErrorMsg] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const schema = yup.object().shape({
    username: yup.string().required('Username is required')
  })

  const { handleSubmit, control, errors, reset } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    setSubmitting(true)
    setShowError(false)

    forgotPassword(data.username)
      .then(() => {
        setSubmitting(false)
        setShowSuccess(true)
        reset()
      })
      .catch((err) => {
        setSubmitting(false)
        try {
          let errorMsg = JSON.parse(err.response.text).detail
          setErrorMsg(errorMsg)
        } catch (e) {
          let errorMsg = err.response.text
          setErrorMsg(errorMsg)
        }
        setShowError(true)
      })
  }

  return (
    <Card className="Guest-form">
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h1>RECOVER YOUR ACCOUNT</h1>
          <Toast className="success" show={showSuccess}>
            <Toast.Header closeButton={false}>
              <strong className="mr-auto">Check your mail</strong>
            </Toast.Header>
            <Toast.Body>
              We've sent you an e-mail with instructions to recover your account.
            </Toast.Body>
          </Toast>
          <Toast show={showError}
            onClose={() => setShowError(false)}>
            <Toast.Header>
              <strong className="mr-auto">Password reset failed</strong>
            </Toast.Header>
            <Toast.Body>{ errorMsg }</Toast.Body>
          </Toast>
          <Form.Group controlId="username">
            <Form.Label>Username</Form.Label>
            <Controller
              as={Form.Control}
              name="username"
              type="text"
              control={control}
              defaultValue=""
              className={cN({
                'is-invalid': errors.username
              })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.username?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Button id="submit-btn"
            variant="primary"
            type="submit"
            disabled={submitting}>
            { submitting ? 'Submitting...' : 'Submit' }
          </Button>
        </Form>
        <div className="text-center">
          <strong>
            <Link id="back-link"
              to="/login">
              Back to login
            </Link>
          </strong>
        </div>
      </Card.Body>
    </Card>
  )
}

export default ForgotPassword
