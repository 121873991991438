import React, { useState, useRef, useEffect } from 'react'
import './chart-date-range.scss'

import ReactDatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"

import { Button } from 'react-bootstrap'

import moment from 'moment'

const ChartDateRange = (props) => {
  const [currentValue, setCurrentValue] = useState(props.value)
  const datePicker = useRef(null)

  useEffect(() => {
    setCurrentValue(props.value)
  }, [props.value])

  const onDateRangeChange = (newRange) => {
    setCurrentValue(newRange)

    if (newRange.end && props.onChange) {
      props.onChange(newRange)
      datePicker.current.handleBlur()
      datePicker.current.handleCalendarClickOutside()
    }
  }

  const parseDateRange = (value) => {
    let parsedRange = null
    if (value && value.start) {
      if (!value.end ||
        moment(value.start).isSame(moment(value.end))) {
        parsedRange = `${moment(value.start).format('MMM DD')}`
      } else {
        if (!props.ranges ||
          !Object.entries(props.ranges)
            .some(([_key, range]) => {
              const sameDate = moment(range.dateRange.start)
                .isSame(moment(value.start), 'day') &&
                moment(range.dateRange.end).isSame(moment(value.end), 'day')
              if (sameDate) {
                parsedRange = range.label
              }

              return sameDate
            })) {
          parsedRange = `${moment(value.start).format('MMM DD')} - ${moment(value.end).format('MMM DD')}`
        }
      }
    }

    return parsedRange
  }

  return (
    <ReactDatePicker
      ref={datePicker}
      onChange={([start, end]) => onDateRangeChange({ start, end })}
      selected={currentValue.start}
      startDate={currentValue.start}
      endDate={currentValue.end}
      placeholderText="Select range"
      className="form-control chart-date-range"
      popperPlacement="bottom"
      selectsRange
      shouldCloseOnSelect={false}
      maxDate={props.maxDate}
      disabledKeyboardNavigation
      onKeyDown={props.onKeyDown}
      value={ parseDateRange(props.value) }
    >
      { props.ranges &&
        <div className="date-ranges">
          <div className="ranges">
            { Object.entries(props.ranges).map(([key, range]) => (
              <Button key={key}
                onClick={() => onDateRangeChange(range.dateRange)}>
                {range.label}
              </Button>
            ))}
          </div>
      </div>
      }
    </ReactDatePicker>
  )
}

export default ChartDateRange
