import React, { useContext, useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'

import { Row, Col, Card, Alert, Badge, Table, Form } from 'react-bootstrap'
import Select from 'react-select'
import moment from 'moment'
import cN from 'classnames'

import { Doughnut, Line, Bar } from 'react-chartjs-2';

import ChartDateRange from '../chart-date-range/ChartDateRange'
import ProjectsDropdown, { allProjectsOption } from '../projects-dropdown/ProjectsDropdown'

import { selectStylesBlack, selectTheme, noOptionsMessage } from '../../utils'

import { target_languages } from '../../temp_lists'

import { GlobalContext } from '../../context/GlobalState'

const allLanguagesOption = {
  value: '',
  label: 'All languages'
}

let languagesDropdownItems = [allLanguagesOption]
  .concat(target_languages.map(lang => {
    return {
      value: lang.code,
      label: lang.name
    }
  }))

const ranges = {
  lastWeek: {
    dateRange: {
      start: moment().subtract(1, 'week').add(1, 'day').toDate(),
      end: new Date()
    },
    label: 'Last week'
  },
  lastMonth: {
    dateRange: {
      start: moment().subtract(1, 'month').add(1, 'day').toDate(),
      end: new Date()
    },
    label: 'Last month'
  },
  lastYear: {
    dateRange: {
      start: moment().subtract(1, 'year').add(1, 'day').toDate(),
      end: new Date()
    },
    label: 'Last year'
  }
}

const CustomDateRange = (props) => {
  return (
    <Form.Group>
      <Form.Label>Date range</Form.Label>
      <ChartDateRange
        ranges={ranges}
        maxDate={new Date()}
        value={props.value}
        onChange={props.onChange} />
    </Form.Group>
  )
}

const ClientStatistics = () => {
  const { id } = useParams()
  const { getRequestsStats, getRequestsTotalsStats,
    getProjectsTotalsStats, getProjectsWordcount,
    getProjectsTotalsWordcount, user } = useContext(GlobalContext)
  const clientId = id || user?.client?.id

  const colorScale = {
    count_100: {
      color: '#00876c',
      label: '100'
    },
    count_101: {
      color: '#45976f',
      label: '101'
    },
    count_total: {
      color: '#6ba675',
      label: 'Total'
    },
    count_adjusted: {
      color: '#8eb47d',
      label: 'Adjusted'
    },
    count_95_99: {
      color: '#afc388',
      label: '95% - 99%'
    },
    count_85_94: {
      color: '#cfd197',
      label: '85% - 94%'
    },
    count_75_84: {
      color: '#eee0a9',
      label: '75% - 84%'
    },
    count_50_74: {
      color: '#eac98c',
      label: '50% - 74%'
    },
    count_fragments: {
      color: '#e7b074',
      label: 'Fragments'
    },
    count_repetitions: {
      color: '#e59761',
      label: 'Repetitions'
    },
    count_x_translated: {
      color: '#e27c56',
      label: 'X Translated'
    },
    count_no_match: {
      color: '#d43d51',
      label: 'No Match'
    },
  }

  const [openRequests, setOpenRequests] = useState([])
  const [openRequestsLoading, setOpenRequestsLoading] = useState(false)
  const [invoicedRequests, setInvoicedRequests] = useState([])
  const [invoicedRequestsLoading, setInvoicedRequestsLoading] = useState(false)
  const [projectsData, setProjectsData] = useState([])
  const [projectsTotal, setProjectsTotal] = useState(0)
  const [totalOpenRequests, setTotalOpenRequests] = useState(0)
  const [totalInvoicedRequests, setTotalInvoicedRequests] = useState(0)
  const [wordCountData, setWordCountData] = useState([])
  const [wordCountDataLoading, setWordCountDataLoading] = useState(false)
  const [totalWordCounts, setTotalWordCounts] = useState([])
  const [lRProject, setLRProject] = useState(allProjectsOption)
  const [ongoingLRProject, setOngoingLRProject] = useState(allProjectsOption)
  const [ongoingLRRange, setOngoingLRRange] = useState(ranges.lastWeek.dateRange)
  const [invoicedLRRange, setInvoicedLRRange] = useState(ranges.lastWeek.dateRange)
  const [invoicedLRProject, setInvoicedLRProject] = useState(allProjectsOption)
  const [wCProject, setWCProject] = useState(allProjectsOption)
  const [wCRange, setWCRange] = useState(ranges.lastWeek.dateRange)
  const [wCLanguage, setWCLanguage] = useState(allLanguagesOption)
  const [wCAdjusted, setWCAdjusted] = useState(false)
  const [wCTotalLanguage, setWCTotalLanguage] = useState(allLanguagesOption)
  const [wCTotalAdjusted, setWCTotalAdjusted] = useState(false)

  useEffect(() => {
    getOngoingRequests()
    getInvoicedRequests()
    getTotalRequests()
    getWC()
    getWCTotal()

    getProjectsTotalsStats(clientId)
      .then((res) => {
        if (res) {
          setProjectsData([
            res.state_new,
            res.state_ongoing,
            res.state_closed
          ])
          setProjectsTotal(
            res.state_new +
            res.state_ongoing +
            res.state_closed
          )
        }
      })
    // eslint-disable-next-line
  }, [])

  const getTotalRequests = (projectId) => {
    projectId = projectId !== ''
      ? projectId
      : null

    getRequestsTotalsStats({
      state: 'open',
      projectId,
      clientId
    })
      .then((res) => {
        if (res && res.total !== undefined) {
          setTotalOpenRequests(res.total)
        }
      })

    getRequestsTotalsStats({
      state: 'invoiced',
      projectId,
      clientId
    })
      .then((res) => {
        if (res && res.total !== undefined) {
          setTotalInvoicedRequests(res.total)
        }
      })
  }

  const getOngoingRequests = (params) => {
    params = params || {}
    if (params.range) {
      setOngoingLRRange(params.range)
    } else {
      params.range = ongoingLRRange
    }

    if (params.projectId) {
      setOngoingLRProject(params.projectId)
    } else {
      params.projectId = ongoingLRProject
    }

    setOpenRequestsLoading(true)
    getRequestsStats({
      state: 'open',
      dateFrom: moment(params.range.start).format('YYYY-MM-DD'),
      dateTo: moment(params.range.end).add(1, 'day').format('YYYY-MM-DD'),
      clientId,
      projectId: params.projectId && params.projectId.value !== ''
        ? params.projectId.value
        : null
    })
      .then((res) => setOpenRequests(
        res.filter(item => item.started).length
          ? res
          : []
      ))
      .finally(() => setOpenRequestsLoading(false))
  }

  const getInvoicedRequests = (params) => {
    params = params || {}
    if (params.range) {
      setInvoicedLRRange(params.range)
    } else {
      params.range = invoicedLRRange
    }

    if (params.projectId) {
      setInvoicedLRProject(params.projectId)
    } else {
      params.projectId = invoicedLRProject
    }

    setInvoicedRequestsLoading(true)
    getRequestsStats({
      state: 'invoiced',
      dateFrom: moment(params.range.start).format('YYYY-MM-DD'),
      dateTo: moment(params.range.end).add(1, 'day').format('YYYY-MM-DD'),
      clientId,
      projectId: params.projectId && params.projectId.value !== ''
        ? params.projectId.value
        : null
    })
      .then(setInvoicedRequests)
      .finally(() => setInvoicedRequestsLoading(false))
  }

  const getWC = (params) => {
    params = params || {}
    if (params.range) {
      setWCRange(params.range)
    } else {
      params.range = wCRange
    }

    if (params.projectId) {
      setWCProject(params.projectId)
    } else {
      params.projectId = wCProject
    }

    if (params.language) {
      setWCLanguage(params.language)
    } else {
      params.language = wCLanguage
    }

    if (params.includeAdjusted !== undefined) {
      setWCAdjusted(params.includeAdjusted)
    } else {
      params.includeAdjusted = wCAdjusted
    }

    setWordCountDataLoading(true)
    getProjectsWordcount({
      dateFrom: moment(params.range.start).format('YYYY-MM-DD'),
      dateTo: moment(params.range.end).add(1, 'day').format('YYYY-MM-DD'),
      clientId,
      projectId: params.projectId && params.projectId.value !== ''
        ? params.projectId.value
        : null,
      language: params.language && params.language.value !== ''
        ? params.language.value
        : null,
      includeAdjusted: params.includeAdjusted
    })
      .then(setWordCountData)
      .finally(() => setWordCountDataLoading(false))
  }

  const getWCTotal = (params) => {
    params = params || {}
    if (params.language) {
      setWCTotalLanguage(params.language)
    } else {
      params.language = wCTotalLanguage
    }

    if (params.includeAdjusted !== undefined) {
      setWCTotalAdjusted(params.includeAdjusted)
    } else {
      params.includeAdjusted = wCTotalAdjusted
    }

    getProjectsTotalsWordcount({
      dateFrom: '2021-01-01',
      language: params.language && params.language.value !== ''
        ? params.language.value
        : null,
      includeAdjusted: params.includeAdjusted,
      clientId,
      view: 'clientpm'
    })
      .then(setTotalWordCounts)
  }

  const filterColumns = (key) => {
    return !key.startsWith('project') &&
      totalWordCounts[0][key] !== undefined &&
      key !== 'language'
  }

  return (
    <div className="Statistics">
      <Card>
        <Card.Header>Projects & Requests Overview</Card.Header>
        <Card.Body>
          <Row>
            <Col sm={6} md={4}>
              <h3>Projects</h3>
              <div className="relative">
                <Doughnut
                  data={{
                    labels: ['NEW', 'ONGOING', 'CLOSED'],
                    datasets: [
                      {
                        label: 'PROJECTS',
                        data: projectsData,
                        backgroundColor: [
                          '#2CB829',
                          '#FFB800',
                          '#7795FF'
                        ],
                        borderWidth: 0
                      },
                    ],
                  }}
                  legend={{ position: 'bottom', fullWidth: false }}
                  options={{ cutoutPercentage: 80 }}
                />
                <div id="projects-count">
                  <span className="big">{ projectsTotal }</span>
                  PROJECTS
                </div>
              </div>
              <h3 className="mt-5 title-and-filters">
                <span>Requests</span>
                <div className="filters">
                  <ProjectsDropdown
                    value={lRProject}
                    onChange={(item) => {
                      setLRProject(item)
                      getTotalRequests(item.value)
                    }}
                  />
                </div>
              </h3>
              <Alert variant="dark">
                Open Requests
                <Badge variant="secondary">
                  { totalOpenRequests }
                </Badge>
              </Alert>
              <Alert variant="dark">
                Invoiced Requests
                <Badge variant="secondary">
                  { totalInvoicedRequests }
                </Badge>
              </Alert>
            </Col>
            <Col sm={6} md={8}>
              <h3 className="mt-5 title-and-filters">
                <span>Ongoing Requests</span>
                 <div className="filters">
                  <ProjectsDropdown
                    value={ongoingLRProject}
                    onChange={(item) => getOngoingRequests({ projectId: item })}
                  />
                  <CustomDateRange
                    value={ongoingLRRange}
                    onChange={(range) => getOngoingRequests({ range })}
                  />
                </div>
              </h3>
              <div className={cN('chart-container', {
                  noData: !openRequests || !openRequests.length,
                  loading: openRequestsLoading
                })}>
                <Line data={{
                    labels: openRequests
                      .map((item) => moment(item.date).format('MMM DD')),
                    datasets: [
                      {
                        label: 'Ongoing',
                        lineTension: 0.2,
                        data: openRequests.map((item) => item.started),
                        fill: true,
                        backgroundColor: '#6E84D4',
                        borderColor: '#B0C2FF',
                      }
                    ]
                  }}
                  legend={{ display: false }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className="mt-5 title-and-filters">
                <span>Invoiced Requests</span>
                <div className="filters">
                  <ProjectsDropdown
                    value={invoicedLRProject}
                    onChange={(item) => getInvoicedRequests({ projectId: item }) }
                  />
                  <CustomDateRange
                    value={invoicedLRRange}
                    onChange={(range) => getInvoicedRequests({ range })}
                  />
                </div>
              </h3>
              <div className={cN('chart-container', {
                  noData: !invoicedRequests || !invoicedRequests.length,
                  loading: invoicedRequestsLoading
                })}>
                <Line data={{
                    labels: invoicedRequests
                      .map((item) => moment(item.date).format('MMM DD')),
                    datasets: [
                      {
                        label: 'Invoiced',
                        lineTension: 0.2,
                        data: invoicedRequests.map((item) => item.invoiced),
                        fill: true,
                        backgroundColor: '#6E84D4',
                        borderColor: '#B0C2FF',
                      }
                    ]
                  }}
                  legend={{ display: false }}
                />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>Wordcount Overview</Card.Header>
        <Card.Body>
        <Row>
            <Col>
              <h3 className="mt-5 title-and-filters">
                <span>Wordcount Total</span>
                 <div className="filters">
                  <Form.Group>
                    <Form.Label>Language</Form.Label>
                    <Select name="language"
                      placeholder="Select language..."
                      styles={selectStylesBlack}
                      defaultValue={allLanguagesOption}
                      value={wCTotalLanguage}
                      theme={selectTheme}
                      className="form-control"
                      options={ languagesDropdownItems }
                      noOptionsMessage={noOptionsMessage}
                      onChange={(item) => getWCTotal({ language: item })}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Check name="include_adjusted"
                      type="checkbox"
                      label="Wordcount adjusted"
                      checked={wCTotalAdjusted}
                      onChange={(e) => getWCTotal({ includeAdjusted: e.target.checked })} />
                  </Form.Group>
                </div>
              </h3>
              { totalWordCounts && totalWordCounts.length
                ? <Table striped responsive className="small mt-4">
                  <thead>
                    <tr>
                      <th>PROJECT</th>
                      {
                        Object.keys(totalWordCounts[0])
                          .filter(filterColumns)
                          .map((key) => {
                            const columnHeader = colorScale[key]
                              ? colorScale[key].label
                              : key

                            return (<th key={columnHeader}>
                              { columnHeader }
                            </th>)
                          })
                      }
                    </tr>
                  </thead>
                  <tbody>
                    { totalWordCounts.map((item) => {
                      return (<tr
                        key={  `${item.project_id}-${item.language}`}>
                        <td>{ item.project_name }</td>
                        { Object.keys(item)
                          .filter(filterColumns)
                          .map((key) => (
                            <td key={key}>{ item[key] }</td>
                          ))
                        }
                      </tr>)
                    }) }
                  </tbody>
                </Table>
                : <p className="text-center mt-3 mb-5">No data available</p>
              }
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className="mt-5 title-and-filters">
                <span>Wordcount</span>
                 <div className="filters">
                  <ProjectsDropdown
                    value={wCProject}
                    onChange={(item) => getWC({ projectId: item })}
                  />
                  <CustomDateRange
                    value={wCRange}
                    onChange={(range) => getWC({ range })}
                  />
                  <Form.Group>
                    <Form.Label>Language</Form.Label>
                    <Select name="language"
                      placeholder="Select language..."
                      styles={selectStylesBlack}
                      defaultValue={allLanguagesOption}
                      value={wCLanguage}
                      theme={selectTheme}
                      className="form-control"
                      options={ languagesDropdownItems }
                      noOptionsMessage={noOptionsMessage}
                      onChange={(item) => getWC({ language: item })}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Check name="include_adjusted"
                      type="checkbox"
                      label="Wordcount adjusted"
                      checked={wCAdjusted}
                      onChange={(e) => getWC({ includeAdjusted: e.target.checked })} />
                  </Form.Group>
                </div>
              </h3>
              <div className={cN('chart-container', {
                  noData: !wordCountData || !wordCountData.length,
                  loading: wordCountDataLoading
                })}>
                <Bar
                  data={{
                    labels: wordCountData
                      .map((item) => moment(item.date).format('MMM DD')),
                    datasets: wordCountData.length
                      ? Object.keys(wordCountData[0])
                        .filter((key) => key !== 'date')
                        .map((key) => {
                          return {
                            label: colorScale[key]
                              ? colorScale[key].label
                              : key,
                            data: wordCountData.map((item) => item[key]),
                            backgroundColor: colorScale[key]
                            ? colorScale[key].color
                            : null,
                          }
                        })
                      : []
                  }}
                  legend={{
                    reverse: true
                  }}
                  options= {{
                    scales: {
                      yAxes: [{
                        stacked: true,
                      }],
                      xAxes: [{
                        stacked: true,
                      }],
                    }
                  }}
                />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}

export default ClientStatistics

