import React, {useContext} from 'react'
import { Table } from 'react-bootstrap'
import Loading from '../../../loading/Loading'

import {TotalRow, LanguageRow} from './content'

import {GlobalContext} from '../../../../context/GlobalState'
import {languageLabelFromCode, serviceLabelFromCode} from '../../../../utils'
import '../style.scss'

// WRAPPER
export default function DetailsTab (props) {
    const {request, results} = props

    if (!(request || results)) {
        return <Loading />
    }

    return <>
        <RequestServiceDetail request={request} />
        <RequestLanguageDetail request={request} />
        <DetailTable results={results} />
    </>
}

// SECTIONS
export function RequestServiceDetail(props) {
    const {request} = props
    return <p className="uppercase text-left">
        <i className="onek-service big"></i>
        Service:{' '}
        {serviceLabelFromCode(request.service)}
    </p>
}

export function RequestLanguageDetail (props) {
    const {request} = props
    return <p className="uppercase text-left">
        <i className="onek-source big"></i>
        Source language:{' '}
        <span className="transform-none">
            {languageLabelFromCode(request.source_language)}
        </span>
    </p>
}
//

// TABLE
export function DetailTable (props) {
    const {results} = props
    const {languages = [], combined = {}, multilingual} = results || {}

    const {user} = useContext(GlobalContext)

    // MULTILINGUAL
    if (multilingual) {
        const {created_at} = multilingual
        languages.forEach(language => {
            language.created_at = created_at
        })
    }


    return <Table striped responsive className='no-padding'>
        <thead>
            <tr>
                <th>Target Languages</th>
                <th>WC</th>
                <th>Cost</th>
                <th>Status</th>
            </tr>
        </thead>
        <tbody>
            {languages.map(l => 
                <LanguageRow key={l.language} details={l} isKWSPM={user.isKWSPM} />
            )}
            <TotalRow languages={combined} />
        </tbody>
    </Table>
}
