import React, {useState, useEffect, useContext} from 'react'
import {useParams} from 'react-router-dom'
import { Card, Image, Container, Row, Col, Button } from 'react-bootstrap'
import { toast } from 'react-toastify';

import { GlobalContext } from '../../context/GlobalState'

import './job-assignment.scss'

// COMPONENT
const JobAssignment = () => {
    const { projectId, requestId, language, token } = useParams()
    const {getRequestByID, setJobAssignment} = useContext(GlobalContext)
    const [request, setRequest] = useState()
    const [enabled, setEnabled] = useState(false)

    // LOGIC UTILS
    const showSuccess = accepted => {
        toast.success(`Job successfully ${accepted ? 'accepted' : 'refused'}`)
    }
    const showError = e => {
        toast.error(() => <>
            <p><strong>Error</strong></p>
            <p>Job already accepted</p>
        </>)
        console.error(e)
    }

    // FETCH DATA
    const getRequest = () => {
        if (!requestId || !projectId) {
            return
        }

        getRequestByID(requestId, projectId)
        .then(setRequest)
        .then(() => setEnabled(true))
        .catch(showError)
    }
    useEffect(getRequest, [projectId, requestId])


    // JOB STATUS SET
    const accept = () => {
        setEnabled(false)
        setJobAssignment(projectId, token, true)
        .then(() => showSuccess(true))
        .catch(showError)
    }
    const reject = () => {
        setEnabled(false)
        setJobAssignment(projectId, token, false)
        .then(() => showSuccess(false))
        .catch(showError)
    }


    // RENDER
    if (!request) {
        return 'Loading'
    }

    // LP-222
    const separator = '-'
    const position = 3
    const char = language.charAt(position)
    const languageLabel = char === separator 
        ? language 
        : [language.slice(0, position), separator, language.slice(position)].join('')
    //
    
    return <Container fluid="md" className="d-flex">
        <Row className="justify-content-center align-content-center w-100">
            <Col>
                <Card className="Job-assign">
                    <Card.Body>
                        <Image src="/assets/images/One-K-Logo_Blue.svg" alt="KWS" />
                        <h2>BY KEYWORDS STUDIOS</h2>
                        <h1>TRANSLATION/REVIEW JOB</h1>
                        <div className="flex-fill padded">
                            <Row>
                                <Col>
                                    <h1>JOB NUMBER: {request.number}</h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h1>TARGET LANGUAGE: {languageLabel}</h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h1>WORDCOUNT: {request.wordcount[language]}</h1>
                                </Col>
                            </Row>
                        </div>
                        
                        <Row>
                            <Col className="text-center">
                                <Button disabled={!enabled} onClick={accept}>ACCEPT</Button>
                            </Col>
                            <Col className="text-center">
                                <Button disabled={!enabled} onClick={reject}>REJECT</Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
}

export default JobAssignment