/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import LRMultilingualTranslationResult from './LRMultilingualTranslationResult';
import LRResultFileItem from './LRResultFileItem';
import LRTranslationResultItem from './LRTranslationResultItem';

/**
 * The LRTranslationResult model module.
 * @module model/LRTranslationResult
 * @version v1
 */
class LRTranslationResult {
    /**
     * Constructs a new <code>LRTranslationResult</code>.
     * @alias module:model/LRTranslationResult
     * @param languages {Array.<module:model/LRTranslationResultItem>} 
     * @param uploads {module:model/LRResultFileItem} 
     * @param combined {module:model/LRTranslationResultItem} 
     * @param multilingual {module:model/LRMultilingualTranslationResult} 
     */
    constructor(languages, uploads, combined, multilingual) { 
        
        LRTranslationResult.initialize(this, languages, uploads, combined, multilingual);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, languages, uploads, combined, multilingual) { 
        obj['languages'] = languages;
        obj['uploads'] = uploads;
        obj['combined'] = combined;
        obj['multilingual'] = multilingual;
    }

    /**
     * Constructs a <code>LRTranslationResult</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/LRTranslationResult} obj Optional instance to populate.
     * @return {module:model/LRTranslationResult} The populated <code>LRTranslationResult</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LRTranslationResult();

            if (data.hasOwnProperty('languages')) {
                obj['languages'] = ApiClient.convertToType(data['languages'], [LRTranslationResultItem]);
            }
            if (data.hasOwnProperty('uploads')) {
                obj['uploads'] = LRResultFileItem.constructFromObject(data['uploads']);
            }
            if (data.hasOwnProperty('combined')) {
                obj['combined'] = LRTranslationResultItem.constructFromObject(data['combined']);
            }
            if (data.hasOwnProperty('multilingual')) {
                obj['multilingual'] = LRMultilingualTranslationResult.constructFromObject(data['multilingual']);
            }
        }
        return obj;
    }


}

/**
 * @member {Array.<module:model/LRTranslationResultItem>} languages
 */
LRTranslationResult.prototype['languages'] = undefined;

/**
 * @member {module:model/LRResultFileItem} uploads
 */
LRTranslationResult.prototype['uploads'] = undefined;

/**
 * @member {module:model/LRTranslationResultItem} combined
 */
LRTranslationResult.prototype['combined'] = undefined;

/**
 * @member {module:model/LRMultilingualTranslationResult} multilingual
 */
LRTranslationResult.prototype['multilingual'] = undefined;






export default LRTranslationResult;

