import React, { useContext, useEffect, useState } from 'react'

import { Button, Table, OverlayTrigger, Tooltip } from 'react-bootstrap'
import cN from 'classnames'

import { GlobalContext } from '../../context/GlobalState'

import Dialog from 'react-bootstrap-dialog'
import { toast } from 'react-toastify';

import EntityNotFound from '../entity-not-found/EntityNotFound'
import UserForm from './UserForm'
import Loading from '../loading/Loading'

import { parseUser } from '../../utils'

const Users = (props) => {
  const { user, getUsers, deleteUser, getClients } = useContext(GlobalContext)
  const [users, setUsers] = useState([])
  const [loadingUsers, setLoadingUsers] = useState(true)
  const [userToEdit, setUserToEdit] = useState(null)
  const [showForm, setShowForm] = useState(false)
  const [dialog, setDialog] = useState(null)

  useEffect(() => {
    if (props.client) {
      setLoadingUsers(true)
      getUsers(props.client.studio, 'client-pm')
        .then((data) => {
          const users = parseUsers(data)
          setUsers(users)
          setLoadingUsers(false)
        })
        .catch(() => setLoadingUsers(false))
    }
    // eslint-disable-next-line
  }, [props.client])
  

  useEffect(() => {
    if (props.activeTab !== 'users' && showForm) {
      // Hide form
      onFormHide()
    }
    // eslint-disable-next-line
  }, [props.activeTab])

  const sortUsers = (users) => {
    return users.sort((a, b) => {
      return a.first_name.toLowerCase() > b.first_name.toLowerCase()
        ? 1 : -1
    })
  }

  const parseUsers = (users) => {
    if (users && users.length) {
      const parsed = users.filter(c => c.user_type === 'client-pm').map(parseUser)
      const u = sortUsers(parsed)
      return u
    }

    return users
  }

  const onDeleteUser = (e, userToDelete) => {
    e.preventDefault()

    dialog.show({
      title: `Remove ${userToDelete.name}`,
      body: `Do you really want to remove this user account?`,
      actions: [
        Dialog.Action(
          'CANCEL',
          () => {},
          'btn-default'
        ),
        Dialog.Action(
          'YES, REMOVE',
          () => {
            deleteUser(userToDelete.id)
              .then(() => {
                toast.success(`${userToDelete.name} has been removed`)
                setUsers(users.filter((u) => {
                  return u.id !== userToDelete.id
                }))
                if (user.isKWSPM) {
                  // Update clients list in case the main admin has changed
                  getClients()
                }
              })
          },
          'btn-danger'
        )
      ],
      bsSize: 'small',
    })
  }

  const onEditUser = (e, user) => {
    e.preventDefault()

    setUserToEdit(user)
    setShowForm(true)
  }

  const onFormHide = (newUser) => {
    setShowForm(false)

    if (newUser) {
      const parsedUser = parseUser(newUser)
      if (userToEdit) {
        toast.success(`${parsedUser.name} has been updated`)
        setUsers(sortUsers(users
          .map((u) => {
            if (u.id === parsedUser.id) {
              return parsedUser
            }

            return u
          })
        ))
      } else {
        toast.success(`${parsedUser.name} has been added`)
        setUsers(sortUsers([
          ...users,
          parsedUser
        ]))
      }

      if (user.isKWSPM) {
        // Update clients list in case the main admin has changed
        getClients()
      }
    }

    setUserToEdit(null)
  }

  return (
    <>{ showForm
      ? <UserForm user={userToEdit}
        clientId={props.client?.id}
        onHide={onFormHide} />
      : <>
        <Dialog ref={ (el) => setDialog(el) }/>
        <div className={cN('text-right', { 'd-none': !user.is_admin && !user.isKWSPM })}>
          <Button id="new-member-btn"
            variant="secondary"
            onClick={() => { setShowForm(true) }}>
            <i className="onek-new" />{' '}
            NEW USER ACCOUNT
          </Button>
        </div>
        { loadingUsers
          ? <Loading />
          : !users?.length
          ? <EntityNotFound entityName="Team Members" />
          : <Table striped className="small mt-4 layout-fixed">
            <thead>
              <tr>
                <th colSpan={2}>
                  TEAM MEMBERS
                </th>
                { (user.is_admin || user.isKWSPM) &&
                  <th className="two-btn"></th>
                }
              </tr>
            </thead>
            <tbody>
            { users.map((u) => {
              return (
                <tr key={u.id}>
                  <td className="w-100">
                    { u.name }
                  </td>
                  <td>{ u.email }</td>
                  { (user.is_admin || user.isKWSPM) &&
                    <td>
                      { user.username !== u.username &&
                        <>
                          <OverlayTrigger placement="top"
                            overlay={<Tooltip>Edit</Tooltip>}>
                            <button className="icon-only edit-user-btn"
                              onClick={(e) => onEditUser(e, u)}>
                              <i className="onek-add-info" />
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger placement="top"
                            overlay={<Tooltip>Delete</Tooltip>}>
                            <button className="icon-only delete-user-btn"
                              onClick={(e) => onDeleteUser(e, u)}>
                              <i className="onek-delete" />
                            </button>
                          </OverlayTrigger>
                        </>
                      }
                    </td>
                  }
                </tr>
              )
            })}
            </tbody>
          </Table>
        }
      </>
    }</>
  )
}

export default Users
