/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Add from '../model/Add';
import Exception from '../model/Exception';
import InlineResponse2002 from '../model/InlineResponse2002';
import InlineResponse2003 from '../model/InlineResponse2003';
import InlineResponse2004 from '../model/InlineResponse2004';
import JobAssignmentStatus from '../model/JobAssignmentStatus';
import LRTranslationResult from '../model/LRTranslationResult';
import LocalizationRequestContact from '../model/LocalizationRequestContact';
import LocalizationRequestCreate from '../model/LocalizationRequestCreate';
import LocalizationRequestDetail from '../model/LocalizationRequestDetail';
import LocalizationRequestFile from '../model/LocalizationRequestFile';
import LocalizationRequestFilesAdd from '../model/LocalizationRequestFilesAdd';
import LocalizationRequestHistory from '../model/LocalizationRequestHistory';
import LocalizationRequestStateUpdate from '../model/LocalizationRequestStateUpdate';
import LocalizationRequestUpdate from '../model/LocalizationRequestUpdate';
import MemberAdd from '../model/MemberAdd';
import MemoqProjectFilter from '../model/MemoqProjectFilter';
import ProjectArchived from '../model/ProjectArchived';
import ProjectFull from '../model/ProjectFull';
import ProjectIsSyncing from '../model/ProjectIsSyncing';
import ProjectMember from '../model/ProjectMember';
import ProjectResource from '../model/ProjectResource';
import ProjectResourceUpdate from '../model/ProjectResourceUpdate';
import ProjectTemplate from '../model/ProjectTemplate';
import ProjectTemplateCreateUpdate from '../model/ProjectTemplateCreateUpdate';
import ProjectUpdateKWSPM from '../model/ProjectUpdateKWSPM';
import S3PresignedURLCreate from '../model/S3PresignedURLCreate';
import S3PresignedURLResponse from '../model/S3PresignedURLResponse';

/**
* Projects service.
* @module api/ProjectsApi
* @version v1
*/
export default class ProjectsApi {

    /**
    * Constructs a new ProjectsApi. 
    * @alias module:api/ProjectsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the projectsAddCreate operation.
     * @callback module:api/ProjectsApi~projectsAddCreateCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} projectId 
     * @param {module:model/Add} data 
     * @param {module:api/ProjectsApi~projectsAddCreateCallback} callback The callback function, accepting three arguments: error, data, response
     */
    projectsAddCreate(projectId, data, callback) {
      let postBody = data;
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsAddCreate");
      }
      // verify the required parameter 'data' is set
      if (data === undefined || data === null) {
        throw new Error("Missing the required parameter 'data' when calling projectsAddCreate");
      }

      let pathParams = {
        'project_id': projectId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/projects/{project_id}/add', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsArchiveCreate operation.
     * @callback module:api/ProjectsApi~projectsArchiveCreateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProjectArchived} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} projectId 
     * @param {module:api/ProjectsApi~projectsArchiveCreateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProjectArchived}
     */
    projectsArchiveCreate(projectId, callback) {
      let postBody = null;
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsArchiveCreate");
      }

      let pathParams = {
        'project_id': projectId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ProjectArchived;
      return this.apiClient.callApi(
        '/projects/{project_id}/archive', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsCheckMemoqTranslationsCreate operation.
     * @callback module:api/ProjectsApi~projectsCheckMemoqTranslationsCreateCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} projectId 
     * @param {module:api/ProjectsApi~projectsCheckMemoqTranslationsCreateCallback} callback The callback function, accepting three arguments: error, data, response
     */
    projectsCheckMemoqTranslationsCreate(projectId, callback) {
      let postBody = null;
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsCheckMemoqTranslationsCreate");
      }

      let pathParams = {
        'project_id': projectId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/projects/{project_id}/check_memoq_translations', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsDelete operation.
     * @callback module:api/ProjectsApi~projectsDeleteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} projectId 
     * @param {module:api/ProjectsApi~projectsDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    projectsDelete(projectId, callback) {
      let postBody = null;
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsDelete");
      }

      let pathParams = {
        'project_id': projectId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/projects/{project_id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsIsSyncingRead operation.
     * @callback module:api/ProjectsApi~projectsIsSyncingReadCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProjectIsSyncing} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} projectId 
     * @param {module:api/ProjectsApi~projectsIsSyncingReadCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProjectIsSyncing}
     */
    projectsIsSyncingRead(projectId, callback) {
      let postBody = null;
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsIsSyncingRead");
      }

      let pathParams = {
        'project_id': projectId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ProjectIsSyncing;
      return this.apiClient.callApi(
        '/projects/{project_id}/is_syncing', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsJobsStatusCreate operation.
     * @callback module:api/ProjectsApi~projectsJobsStatusCreateCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} projectId 
     * @param {module:model/JobAssignmentStatus} data 
     * @param {module:api/ProjectsApi~projectsJobsStatusCreateCallback} callback The callback function, accepting three arguments: error, data, response
     */
    projectsJobsStatusCreate(projectId, data, callback) {
      let postBody = data;
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsJobsStatusCreate");
      }
      // verify the required parameter 'data' is set
      if (data === undefined || data === null) {
        throw new Error("Missing the required parameter 'data' when calling projectsJobsStatusCreate");
      }

      let pathParams = {
        'project_id': projectId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/projects/{project_id}/jobs/status', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsLocreqContactCreate operation.
     * @callback module:api/ProjectsApi~projectsLocreqContactCreateCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} locreqId 
     * @param {String} projectId 
     * @param {module:model/LocalizationRequestContact} data 
     * @param {module:api/ProjectsApi~projectsLocreqContactCreateCallback} callback The callback function, accepting three arguments: error, data, response
     */
    projectsLocreqContactCreate(locreqId, projectId, data, callback) {
      let postBody = data;
      // verify the required parameter 'locreqId' is set
      if (locreqId === undefined || locreqId === null) {
        throw new Error("Missing the required parameter 'locreqId' when calling projectsLocreqContactCreate");
      }
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsLocreqContactCreate");
      }
      // verify the required parameter 'data' is set
      if (data === undefined || data === null) {
        throw new Error("Missing the required parameter 'data' when calling projectsLocreqContactCreate");
      }

      let pathParams = {
        'locreq_id': locreqId,
        'project_id': projectId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/projects/{project_id}/locreq/{locreq_id}/contact', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsLocreqCreate operation.
     * @callback module:api/ProjectsApi~projectsLocreqCreateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/LocalizationRequestDetail} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} projectId 
     * @param {module:model/LocalizationRequestCreate} data 
     * @param {module:api/ProjectsApi~projectsLocreqCreateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/LocalizationRequestDetail}
     */
    projectsLocreqCreate(projectId, data, callback) {
      let postBody = data;
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsLocreqCreate");
      }
      // verify the required parameter 'data' is set
      if (data === undefined || data === null) {
        throw new Error("Missing the required parameter 'data' when calling projectsLocreqCreate");
      }

      let pathParams = {
        'project_id': projectId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = LocalizationRequestDetail;
      return this.apiClient.callApi(
        '/projects/{project_id}/locreq/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsLocreqDelete operation.
     * @callback module:api/ProjectsApi~projectsLocreqDeleteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} locreqId 
     * @param {String} projectId 
     * @param {module:api/ProjectsApi~projectsLocreqDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    projectsLocreqDelete(locreqId, projectId, callback) {
      let postBody = null;
      // verify the required parameter 'locreqId' is set
      if (locreqId === undefined || locreqId === null) {
        throw new Error("Missing the required parameter 'locreqId' when calling projectsLocreqDelete");
      }
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsLocreqDelete");
      }

      let pathParams = {
        'locreq_id': locreqId,
        'project_id': projectId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/projects/{project_id}/locreq/{locreq_id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsLocreqFilesCreate operation.
     * @callback module:api/ProjectsApi~projectsLocreqFilesCreateCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/LocalizationRequestFile>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} locreqId 
     * @param {String} projectId 
     * @param {module:model/LocalizationRequestFilesAdd} data 
     * @param {module:api/ProjectsApi~projectsLocreqFilesCreateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/LocalizationRequestFile>}
     */
    projectsLocreqFilesCreate(locreqId, projectId, data, callback) {
      let postBody = data;
      // verify the required parameter 'locreqId' is set
      if (locreqId === undefined || locreqId === null) {
        throw new Error("Missing the required parameter 'locreqId' when calling projectsLocreqFilesCreate");
      }
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsLocreqFilesCreate");
      }
      // verify the required parameter 'data' is set
      if (data === undefined || data === null) {
        throw new Error("Missing the required parameter 'data' when calling projectsLocreqFilesCreate");
      }

      let pathParams = {
        'locreq_id': locreqId,
        'project_id': projectId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [LocalizationRequestFile];
      return this.apiClient.callApi(
        '/projects/{project_id}/locreq/{locreq_id}/files', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsLocreqFilesDelete operation.
     * @callback module:api/ProjectsApi~projectsLocreqFilesDeleteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} fileId 
     * @param {String} locreqId 
     * @param {String} projectId 
     * @param {module:api/ProjectsApi~projectsLocreqFilesDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    projectsLocreqFilesDelete(fileId, locreqId, projectId, callback) {
      let postBody = null;
      // verify the required parameter 'fileId' is set
      if (fileId === undefined || fileId === null) {
        throw new Error("Missing the required parameter 'fileId' when calling projectsLocreqFilesDelete");
      }
      // verify the required parameter 'locreqId' is set
      if (locreqId === undefined || locreqId === null) {
        throw new Error("Missing the required parameter 'locreqId' when calling projectsLocreqFilesDelete");
      }
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsLocreqFilesDelete");
      }

      let pathParams = {
        'file_id': fileId,
        'locreq_id': locreqId,
        'project_id': projectId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/projects/{project_id}/locreq/{locreq_id}/files/{file_id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsLocreqHistoryList operation.
     * @callback module:api/ProjectsApi~projectsLocreqHistoryListCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/LocalizationRequestHistory>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} locreqId 
     * @param {String} projectId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page A page number within the paginated result set.
     * @param {module:api/ProjectsApi~projectsLocreqHistoryListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/LocalizationRequestHistory>}
     */
    projectsLocreqHistoryList(locreqId, projectId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'locreqId' is set
      if (locreqId === undefined || locreqId === null) {
        throw new Error("Missing the required parameter 'locreqId' when calling projectsLocreqHistoryList");
      }
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsLocreqHistoryList");
      }

      let pathParams = {
        'locreq_id': locreqId,
        'project_id': projectId
      };
      let queryParams = {
        'page': opts['page']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [LocalizationRequestHistory];
      return this.apiClient.callApi(
        '/projects/{project_id}/locreq/{locreq_id}/history', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsLocreqList operation.
     * @callback module:api/ProjectsApi~projectsLocreqListCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2002} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} projectId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.ordering Which field to use when ordering the results.
     * @param {Number} opts.id 
     * @param {String} opts.state 
     * @param {String} opts.service 
     * @param {String} opts.startAfter 
     * @param {String} opts.startBefore 
     * @param {String} opts.endAfter 
     * @param {String} opts.endBefore 
     * @param {Number} opts.page A page number within the paginated result set.
     * @param {Number} opts.size Number of results to return per page.
     * @param {module:api/ProjectsApi~projectsLocreqListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse2002}
     */
    projectsLocreqList(projectId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsLocreqList");
      }

      let pathParams = {
        'project_id': projectId
      };
      let queryParams = {
        'ordering': opts['ordering'],
        'id': opts['id'],
        'state': opts['state'],
        'service': opts['service'],
        'start_after': opts['startAfter'],
        'start_before': opts['startBefore'],
        'end_after': opts['endAfter'],
        'end_before': opts['endBefore'],
        'page': opts['page'],
        'size': opts['size']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2002;
      return this.apiClient.callApi(
        '/projects/{project_id}/locreq/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsLocreqRead operation.
     * @callback module:api/ProjectsApi~projectsLocreqReadCallback
     * @param {String} error Error message, if any.
     * @param {module:model/LocalizationRequestDetail} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} locreqId 
     * @param {String} projectId 
     * @param {module:api/ProjectsApi~projectsLocreqReadCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/LocalizationRequestDetail}
     */
    projectsLocreqRead(locreqId, projectId, callback) {
      let postBody = null;
      // verify the required parameter 'locreqId' is set
      if (locreqId === undefined || locreqId === null) {
        throw new Error("Missing the required parameter 'locreqId' when calling projectsLocreqRead");
      }
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsLocreqRead");
      }

      let pathParams = {
        'locreq_id': locreqId,
        'project_id': projectId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = LocalizationRequestDetail;
      return this.apiClient.callApi(
        '/projects/{project_id}/locreq/{locreq_id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsLocreqResultList operation.
     * @callback module:api/ProjectsApi~projectsLocreqResultListCallback
     * @param {String} error Error message, if any.
     * @param {module:model/LRTranslationResult} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} locreqId 
     * @param {String} projectId 
     * @param {module:api/ProjectsApi~projectsLocreqResultListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/LRTranslationResult}
     */
    projectsLocreqResultList(locreqId, projectId, callback) {
      let postBody = null;
      // verify the required parameter 'locreqId' is set
      if (locreqId === undefined || locreqId === null) {
        throw new Error("Missing the required parameter 'locreqId' when calling projectsLocreqResultList");
      }
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsLocreqResultList");
      }

      let pathParams = {
        'locreq_id': locreqId,
        'project_id': projectId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = LRTranslationResult;
      return this.apiClient.callApi(
        '/projects/{project_id}/locreq/{locreq_id}/result', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsLocreqStatusUpdate operation.
     * @callback module:api/ProjectsApi~projectsLocreqStatusUpdateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/LocalizationRequestDetail} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} locreqId 
     * @param {String} projectId 
     * @param {module:model/LocalizationRequestStateUpdate} data 
     * @param {module:api/ProjectsApi~projectsLocreqStatusUpdateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/LocalizationRequestDetail}
     */
    projectsLocreqStatusUpdate(locreqId, projectId, data, callback) {
      let postBody = data;
      // verify the required parameter 'locreqId' is set
      if (locreqId === undefined || locreqId === null) {
        throw new Error("Missing the required parameter 'locreqId' when calling projectsLocreqStatusUpdate");
      }
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsLocreqStatusUpdate");
      }
      // verify the required parameter 'data' is set
      if (data === undefined || data === null) {
        throw new Error("Missing the required parameter 'data' when calling projectsLocreqStatusUpdate");
      }

      let pathParams = {
        'locreq_id': locreqId,
        'project_id': projectId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = LocalizationRequestDetail;
      return this.apiClient.callApi(
        '/projects/{project_id}/locreq/{locreq_id}/status', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsLocreqSyncBpsTransactionCreate operation.
     * @callback module:api/ProjectsApi~projectsLocreqSyncBpsTransactionCreateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/LocalizationRequestDetail} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} locreqId 
     * @param {String} projectId 
     * @param {module:api/ProjectsApi~projectsLocreqSyncBpsTransactionCreateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/LocalizationRequestDetail}
     */
    projectsLocreqSyncBpsTransactionCreate(locreqId, projectId, callback) {
      let postBody = null;
      // verify the required parameter 'locreqId' is set
      if (locreqId === undefined || locreqId === null) {
        throw new Error("Missing the required parameter 'locreqId' when calling projectsLocreqSyncBpsTransactionCreate");
      }
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsLocreqSyncBpsTransactionCreate");
      }

      let pathParams = {
        'locreq_id': locreqId,
        'project_id': projectId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = LocalizationRequestDetail;
      return this.apiClient.callApi(
        '/projects/{project_id}/locreq/{locreq_id}/sync_bps_transaction', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsLocreqUpdate operation.
     * @callback module:api/ProjectsApi~projectsLocreqUpdateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/LocalizationRequestDetail} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} locreqId 
     * @param {String} projectId 
     * @param {module:model/LocalizationRequestUpdate} data 
     * @param {module:api/ProjectsApi~projectsLocreqUpdateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/LocalizationRequestDetail}
     */
    projectsLocreqUpdate(locreqId, projectId, data, callback) {
      let postBody = data;
      // verify the required parameter 'locreqId' is set
      if (locreqId === undefined || locreqId === null) {
        throw new Error("Missing the required parameter 'locreqId' when calling projectsLocreqUpdate");
      }
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsLocreqUpdate");
      }
      // verify the required parameter 'data' is set
      if (data === undefined || data === null) {
        throw new Error("Missing the required parameter 'data' when calling projectsLocreqUpdate");
      }

      let pathParams = {
        'locreq_id': locreqId,
        'project_id': projectId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = LocalizationRequestDetail;
      return this.apiClient.callApi(
        '/projects/{project_id}/locreq/{locreq_id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsMembersCreate operation.
     * @callback module:api/ProjectsApi~projectsMembersCreateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProjectMember} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} projectId 
     * @param {module:model/MemberAdd} data 
     * @param {module:api/ProjectsApi~projectsMembersCreateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProjectMember}
     */
    projectsMembersCreate(projectId, data, callback) {
      let postBody = data;
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsMembersCreate");
      }
      // verify the required parameter 'data' is set
      if (data === undefined || data === null) {
        throw new Error("Missing the required parameter 'data' when calling projectsMembersCreate");
      }

      let pathParams = {
        'project_id': projectId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ProjectMember;
      return this.apiClient.callApi(
        '/projects/{project_id}/members/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsMembersDelete operation.
     * @callback module:api/ProjectsApi~projectsMembersDeleteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} memberId 
     * @param {String} projectId 
     * @param {module:api/ProjectsApi~projectsMembersDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    projectsMembersDelete(memberId, projectId, callback) {
      let postBody = null;
      // verify the required parameter 'memberId' is set
      if (memberId === undefined || memberId === null) {
        throw new Error("Missing the required parameter 'memberId' when calling projectsMembersDelete");
      }
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsMembersDelete");
      }

      let pathParams = {
        'member_id': memberId,
        'project_id': projectId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/projects/{project_id}/members/{member_id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsMembersList operation.
     * @callback module:api/ProjectsApi~projectsMembersListCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2003} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} projectId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page A page number within the paginated result set.
     * @param {module:api/ProjectsApi~projectsMembersListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse2003}
     */
    projectsMembersList(projectId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsMembersList");
      }

      let pathParams = {
        'project_id': projectId
      };
      let queryParams = {
        'page': opts['page']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2003;
      return this.apiClient.callApi(
        '/projects/{project_id}/members/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsMemoqfiltersList operation.
     * @callback module:api/ProjectsApi~projectsMemoqfiltersListCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/MemoqProjectFilter>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} projectId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.serviceLevel lite or platinum
     * @param {module:api/ProjectsApi~projectsMemoqfiltersListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/MemoqProjectFilter>}
     */
    projectsMemoqfiltersList(projectId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsMemoqfiltersList");
      }

      let pathParams = {
        'project_id': projectId
      };
      let queryParams = {
        'service_level': opts['serviceLevel']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [MemoqProjectFilter];
      return this.apiClient.callApi(
        '/projects/{project_id}/memoqfilters', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsRead operation.
     * @callback module:api/ProjectsApi~projectsReadCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProjectFull} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} projectId 
     * @param {module:api/ProjectsApi~projectsReadCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProjectFull}
     */
    projectsRead(projectId, callback) {
      let postBody = null;
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsRead");
      }

      let pathParams = {
        'project_id': projectId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ProjectFull;
      return this.apiClient.callApi(
        '/projects/{project_id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsServicesResourcesList operation.
     * @callback module:api/ProjectsApi~projectsServicesResourcesListCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/ProjectResource>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} projectId 
     * @param {String} service 
     * @param {module:api/ProjectsApi~projectsServicesResourcesListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/ProjectResource>}
     */
    projectsServicesResourcesList(projectId, service, callback) {
      let postBody = null;
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsServicesResourcesList");
      }
      // verify the required parameter 'service' is set
      if (service === undefined || service === null) {
        throw new Error("Missing the required parameter 'service' when calling projectsServicesResourcesList");
      }

      let pathParams = {
        'project_id': projectId,
        'service': service
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ProjectResource];
      return this.apiClient.callApi(
        '/projects/{project_id}/services/{service}/resources', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsServicesResourcesUpdate operation.
     * @callback module:api/ProjectsApi~projectsServicesResourcesUpdateCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/ProjectResource>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} projectId 
     * @param {String} service 
     * @param {Array.<module:model/ProjectResourceUpdate>} data 
     * @param {module:api/ProjectsApi~projectsServicesResourcesUpdateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/ProjectResource>}
     */
    projectsServicesResourcesUpdate(projectId, service, data, callback) {
      let postBody = data;
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsServicesResourcesUpdate");
      }
      // verify the required parameter 'service' is set
      if (service === undefined || service === null) {
        throw new Error("Missing the required parameter 'service' when calling projectsServicesResourcesUpdate");
      }
      // verify the required parameter 'data' is set
      if (data === undefined || data === null) {
        throw new Error("Missing the required parameter 'data' when calling projectsServicesResourcesUpdate");
      }

      let pathParams = {
        'project_id': projectId,
        'service': service
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [ProjectResource];
      return this.apiClient.callApi(
        '/projects/{project_id}/services/{service}/resources', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsSyncBpsCreate operation.
     * @callback module:api/ProjectsApi~projectsSyncBpsCreateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProjectFull} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} projectId 
     * @param {module:api/ProjectsApi~projectsSyncBpsCreateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProjectFull}
     */
    projectsSyncBpsCreate(projectId, callback) {
      let postBody = null;
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsSyncBpsCreate");
      }

      let pathParams = {
        'project_id': projectId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ProjectFull;
      return this.apiClient.callApi(
        '/projects/{project_id}/sync_bps', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsTemplatesCreate operation.
     * @callback module:api/ProjectsApi~projectsTemplatesCreateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProjectTemplate} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} projectId 
     * @param {module:model/ProjectTemplateCreateUpdate} data 
     * @param {module:api/ProjectsApi~projectsTemplatesCreateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProjectTemplate}
     */
    projectsTemplatesCreate(projectId, data, callback) {
      let postBody = data;
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsTemplatesCreate");
      }
      // verify the required parameter 'data' is set
      if (data === undefined || data === null) {
        throw new Error("Missing the required parameter 'data' when calling projectsTemplatesCreate");
      }

      let pathParams = {
        'project_id': projectId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ProjectTemplate;
      return this.apiClient.callApi(
        '/projects/{project_id}/templates/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsTemplatesDelete operation.
     * @callback module:api/ProjectsApi~projectsTemplatesDeleteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} projectId 
     * @param {String} templateId 
     * @param {module:api/ProjectsApi~projectsTemplatesDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    projectsTemplatesDelete(projectId, templateId, callback) {
      let postBody = null;
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsTemplatesDelete");
      }
      // verify the required parameter 'templateId' is set
      if (templateId === undefined || templateId === null) {
        throw new Error("Missing the required parameter 'templateId' when calling projectsTemplatesDelete");
      }

      let pathParams = {
        'project_id': projectId,
        'template_id': templateId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/projects/{project_id}/templates/{template_id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsTemplatesList operation.
     * @callback module:api/ProjectsApi~projectsTemplatesListCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2004} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} projectId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page A page number within the paginated result set.
     * @param {module:api/ProjectsApi~projectsTemplatesListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse2004}
     */
    projectsTemplatesList(projectId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsTemplatesList");
      }

      let pathParams = {
        'project_id': projectId
      };
      let queryParams = {
        'page': opts['page']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2004;
      return this.apiClient.callApi(
        '/projects/{project_id}/templates/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsTemplatesRead operation.
     * @callback module:api/ProjectsApi~projectsTemplatesReadCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProjectTemplate} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} projectId 
     * @param {String} templateId 
     * @param {module:api/ProjectsApi~projectsTemplatesReadCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProjectTemplate}
     */
    projectsTemplatesRead(projectId, templateId, callback) {
      let postBody = null;
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsTemplatesRead");
      }
      // verify the required parameter 'templateId' is set
      if (templateId === undefined || templateId === null) {
        throw new Error("Missing the required parameter 'templateId' when calling projectsTemplatesRead");
      }

      let pathParams = {
        'project_id': projectId,
        'template_id': templateId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ProjectTemplate;
      return this.apiClient.callApi(
        '/projects/{project_id}/templates/{template_id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsTemplatesUpdate operation.
     * @callback module:api/ProjectsApi~projectsTemplatesUpdateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProjectTemplate} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} projectId 
     * @param {String} templateId 
     * @param {module:model/ProjectTemplateCreateUpdate} data 
     * @param {module:api/ProjectsApi~projectsTemplatesUpdateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProjectTemplate}
     */
    projectsTemplatesUpdate(projectId, templateId, data, callback) {
      let postBody = data;
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsTemplatesUpdate");
      }
      // verify the required parameter 'templateId' is set
      if (templateId === undefined || templateId === null) {
        throw new Error("Missing the required parameter 'templateId' when calling projectsTemplatesUpdate");
      }
      // verify the required parameter 'data' is set
      if (data === undefined || data === null) {
        throw new Error("Missing the required parameter 'data' when calling projectsTemplatesUpdate");
      }

      let pathParams = {
        'project_id': projectId,
        'template_id': templateId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ProjectTemplate;
      return this.apiClient.callApi(
        '/projects/{project_id}/templates/{template_id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsUpdate operation.
     * @callback module:api/ProjectsApi~projectsUpdateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProjectFull} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} projectId 
     * @param {module:model/ProjectUpdateKWSPM} data 
     * @param {module:api/ProjectsApi~projectsUpdateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProjectFull}
     */
    projectsUpdate(projectId, data, callback) {
      let postBody = data;
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsUpdate");
      }
      // verify the required parameter 'data' is set
      if (data === undefined || data === null) {
        throw new Error("Missing the required parameter 'data' when calling projectsUpdate");
      }

      let pathParams = {
        'project_id': projectId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ProjectFull;
      return this.apiClient.callApi(
        '/projects/{project_id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the projectsUploadsCreate operation.
     * @callback module:api/ProjectsApi~projectsUploadsCreateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/S3PresignedURLResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} projectId 
     * @param {module:model/S3PresignedURLCreate} data 
     * @param {module:api/ProjectsApi~projectsUploadsCreateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/S3PresignedURLResponse}
     */
    projectsUploadsCreate(projectId, data, callback) {
      let postBody = data;
      // verify the required parameter 'projectId' is set
      if (projectId === undefined || projectId === null) {
        throw new Error("Missing the required parameter 'projectId' when calling projectsUploadsCreate");
      }
      // verify the required parameter 'data' is set
      if (data === undefined || data === null) {
        throw new Error("Missing the required parameter 'data' when calling projectsUploadsCreate");
      }

      let pathParams = {
        'project_id': projectId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = S3PresignedURLResponse;
      return this.apiClient.callApi(
        '/projects/{project_id}/uploads/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
