/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The S3PresignedURL model module.
 * @module model/S3PresignedURL
 * @version v1
 */
class S3PresignedURL {
    /**
     * Constructs a new <code>S3PresignedURL</code>.
     * @alias module:model/S3PresignedURL
     * @param name {String} 
     * @param url {String} 
     * @param fields {Object.<String, String>} 
     */
    constructor(name, url, fields) { 
        
        S3PresignedURL.initialize(this, name, url, fields);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, name, url, fields) { 
        obj['name'] = name;
        obj['url'] = url;
        obj['fields'] = fields;
    }

    /**
     * Constructs a <code>S3PresignedURL</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/S3PresignedURL} obj Optional instance to populate.
     * @return {module:model/S3PresignedURL} The populated <code>S3PresignedURL</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new S3PresignedURL();

            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
            if (data.hasOwnProperty('fields')) {
                obj['fields'] = ApiClient.convertToType(data['fields'], {'String': 'String'});
            }
        }
        return obj;
    }


}

/**
 * @member {String} name
 */
S3PresignedURL.prototype['name'] = undefined;

/**
 * @member {String} url
 */
S3PresignedURL.prototype['url'] = undefined;

/**
 * @member {Object.<String, String>} fields
 */
S3PresignedURL.prototype['fields'] = undefined;






export default S3PresignedURL;

