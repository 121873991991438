/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import MultilingualOption from './MultilingualOption';

/**
 * The MultilingualFileUpdate model module.
 * @module model/MultilingualFileUpdate
 * @version v1
 */
class MultilingualFileUpdate {
    /**
     * Constructs a new <code>MultilingualFileUpdate</code>.
     * @alias module:model/MultilingualFileUpdate
     * @param id {Number} 
     */
    constructor(id) { 
        
        MultilingualFileUpdate.initialize(this, id);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id) { 
        obj['id'] = id;
    }

    /**
     * Constructs a <code>MultilingualFileUpdate</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MultilingualFileUpdate} obj Optional instance to populate.
     * @return {module:model/MultilingualFileUpdate} The populated <code>MultilingualFileUpdate</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MultilingualFileUpdate();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('multilingual')) {
                obj['multilingual'] = MultilingualOption.constructFromObject(data['multilingual']);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
MultilingualFileUpdate.prototype['id'] = undefined;

/**
 * @member {module:model/MultilingualOption} multilingual
 */
MultilingualFileUpdate.prototype['multilingual'] = undefined;






export default MultilingualFileUpdate;

