const primaryColor = '#E3E9FF'
const backgroundColor = 'rgba(255, 255, 255, 0.2)'
const menuBackgroundColor = '#012169'
const menuBackgroundColorSelected = '#888B8D'
const textBlack = {color: 'black'}
const textWhite = {color: 'white'}

//
export const selectTheme = theme => ({
    ...theme,
    borderRadius: 0,
    colors: {
        ...theme.colors,
        primary: primaryColor,
        primary25: primaryColor
    }
})

//
export const selectStyles = {
    control:            provided => ({ ...provided, backgroundColor }),
    placeholder:        provided => ({ ...provided, ...textWhite,textTransform: 'uppercase' }),
    indicatorSeparator: provided => ({ ...provided, width: 0 }),
    dropdownIndicator:  provided => ({ ...provided, ...textWhite }),
    menu:               provided => ({
        ...provided,
        backgroundColor: menuBackgroundColor,
        marginTop: 2
    }),
    menuList:           provided => ({
        ...provided,
        maxHeight: '200px'
    }),
    option: (provided, state) => {
        const optionStyles = {
            ...provided,
            ...textWhite,
            fontSize: '14px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        }
  
        const selectedStyles = { 
            ...optionStyles, 
            backgroundColor: menuBackgroundColorSelected 
        }
        return state.isFocused || state.isSelected ? selectedStyles : optionStyles
    },
    singleValue:        provided => ({ ...provided, ...textWhite }),
    multiValueRemove:   provided => ({ ...provided, ...textBlack }),
    clearIndicator:     provided => ({ ...provided, ...textWhite })
  }
  
export const selectStylesBlack = {
    ...selectStyles,
    placeholder:        provided => ({ ...provided, ...textBlack, textTransform: 'uppercase' }),
    dropdownIndicator:  provided => ({ ...provided, ...textBlack }),
    singleValue:        provided => ({ ...provided, ...textBlack }),
    multiValueRemove:   provided => ({ ...provided, ...textWhite }),
    clearIndicator:     provided => ({ ...provided, ...textBlack })
}