export const dateLabel = d => new Date(d).toUTCString()

export const tranlationEvents = {
    translation_assigned:       ({user, timestamp}, isFirst) => 
    `\n${isFirst ? 'Assigned' : 'Re-assigned'} to: ${user}\n${isFirst ? 'Assigned' : 'Re-assigned'} on: ${dateLabel(timestamp)}`,

    translation_accepted:       ({user, timestamp}) => 
    `\nAccepted by: ${user}\nAccepted on: ${dateLabel(timestamp)}`,

    translation_delivered:      ({user, timestamp}) => 
    `\nDelivered by: ${user}\nDelivered on: ${dateLabel(timestamp)}`,

    translation_not_responded:  ({user, timestamp}) => 
    `\nNot Responded by: ${user}\nNot Responded on: ${dateLabel(timestamp)}`,

    translation_rejected:       ({user, timestamp}) => 
    `\nRejected by: ${user}\nRejected on: ${dateLabel(timestamp)}`,
}

export const reviewEvents = {
    review_assigned:       ({user, timestamp}, isFirst) => 
    `\n${isFirst ? 'Assigned' : 'Re-assigned'} to: ${user}\n${isFirst ? 'Assigned' : 'Re-assigned'} on: ${dateLabel(timestamp)}`,

    review_accepted:       ({user, timestamp}) => 
    `\nAccepted by: ${user}\nAccepted on: ${dateLabel(timestamp)}`,

    review_delivered:      ({user, timestamp}) => 
    `\nDelivered by: ${user}\nDelivered on: ${dateLabel(timestamp)}`,

    review_not_responded:  ({user, timestamp}) => 
    `\nNot Responded by: ${user}\nNot Responded on: ${dateLabel(timestamp)}`,

    review_rejected:       ({user, timestamp}) => 
    `\nRejected by: ${user}\nRejected on: ${dateLabel(timestamp)}`,
}