/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The StatisticsFileCountAvg model module.
 * @module model/StatisticsFileCountAvg
 * @version v1
 */
class StatisticsFileCountAvg {
    /**
     * Constructs a new <code>StatisticsFileCountAvg</code>.
     * @alias module:model/StatisticsFileCountAvg
     * @param month {Date} 
     * @param avg {Number} 
     */
    constructor(month, avg) { 
        
        StatisticsFileCountAvg.initialize(this, month, avg);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, month, avg) { 
        obj['month'] = month;
        obj['avg'] = avg;
    }

    /**
     * Constructs a <code>StatisticsFileCountAvg</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/StatisticsFileCountAvg} obj Optional instance to populate.
     * @return {module:model/StatisticsFileCountAvg} The populated <code>StatisticsFileCountAvg</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new StatisticsFileCountAvg();

            if (data.hasOwnProperty('month')) {
                obj['month'] = ApiClient.convertToType(data['month'], 'Date');
            }
            if (data.hasOwnProperty('avg')) {
                obj['avg'] = ApiClient.convertToType(data['avg'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} month
 */
StatisticsFileCountAvg.prototype['month'] = undefined;

/**
 * @member {Number} avg
 */
StatisticsFileCountAvg.prototype['avg'] = undefined;






export default StatisticsFileCountAvg;

