/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import LocalizationRequestFile from './LocalizationRequestFile';

/**
 * The LocalizationRequestListItem model module.
 * @module model/LocalizationRequestListItem
 * @version v1
 */
class LocalizationRequestListItem {
    /**
     * Constructs a new <code>LocalizationRequestListItem</code>.
     * @alias module:model/LocalizationRequestListItem
     * @param id {Number} 
     * @param state {module:model/LocalizationRequestListItem.StateEnum} 
     * @param start {String} 
     * @param end {String} 
     * @param contactedAt {String} 
     * @param stateMeta {Object.<String, String>} 
     * @param desiredDelivery {Date} 
     * @param sourceLanguage {module:model/LocalizationRequestListItem.SourceLanguageEnum} 
     * @param targetLanguages {Array.<module:model/LocalizationRequestListItem.TargetLanguagesEnum>} 
     * @param service {module:model/LocalizationRequestListItem.ServiceEnum} 
     * @param wordcount {Object.<String, String>} dictionary of <language_code:wordcount> pairs
     * @param currency {String} 
     * @param totalPrice {Number} 
     */
    constructor(id, state, start, end, contactedAt, stateMeta, desiredDelivery, sourceLanguage, targetLanguages, service, wordcount, currency, totalPrice) { 
        
        LocalizationRequestListItem.initialize(this, id, state, start, end, contactedAt, stateMeta, desiredDelivery, sourceLanguage, targetLanguages, service, wordcount, currency, totalPrice);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, state, start, end, contactedAt, stateMeta, desiredDelivery, sourceLanguage, targetLanguages, service, wordcount, currency, totalPrice) { 
        obj['id'] = id;
        obj['state'] = state;
        obj['start'] = start;
        obj['end'] = end;
        obj['contacted_at'] = contactedAt;
        obj['state_meta'] = stateMeta;
        obj['desired_delivery'] = desiredDelivery;
        obj['source_language'] = sourceLanguage;
        obj['target_languages'] = targetLanguages;
        obj['service'] = service;
        obj['wordcount'] = wordcount;
        obj['currency'] = currency;
        obj['total_price'] = totalPrice;
    }

    /**
     * Constructs a <code>LocalizationRequestListItem</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/LocalizationRequestListItem} obj Optional instance to populate.
     * @return {module:model/LocalizationRequestListItem} The populated <code>LocalizationRequestListItem</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LocalizationRequestListItem();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('number')) {
                obj['number'] = ApiClient.convertToType(data['number'], 'String');
            }
            if (data.hasOwnProperty('state')) {
                obj['state'] = ApiClient.convertToType(data['state'], 'String');
            }
            if (data.hasOwnProperty('start')) {
                obj['start'] = ApiClient.convertToType(data['start'], 'String');
            }
            if (data.hasOwnProperty('end')) {
                obj['end'] = ApiClient.convertToType(data['end'], 'String');
            }
            if (data.hasOwnProperty('contacted_at')) {
                obj['contacted_at'] = ApiClient.convertToType(data['contacted_at'], 'String');
            }
            if (data.hasOwnProperty('state_meta')) {
                obj['state_meta'] = ApiClient.convertToType(data['state_meta'], {'String': 'String'});
            }
            if (data.hasOwnProperty('desired_delivery')) {
                obj['desired_delivery'] = ApiClient.convertToType(data['desired_delivery'], 'Date');
            }
            if (data.hasOwnProperty('source_language')) {
                obj['source_language'] = ApiClient.convertToType(data['source_language'], 'String');
            }
            if (data.hasOwnProperty('target_languages')) {
                obj['target_languages'] = ApiClient.convertToType(data['target_languages'], ['String']);
            }
            if (data.hasOwnProperty('service')) {
                obj['service'] = ApiClient.convertToType(data['service'], 'String');
            }
            if (data.hasOwnProperty('wordcount')) {
                obj['wordcount'] = ApiClient.convertToType(data['wordcount'], {'String': 'String'});
            }
            if (data.hasOwnProperty('currency')) {
                obj['currency'] = ApiClient.convertToType(data['currency'], 'String');
            }
            if (data.hasOwnProperty('total_price')) {
                obj['total_price'] = ApiClient.convertToType(data['total_price'], 'Number');
            }
            if (data.hasOwnProperty('files')) {
                obj['files'] = ApiClient.convertToType(data['files'], [LocalizationRequestFile]);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
LocalizationRequestListItem.prototype['id'] = undefined;

/**
 * @member {String} number
 */
LocalizationRequestListItem.prototype['number'] = undefined;

/**
 * @member {module:model/LocalizationRequestListItem.StateEnum} state
 */
LocalizationRequestListItem.prototype['state'] = undefined;

/**
 * @member {String} start
 */
LocalizationRequestListItem.prototype['start'] = undefined;

/**
 * @member {String} end
 */
LocalizationRequestListItem.prototype['end'] = undefined;

/**
 * @member {String} contacted_at
 */
LocalizationRequestListItem.prototype['contacted_at'] = undefined;

/**
 * @member {Object.<String, String>} state_meta
 */
LocalizationRequestListItem.prototype['state_meta'] = undefined;

/**
 * @member {Date} desired_delivery
 */
LocalizationRequestListItem.prototype['desired_delivery'] = undefined;

/**
 * @member {module:model/LocalizationRequestListItem.SourceLanguageEnum} source_language
 */
LocalizationRequestListItem.prototype['source_language'] = undefined;

/**
 * @member {Array.<module:model/LocalizationRequestListItem.TargetLanguagesEnum>} target_languages
 */
LocalizationRequestListItem.prototype['target_languages'] = undefined;

/**
 * @member {module:model/LocalizationRequestListItem.ServiceEnum} service
 */
LocalizationRequestListItem.prototype['service'] = undefined;

/**
 * dictionary of <language_code:wordcount> pairs
 * @member {Object.<String, String>} wordcount
 */
LocalizationRequestListItem.prototype['wordcount'] = undefined;

/**
 * @member {String} currency
 */
LocalizationRequestListItem.prototype['currency'] = undefined;

/**
 * @member {Number} total_price
 */
LocalizationRequestListItem.prototype['total_price'] = undefined;

/**
 * @member {Array.<module:model/LocalizationRequestFile>} files
 */
LocalizationRequestListItem.prototype['files'] = undefined;





/**
 * Allowed values for the <code>state</code> property.
 * @enum {String}
 * @readonly
 */
LocalizationRequestListItem['StateEnum'] = {

    /**
     * value: "draft"
     * @const
     */
    "draft": "draft",

    /**
     * value: "draft_to_ongoing"
     * @const
     */
    "draft_to_ongoing": "draft_to_ongoing",

    /**
     * value: "ongoing"
     * @const
     */
    "ongoing": "ongoing",

    /**
     * value: "delivered"
     * @const
     */
    "delivered": "delivered",

    /**
     * value: "closed"
     * @const
     */
    "closed": "closed",

    /**
     * value: "cancelled"
     * @const
     */
    "cancelled": "cancelled",

    /**
     * value: "ongoing_to_cancelled"
     * @const
     */
    "ongoing_to_cancelled": "ongoing_to_cancelled",

    /**
     * value: "invoiced"
     * @const
     */
    "invoiced": "invoiced"
};


/**
 * Allowed values for the <code>source_language</code> property.
 * @enum {String}
 * @readonly
 */
LocalizationRequestListItem['SourceLanguageEnum'] = {

    /**
     * value: "engUS"
     * @const
     */
    "engUS": "engUS"
};


/**
 * Allowed values for the <code>targetLanguages</code> property.
 * @enum {String}
 * @readonly
 */
LocalizationRequestListItem['TargetLanguagesEnum'] = {

    /**
     * value: "afrAF"
     * @const
     */
    "afrAF": "afrAF",

    /**
     * value: "albAL"
     * @const
     */
    "albAL": "albAL",

    /**
     * value: "amhET"
     * @const
     */
    "amhET": "amhET",

    /**
     * value: "araAE"
     * @const
     */
    "araAE": "araAE",

    /**
     * value: "araBH"
     * @const
     */
    "araBH": "araBH",

    /**
     * value: "araEG"
     * @const
     */
    "araEG": "araEG",

    /**
     * value: "araKW"
     * @const
     */
    "araKW": "araKW",

    /**
     * value: "araLB"
     * @const
     */
    "araLB": "araLB",

    /**
     * value: "araMS"
     * @const
     */
    "araMS": "araMS",

    /**
     * value: "araOM"
     * @const
     */
    "araOM": "araOM",

    /**
     * value: "araQA"
     * @const
     */
    "araQA": "araQA",

    /**
     * value: "araSA"
     * @const
     */
    "araSA": "araSA",

    /**
     * value: "asmIN"
     * @const
     */
    "asmIN": "asmIN",

    /**
     * value: "azeAZ"
     * @const
     */
    "azeAZ": "azeAZ",

    /**
     * value: "baqES"
     * @const
     */
    "baqES": "baqES",

    /**
     * value: "belBY"
     * @const
     */
    "belBY": "belBY",

    /**
     * value: "benBD"
     * @const
     */
    "benBD": "benBD",

    /**
     * value: "benIN"
     * @const
     */
    "benIN": "benIN",

    /**
     * value: "bosBH"
     * @const
     */
    "bosBH": "bosBH",

    /**
     * value: "bulBG"
     * @const
     */
    "bulBG": "bulBG",

    /**
     * value: "burMM"
     * @const
     */
    "burMM": "burMM",

    /**
     * value: "catES"
     * @const
     */
    "catES": "catES",

    /**
     * value: "chrUS"
     * @const
     */
    "chrUS": "chrUS",

    /**
     * value: "chsCL"
     * @const
     */
    "chsCL": "chsCL",

    /**
     * value: "chsCN"
     * @const
     */
    "chsCN": "chsCN",

    /**
     * value: "chsSG"
     * @const
     */
    "chsSG": "chsSG",

    /**
     * value: "chtCL"
     * @const
     */
    "chtCL": "chtCL",

    /**
     * value: "chtHG"
     * @const
     */
    "chtHG": "chtHG",

    /**
     * value: "chtTW"
     * @const
     */
    "chtTW": "chtTW",

    /**
     * value: "ckbIQ"
     * @const
     */
    "ckbIQ": "ckbIQ",

    /**
     * value: "cymUK"
     * @const
     */
    "cymUK": "cymUK",

    /**
     * value: "czeCZ"
     * @const
     */
    "czeCZ": "czeCZ",

    /**
     * value: "danDK"
     * @const
     */
    "danDK": "danDK",

    /**
     * value: "dutNL"
     * @const
     */
    "dutNL": "dutNL",

    /**
     * value: "engAU"
     * @const
     */
    "engAU": "engAU",

    /**
     * value: "engCA"
     * @const
     */
    "engCA": "engCA",

    /**
     * value: "engEI"
     * @const
     */
    "engEI": "engEI",

    /**
     * value: "engIN"
     * @const
     */
    "engIN": "engIN",

    /**
     * value: "engNZ"
     * @const
     */
    "engNZ": "engNZ",

    /**
     * value: "engSG"
     * @const
     */
    "engSG": "engSG",

    /**
     * value: "engUK"
     * @const
     */
    "engUK": "engUK",

    /**
     * value: "engUS"
     * @const
     */
    "engUS": "engUS",

    /**
     * value: "engZA"
     * @const
     */
    "engZA": "engZA",

    /**
     * value: "estEE"
     * @const
     */
    "estEE": "estEE",

    /**
     * value: "fasIR"
     * @const
     */
    "fasIR": "fasIR",

    /**
     * value: "finFI"
     * @const
     */
    "finFI": "finFI",

    /**
     * value: "freBE"
     * @const
     */
    "freBE": "freBE",

    /**
     * value: "freCA"
     * @const
     */
    "freCA": "freCA",

    /**
     * value: "freCH"
     * @const
     */
    "freCH": "freCH",

    /**
     * value: "freFR"
     * @const
     */
    "freFR": "freFR",

    /**
     * value: "freLU"
     * @const
     */
    "freLU": "freLU",

    /**
     * value: "freUS"
     * @const
     */
    "freUS": "freUS",

    /**
     * value: "geoGE"
     * @const
     */
    "geoGE": "geoGE",

    /**
     * value: "gerAT"
     * @const
     */
    "gerAT": "gerAT",

    /**
     * value: "gerCH"
     * @const
     */
    "gerCH": "gerCH",

    /**
     * value: "gerDE"
     * @const
     */
    "gerDE": "gerDE",

    /**
     * value: "gerLI"
     * @const
     */
    "gerLI": "gerLI",

    /**
     * value: "glaUK"
     * @const
     */
    "glaUK": "glaUK",

    /**
     * value: "glgES"
     * @const
     */
    "glgES": "glgES",

    /**
     * value: "greCY"
     * @const
     */
    "greCY": "greCY",

    /**
     * value: "greGR"
     * @const
     */
    "greGR": "greGR",

    /**
     * value: "gujIN"
     * @const
     */
    "gujIN": "gujIN",

    /**
     * value: "hatHT"
     * @const
     */
    "hatHT": "hatHT",

    /**
     * value: "hauWA"
     * @const
     */
    "hauWA": "hauWA",

    /**
     * value: "hebIL"
     * @const
     */
    "hebIL": "hebIL",

    /**
     * value: "hinIN"
     * @const
     */
    "hinIN": "hinIN",

    /**
     * value: "hrvHR"
     * @const
     */
    "hrvHR": "hrvHR",

    /**
     * value: "hunHU"
     * @const
     */
    "hunHU": "hunHU",

    /**
     * value: "hyeAR"
     * @const
     */
    "hyeAR": "hyeAR",

    /**
     * value: "iboWA"
     * @const
     */
    "iboWA": "iboWA",

    /**
     * value: "IceIS"
     * @const
     */
    "IceIS": "IceIS",

    /**
     * value: "ikuCA"
     * @const
     */
    "ikuCA": "ikuCA",

    /**
     * value: "indID"
     * @const
     */
    "indID": "indID",

    /**
     * value: "irlIE"
     * @const
     */
    "irlIE": "irlIE",

    /**
     * value: "itaCH"
     * @const
     */
    "itaCH": "itaCH",

    /**
     * value: "itaIT"
     * @const
     */
    "itaIT": "itaIT",

    /**
     * value: "jpnJP"
     * @const
     */
    "jpnJP": "jpnJP",

    /**
     * value: "kanIN"
     * @const
     */
    "kanIN": "kanIN",

    /**
     * value: "kazKZ"
     * @const
     */
    "kazKZ": "kazKZ",

    /**
     * value: "khmKA"
     * @const
     */
    "khmKA": "khmKA",

    /**
     * value: "kinRW"
     * @const
     */
    "kinRW": "kinRW",

    /**
     * value: "kirKG"
     * @const
     */
    "kirKG": "kirKG",

    /**
     * value: "kokIN"
     * @const
     */
    "kokIN": "kokIN",

    /**
     * value: "konCO"
     * @const
     */
    "konCO": "konCO",

    /**
     * value: "korKR"
     * @const
     */
    "korKR": "korKR",

    /**
     * value: "laoLA"
     * @const
     */
    "laoLA": "laoLA",

    /**
     * value: "lavLV"
     * @const
     */
    "lavLV": "lavLV",

    /**
     * value: "litLT"
     * @const
     */
    "litLT": "litLT",

    /**
     * value: "ltzLU"
     * @const
     */
    "ltzLU": "ltzLU",

    /**
     * value: "malML"
     * @const
     */
    "malML": "malML",

    /**
     * value: "marIN"
     * @const
     */
    "marIN": "marIN",

    /**
     * value: "mgcME"
     * @const
     */
    "mgcME": "mgcME",

    /**
     * value: "mglME"
     * @const
     */
    "mglME": "mglME",

    /**
     * value: "mkdMK"
     * @const
     */
    "mkdMK": "mkdMK",

    /**
     * value: "mlgMG"
     * @const
     */
    "mlgMG": "mlgMG",

    /**
     * value: "mltMT"
     * @const
     */
    "mltMT": "mltMT",

    /**
     * value: "monMO"
     * @const
     */
    "monMO": "monMO",

    /**
     * value: "mriNZ"
     * @const
     */
    "mriNZ": "mriNZ",

    /**
     * value: "mymIN"
     * @const
     */
    "mymIN": "mymIN",

    /**
     * value: "nahMX"
     * @const
     */
    "nahMX": "nahMX",

    /**
     * value: "navUS"
     * @const
     */
    "navUS": "navUS",

    /**
     * value: "nepNE"
     * @const
     */
    "nepNE": "nepNE",

    /**
     * value: "nnoNO"
     * @const
     */
    "nnoNO": "nnoNO",

    /**
     * value: "norNO"
     * @const
     */
    "norNO": "norNO",

    /**
     * value: "nsoZA"
     * @const
     */
    "nsoZA": "nsoZA",

    /**
     * value: "oriIN"
     * @const
     */
    "oriIN": "oriIN",

    /**
     * value: "panIN"
     * @const
     */
    "panIN": "panIN",

    /**
     * value: "panPK"
     * @const
     */
    "panPK": "panPK",

    /**
     * value: "papNL"
     * @const
     */
    "papNL": "papNL",

    /**
     * value: "pinCN"
     * @const
     */
    "pinCN": "pinCN",

    /**
     * value: "polPL"
     * @const
     */
    "polPL": "polPL",

    /**
     * value: "porBR"
     * @const
     */
    "porBR": "porBR",

    /**
     * value: "porPT"
     * @const
     */
    "porPT": "porPT",

    /**
     * value: "prsAF"
     * @const
     */
    "prsAF": "prsAF",

    /**
     * value: "pusAF"
     * @const
     */
    "pusAF": "pusAF",

    /**
     * value: "qucGC"
     * @const
     */
    "qucGC": "qucGC",

    /**
     * value: "quePE"
     * @const
     */
    "quePE": "quePE",

    /**
     * value: "rumMD"
     * @const
     */
    "rumMD": "rumMD",

    /**
     * value: "rumRO"
     * @const
     */
    "rumRO": "rumRO",

    /**
     * value: "rusRU"
     * @const
     */
    "rusRU": "rusRU",

    /**
     * value: "sinCL"
     * @const
     */
    "sinCL": "sinCL",

    /**
     * value: "sloSK"
     * @const
     */
    "sloSK": "sloSK",

    /**
     * value: "slvSI"
     * @const
     */
    "slvSI": "slvSI",

    /**
     * value: "smnSM"
     * @const
     */
    "smnSM": "smnSM",

    /**
     * value: "sndPK"
     * @const
     */
    "sndPK": "sndPK",

    /**
     * value: "somSO"
     * @const
     */
    "somSO": "somSO",

    /**
     * value: "spaAR"
     * @const
     */
    "spaAR": "spaAR",

    /**
     * value: "spaCL"
     * @const
     */
    "spaCL": "spaCL",

    /**
     * value: "spaCO"
     * @const
     */
    "spaCO": "spaCO",

    /**
     * value: "spaES"
     * @const
     */
    "spaES": "spaES",

    /**
     * value: "spaLA"
     * @const
     */
    "spaLA": "spaLA",

    /**
     * value: "spaMX"
     * @const
     */
    "spaMX": "spaMX",

    /**
     * value: "spaNT"
     * @const
     */
    "spaNT": "spaNT",

    /**
     * value: "spaUS"
     * @const
     */
    "spaUS": "spaUS",

    /**
     * value: "srcBH"
     * @const
     */
    "srcBH": "srcBH",

    /**
     * value: "srcSR"
     * @const
     */
    "srcSR": "srcSR",

    /**
     * value: "srpSR"
     * @const
     */
    "srpSR": "srpSR",

    /**
     * value: "swaKE"
     * @const
     */
    "swaKE": "swaKE",

    /**
     * value: "swaTZ"
     * @const
     */
    "swaTZ": "swaTZ",

    /**
     * value: "sweSE"
     * @const
     */
    "sweSE": "sweSE",

    /**
     * value: "tamIN"
     * @const
     */
    "tamIN": "tamIN",

    /**
     * value: "tatRU"
     * @const
     */
    "tatRU": "tatRU",

    /**
     * value: "telIN"
     * @const
     */
    "telIN": "telIN",

    /**
     * value: "tetID"
     * @const
     */
    "tetID": "tetID",

    /**
     * value: "tgkTJ"
     * @const
     */
    "tgkTJ": "tgkTJ",

    /**
     * value: "tglRP"
     * @const
     */
    "tglRP": "tglRP",

    /**
     * value: "thaTH"
     * @const
     */
    "thaTH": "thaTH",

    /**
     * value: "tirET"
     * @const
     */
    "tirET": "tirET",

    /**
     * value: "tsnZA"
     * @const
     */
    "tsnZA": "tsnZA",

    /**
     * value: "tukTM"
     * @const
     */
    "tukTM": "tukTM",

    /**
     * value: "turTR"
     * @const
     */
    "turTR": "turTR",

    /**
     * value: "uigCN"
     * @const
     */
    "uigCN": "uigCN",

    /**
     * value: "ukrUA"
     * @const
     */
    "ukrUA": "ukrUA",

    /**
     * value: "urdUR"
     * @const
     */
    "urdUR": "urdUR",

    /**
     * value: "uzbUZ"
     * @const
     */
    "uzbUZ": "uzbUZ",

    /**
     * value: "valES"
     * @const
     */
    "valES": "valES",

    /**
     * value: "vieVI"
     * @const
     */
    "vieVI": "vieVI",

    /**
     * value: "vlsBE"
     * @const
     */
    "vlsBE": "vlsBE",

    /**
     * value: "wolWO"
     * @const
     */
    "wolWO": "wolWO",

    /**
     * value: "xhoZA"
     * @const
     */
    "xhoZA": "xhoZA",

    /**
     * value: "yorYO"
     * @const
     */
    "yorYO": "yorYO",

    /**
     * value: "zulZA"
     * @const
     */
    "zulZA": "zulZA"
};


/**
 * Allowed values for the <code>service</code> property.
 * @enum {String}
 * @readonly
 */
LocalizationRequestListItem['ServiceEnum'] = {

    /**
     * value: "lite"
     * @const
     */
    "lite": "lite",

    /**
     * value: "platinum"
     * @const
     */
    "platinum": "platinum"
};



export default LocalizationRequestListItem;

