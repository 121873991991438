import React, { useContext, useEffect } from 'react'
import { Route, Redirect } from "react-router-dom"

import { GlobalContext } from '../context/GlobalState'

import Loading from './loading/Loading'

export default function PrivateRoute({ children, ...rest }) {
  const { user, authenticating, getUser, termsNotAccepted, authFailed } = useContext(GlobalContext)

  useEffect(() => {
    getUser().catch(() => {})
    // eslint-disable-next-line
  }, [])

  if (user && termsNotAccepted && !user.isKWSPM) {
    return <Redirect to="/terms" />
  }

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return user
          ? (
            children
          )
          : authenticating
            ? <Loading />
            : <Redirect
              to={{
                pathname: "/login",
                state: {
                  unauthenticated: authFailed,
                  from: location
                }
              }}
            />
      }}
    />
  )
}
