import React, { useState, useContext, useEffect } from 'react'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import cN from 'classnames'

import { Col, Row, Form, Button, Toast, Image } from 'react-bootstrap'

import { GlobalContext } from '../../../context/GlobalState'
import InputForm from './input-form'
import UploadFileBtn from '../../UploadFileBtn'

import './styles.scss'


const AccountFormContent = () => {
    const { 
        user, uploadProfilePicture, updateUser
    } = useContext(GlobalContext)

    const [showError, setShowError] = useState(false)
    const [profilePictureUrl, setProfilePictureUrl] = useState(null)
    const [profilePicture, setProfilePicture] = useState(null)
    const [isUploading, setIsUploading] = useState(false)
    const [isWaiting, setIsWaiting] = useState(false)
    const [notificationEnabled, setNotificationEnabled] = useState(user.project_notifications_enabled)


    // FORM 
    const schema = yup.object()
    .shape({
        first_name: yup.string().trim()
            .required('First Name is required')
            // eslint-disable-next-line
            .max(35, 'Max number of characters: ${max}'),
        last_name: yup.string().trim()
            .required('Last Name is required')
            // eslint-disable-next-line
            .max(35, 'Max number of characters: ${max}'),
        email: yup.string().trim()
            .email('E-mail is invalid')
            .required('E-mail is required'),
    })

    const { 
        handleSubmit, 
        register, errors,
        // setError, 
        formState, 
        setValue, 
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
    })
    const canSubmit =   !isUploading && !isWaiting &&
                        (formState.isValid || profilePictureUrl) && 
                        !formState.isSubmitting

    //
    useEffect(() => {
        setValue('first_name', user.first_name)
        setValue('last_name', user.last_name)
        setValue('email', user.email)
        setNotificationEnabled(user.project_notifications_enabled)
        setProfilePicture(user.picture ? user.picture_download_link : null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])


    // EVENTS
    const onSubmit = (data) => {
        setIsWaiting(true)
        const changes = {
            ...data, 
            project_notifications_enabled: notificationEnabled,
            picture: profilePictureUrl
        }
        updateUser(user.id, changes).finally(() => setIsWaiting(false))
    }

    const onFileSelected = (data) => {
        setIsUploading(true)
        /** */
        uploadProfilePicture(data, data.name, user.id)
        .then((presignedUrl) => {
            // setIsUploading(false)
            const {fields} = presignedUrl
            const path = fields.key
            setProfilePictureUrl(path)

            const fr = new FileReader();
            fr.onload = function () {
                setProfilePicture(fr.result)
            }
            fr.readAsDataURL(data)
        })
        .catch(console.error)
        .finally(() => setIsUploading(false))
        /** */
    }


    return <Form    onSubmit={handleSubmit(onSubmit)}
                    className={cN({'has-error': showError})}>
        {/** ERRORS */}
        <Toast show={showError} onClose={() => setShowError(false)}>
            <Toast.Header>
                <strong className="mr-auto">
                    User Account update failed
                </strong>
            </Toast.Header>
        </Toast>

        <Row className='m-1'>
            <Col>
                {/** FIRST NAME */}
                <InputForm  label='First Name' 
                            register={register} 
                            errors={errors} 
                            propertyName='first_name'>
                    <input type="text" readOnly={!user?.isClientPM} />
                </InputForm>
                
                {/** LAST NAME */}
                <InputForm  label='Last Name' 
                            register={register} 
                            errors={errors} 
                            propertyName='last_name'>
                    <input type="text" readOnly={!user?.isClientPM} />
                </InputForm>

                {/** EMAIL */}
                <InputForm  label='Email' 
                            register={register} 
                            errors={errors} 
                            propertyName='email'>
                    <input type="text" readOnly={!user?.isClientPM} />
                </InputForm>
                
                {/* UPLOAD PHOTO */}
                <InputForm  label='Profile picture' 
                            register={register} 
                            errors={errors} 
                            propertyName='picture'>
                    <div className='profile-picture-wrapper'>
                        { profilePicture && <Image src={profilePicture}
                            alt="Profile picture" className="profile-picture-preview" />
                        }
                        <UploadFileBtn accept="image/*" maxSize={2}
                            uploading={isUploading}
                            onFilesDrop={(image) => onFileSelected(image, 'logo')}>
                            Upload Photo
                        </UploadFileBtn>
                    </div>
                </InputForm>

                {/* MAIL NOTIFICATION */}
                <div className="checkbox-wrapper">
                    <input  type="checkbox" 
                            checked={notificationEnabled} 
                            onChange={e => setNotificationEnabled(e.target.checked)}
                    />
                    <span>Email notifications</span>
                </div>
            </Col>
        </Row>
        <Row className='m-1'>
            <Col id='footer-col'>
                {/** ACTIONS */}
                <Button type="submit"
                        disabled={!canSubmit}
                        variant="primary"
                        id="submit-btn">
                    Save
                </Button>
            </Col>

            <p className='m-3'>onek by keywords studio, version 1.5</p>
        </Row>
    </Form>
}

export default AccountFormContent