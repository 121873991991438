import React from 'react'
import './lp-header-col.scss'

import cN from 'classnames'

const LPHeaderCol = (props) => {
  const isAsc = props.sortElement &&
    props.sortElement.props.order === 'asc'
  return <div className="lp-header-col">
    { props.icon &&
      <i className={props.icon} />
    }
    {props.text}
    { props.sortElement &&
      <span className="sort-carets">
        <i className={cN('onek-caret-up', {
          'active': isAsc
        })} />
        <i className={cN('onek-caret-down', {
          'active': props.sortElement.props.order && !isAsc
        })} />
      </span>
    }
  </div>
}

export default LPHeaderCol

