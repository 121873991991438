import { parseUser, uploadImage } from '../../utils'


export const updateUser = (UsersAPI, refreshTokenPipe) => {
    const foo = (id, changes) => new Promise((resolve, reject) => {
        UsersAPI.usersPartialUpdate(id, changes, (err, data) => {
            refreshTokenPipe(err)
            .then((reAuthenticated) => {
                if (reAuthenticated) {
                    foo(id, changes)
                    .then(resolve, reject)
                    return
                } 
                
                if (err) {
                    reject(err)
                    return
                }
                resolve(parseUser({...data}))
            })
        })
        /** */
    })
   
    return foo
}

export const uploadProfilePicture = (UsersAPI) => {
    const foo = (file, name, id) => new Promise((resolve, reject) => {
        getUserPictureUrl(UsersAPI)(id, name)
        .then(presignedUrl => {
            if (!presignedUrl.url) {
                reject({ 
                    response: { 
                        text: 'No filename found in presigned URL' 
                    } 
                })
            }
    
            // Upload file
            uploadImage(presignedUrl, file)
                .then(() => resolve(presignedUrl))
                .catch(reject)
        })
        .catch(reject)
    })
   
    return foo
}

export const getUserPictureUrl = (UsersAPI) => {
    const foo = (id, name) => new Promise((resolve, reject) => {
        UsersAPI.usersPictureRead(name, id, (err, presignedUrl) => {
            if (err) {
                reject(err)
                return
            }

            resolve(presignedUrl)
        })
    })

    return foo
}

export const getUser = (UsersAPI, refreshTokenPipe, getTokens, logout, getMemoqProjects, state, dispatch) => { 
    const foo = () => {
        if (state.user) {
            return Promise.resolve(state.user)
        } else if (state.authenticating && state.userPromise) {
            return state.userPromise
        }

        const tokens = state.tokens || getTokens()

        if (tokens) {
            const promise = new Promise((resolve, reject) => {
                UsersAPI.usersRead('me', (err, data) => {
                refreshTokenPipe(err)
                    .then((reAuthenticated) => {
                        if (reAuthenticated) {
                            foo()
                        } else {
                            if (err) {
                                logout()
                                reject(err)
                            } else {

                                const user = parseUser(data)
                                if (!user.client) {
                                    user.client = {}
                                }

                                dispatch({
                                    type: 'SET_USER',
                                    payload: user
                                })

                                // Init after auth
                                if (!user?.isKWSPM) {
                                    getMemoqProjects(user?.client?.id)
                                }

                                resolve(user)
                            }
                        }
                    })
                    .catch(reject)
                })
            })

            dispatch({
                type: 'AUTHENTICATING',
                payload: promise
            })

            return promise
        }

        logout()

        return Promise.reject(new Error('Unauthenticated'))
    }

    return foo
}