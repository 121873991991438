import React, {useState, useEffect, useContext} from 'react'
import { useParams } from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next'
import { Row, Col, Container } from 'react-bootstrap'

import ClientBanner from '../client-banner'
import EntityNotFound from '../entity-not-found/EntityNotFound'
import Loading from '../loading/Loading'
import LPDateTime from '../lp-datetime'

import columns from './columns'
import { GlobalContext } from '../../context/GlobalState'

import './style.scss'


export const ArchivedRequests = (props) => {
    const { clientId, projectId } = useParams()
    const [loading, setLoading] = useState(false)
    const [project, setProject] = useState(false)
    const [pageSize, setPageSize] = useState(50)
    
    const { 
        getArchivedRequests, archivedRequests, archivedRequestsCount, 
        getArchivedProjectById
    } = useContext(GlobalContext)

    useEffect(() => {
        if (!projectId || !clientId) { return }

        if (!loading && !project) {
            setLoading(true)
            getArchivedProjectById(projectId, clientId)
            .then(data => setProject(data))
        }

        if (!loading && !archivedRequestsCount) {
            setLoading(true)
            getArchivedRequests(projectId, clientId)
        }

        if (loading && archivedRequestsCount && project) {
            setLoading(false)
        }
    // eslint-disable-next-line
    }, [projectId, clientId, project, archivedRequests])


    // TABLE OPTIONS 
    const handleTableChange = (type, { 
        // page, sortField, sortOrder, 
        sizePerPage 
    }) => {
    if (type === 'pagination' || type === 'sort') {
          if (pageSize !== sizePerPage) {
            setPageSize(sizePerPage)
          }
      }
    }

    const data = archivedRequests
    const remote = { 
        filter: false, //!!archivedRequestsCount,// && !loading, 
        pagination: false, //!!archivedRequestsCount// && !loading
    }
    const defaultOrder = [{
        dataField: 'id',
        order: 'desc'
    }]
    const NoData = () => {
        if (loading) {
            return <Loading />
        }

        return <EntityNotFound entityName="Requests Archived" />
    }

    return <Container fluid>
        <ClientBanner bannerFooter={
            <ArchivedProjectDetails project={project} />
        } />
        <BootstrapTable id="archived-request-table"
            remote={ remote }
            keyField='id'
            data={ data || [] }
            columns={ columns }
            defaultSorted={ defaultOrder }
            defaultSortDirection={'asc'}
            onTableChange={ handleTableChange }
            // pagination={ pagination }
            noDataIndication={<NoData />}
            bootstrap4
            striped
            hover
            wrapperClasses="table-responsive"
            classes="table-fixed" 
        />
    </Container>
}

export default ArchivedRequests


function ArchivedProjectDetails (props) {
    const {project} = props

    if (!project) {
        return <Col>Loading...</Col>
    }

    // creator, start date, close date, number of LR processed)
    const {created_by = {}, created_at, closed_at, lr_complete_count} = project

    return <>
        <Col>
            <Row>
                <span className='detail-proj-label'>CREATED BY:</span> 
                {created_by.username.toUpperCase()}
            </Row>
            <Row>
                <span className='detail-proj-label'>PROCESSED LR:</span>
                {lr_complete_count}
            </Row>
        </Col>
        <Col>
            <Row>
                <span className='detail-proj-label'>START DATE:</span>
                <LPDateTime timestamp={created_at} />
            </Row>
            <Row>
                <span className='detail-proj-label'>CLOSE DATE:</span>
                <LPDateTime timestamp={closed_at} />
            </Row>
        </Col>
    </>
}