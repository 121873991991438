/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The StatisticsTotalProjects model module.
 * @module model/StatisticsTotalProjects
 * @version v1
 */
class StatisticsTotalProjects {
    /**
     * Constructs a new <code>StatisticsTotalProjects</code>.
     * @alias module:model/StatisticsTotalProjects
     * @param stateNew {Number} 
     * @param stateOngoing {Number} 
     * @param stateClosed {Number} 
     */
    constructor(stateNew, stateOngoing, stateClosed) { 
        
        StatisticsTotalProjects.initialize(this, stateNew, stateOngoing, stateClosed);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, stateNew, stateOngoing, stateClosed) { 
        obj['state_new'] = stateNew;
        obj['state_ongoing'] = stateOngoing;
        obj['state_closed'] = stateClosed;
    }

    /**
     * Constructs a <code>StatisticsTotalProjects</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/StatisticsTotalProjects} obj Optional instance to populate.
     * @return {module:model/StatisticsTotalProjects} The populated <code>StatisticsTotalProjects</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new StatisticsTotalProjects();

            if (data.hasOwnProperty('state_new')) {
                obj['state_new'] = ApiClient.convertToType(data['state_new'], 'Number');
            }
            if (data.hasOwnProperty('state_ongoing')) {
                obj['state_ongoing'] = ApiClient.convertToType(data['state_ongoing'], 'Number');
            }
            if (data.hasOwnProperty('state_closed')) {
                obj['state_closed'] = ApiClient.convertToType(data['state_closed'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} state_new
 */
StatisticsTotalProjects.prototype['state_new'] = undefined;

/**
 * @member {Number} state_ongoing
 */
StatisticsTotalProjects.prototype['state_ongoing'] = undefined;

/**
 * @member {Number} state_closed
 */
StatisticsTotalProjects.prototype['state_closed'] = undefined;






export default StatisticsTotalProjects;

