/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import MultilingualOption from './MultilingualOption';

/**
 * The LocalizationRequestFile model module.
 * @module model/LocalizationRequestFile
 * @version v1
 */
class LocalizationRequestFile {
    /**
     * Constructs a new <code>LocalizationRequestFile</code>.
     * @alias module:model/LocalizationRequestFile
     * @param id {Number} 
     * @param name {String} 
     * @param fileType {module:model/LocalizationRequestFile.FileTypeEnum} 
     * @param size {Number} 
     */
    constructor(id, name, fileType, size) { 
        
        LocalizationRequestFile.initialize(this, id, name, fileType, size);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, name, fileType, size) { 
        obj['id'] = id;
        obj['name'] = name;
        obj['file_type'] = fileType;
        obj['size'] = size;
    }

    /**
     * Constructs a <code>LocalizationRequestFile</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/LocalizationRequestFile} obj Optional instance to populate.
     * @return {module:model/LocalizationRequestFile} The populated <code>LocalizationRequestFile</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LocalizationRequestFile();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('file_type')) {
                obj['file_type'] = ApiClient.convertToType(data['file_type'], 'String');
            }
            if (data.hasOwnProperty('format')) {
                obj['format'] = ApiClient.convertToType(data['format'], 'String');
            }
            if (data.hasOwnProperty('size')) {
                obj['size'] = ApiClient.convertToType(data['size'], 'Number');
            }
            if (data.hasOwnProperty('error')) {
                obj['error'] = ApiClient.convertToType(data['error'], 'String');
            }
            if (data.hasOwnProperty('multilingual')) {
                obj['multilingual'] = MultilingualOption.constructFromObject(data['multilingual']);
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
LocalizationRequestFile.prototype['id'] = undefined;

/**
 * @member {String} name
 */
LocalizationRequestFile.prototype['name'] = undefined;

/**
 * @member {module:model/LocalizationRequestFile.FileTypeEnum} file_type
 */
LocalizationRequestFile.prototype['file_type'] = undefined;

/**
 * @member {String} format
 */
LocalizationRequestFile.prototype['format'] = undefined;

/**
 * @member {Number} size
 */
LocalizationRequestFile.prototype['size'] = undefined;

/**
 * @member {String} error
 */
LocalizationRequestFile.prototype['error'] = undefined;

/**
 * @member {module:model/MultilingualOption} multilingual
 */
LocalizationRequestFile.prototype['multilingual'] = undefined;

/**
 * @member {String} url
 */
LocalizationRequestFile.prototype['url'] = undefined;





/**
 * Allowed values for the <code>file_type</code> property.
 * @enum {String}
 * @readonly
 */
LocalizationRequestFile['FileTypeEnum'] = {

    /**
     * value: "source"
     * @const
     */
    "source": "source",

    /**
     * value: "reference"
     * @const
     */
    "reference": "reference",

    /**
     * value: "zipped_uploads"
     * @const
     */
    "zipped_uploads": "zipped_uploads",

    /**
     * value: "localized"
     * @const
     */
    "localized": "localized"
};



export default LocalizationRequestFile;

