import React, { useState, useContext, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next'
import { Container } from 'react-bootstrap'

import ClientBanner from '../client-banner'
import Loading from '../loading/Loading'
import EntityNotFound from '../entity-not-found/EntityNotFound'

import { GlobalContext } from '../../context/GlobalState'
// import { processPagination } from './table-options'
import columns from './columns'
import './styles.scss'


const ArchivedTable = (props) => {
  const history = useHistory()
  const { clientId } = useParams()
  const { 
    getArchivedProjects, archivedProjects, archivedProjectsNotFound, // archivedProjectsCount, 
    archivedProjectHasPages, currentClient
   } = useContext(GlobalContext)

  // const [loading, setLoading] = useState(false)
  const [pageSize, setPageSize] = useState(50)


  // FETCH
  useEffect(() => {
    getArchivedProjects(clientId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient])


  // TABLE OPTIONS
  const data = archivedProjects // loading ? [] : archivedProjects
  // const pagination = processPagination(archivedProjectHasPages, pageSize, archivedProjectsCount) 

  const remote = { 
    filter: !!archivedProjectHasPages, 
    pagination: !!archivedProjectHasPages 
  }

  const defaultOrder = [{
    dataField: 'name',
    order: 'asc'
  }]

  const NoData = () => {
    return archivedProjectsNotFound
      ? <EntityNotFound entityName="Projects Archived" />
      : <Loading />
  } 
  //
  

  // TABLE EVENTS
  const handleTableChange = (type, { page, sortField, sortOrder, sizePerPage }) => {
    if (type === 'pagination' || type === 'sort') {
      // const ordering = sortOrder === 'asc'
      //  ? sortField
      //  : '-' + sortField
        /*
        getProjectsPage({
          page,
          ordering,
          size: sizePerPage
        })
        /** */

        if (pageSize !== sizePerPage) {
          setPageSize(sizePerPage)
        }
    }
  }

  /*
  const getProjectsPage = (opts) => {
    setLoading(true)
    return getArchivedProjects(clientId, opts)
      .finally(() => setLoading(false))
  }
  /** */
  //  
  
  
  // CUSTOM EVENTS
  const selectProject = (e, project) => {
    history.push('/client/' + clientId + '/archived/' + project.id)
  }
  //

  // 
  return <Container fluid>
    <ClientBanner clientNameSuffix='archived' />
    <BootstrapTable id='archived-table'
      remote={ remote }
      keyField='id'
      data={ data || [] }
      columns={ columns(selectProject) }
      defaultSorted={ defaultOrder }
      defaultSortDirection={'asc'}
      // pagination={ pagination }
      onTableChange={ handleTableChange }
      noDataIndication={<NoData />}
      bootstrap4
      striped
      hover
      wrapperClasses="table-responsive"
      classes="table-fixed" 
    />
  </Container>
}

export default ArchivedTable