import React, {useEffect, useState} from 'react'

import {sizeLabel} from '../utils'

const group = fileMap => {
    const fileGrouped = []

    const fileTypes = Object.keys(fileMap)
    fileTypes.forEach(type => {
        const {maxSize, maxFiles} = fileMap[type]
        const i = fileGrouped.findIndex((grouped) => {
            const {maxSize: size, maxFiles: count} = grouped || {}
            return maxSize === size && count === maxFiles
        })

        // type of file not yet grouped
        if (i < 0) {
            fileGrouped.push({
                type,
                maxSize,
                maxFiles
            })
        } else {
            // add to the group
            fileGrouped[i].type = fileGrouped[i].type + ', ' + type
        }
    })

    return fileGrouped
}
//

export default function FilesLabels (props) {
    const [groupedFiles, setGroupedFiles] = useState([])
    const {fileOptions} = props

    useEffect(() => {
        setGroupedFiles(group(fileOptions))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return groupedFiles.map(({type, maxSize, maxFiles}) => <span key={type}>
        {type} : Max size per file: {sizeLabel(maxSize)}, Max files: {maxFiles}
    </span>)
}