/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ClientStatus model module.
 * @module model/ClientStatus
 * @version v1
 */
class ClientStatus {
    /**
     * Constructs a new <code>ClientStatus</code>.
     * @alias module:model/ClientStatus
     * @param id {Number} 
     * @param name {String} 
     * @param projectsActive {Number} 
     * @param projectsClosed {Number} 
     * @param email {String} email of admin
     * @param studioId {String} 
     */
    constructor(id, name, projectsActive, projectsClosed, email, studioId) { 
        
        ClientStatus.initialize(this, id, name, projectsActive, projectsClosed, email, studioId);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, name, projectsActive, projectsClosed, email, studioId) { 
        obj['id'] = id;
        obj['name'] = name;
        obj['projects_active'] = projectsActive;
        obj['projects_closed'] = projectsClosed;
        obj['email'] = email;
        obj['studio_id'] = studioId;
    }

    /**
     * Constructs a <code>ClientStatus</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ClientStatus} obj Optional instance to populate.
     * @return {module:model/ClientStatus} The populated <code>ClientStatus</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ClientStatus();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('projects_active')) {
                obj['projects_active'] = ApiClient.convertToType(data['projects_active'], 'Number');
            }
            if (data.hasOwnProperty('projects_closed')) {
                obj['projects_closed'] = ApiClient.convertToType(data['projects_closed'], 'Number');
            }
            if (data.hasOwnProperty('admin')) {
                obj['admin'] = ApiClient.convertToType(data['admin'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('logo')) {
                obj['logo'] = ApiClient.convertToType(data['logo'], 'String');
            }
            if (data.hasOwnProperty('studio_id')) {
                obj['studio_id'] = ApiClient.convertToType(data['studio_id'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
ClientStatus.prototype['id'] = undefined;

/**
 * @member {String} name
 */
ClientStatus.prototype['name'] = undefined;

/**
 * @member {Number} projects_active
 */
ClientStatus.prototype['projects_active'] = undefined;

/**
 * @member {Number} projects_closed
 */
ClientStatus.prototype['projects_closed'] = undefined;

/**
 * @member {String} admin
 */
ClientStatus.prototype['admin'] = undefined;

/**
 * email of admin
 * @member {String} email
 */
ClientStatus.prototype['email'] = undefined;

/**
 * @member {String} logo
 */
ClientStatus.prototype['logo'] = undefined;

/**
 * @member {String} studio_id
 */
ClientStatus.prototype['studio_id'] = undefined;






export default ClientStatus;

