export const createMenuItems = [{
    id: 'client',
    label: 'New Client',
}, {
    id: 'project',
    label: 'New Project',
}, {
    id: 'request',
    label: 'New Request'
}]


//
// /project/{projectId} && /project/{projectId}/*
export const isProjectPage  = pathComponents => pathComponents[0] === 'project'
// /client/{clientId} && /client/{clientId}/*
export const isClientPage   = pathComponents => pathComponents[0] === 'client'
// /client/{clientId}/archived
export const isArchivedPage = pathComponents => pathComponents[2] === 'archived'
