import {dateLabel} from './utils'

export const parseGeneralInfo = (languageHistory, request) => {
    const {
        draft_created_at = '', 
        project_name = '',
        started_at = '', 
        started_by = '',
    } = languageHistory

    const {
        number = '', 
        service = '', 
        target_language = ''
    } = request

    // 1: General info
    const ProjectRow = 'Project: ' + project_name
    const LRNameRow = number
    const serviceRow = 'Service: ' + service
    const languageRow = 'Target language: ' + target_language 
    const draftCreated = 'Draft: ' + dateLabel(draft_created_at)
    const draftStarted = 'Start: ' + dateLabel(started_at)
    const startedRow = 'Started by: ' + started_by

    return [ ProjectRow, LRNameRow, serviceRow, languageRow, draftCreated, draftStarted, startedRow ].join('\n')
}