import {
  setArchivedProjects, archivedProjectActionName,
  setArchivedRequests, archivedRequestsActionName
} from './chunks/archived'


export default (state, action) => {
  switch(action.type) {
    case 'ACCEPT_TERMS':
      return {
        ...state,
        termsNotAccepted: false
      }
    case 'AUTHENTICATING':
      return {
        ...state,
        authenticating: true,
        authFailed: false,
        loggedOut: false,
        userPromise: action.payload
      }
    case 'SET_TOKENS':
      return {
        ...state,
        tokens: action.payload
      }
    case 'REFRESH_TOKEN':
      return {
        ...state,
        tokens: {
          ...state.tokens,
          access: action.payload.access
        }
      }
    case 'LOGOUT':
      return {
        ...action.payload.state,
        loggedOut: action.payload.loggedOut,
        authenticating: false,
        user: null
      }
    case 'SET_USER':
      return {
        ...state,
        authenticating: false,
        userPromise: null,
        user: action.payload
      }
    case 'AUTH_FAILED':
      return {
        ...state,
        authenticating: false,
        userPromise: null,
        authFailed: true
      }
    case 'SET_PROJECTS':
      return {
        ...state,
        projects: action.payload.results || [],
        projectsHasPages: !!action.payload.next || !!action.payload.previous,
        projectsCount: action.payload.count,
        projectsNotFound: false
      }
    case 'SET_REQUESTS':
      return {
        ...state,
        requests: action.payload.results || [],
        requestsHasPages: state.requestsHasPages ||
          !!action.payload.next || !!action.payload.previous,
        requestsCount: action.payload.count,
        requestsNotFound: !action.payload.count
      }
    case 'CLEAR_REQUESTS':
      return {
        ...state,
        requests: [],
        requestsHasPages: null,
        requestsCount: null,
        requestsNotFound: null
      }
    case 'PROJECTS_NOT_FOUND':
      return {
        ...state,
        projects: [],
        projectsCount: 0,
        projectsNotFound: true
      }
    case 'ADD_PROJECT':
      return {
        ...state,
        projects: [
          action.payload,
          ...state.projects
        ],
        projectsCount: state.projectsCount
          ? ++state.projectsCount
          : 1,
        projectsNotFound: false
      }
    case 'UPDATE_PROJECT':
      return {
        ...state,
        projects: state.projects.map((project) => {
          if (project.id === action.payload.id) {
            return action.payload
          }

          return project
        })
      }
    case 'REMOVE_PROJECT':
      return {
        ...state,
        projects: state.projects
          .filter(project => project.id !== action.payload),
        projectsCount: --state.projectsCount,
        projectsNotFound: state.projectsCount < 2,
      }
    case 'RESET_PROJECTS_LIST':
      return {
        ...state,
        projects: [],
        projectsCount: 0,
        projectsNotFound: false,
        memoqProjectsNotFound: false,
        memoqProjects: [],
        bpsProjectsNotFound: false,
        bpsProjects: []
      }
    case 'ADD_REQUEST':
      return {
        ...state,
        requests: [
          action.payload,
          ...state.requests
        ],
        requestsCount: state.requestsCount
          ? ++state.requestsCount
          : 1,
        requestsNotFound: false
      }
    case 'UPDATE_REQUEST_STATUS':
      return {
        ...state,
        requests: state.requests.map((req) => {
          if (req.id === action.payload.id) {
            return {
              ...req,
              state: action.payload.newStatus
            }
          }

          return req
        })
      }
    case 'UPDATE_REQUEST':
      const { request, projectId } = action.payload
      const oldState = state.requests
        .find((req) => req.id === request.id)
        .state
      const updateCounter = oldState !== request.state

      return {
        ...state,
        requests: state.requests.map((req) => {
          if (req.id === request.id) {
            return request
          }

          return req
        }),
        projects: updateCounter
          ? state.projects.map((project) => {
            if (project.id === parseInt(projectId)) {
              return {
                ...project,
                ongoing_count: state.requests.reduce(function(acc, req) {
                  return req.state === 'ongoing' ? acc + 1 : acc;
                }, 0)
              }
            }

            return project
          })
          : state.projects
      }
    case 'DELETE_REQUEST':
      return {
        ...state,
        requests: state.requests
          .filter(req => req.id !== action.payload),
        requestsCount: --state.requestsCount,
        requestsNotFound: state.requestsCount < 2
      }
    case 'SET_MEMOQ_PROJECTS':
      return {
        ...state,
        memoqProjects: action.payload || [],
        memoqProjectsNotFound: !action.payload?.length
      }
    case 'SET_BPS_PROJECTS':
      return {
        ...state,
        bpsProjects: action.payload || [],
        bpsProjectsNotFound: !action.payload?.length
      }
    case 'OPEN_TERMS':
      return {
        ...state,
        reviewingTerms: true
      }
    case 'CLOSE_TERMS':
      return {
        ...state,
        reviewingTerms: false
      }
    case 'SET_CLIENTS':
      return {
        ...state,
        clients: action.payload || [],
        clientsHasPages: !!action.payload.next || !!action.payload.previous,
        clientsCount: action.payload?.length || 0,
        clientsNotFound: !action.payload?.length
      }
    case 'ADD_CLIENT':
      return {
        ...state,
        clients: [
          action.payload,
          ...state.clients
        ],
        clientsCount: ++state.clientsCount,
        clientsNotFound: false
      }
    case 'UPDATE_CLIENT':
      return {
        ...state,
        clients: state.clients.map((client) => {
          if (client.id === action.payload.id) {
            return action.payload
          }

          return client
        })
      }

    // MARK: - Archived projects
    case archivedProjectActionName:
      return setArchivedProjects(state, action)
    case archivedRequestsActionName:
      return setArchivedRequests(state, action)
    //

    case 'SET_LETTERS':
      return {
        ...state,
        lettersGroups: action.payload
      }

    // UI POP UP STATES
    case 'TOGGLE_MODAL':
      const newIsOpen = action.payload || !state.newIsOpen
      return {
        ...state,
        newIsOpen
      }
    case 'TOGGLE_REQUEST_NEW':
      const requestNewIsOpen = action.payload || !state.requestNewIsOpen
      return {
        ...state,
        requestNewIsOpen
      }
    case 'TOGGLE_PROJECT_NEW':
      const projectNewIsOpen = action.payload || !state.projectNewIsOpen
      return {
        ...state,
        projectNewIsOpen
      }
    case 'TOGGLE_CLIENT_NEW':
      const clientNewIsOpen = action.payload || !state.clientNewIsOpen
      return {
        ...state,
        clientNewIsOpen
      }
    case 'TOGGLE_PROJECT_EDIT':
      const project = action.payload
      return {
        ...state,
        projectEditIsOpen: !!project,
        projectToEdit : project
      }
    case 'TOGGLE_CLIENT_EDIT':
      const client = action.payload
      return {
        ...state,
        clientEditIsOpen: !!client,
        clientToEdit : client
      }
    case 'TOGGLE_USER_EDIT':
      const userFormIsOpen = !!action.payload
      return {
        ...state,
        userFormIsOpen
      }

    // APP STATE FOR DETAILS ETC...
    case 'SET_CURRENT_CLIENT':
      const currentClient = action.payload
      return {
        ...state,
        currentClient
      }

    case 'SET_CURRENT_PROJECT':
      const currentProject = action.payload
      return {
        ...state,
        currentProject
      }

    case 'SET_CURRENT_REQUEST':
      const currentRequest = action.payload
      return {
        ...state,
        currentRequest
      }
    case 'SET_LOADING_PROJECT':
      const loadingProject = !!action.payload
      return  {
        ...state,
        loadingProject
      }
    case 'SET_LOADING_CLIENT':
      const loadingClient = !!action.payload
      return  {
        ...state,
        loadingClient
      }

    case 'SET_PROJECT_IS_SYNCING':
      const projectIsSyncing = !!action.payload
      return {
        ...state,
        projectIsSyncing
      }
    case 'SET_RESOURCES_IS_SYNCING':
      const updatingResources = action.payload
      return {
        ...state,
        updatingResources
      }
    case 'SET_RESOURCES_CHANGES':
      if (!action.payload) {
        return {
          ...state,
          resourceChanges: {}
        }
      }
      
      const {id, value} = action.payload
      const {resourceChanges} = state
      
      if (value) {
        resourceChanges[id] = value
      } else {
        delete resourceChanges[id]
      }

      return {
        ...state,
        resourceChanges
      }
    case 'SET_CLIENT_ACTIVE_USERS_COUNT':
      const activeProjectUsersCount = action.payload
      return {
        ...state,
        activeProjectUsersCount
      }
    default:
      return state
  }
}