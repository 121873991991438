export const gotoMenuItems = [{
    id: 'statistics',
    label: 'Statistics',
    link: '/dashboard/stats',
}, {
    id: 'client-list',
    label: 'Client List',
    link: '/dashboard',
}, {
    id: 'project-list',
    label: 'Open Projects'
}, {
    id: 'project-archived',
    label: 'Archived Projects'
}]


// /project/{projectId} && /project/{projectId}/*
export const isProjectPage  = pathComponents => pathComponents[0] === 'project'
// /client/{clientId} && /client/{clientId}/*
export const isClientPage   = pathComponents => pathComponents[0] === 'client'
// /client/{clientId}/archived
export const isArchivedPage = pathComponents => pathComponents[2] === 'archived'
// /dashboard/stats
export const isStatPage     = pathComponents => pathComponents[1] === 'stats'