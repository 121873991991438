import React, { useContext, useState } from 'react'
import './projects-table.scss'

import { useHistory } from 'react-router-dom'

import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'

import { GlobalContext } from '../../context/GlobalState'

import Loading from '../loading/Loading'
import EntityNotFound from '../entity-not-found/EntityNotFound'
import LPHeaderCol from '../lp-header-col/LPHeaderCol'
import LPDateTime from '../lp-datetime';

const ProjectsTable = (props) => {
  const history = useHistory()
  const { projects, projectsHasPages, projectsCount, getProjects, projectsNotFound } = useContext(GlobalContext)
  const [loading, setLoading] = useState(false)

  const pagination = projectsHasPages
    ? paginationFactory({
      sizePerPage: 50,
      totalSize: projectsCount,
      hideSizePerPage: true,
      showTotal: true,
      disablePageTitle: true
    })
    : null

  const columns = [{
    dataField: 'name',
    text: 'Projects',
    sort: true,
    classes: 'ellipsis',
    headerFormatter: (column, _i, { sortElement }) => {
      return <LPHeaderCol
        icon="onek-projects"
        text={column.text}
        sortElement={sortElement} />
    }
  }, {
    dataField: 'state',
    text: 'Project Status',
    sort: true,
    formatter: (cell) => cell.toUpperCase(),
    headerClasses: 'status',
    headerFormatter: (column, _i, { sortElement }) => {
      return <LPHeaderCol
        icon="onek-status"
        text={column.text}
        sortElement={sortElement} />
    }
  }, {
    dataField: 'ongoing_count',
    text: 'Active Requests',
    classes: 'text-center',
    headerClasses: 'active',
    headerFormatter: (column) => {
      return <LPHeaderCol
        icon="onek-active-requests"
        text={column.text} />
    }
  }, {
    dataField: 'delivered_count',
    text: 'Complete Requests',
    classes: 'text-center',
    headerClasses: 'complete',
    headerFormatter: (column) => {
      return <LPHeaderCol
        icon="onek-complete-requests"
        text={column.text} />
    }
  }, {
    dataField: 'created_at',
    text: 'Created',
    sort: true,
    formatter: (cell) => cell &&
      <LPDateTime timestamp={cell} />,
    headerClasses: 'datetime',
    headerFormatter: (column, _i, { sortElement }) => {
      return <LPHeaderCol
        icon="onek-created"
        text={column.text}
        sortElement={sortElement} />
    }
  }, {
    dataField: 'updated_at',
    text: 'Updated',
    sort: true,
    formatter: (cell) => cell &&
      <LPDateTime timestamp={cell} />,
    headerClasses: 'datetime',
    headerFormatter: (column, _i, { sortElement }) => {
      return <LPHeaderCol
        icon="onek-update"
        text={column.text}
        sortElement={sortElement} />
    }
  }]

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === 'pagination') {
      getProjectsPage(page)
    } else if (type === 'sort') {
      const ordering = sortOrder === 'asc'
        ? sortField
        : '-' + sortField
        getProjectsPage(null, ordering)
    }
  }

  const getProjectsPage = (page, ordering) => {
    setLoading(true)
      getProjects(props.clientId, page, ordering).then(() => {
        setLoading(false)
      }, () => {
        setLoading(false)
      })
  }

  const rowEvents = {
    onClick: (_e, project) => {
      history.push('/project/' + project.id)
    }
  };

  return (
    <div className="ResourceTable">
      <BootstrapTable
        id="projects-table"
        remote={{ filter: !!projectsHasPages, pagination: !!projectsHasPages }}
        keyField='id'
        data={ loading ? [] : projects }
        columns={ columns }
        defaultSorted={ [{
          dataField: 'created_at',
          order: 'desc'
        }] }
        defaultSortDirection={'asc'}
        pagination={ pagination }
        onTableChange={ handleTableChange }
        noDataIndication={ () => {
          return projectsNotFound
            ? <EntityNotFound entityName="Projects" />
            : <Loading />
        } }
        rowEvents={ rowEvents }
        rowClasses="clickable project-row"
        bootstrap4
        striped
        hover
        wrapperClasses="table-responsive"
        classes="table-fixed" />
    </div>
  )
}

export default ProjectsTable
