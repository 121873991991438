/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The LocalizationRequestContact model module.
 * @module model/LocalizationRequestContact
 * @version v1
 */
class LocalizationRequestContact {
    /**
     * Constructs a new <code>LocalizationRequestContact</code>.
     * @alias module:model/LocalizationRequestContact
     * @param subject {String} 
     * @param body {String} 
     */
    constructor(subject, body) { 
        
        LocalizationRequestContact.initialize(this, subject, body);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, subject, body) { 
        obj['subject'] = subject;
        obj['body'] = body;
    }

    /**
     * Constructs a <code>LocalizationRequestContact</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/LocalizationRequestContact} obj Optional instance to populate.
     * @return {module:model/LocalizationRequestContact} The populated <code>LocalizationRequestContact</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LocalizationRequestContact();

            if (data.hasOwnProperty('subject')) {
                obj['subject'] = ApiClient.convertToType(data['subject'], 'String');
            }
            if (data.hasOwnProperty('body')) {
                obj['body'] = ApiClient.convertToType(data['body'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} subject
 */
LocalizationRequestContact.prototype['subject'] = undefined;

/**
 * @member {String} body
 */
LocalizationRequestContact.prototype['body'] = undefined;






export default LocalizationRequestContact;

