/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import UserWithoutClient from './UserWithoutClient';

/**
 * The ProjectArchived model module.
 * @module model/ProjectArchived
 * @version v1
 */
class ProjectArchived {
    /**
     * Constructs a new <code>ProjectArchived</code>.
     * @alias module:model/ProjectArchived
     * @param id {Number} 
     * @param originalProjectId {Number} 
     * @param name {String} 
     * @param lrCompleteCount {Number} 
     * @param createdAt {Date} 
     * @param closedAt {Date} 
     * @param clientId {Number} 
     * @param createdBy {module:model/UserWithoutClient} 
     */
    constructor(id, originalProjectId, name, lrCompleteCount, createdAt, closedAt, clientId, createdBy) { 
        
        ProjectArchived.initialize(this, id, originalProjectId, name, lrCompleteCount, createdAt, closedAt, clientId, createdBy);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, originalProjectId, name, lrCompleteCount, createdAt, closedAt, clientId, createdBy) { 
        obj['id'] = id;
        obj['original_project_id'] = originalProjectId;
        obj['name'] = name;
        obj['lr_complete_count'] = lrCompleteCount;
        obj['created_at'] = createdAt;
        obj['closed_at'] = closedAt;
        obj['client_id'] = clientId;
        obj['created_by'] = createdBy;
    }

    /**
     * Constructs a <code>ProjectArchived</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProjectArchived} obj Optional instance to populate.
     * @return {module:model/ProjectArchived} The populated <code>ProjectArchived</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProjectArchived();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('original_project_id')) {
                obj['original_project_id'] = ApiClient.convertToType(data['original_project_id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('state')) {
                obj['state'] = ApiClient.convertToType(data['state'], 'String');
            }
            if (data.hasOwnProperty('lr_complete_count')) {
                obj['lr_complete_count'] = ApiClient.convertToType(data['lr_complete_count'], 'Number');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('closed_at')) {
                obj['closed_at'] = ApiClient.convertToType(data['closed_at'], 'Date');
            }
            if (data.hasOwnProperty('client_id')) {
                obj['client_id'] = ApiClient.convertToType(data['client_id'], 'Number');
            }
            if (data.hasOwnProperty('created_by')) {
                obj['created_by'] = UserWithoutClient.constructFromObject(data['created_by']);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
ProjectArchived.prototype['id'] = undefined;

/**
 * @member {Number} original_project_id
 */
ProjectArchived.prototype['original_project_id'] = undefined;

/**
 * @member {String} name
 */
ProjectArchived.prototype['name'] = undefined;

/**
 * @member {String} state
 * @default 'closed'
 */
ProjectArchived.prototype['state'] = 'closed';

/**
 * @member {Number} lr_complete_count
 */
ProjectArchived.prototype['lr_complete_count'] = undefined;

/**
 * @member {Date} created_at
 */
ProjectArchived.prototype['created_at'] = undefined;

/**
 * @member {Date} closed_at
 */
ProjectArchived.prototype['closed_at'] = undefined;

/**
 * @member {Number} client_id
 */
ProjectArchived.prototype['client_id'] = undefined;

/**
 * @member {module:model/UserWithoutClient} created_by
 */
ProjectArchived.prototype['created_by'] = undefined;






export default ProjectArchived;

