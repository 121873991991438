
import {gotoMenuItems, isProjectPage, isClientPage, isArchivedPage, isStatPage} from './utils'


// generate logic for Superuser routes
export const superuserRoutes = ({getClientByID, getProjectByID}, callback) => (pathComponents) => {
    const items = gotoMenuItems
    const id = pathComponents[1]
    
    // /project/{projectId}
    if (isProjectPage(pathComponents)) {
        getProjectByID(id)
        .then((project) => {
            const {client} = project
            items[1].link = '/dashboard'
            items[2].link = '/client/' + client
            items[3].link = '/client/' + client + '/archived'
            callback(items)
        }).catch(console.error)
        return
    }

    // /client/{clientId} && /client/{clientId}/stats && /client/{clientId}/archived
    if (isClientPage(pathComponents) && id) {
        getClientByID(id)
        .then(() => {
            // /client/{clientId}/*
            items[0].link = '/dashboard/stats'
            const archived = isArchivedPage(pathComponents)
            items[1].link = '/dashboard'
            items[2].link = archived ? '/client/' + id : false
            // /client/{clientId} && /client/{clientId}/archived/{archivedProjectId}
            const showArchived = (archived && pathComponents[3]) || (!archived && !pathComponents[2])
            items[3].link = showArchived ? '/client/' + id + '/archived' : false
            callback(items)
        })
        return
    }

    // /dashboard/stats
    if (isStatPage(pathComponents)) {
        items[0].link = false
        items[1].link = '/dashboard'
        items[2].link = false
        callback(items)
        return
    }

    // /dashboard 
    items[0].link = '/dashboard/stats'
    items[1].link = false
    items[2].link = false
    items[3].link = false
    callback(items)
}
